/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, InfoLabel } from 'bold-ui'
import { formatDate } from 'util/date/formatDate'

import { ResultadoExameComSolicitacaoModel, ResultadoExameEspecificoModel, ResultadoExameQueryModel } from '../model'
import { getResultadoExameEspecificoView, isExameEspecifico, showCodigoProcedimentoRef } from '../util-resultadosExames'
import { ResultadoExameListItemContainer } from './ResultadoExameListItemContainer'

interface ResultadoExameComSolicitacaoListItemProps {
  item: ResultadoExameComSolicitacaoModel & ResultadoExameEspecificoModel
  resultadoSolicitado: ResultadoExameQueryModel
}

export const ResultadoExameComSolicitacaoListItem = ({
  item,
  resultadoSolicitado,
}: ResultadoExameComSolicitacaoListItemProps) => {
  const { resultado, dataRealizado, dataResultado } = item

  const { exame } = resultadoSolicitado
  const isEspecifico = isExameEspecifico(exame)
  const ResultadoExameEspecificoView = getResultadoExameEspecificoView(exame)

  return (
    <ResultadoExameListItemContainer title={showCodigoProcedimentoRef(exame, true)}>
      <HFlow hSpacing={2}>
        <InfoLabel title='Data de realização'>{formatDate(dataRealizado)}</InfoLabel>

        <InfoLabel title='Data de resultado'>{formatDate(dataResultado)}</InfoLabel>

        {isEspecifico && <ResultadoExameEspecificoView value={resultado} exame={exame} />}
      </HFlow>

      <InfoLabel
        title={isEspecifico ? 'Descrição' : 'Resultado'}
        childStyles={css`
          word-break: break-word;
        `}
      >
        {isEspecifico ? item.descricao : resultado}
      </InfoLabel>
    </ResultadoExameListItemContainer>
  )
}
