import { FORM_ERROR } from 'final-form'
import { PosicaoCampoRelatorioGerencialEnum, UnidadePeriodoRelatorioGerencialEnum } from 'graphql/types.generated'
import {
  createValidator,
  empty,
  maxLength,
  maxWeeksPeriod,
  maxYearsPeriod,
  periodBeforeEqualToday,
  required,
  requiredPeriod,
} from 'util/validation'
import { isValid } from 'util/validation/Util'
import {
  RELATORIOS_GERENCIAIS_PERIOD_MAX_WEEKS,
  RELATORIOS_GERENCIAIS_PERIOD_MAX_YEARS,
} from 'view/relatorio/relatoriosgerenciais/common/model-relatorioGerencial'

import { ModeloPersonalizadoRelatorioGerencialFormModel } from './model-modeloPersonalizadoRelatorioGerencialForm'

export const modeloPersonalizadoRelatorioGerencialFormValidator = (hasFiltroCiapCid: boolean) =>
  createValidator<ModeloPersonalizadoRelatorioGerencialFormModel>(
    {
      nome: [required, maxLength(255)],
      aggregatorModal: [required],
      opcaoSelecionadaModal: [required],
      unidadePeriodoModal: [required],
      modulo: [required],
      periodo: [requiredPeriod, periodBeforeEqualToday],
    },
    (value, errors) => {
      const hasNoColunaOrLinha =
        value.campos?.isEmpty() ||
        !value.campos?.some(
          ({ posicao }) =>
            posicao === PosicaoCampoRelatorioGerencialEnum.COLUNA ||
            posicao === PosicaoCampoRelatorioGerencialEnum.LINHA
        )

      if (hasNoColunaOrLinha) {
        errors.campos = 'Obrigatório adicionar uma coluna/linha.'
      }
      if (isValid(errors.periodo)) {
        if (value.unidadePeriodoModal === UnidadePeriodoRelatorioGerencialEnum.SEMANA) {
          errors[FORM_ERROR] = maxWeeksPeriod(value.periodo, RELATORIOS_GERENCIAIS_PERIOD_MAX_WEEKS)
        } else {
          errors[FORM_ERROR] = maxYearsPeriod(value.periodo, RELATORIOS_GERENCIAIS_PERIOD_MAX_YEARS)
        }
      }
      if (!hasFiltroCiapCid) {
        errors.ciapsCids = empty(value.ciapsCids)
        errors.gruposCondicoes = empty(value.gruposCondicoes)
      }
      return errors
    }
  )
