import { Cell, Grid } from 'bold-ui'
import { DateField } from 'components/form'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { max } from 'lodash'
import React from 'react'
import { Fragment } from 'react'
import { isUndefinedOrNull } from 'util/checks'
import { toDate } from 'util/date/formatDate'
import { MetaPath } from 'util/metaPath'
import { isObjectDeepEmpty } from 'util/object'

import { ResultadoExameComSolicitacaoModel, ResultadoExameEspecificoModel, ResultadoExameQueryModel } from '../../model'
import { isExameEspecifico } from '../../util-resultadosExames'
import { CardResultadoExameContainer, CardResultadoExameStatus } from '../components/CardResultadoExameContainer'
import { ResultadoExameExpandableContainer } from '../components/ResultadoExameExpandableContainer'
import { ResultadoExameTextAreaField } from '../components/ResultadoExameTextAreaField'
import { ResultadoExameEspecificoField } from '../exames-especificos/ResultadoExameEspecificoField'

interface ResultadoExameComSolicitacaoFieldProps {
  name: MetaPath<ResultadoExameComSolicitacaoModel & ResultadoExameEspecificoModel>
  resultadoExame: ResultadoExameQueryModel
  value: ResultadoExameComSolicitacaoModel & ResultadoExameEspecificoModel
  status: CardResultadoExameStatus
  ignoringTouchedAndDirtyStatus: CardResultadoExameStatus
}

export const ResultadoExameComSolicitacaoField = (props: ResultadoExameComSolicitacaoFieldProps) => {
  const { name, resultadoExame, value, status, ignoringTouchedAndDirtyStatus } = props

  const {
    atendimentoProfissional: { iniciadoEm },
    cidadao: { dataNascimento },
  } = useAtendimentoContext()

  const isEspecifico = isExameEspecifico(resultadoExame.exame)

  const { dataRealizado, dataResultado, resultado } = value ?? {}
  const dataSolicitacao = toDate(resultadoExame.solicitacao.data)
  const dataNascimentoAsDate = toDate(dataNascimento)
  const dataRealizadoAsDate = toDate(dataRealizado)

  const disableOtherFields = isObjectDeepEmpty(resultado)

  return (
    <CardResultadoExameContainer
      exame={resultadoExame.exame}
      headerDates={{ dataRealizado, dataResultado }}
      status={status}
      ignoringTouchedAndDirtyStatus={ignoringTouchedAndDirtyStatus}
    >
      {({ expanded }) =>
        isEspecifico ? (
          <ResultadoExameEspecificoField
            name={name}
            exame={resultadoExame.exame}
            hasSolicitacao
            expanded={expanded}
            disableOtherFields={disableOtherFields}
            dataSolicitacao={dataSolicitacao}
            dataRealizado={dataRealizadoAsDate}
          />
        ) : (
          <Fragment>
            <ResultadoExameTextAreaField
              name={name.resultado}
              expanded={expanded}
              hasValue={!isUndefinedOrNull(resultado)}
            />

            <ResultadoExameExpandableContainer expanded={expanded}>
              <Grid gap={1.5}>
                <Cell size={5}>
                  <DateField
                    name={name.dataRealizado}
                    label='Realizado em'
                    minDate={max([dataNascimentoAsDate, dataSolicitacao])}
                    maxDate={iniciadoEm}
                    disabled={disableOtherFields}
                    required
                    inline
                  />
                </Cell>
                <Cell size={5}>
                  <DateField
                    name={name.dataResultado}
                    label='Resultado em'
                    minDate={max([dataNascimentoAsDate, dataRealizadoAsDate])}
                    maxDate={iniciadoEm}
                    disabled={disableOtherFields}
                    inline
                  />
                </Cell>
              </Grid>
            </ResultadoExameExpandableContainer>
          </Fragment>
        )
      }
    </CardResultadoExameContainer>
  )
}
