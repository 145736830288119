/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { focusBoxShadow, Theme, useTheme } from 'bold-ui'
import { ColorScale, gray, turquoise } from 'bold-ui/lib/styles/colors'
import { SituacaoAgendadoEnum } from 'graphql/types.generated'
import { useMemo } from 'react'
import { SituacaoAgendamentoBar } from 'view/agenda/components/SituacaoAgendamentoBar'
import { situacaoAgendamentoRecord } from 'view/agenda/model-agenda'

export interface ContainerAgendamentoConsultaProps {
  status: SituacaoAgendadoEnum
  isForaUbs: boolean
  children: React.ReactNode
}

export const ContainerAgendamentoConsulta = ({ status, isForaUbs, children }: ContainerAgendamentoConsultaProps) => {
  const theme = useTheme()
  const styles = useMemo(() => createStyles(theme, isForaUbs ? turquoise : situacaoAgendamentoRecord[status].cor), [
    isForaUbs,
    status,
    theme,
  ])

  return (
    <div css={styles.container}>
      <SituacaoAgendamentoBar situacao={status} isForaUbs={isForaUbs} />
      {children}
    </div>
  )
}

const createStyles = (theme: Theme, statusColor: ColorScale) => ({
  container: css`
    height: calc(100% - 0.25rem);
    display: flex;
    padding-right: 1rem;
    width: 100%;
    background-color: ${theme.pallete.surface.main};
    border: 1px solid ${gray.c60};
    border-radius: 2px;
    border-bottom: ${!!statusColor ? '2px' : 0} solid ${statusColor?.c50 ?? theme.pallete.surface.main};
    margin: 0;
    min-height: 3rem;
    box-shadow: ${theme.shadows.outer[10]};
    &:focus-within {
      outline: none;
      box-shadow: ${focusBoxShadow(theme)};
    }
  `,
})
