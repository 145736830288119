import { Button, Cell, Grid, HeadingSection, TextField as BoldTextField, VFlow } from 'bold-ui'
import { FormFooter, FormRenderProps, PasswordField } from 'components/form'
import { InfoNovaSenha } from 'components/InfoNovaSenha'
import React from 'react'
import { ProfissionalDadosFieldGroup } from 'view/profissional/components/ProfissionalDadosFieldGroup'

import { InstalacaoFormModel, meta } from './InstalacaoRootView'

interface CadastroInstaladorViewProps {
  formProps: FormRenderProps<InstalacaoFormModel>
  onVoltar(): void
  onAvancar(): void
}

export const CadastroInstaladorView = (props: CadastroInstaladorViewProps) => {
  const { formProps, onVoltar, onAvancar } = props

  return (
    <VFlow>
      <HeadingSection level={2} title='Dados pessoais'>
        <ProfissionalDadosFieldGroup name={meta.step2} formProps={formProps} />
      </HeadingSection>

      <HeadingSection level={2} title='Dados de autenticação'>
        <VFlow vSpacing={1}>
          <Grid>
            <Cell xs={12} sm={3}>
              <BoldTextField
                name='usuario'
                disabled={true}
                label='Usuário'
                value={formProps.values.step2 ? formProps.values.step2.cpf || '' : ''}
              />
            </Cell>

            <Cell size={9} />

            <Cell xs={12} sm={6}>
              <InfoNovaSenha />
            </Cell>

            <Cell sm={6} />

            <Cell xs={12} sm={3}>
              <PasswordField
                name={meta.step2.novaSenhaInput.novaSenha}
                label='Senha'
                placeholder='Senha'
                maxLength={20}
                clearable={false}
                required
              />
            </Cell>
            <Cell xs={12} sm={3}>
              <PasswordField
                name={meta.step2.novaSenhaInput.confirmacaoSenha}
                label='Confirmação de senha'
                placeholder='Senha'
                maxLength={20}
                clearable={false}
                required
              />
            </Cell>
          </Grid>
        </VFlow>
      </HeadingSection>

      <FormFooter style={{ marginBottom: '1rem' }}>
        <Button onClick={onVoltar}>Voltar</Button>
        <Button kind='primary' onClick={onAvancar}>
          Avançar
        </Button>
      </FormFooter>
    </VFlow>
  )
}
