/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, DataTable, Heading, HFlow, InfoLabel, Text, VFlow } from 'bold-ui'
import { LoadingIndicator } from 'components/loading'
import { useHistoricoAtendimentoOdontologicoDwQuery } from 'graphql/hooks.generated'
import { FatoAtendOdontoProced } from 'graphql/types.generated'
import { memo } from 'react'
import { stringGrouper } from 'util/string/string-grouper'
import { isEmpty } from 'util/validation/Util'
import {
  HistoricoDwPanelProps,
  HistoricoSectionStyles,
  historicoSubsectionStyles,
} from 'view/atendimentos/detail/historico/model/model-historico'
import {
  mountInfosAdministrativasAtendimentoCompartilhado,
  naoRegistradoNesseAtendimento,
} from 'view/atendimentos/detail/historico/utils/utils-historico'

import HistoricoSection from '../../../../HistoricoSection'
import HistoricoGapPanel from '../../HistoricoGapPanel'
import HistoricoNoDetailAvailable from '../../HistoricoNoDetailAvailable'

interface HistoricoAtendimentoOdontoDwProps extends HistoricoDwPanelProps {}

const colunasTabelaProcedimentos = [
  {
    name: 'quantidade',
    header: 'Quantidade',
    render: (item) => <Text fontWeight='bold'>{item.quantidadeProcedimentos}</Text>,
  },
  {
    name: 'procedimento',
    header: 'Procedimento',
    render: (item) =>
      `${item.dimensaoProcedimento.descricaoProcedimento} - ${item.dimensaoProcedimento.codigoProcedimento}`,
    style: css`
      width: 90%;
    `,
  },
]

function HistoricoAtendimentoOdontoDw({
  uuidRegistro,
  cpfOuCnsCidadao,
  idadeOrigemAtendimento,
}: HistoricoAtendimentoOdontoDwProps) {
  const {
    data: { historicoAtendimentoOdontoDw: historico },
    loading,
  } = useHistoricoAtendimentoOdontologicoDwQuery({
    variables: {
      input: {
        uuidRegistro,
        cpfOuCnsCidadao,
        isOrigemPec: false,
      },
    },
  })

  if (loading) return <LoadingIndicator />

  if (!historico) return <HistoricoNoDetailAvailable />

  const hasPlano = !isEmpty(historico.procedimentos) || !isEmpty(historico.fornecimento)
  const hasCiapCidAvaliados = !isEmpty(historico.cidsCiapsAvaliados)
  const hasAvaliacao = hasCiapCidAvaliados || !isEmpty(historico.vigilanciaSaudeBucal)
  const hasDesfecho = !isEmpty(historico.conduta) || !isEmpty(historico.encaminhamento)

  return (
    <HistoricoGapPanel
      idadeOrigemAtendimento={idadeOrigemAtendimento}
      informacoesAdministrativas={mountInfosAdministrativasAtendimentoCompartilhado(historico)}
      tipoParticipacaoCidadao={historico.dimTipoParticipacaoCidadao?.descricao}
    >
      <VFlow>
        {(historico.isPacienteNecessidadesEspeciais || historico.isGestante) && (
          <HFlow>
            {historico.isPacienteNecessidadesEspeciais && (
              <Alert type='info' inline>
                <Text color='primary' fontWeight='bold'>
                  Possui necessidades especiais
                </Text>
              </Alert>
            )}
            {historico.isGestante && (
              <Alert type='info' inline>
                <Text color='primary' fontWeight='bold'>
                  Está gestante
                </Text>
              </Alert>
            )}
          </HFlow>
        )}
        <HistoricoSection section='S'>
          <Text fontStyle='italic'>{naoRegistradoNesseAtendimento}</Text>
        </HistoricoSection>
        <HistoricoSection section='O'>
          <Text fontStyle='italic'>{naoRegistradoNesseAtendimento}</Text>
        </HistoricoSection>
        <HistoricoSection section='A'>
          {hasAvaliacao ? (
            <VFlow vSpacing={0.5}>
              {hasCiapCidAvaliados && (
                <DataTable
                  rows={historico.cidsCiapsAvaliados.sort((a, b) => a.localeCompare(b))}
                  columns={[
                    {
                      name: 'nome',
                      header: (
                        <Text
                          style={css`
                            color: ${HistoricoSectionStyles['A'].color};
                          `}
                        >
                          Problemas e/ou condições avaliados neste atendimento
                        </Text>
                      ),
                      render: (ciapCid: string) => <Text>{ciapCid}</Text>,
                    },
                  ]}
                />
              )}
              {!isEmpty(historico.vigilanciaSaudeBucal) && (
                <InfoLabel title='Vigilância em saúde bucal' titleStyles={historicoSubsectionStyles.avaliacao}>
                  <Text>{stringGrouper(...historico.vigilanciaSaudeBucal)}</Text>
                </InfoLabel>
              )}
            </VFlow>
          ) : (
            <Text fontStyle='italic'>{naoRegistradoNesseAtendimento}</Text>
          )}
        </HistoricoSection>
        <HistoricoSection section='P'>
          {hasPlano ? (
            <VFlow>
              {!isEmpty(historico.procedimentos) && (
                <VFlow vSpacing={0.5}>
                  <Heading level={5} style={historicoSubsectionStyles.plano}>
                    Procedimentos
                  </Heading>
                  <DataTable<FatoAtendOdontoProced>
                    rows={historico.procedimentos}
                    columns={colunasTabelaProcedimentos}
                  />
                </VFlow>
              )}
              {!isEmpty(historico.fornecimento) && (
                <InfoLabel title='Fornecimento' titleStyles={historicoSubsectionStyles.plano}>
                  <Text>{stringGrouper(...historico.fornecimento)}</Text>
                </InfoLabel>
              )}
            </VFlow>
          ) : (
            <Text fontStyle='italic'>{naoRegistradoNesseAtendimento}</Text>
          )}
        </HistoricoSection>
        <HistoricoSection section='D'>
          {hasDesfecho ? (
            <VFlow>
              {!isEmpty(historico.conduta) && (
                <InfoLabel title='Conduta' titleStyles={historicoSubsectionStyles.desfecho}>
                  <Text>{stringGrouper(...historico.conduta)}</Text>
                </InfoLabel>
              )}
              {!isEmpty(historico.encaminhamento) && (
                <InfoLabel title='Encaminhamento' titleStyles={historicoSubsectionStyles.desfecho}>
                  <Text>{stringGrouper(...historico.encaminhamento)}</Text>
                </InfoLabel>
              )}
            </VFlow>
          ) : (
            <Text fontStyle='italic'>{naoRegistradoNesseAtendimento}</Text>
          )}
        </HistoricoSection>
      </VFlow>
    </HistoricoGapPanel>
  )
}

export default memo(HistoricoAtendimentoOdontoDw)
