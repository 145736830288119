/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Icon, TableFooter, Theme, Tooltip, useTheme, VFlow } from 'bold-ui'
import { AccordionDataTable } from 'components/accordion/accordion-data-table/AccordionDataTable'
import { DateTime } from 'components/date'
import { CboSelectModel, ProfissionalSelectModel, UnidadeSaudeSelectModel } from 'components/form'
import { HLabel } from 'components/HLabel'
import { TableBox } from 'components/table'
import { usePagination } from 'components/table/usePagination'
import { startOfDay } from 'date-fns'
import { useCompartilhamentoCuidadoPlanoQuery } from 'graphql/hooks.generated'
import { ClassificacaoPrioridadeCuidadoEnum, CompartilhamentoCuidadoPlanoDto, DateRange } from 'graphql/types.generated'
import { LotacaoContextModel } from 'hooks/atendimento-context/model'
import { isDateInDateRange, useFilter } from 'hooks/filter/useFilter'
import { useState } from 'react'
import StatusSquare from 'view/atendimentos/detail/historico/components/StatusSquare'

import {
  cuidadoCompartilhadoClassificacaoPrioridadeRecord,
  CuidadoCompartilhadoPlanoModel,
  TipoCompartilhamentoCuidadoPlanoEnum,
} from '../../cuidado-compartilhado/model-cuidadocompartilhado'
import {
  CompartilhamentoCuidadoPlanoModel,
  CompartilhamentoCuidadoTableFilterModel,
  filterInitialValues,
} from '../model-compartilhamentocuidado'
import { getProfissionaisCompartilhamentoCuidado, joinCompartilhamentosCuidado } from '../util-compartilhamentocuidado'
import { CompartilhamentoCuidadoTableActions } from './CompartilhamentoCuidadoTableActions'
import { CompartilhamentoCuidadoTableFilter } from './CompartilhamentoCuidadoTableFilter'
import { CompartilhamentoCuidadoTablePanel } from './CompartilhamentoCuidadoTablePanel'

interface CompartilhamentoCuidadoTableProps {
  compartilhamentosAtendimentoAtual?: CuidadoCompartilhadoPlanoModel[]
  prontuarioId: ID
  iniciadoEm?: Date
  lotacaoAtual?: LotacaoContextModel
  viaFolhaRosto?: boolean
  onEdit?: (id: ID) => void
  onDelete?: (id: ID) => void
}

export const CompartilhamentoCuidadoTable = (props: CompartilhamentoCuidadoTableProps) => {
  const { compartilhamentosAtendimentoAtual, prontuarioId, iniciadoEm, viaFolhaRosto, onEdit, onDelete } = props
  const theme = useTheme()

  const [filter, setFilter] = useState<CompartilhamentoCuidadoTableFilterModel>(filterInitialValues)
  const isAllTiposCompartilhamentoSelected = filterInitialValues.tiposCompartilhamento.length === 2
  const isEncaminhamento =
    isAllTiposCompartilhamentoSelected || filter.tiposCompartilhamento.isEmpty()
      ? null
      : filter.tiposCompartilhamento.includes(TipoCompartilhamentoCuidadoPlanoEnum.GUIA_ENCAMINHAMENTO)
  const {
    data: { compartilhamentosCuidadoPlano },
    loading,
  } = useCompartilhamentoCuidadoPlanoQuery({
    variables: {
      input: {
        prontuarioId,
        profissionalExecutanteId: filter.profissionalExecutante?.id,
        profissionalSolicitanteId: filter.profissionalSolicitante?.id,
        unidadeSaudeId: filter.unidadeSaude?.id,
        cboId: filter.cbo?.id,
        dataInicio: filter.periodo?.startDate,
        dataFim: filter.periodo?.endDate,
        tipoPrioridades: filter.prioridades,
        isEncaminhamento: isEncaminhamento,
      },
    },
    fetchPolicy: 'no-cache',
  })

  const { profissionaisSolicitantes, profissionaisExecutantes } = getProfissionaisCompartilhamentoCuidado(
    compartilhamentosAtendimentoAtual,
    compartilhamentosCuidadoPlano as CompartilhamentoCuidadoPlanoDto[]
  )

  const filteredItems = useFilter<CuidadoCompartilhadoPlanoModel, CompartilhamentoCuidadoTableFilterModel>({
    items: compartilhamentosAtendimentoAtual || [],
    filter,
    filtersType: [],
    customFilters: [
      filterByPrioridade(filter?.prioridades),
      filterBySolicitante(filter?.profissionalSolicitante),
      filterByExecutante(filter?.profissionalExecutante),
      filterByUnidadeSaude(filter?.unidadeSaude),
      filterByPeriodo(filter?.periodo, iniciadoEm),
      filterByCbo(filter?.cbo),
      filterByTiposCompartilhamento(filter?.tiposCompartilhamento),
    ],
  })

  const joinedItems = joinCompartilhamentosCuidado(
    filteredItems,
    compartilhamentosCuidadoPlano as CompartilhamentoCuidadoPlanoDto[],
    iniciadoEm
  )

  const { paginatedItems, tableProps } = usePagination<CompartilhamentoCuidadoPlanoModel>({ items: joinedItems })

  return (
    <VFlow vSpacing={0}>
      <TableBox
        header={
          <CompartilhamentoCuidadoTableFilter
            onFilterChange={setFilter}
            filter={filter}
            profissionaisExecutantes={profissionaisExecutantes}
            profissionaisSolicitantes={profissionaisSolicitantes}
          />
        }
      >
        <AccordionDataTable
          rows={paginatedItems}
          columns={[
            {
              name: 'data',
              header: 'Data',
              render: (row: CompartilhamentoCuidadoPlanoModel) => renderData(row, theme),
              size: 2,
              style: styles.dataStyle,
            },
            {
              name: 'prioridade',
              header: 'Prioridade',
              render: renderPrioridade,
              size: 2,
              style: css`
                text-align: center;
              `,
            },
            {
              name: 'tipoCompartilhamento',
              header: 'Tipo de compartilhamento',
              render: renderTipoCompartilhamento,
              size: 7,
              style: css`
                flex-grow: 1;
              `,
            },
            {
              name: 'actions',
              size: 1,
              style: styles.actionsColumn,
              render: (row) => (
                <CompartilhamentoCuidadoTableActions
                  row={row}
                  viaFolhaRosto={viaFolhaRosto}
                  onEdit={onEdit}
                  onDelete={onDelete}
                />
              ),
            },
          ]}
          components={{ AccordionPanel: CompartilhamentoCuidadoTablePanel }}
          loading={loading}
        />
        <TableFooter {...tableProps} style={styles.tableFooter} />
      </TableBox>
    </VFlow>
  )
}

const renderData = (row: CompartilhamentoCuidadoPlanoModel, theme: Theme) => (
  <HFlow hSpacing={0.5} alignItems='center'>
    <DateTime format='DD/MM/YYYY' value={row.iniciadoEm} />

    {row.isRegistradoAgora && (
      <Tooltip text='Registrado agora'>
        <Icon icon='clockOutline' color={theme.pallete.primary.c40} size={1} />
      </Tooltip>
    )}
  </HFlow>
)

const renderTipoCompartilhamento = (row: CompartilhamentoCuidadoPlanoModel) =>
  !row.isEncaminhamento ? (
    <VFlow vSpacing={0}>
      <HLabel title='Cuidado compartilhado:'>
        {row.lotacaoExecutante?.profissional?.nome
          ? `${row.lotacaoExecutante.profissional.nome} - ${row.cbo.nome.capitalize()}`
          : row.cbo.nome.capitalize()}
      </HLabel>
      <HLabel title='Unidade de saúde:'>{row.unidadeSaude?.nome}</HLabel>
    </VFlow>
  ) : (
    <HLabel title='Guia de encaminhamento:'>{row.cbo.nome.capitalize()}</HLabel>
  )

const renderPrioridade = (row: CompartilhamentoCuidadoPlanoModel) => {
  const { label, mainColor } = cuidadoCompartilhadoClassificacaoPrioridadeRecord[row.prioridade]

  return (
    <HFlow justifyContent='center'>
      <StatusSquare color={mainColor} description={label} descriptionAsTooltip />
    </HFlow>
  )
}

const filterByPrioridade = (prioridades: readonly ClassificacaoPrioridadeCuidadoEnum[]) => (
  row: CuidadoCompartilhadoPlanoModel
) => {
  return prioridades?.length > 0 ? prioridades.includes(row.prioridade) : true
}

const filterBySolicitante = (solicitante: ProfissionalSelectModel) => (row: CuidadoCompartilhadoPlanoModel) => {
  return solicitante ? solicitante.id === row.lotacaoSolicitante.profissional.id : true
}

const filterByExecutante = (executante: ProfissionalSelectModel) => (row: CuidadoCompartilhadoPlanoModel) => {
  return executante ? executante.id === row.lotacao?.profissional?.id : true
}

const filterByUnidadeSaude = (unidadeSaude: UnidadeSaudeSelectModel) => (row: CuidadoCompartilhadoPlanoModel) => {
  return unidadeSaude ? unidadeSaude.id === row.unidadeSaude?.id : true
}

const filterByPeriodo = (periodo: DateRange, iniciadoEm: Date) => () => {
  return isDateInDateRange(startOfDay(iniciadoEm), periodo)
}

const filterByCbo = (cbo: CboSelectModel) => (row: CuidadoCompartilhadoPlanoModel) => {
  return cbo ? cbo.id === row.cbo.cbo.id : true
}

const filterByTiposCompartilhamento = (tipoCompartilhamento: TipoCompartilhamentoCuidadoPlanoEnum[]) => (
  row: CuidadoCompartilhadoPlanoModel
) => {
  return tipoCompartilhamento?.isNotEmpty() ? tipoCompartilhamento.includes(row.tipo) : true
}

const styles = {
  actionsColumn: css`
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    align-self: stretch;
    align-items: center;
    justify-content: flex-end;
  `,
  tableFooter: css`
    border-top-width: 0;
  `,
  dataStyle: css`
    width: 8rem;
    max-width: 8rem;
    min-width: 8rem;
  `,
}
