/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, Modal, ModalBody, ModalProps, VFlow } from 'bold-ui'
import { format } from 'date-fns'
import { TipoRegistroHistoricoClinico } from 'graphql/types.generated'
import HistoricoDetalhesPanel from 'view/atendimentos/detail/historico/components/accordion/detail/HistoricoDetalhesPanel'
import ProfissionaisHistoricoHeaderLabel from 'view/atendimentos/detail/historico/components/accordion/header/components/ProfissionaisHistoricoHeaderLabel'
import {
  HistoricoAtendimentoModel,
  HistoricoDetalhesModel,
  IdadeOrigemAtendimento,
} from 'view/atendimentos/detail/historico/model/model-historico'

interface DetalhesUltimosContatosModalProps extends ModalProps {
  historicoAtendimento: HistoricoDetalhesModel
  title: string
  titleColor: string
  dataNascimentoCidadao: LocalDate
}

export function DetalhesUltimosContatosModal(props: DetalhesUltimosContatosModalProps) {
  const { historicoAtendimento, dataNascimentoCidadao, title, titleColor, ...modalProps } = props

  const idadeOrigemAtendimento: IdadeOrigemAtendimento = {
    dataAtendimento: format(historicoAtendimento.dataAtendimento, 'yyyy-MM-dd'),
    dataNascimentoCidadao,
    origemAtendimento: historicoAtendimento.origemAtendimento,
  }

  return (
    <Modal {...modalProps}>
      <ModalBody>
        <VFlow vSpacing={0.5}>
          <Heading
            level={1}
            style={css`
              color: ${titleColor};
            `}
          >
            {title}
          </Heading>
          <VFlow vSpacing={0.5}>
            {historicoAtendimento.tipoApresentacao === TipoRegistroHistoricoClinico.CUIDADO_COMPARTILHADO && (
              <ProfissionaisHistoricoHeaderLabel
                historicoAtendimento={historicoAtendimento as HistoricoAtendimentoModel}
                isCuidadoCompartilhado={true}
              />
            )}
            <HistoricoDetalhesPanel
              historicoAtendimento={historicoAtendimento}
              idadeOrigemAtendimento={idadeOrigemAtendimento}
              dataNascimento={dataNascimentoCidadao}
            />
          </VFlow>
        </VFlow>
      </ModalBody>
    </Modal>
  )
}
