import { Heading, VFlow } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import React from 'react'
import Permissions from 'types/Permissions'

import EquipeEMultiVinculacoesTable from './equipe-emulti/EquipeEMultiVinculacoesTable'
import VinculacoesEstabelecimentosTable from './estabelecimento/list/VinculacoesEstabelecimentosTable'

export const SERVICOS_REFERENCIA_PATH = '/servicosreferencia'

export function ServicosReferenciaView() {
  return (
    <CheckPermission permission={Permissions.configuracaoMunicipal}>
      <VFlow vSpacing={2}>
        <VFlow vSpacing={0.5}>
          <Heading level={3}>Unidades de saúde</Heading>
          <VinculacoesEstabelecimentosTable />
        </VFlow>
        <VFlow vSpacing={0.5}>
          <Heading level={3}>Equipes eMulti/multiprofissionais</Heading>
          <EquipeEMultiVinculacoesTable />
        </VFlow>
      </VFlow>
    </CheckPermission>
  )
}
