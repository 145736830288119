import { AgeRangeType } from 'components/date/AgeRange'
import { GrupoAlvoBuscaAtivaVacinacaoEnum } from 'graphql/types.generated'
import { createValidator, ErrorObject, maxRange, minLength, minRange, range, required } from 'util/validation'

import { BuscaAtivaVacinacaoFilterPopperFormModel, BuscaAtivaVacinacaoFormModel } from './model'

const validationsByGrupoAlvo = {
  [GrupoAlvoBuscaAtivaVacinacaoEnum.ADOLESCENTES]: range(10, 19),
  [GrupoAlvoBuscaAtivaVacinacaoEnum.ADULTOS]: range(20, 59),
  [GrupoAlvoBuscaAtivaVacinacaoEnum.IDOSOS]: minRange(60),
}

const validationsGrupoAlvoCriancaByAgeRangeType = {
  [AgeRangeType.ANOS]: maxRange(9),
  // 12 meses * 9 anos + 11 meses
  [AgeRangeType.MESES]: maxRange(12 * 9 + 11),
  // 31 dias * 12 meses * 10 anos - 1 dia
  [AgeRangeType.DIAS]: maxRange(31 * 12 * 10 - 1),
}

const getFaixaEtariaValue = (faixaEtaria) => faixaEtaria?.secondValue ?? faixaEtaria?.firstValue

export const validator = createValidator<BuscaAtivaVacinacaoFormModel>(
  {
    equipe: required,
    grupoAlvo: required,
    status: required,
    tipoVisualizacao: required,
  },
  (values: BuscaAtivaVacinacaoFormModel, errors: ErrorObject<BuscaAtivaVacinacaoFormModel>) => {
    const { grupoAlvo, faixaEtaria } = values
    const faixaEtariaToValidate = getFaixaEtariaValue(faixaEtaria)
    const firstFaixaEtariaValue = faixaEtaria?.firstValue
    const secondFaixaEtariaValue = faixaEtaria?.secondValue

    if (grupoAlvo?.enum === GrupoAlvoBuscaAtivaVacinacaoEnum.CRIANCAS && !!faixaEtaria?.periodo) {
      errors.faixaEtaria = validationsGrupoAlvoCriancaByAgeRangeType[faixaEtaria?.periodo](faixaEtariaToValidate)
    } else if (grupoAlvo?.enum in validationsByGrupoAlvo) {
      errors.faixaEtaria =
        validationsByGrupoAlvo[grupoAlvo.enum](firstFaixaEtariaValue) ||
        validationsByGrupoAlvo[grupoAlvo.enum](secondFaixaEtariaValue)
    }

    return errors
  }
)

export const validatorFiltrosAvancados = createValidator<BuscaAtivaVacinacaoFilterPopperFormModel>({
  bairro: minLength(4, true),
})
