import { Text, VFlow } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import React from 'react'
import { isUndefinedOrNull } from 'util/checks'
import { HistoricoAtendimentoModel } from 'view/atendimentos/detail/historico/model/model-historico'
import {
  lotacaoCuidadoCompartilhadoDwFormatter,
  lotacaoCuidadoCompartilhadoPecFormatter,
} from 'view/cuidados-compartilhados/util-cuidadoCompartilhado'

interface ProfissionaisHistoricoHeaderLabelProps {
  historicoAtendimento: HistoricoAtendimentoModel
  isCuidadoCompartilhado: boolean
}

export default function ProfissionaisHistoricoHeaderLabel(props: ProfissionaisHistoricoHeaderLabelProps) {
  const { historicoAtendimento, isCuidadoCompartilhado } = props
  const isFromDw = !isUndefinedOrNull(historicoAtendimento.codigoUnicoRegistro)

  if (isCuidadoCompartilhado) {
    return (
      <VFlow vSpacing={0}>
        <HLabel title='Solicitante:'>
          {isFromDw
            ? lotacaoCuidadoCompartilhadoDwFormatter(
                historicoAtendimento.nomeSolicitante,
                historicoAtendimento.cboSolicitante,
                historicoAtendimento.ineSolicitante,
                historicoAtendimento.siglaEquipeSolicitante,
                historicoAtendimento.nomeUbsSolicitante
              )
            : lotacaoCuidadoCompartilhadoPecFormatter(
                historicoAtendimento.cuidadoCompartilhado.lotacaoSolicitante,
                historicoAtendimento.cuidadoCompartilhado.lotacaoSolicitante.cbo.nome,
                historicoAtendimento.cuidadoCompartilhado.lotacaoSolicitante.unidadeSaude.nome
              )}
        </HLabel>
        <HLabel title='Executante:'>
          {isFromDw
            ? lotacaoCuidadoCompartilhadoDwFormatter(
                historicoAtendimento.nomeExecutante,
                historicoAtendimento.cboExecutante,
                historicoAtendimento.ineExecutante,
                historicoAtendimento.siglaEquipeExecutante,
                historicoAtendimento.nomeUbsExecutante
              )
            : lotacaoCuidadoCompartilhadoPecFormatter(
                historicoAtendimento.cuidadoCompartilhadoEvolucao.lotacaoExecutante,
                historicoAtendimento.cuidadoCompartilhadoEvolucao.cboExecutante.nome,
                historicoAtendimento.cuidadoCompartilhadoEvolucao.unidadeSaudeExecutante.nome
              )}
        </HLabel>
      </VFlow>
    )
  }

  return (
    <Text>
      {`${
        historicoAtendimento.profissional?.nome ?? `CNS: ${historicoAtendimento.cnsProfissional}`
      } - ${historicoAtendimento.cbo.nome.capitalize()} - ${historicoAtendimento.cbo.cbo2002} ${
        historicoAtendimento.equipe
          ? `| Equipe ${historicoAtendimento.equipe?.ine} - ${historicoAtendimento.equipe?.nome}`
          : ''
      }`}
    </Text>
  )
}
