import { AcessoCbo, EstabelecimentoSaudeEnum, SessaoQuery, TipoEstabelecimentoEnum } from 'graphql/types.generated'
import { Permission } from 'types/Permissions'

export type AcessoProfissionalType = SessaoQuery['sessao']['acesso']

export interface CboAndPermissionAuthorization {
  hasAnyCbo: AcessoCbo[][]
  hasAnyPermission: Permission[]
}

export interface PermissionAuthorization {
  permission: Authorization
}

export interface TipoEstabelecimentoAuthorization {
  tiposEstabelecimento?: TipoEstabelecimentoEnum[]
}

export type AllAuthorizations = (PermissionAuthorization | CboAndPermissionAuthorization) &
  TipoEstabelecimentoAuthorization
export type Authorization = Permission | Permission[] | AcessoCbo[] | EstabelecimentoSaudeEnum[]

export const isCboAndPermissionAuthorization = (
  x: CboAndPermissionAuthorization | PermissionAuthorization | TipoEstabelecimentoAuthorization
): x is CboAndPermissionAuthorization => !!(x as CboAndPermissionAuthorization).hasAnyCbo

export const getAuthorizationProps = (item: AllAuthorizations): AllAuthorizations => {
  const cboOrPermissionAuthorizations = isCboAndPermissionAuthorization(item)
    ? {
        hasAnyCbo: item.hasAnyCbo,
        hasAnyPermission: item.hasAnyPermission,
      }
    : {
        permission: item.permission,
      }

  return {
    ...cboOrPermissionAuthorizations,
    tiposEstabelecimento: item.tiposEstabelecimento,
  }
}

export interface EstabelecimentoCodigosMs {
  tipoCoMs: number
  subtipoCoMs: string
}

export const estabelecimentoRecord: Record<EstabelecimentoSaudeEnum, EstabelecimentoCodigosMs> = {
  CENTRO_DE_ESPECIALIDADES_ODONTOLOGICAS_CEO_I: {
    tipoCoMs: 36,
    subtipoCoMs: '006',
  },
  CENTRO_DE_ESPECIALIDADES_ODONTOLOGICAS_CEO_II: {
    tipoCoMs: 36,
    subtipoCoMs: '007',
  },
  CENTRO_DE_ESPECIALIDADES_ODONTOLOGICAS_CEO_III: {
    tipoCoMs: 36,
    subtipoCoMs: '008',
  },
  PRONTO_ATENDIMENTO_GERAL: {
    tipoCoMs: 73,
    subtipoCoMs: '001',
  },
  PRONTO_ATENDIMENTO_ESPECIALIZADO: {
    tipoCoMs: 73,
    subtipoCoMs: '002',
  },
  UPA: {
    tipoCoMs: 73,
    subtipoCoMs: '003',
  },
  POLICLINICA: {
    tipoCoMs: 4,
    subtipoCoMs: null,
  },
  CENTRO_DE_ATENCAO_PSICOSSOCIAL: {
    tipoCoMs: 70,
    subtipoCoMs: null,
  },
  TELESSAUDE: {
    tipoCoMs: 75,
    subtipoCoMs: null,
  },
  UNIDADE_DE_ATENCAO_EM_REGIME_RESIDENCIAL: {
    tipoCoMs: 78,
    subtipoCoMs: null,
  },
  HOSPITAL_GERAL: {
    tipoCoMs: 5,
    subtipoCoMs: null,
  },
  MEDIA_COMPLEXIDADE: {
    tipoCoMs: 36,
    subtipoCoMs: null,
  },
  UNIDADE_DE_APOIO_DIAGNOSE_E_TERAPIA_SADT_ISOLADO: {
    tipoCoMs: 39,
    subtipoCoMs: null,
  },
  FARMACIA: {
    tipoCoMs: 43,
    subtipoCoMs: null,
  },
  CENTRO_DE_PARTO_NORMAL_ISOLADO: {
    tipoCoMs: 61,
    subtipoCoMs: null,
  },
  HOSPITAL_DIA_ISOLADO: {
    tipoCoMs: 62,
    subtipoCoMs: null,
  },
  CENTRAL_DE_REGULACAO_DE_SERVICOS_DE_SAUDE: {
    tipoCoMs: 64,
    subtipoCoMs: null,
  },
  CENTRAL_DE_GESTAO_EM_SAUDE: {
    tipoCoMs: 68,
    subtipoCoMs: null,
  },
  PRONTO_ATENDIMENTO: {
    tipoCoMs: 73,
    subtipoCoMs: null,
  },
  CENTRAL_DE_REGULACAO_MEDICA_DAS_URGENCIAS: {
    tipoCoMs: 76,
    subtipoCoMs: null,
  },
  SERVICO_DE_ATENCAO_DOMICILIAR_ISOLADO_HOME_CARE: {
    tipoCoMs: 77,
    subtipoCoMs: null,
  },
  CENTRAL_DE_REGULACAO_DO_ACESSO: {
    tipoCoMs: 81,
    subtipoCoMs: null,
  },
  CENTRAL_DE_ABASTECIMENTO: {
    tipoCoMs: 85,
    subtipoCoMs: null,
  },
}

export const hasPermissionByTipoEstabelecimento = (props): props is TipoEstabelecimentoAuthorization =>
  !!props.tiposEstabelecimento
