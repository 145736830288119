import { SideMenu } from 'components/side-menu-navegation/SideMenu'
import { SideMenuAccordionItem } from 'components/side-menu-navegation/SideMenuAccordionItem'
import { SideMenuSublink } from 'components/side-menu-navegation/SideMenuSublink'
import { useFlags } from 'config/useFlagsContext'
import React from 'react'
import { Accordion } from 'react-accessible-accordion'

import useNewsItems, { NewsItem, NewsVersion } from '../hooks/useNewsItems'
import { NewsSideMenuHeader } from './NewsSideMenuHeader'

const ALTURA_CABECALHO_TO_MENU = 125
const ALTURA_CABECALHO = 340
const MAX_WIDTH = 350

interface NewsSideMenuProps {
  currentVersion: string
  selectedVersion: string
  setSelectedSession: (version: NewsVersion) => void
}

export const NewsSideMenu = (props: NewsSideMenuProps) => {
  const { currentVersion, selectedVersion, setSelectedSession } = props
  const items = useNewsItems()
  const {
    TERRITORIO_ENABLED,
    VISUALIZACAO_TERRITORIO_ENABLED,
    HISTORICO_CUIDADO_COMPARTILHADO_ENABLED,
    PILOTO_VIDEOCHAMADA_2_ENABLED,
    PILOTO_VIDEOCHAMADA_3_ENABLED,
    MODELOS_PERSONALIZADOS_ENABLED,
  } = useFlags()

  // Remover a constante da linha de baixo quando for remover a flag 'TERRITORIO_ENABLED'
  const flaggedHashTerritorio = !TERRITORIO_ENABLED && '#reterritorializacao-5-1'
  // Remover a constante da linha de baixo quando for remover a flag 'VISUALIZACAO_TERRITORIO_ENABLED'
  const flaggedHashVisualizacaoTerritorio = !VISUALIZACAO_TERRITORIO_ENABLED && '#acompanhamento-territorio-5-2'
  // Remover a constante da linha de baixo quando for remover a flag 'HISTORICO_CUIDADO_COMPARTILHADO_ENABLED'
  const flaggedHashHistoricoCuidadoCompartilhado =
    !HISTORICO_CUIDADO_COMPARTILHADO_ENABLED && '#historico-cuidado-compartilhado-5-2'
  // Remover a constante da linha de baixo quando for remover as flags 'PILOTO_VIDEOCHAMADA_2_ENABLED' e 'PILOTO_VIDEOCHAMADA_3_ENABLED'
  const flaggedHashAgendamentoEntreProfissionais =
    !PILOTO_VIDEOCHAMADA_2_ENABLED && !PILOTO_VIDEOCHAMADA_3_ENABLED && '#agendamento-entre-profissionais-5-2'
  // Remover a constante da linha de baixo quando for remover as flags 'MODELOS_PERSONALIZADOS_ENABLED'
  const flaggedHashModelosPersonalizadosRelatoriosGerenciais =
    !MODELOS_PERSONALIZADOS_ENABLED && '#modelos-personalizados-relatorios-gerenciais-5-2'

  const flaggedHashList = [
    flaggedHashTerritorio,
    flaggedHashVisualizacaoTerritorio,
    flaggedHashHistoricoCuidadoCompartilhado,
    flaggedHashAgendamentoEntreProfissionais,
    flaggedHashModelosPersonalizadosRelatoriosGerenciais,
  ]

  const handleClick = (item: NewsItem) => {
    setSelectedSession(item.version)
    window.scrollTo({ behavior: 'smooth', top: 0 })
  }

  return (
    <SideMenu alturaCabecalho={ALTURA_CABECALHO} alturaCabecalhoToMenu={ALTURA_CABECALHO_TO_MENU} maxWidth={MAX_WIDTH}>
      <NewsSideMenuHeader />
      <Accordion preExpanded={[currentVersion]}>
        {items.map((item, index) => {
          return (
            <SideMenuAccordionItem
              title={item.title}
              index={index}
              totalItems={items.length}
              isMenuActive={selectedVersion === item.version}
              onClickAccordionButton={() => handleClick(item)}
              firstItemTagContent='Versão atual'
              hasHeader
              key={item.title}
            >
              {item.children
                .filter((child) => !flaggedHashList.includes(child.to))
                .map((subItem) => {
                  return <SideMenuSublink to={subItem.to} key={subItem.title} title={subItem.title} />
                })}
            </SideMenuAccordionItem>
          )
        })}
      </Accordion>
    </SideMenu>
  )
}
