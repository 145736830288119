/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, InfoLabel, Text, VFlow } from 'bold-ui'
import { pink } from 'bold-ui/lib/styles/colors'
import { Panel } from 'components/Panel'
import { ReactComponent as Icon } from 'images/historico/icone-escuta-inicial.svg'
import StatusSquare from 'view/atendimentos/detail/historico/components/StatusSquare'
import {
  ClassificacaoPrioridadeCuidadoRecord,
  CuidadoCompartilhadoModel,
} from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'
import { formatCiapCid } from 'view/cuidados-compartilhados/util-cuidadoCompartilhado'

import { DateTimeInfoCuidadoCompartilhado } from './DateTimeInfoCuidadoCompartilhado'

interface CuidadoCompartilhadoPanelProps {
  cuidadoCompartilhado: CuidadoCompartilhadoModel
}

export const CuidadoCompartilhadoPanel = (props: CuidadoCompartilhadoPanelProps) => {
  const { cuidadoCompartilhado } = props

  const prioridade = ClassificacaoPrioridadeCuidadoRecord[cuidadoCompartilhado.classificacaoPrioridadeAtual]

  return (
    <Panel title='Cuidado compartilhado' icon={<Icon css={styles.icon} />} color={pink.c40}>
      <VFlow vSpacing={0.5}>
        <InfoLabel title='Problemas e condições'>
          <Text>{formatCiapCid(cuidadoCompartilhado.ciap, cuidadoCompartilhado.cid10)}</Text>
        </InfoLabel>
        <InfoLabel title='Discussão do caso clínico'>
          <Text dangerouslySetInnerHTML={{ __html: cuidadoCompartilhado.discussao }} style={styles.richText} />
        </InfoLabel>
        <HFlow hSpacing={2}>
          <InfoLabel title='Prioridade'>
            <StatusSquare color={prioridade.cor} description={prioridade.descricao} />
          </InfoLabel>
          <DateTimeInfoCuidadoCompartilhado cuidadoCompartilhado={cuidadoCompartilhado} />
        </HFlow>
        <InfoLabel title='Solicitante'>
          {cuidadoCompartilhado.lotacaoSolicitante.profissional.nome} |{' '}
          {cuidadoCompartilhado.lotacaoSolicitante.equipe &&
            `${cuidadoCompartilhado.lotacaoSolicitante.equipe.nome.titleCase()} | `}
          {cuidadoCompartilhado.lotacaoSolicitante.unidadeSaude.nome}
        </InfoLabel>
        <InfoLabel title='Executante'>
          {cuidadoCompartilhado.lotacaoExecutanteAtual &&
            `${cuidadoCompartilhado.lotacaoExecutanteAtual.profissional.nome} | `}
          {cuidadoCompartilhado.cboExecutanteAtual.nome.titleCase()} |{' '}
          {cuidadoCompartilhado.lotacaoExecutanteAtual?.equipe &&
            `${cuidadoCompartilhado.lotacaoExecutanteAtual.equipe.nome.titleCase()} | `}
          {cuidadoCompartilhado.unidadeSaudeExecutanteAtual.nome}
        </InfoLabel>
      </VFlow>
    </Panel>
  )
}

const styles = {
  icon: css`
    height: 1.5rem;
    width: 1.5rem;
  `,
  richText: css`
    word-break: break-all;
  `,
}
