import { VFlow } from 'bold-ui'
import { ErrorField } from 'components/form'
import React from 'react'
import { MetaPath } from 'util/metaPath'
import {
  CuidadoCompartilhadoModel,
  CuidadoCompartilhadoVideochamadaState,
  DiscussaoCasoSolicitanteFieldsModel,
} from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'

import { AgendarConsultaCuidadoCompartilhadoField } from '../components/discussao-caso-fields/AgendarConsultaCuidadoCompartilhadoField'
import { PerguntaCuidadoCompartilhadoField } from '../components/discussao-caso-fields/PerguntaCuidadoCompartilhadoField'
import { TrocaExecutanteCuidadoCompartilhadoField } from '../components/discussao-caso-fields/TrocaExecutanteCuidadoCompartilhadoField'

interface DiscussaoCasoSolicitanteFieldsProps {
  path: MetaPath<DiscussaoCasoSolicitanteFieldsModel>
  cuidadoCompartilhado: CuidadoCompartilhadoModel
  videochamadaUuidState: CuidadoCompartilhadoVideochamadaState
}

export const DiscussaoCasoSolicitanteFields = (props: DiscussaoCasoSolicitanteFieldsProps) => {
  const { path, cuidadoCompartilhado, videochamadaUuidState } = props

  return (
    <VFlow vSpacing={0.5}>
      <PerguntaCuidadoCompartilhadoField path={path} />

      <TrocaExecutanteCuidadoCompartilhadoField
        path={path}
        lotacaoSolicitante={cuidadoCompartilhado.lotacaoSolicitante}
        profissionalExecutanteId={cuidadoCompartilhado.lotacaoExecutanteAtual.profissional.id}
      />

      <AgendarConsultaCuidadoCompartilhadoField
        path={path}
        cuidadoCompartilhado={cuidadoCompartilhado}
        videochamadaUuidState={videochamadaUuidState}
      />

      <ErrorField name={path} ignoreObjectError />
    </VFlow>
  )
}
