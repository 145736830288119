/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, HeadingSection, HFlow, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { Form, FormFooter, FormRenderProps, PasswordField, SubmitButton } from 'components/form'
import { NovaSenhaModel } from 'components/form/alterar-senha/model'
import { novaSenhaInputValidator } from 'components/form/alterar-senha/validator'
import { InfoNovaSenha } from 'components/InfoNovaSenha'
import { useAlteraAdminMutation, useSaveAdminProfissionalMutation } from 'graphql/hooks.generated'
import { Fragment, useMemo } from 'react'
import { useHistory } from 'react-router'
import { metaPath } from 'util/metaPath'
import { createValidator } from 'util/validation'
import { ProfissionalDadosFieldGroup } from 'view/profissional/components/ProfissionalDadosFieldGroup'
import ProfissionalFormModel from 'view/profissional/components/ProfissionalFormModel'
import convertModelToInput from 'view/profissional/converter-profissional'
import { createDadosValidator } from 'view/profissional/validator-profissional'

const validate = createValidator<ResetInstaladorDadosFormModel>({
  profissional: createDadosValidator(false),
  novaSenhaInput: novaSenhaInputValidator,
})

export interface ResetInstaladorDadosFormModel {
  profissional: ProfissionalFormModel
  novaSenhaInput: NovaSenhaModel
}

const meta = metaPath<ResetInstaladorDadosFormModel>()

interface ResetNovoProfissionalProps {
  contraChave: string
  cpf: string
}

export function ResetInstaladorNovoProfissionalComponent({ cpf, contraChave }: ResetNovoProfissionalProps) {
  const [saveProfissionalMutation] = useSaveAdminProfissionalMutation()
  const [alteraAdminMutation] = useAlteraAdminMutation()
  const history = useHistory()
  const alert = useAlert()

  const onCancelarClicked = () => history.push('/')

  const onVoltarClicked = () => history.push('/reset/chave/0')

  const handleSubmit = async ({ profissional, novaSenhaInput }: ResetInstaladorDadosFormModel) => {
    const input = convertModelToInput(profissional)

    await saveProfissionalMutation({
      variables: { input: { profissional: input, contraChave, novaSenha: novaSenhaInput.novaSenha } },
    })

    await alteraAdminMutation({ variables: { input: { cpf, contraChave } } })

    alert('success', 'Administrador da instalação salvo com sucesso.')
    history.push('/')
  }

  const initialValues = useMemo(
    () => ({
      profissional: { cpf: cpf, dataNascimento: null, nome: null },
      novaSenhaInput: undefined,
    }),
    [cpf]
  )

  const renderForm = (formProps: FormRenderProps<ResetInstaladorDadosFormModel>) => {
    return (
      <Fragment>
        <VFlow vSpacing={2}>
          <HeadingSection level={2} title='Dados pessoais'>
            <ProfissionalDadosFieldGroup name={meta.profissional} formProps={formProps} edit />
          </HeadingSection>

          <HeadingSection level={2} title='Dados de autenticação'>
            <Grid>
              <Cell xs={12} sm={6}>
                <InfoNovaSenha />
              </Cell>

              <Cell sm={6} />

              <Cell xs={12} sm={3}>
                <PasswordField
                  name={meta.novaSenhaInput.novaSenha}
                  label='Senha'
                  placeholder='Senha'
                  maxLength={20}
                  clearable={false}
                  required
                />
              </Cell>
              <Cell xs={12} sm={3}>
                <PasswordField
                  name={meta.novaSenhaInput.confirmacaoSenha}
                  label='Confirmação de senha'
                  placeholder='Senha'
                  maxLength={20}
                  clearable={false}
                  required
                />
              </Cell>
            </Grid>
          </HeadingSection>

          <FormFooter
            style={css`
              display: flex;
              justify-content: space-between;
            `}
          >
            <Button onClick={onVoltarClicked} data-cy='FormFooter.voltar'>
              Voltar
            </Button>

            <HFlow>
              <Button onClick={onCancelarClicked} data-cy='FormFooter.cancelar'>
                Cancelar
              </Button>
              <SubmitButton handleSubmit={formProps.handleSubmit} data-cy='FormFooter.salvar'>
                Salvar
              </SubmitButton>
            </HFlow>
          </FormFooter>
        </VFlow>
      </Fragment>
    )
  }

  return (
    <Form<ResetInstaladorDadosFormModel>
      render={renderForm}
      validate={validate}
      validateOnBlur={false}
      onSubmit={handleSubmit}
      initialValues={initialValues}
    />
  )
}
