/** @jsx jsx */
import { jsx } from '@emotion/core'
import { isEmpty } from 'bold-ui'
import { FormProps } from 'components/form'
import { useProcedimentoSelectFieldQuery } from 'graphql/hooks.generated'
import { GrupoProcedimentoEnum } from 'graphql/types.generated'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { partition } from 'lodash'
import { useParams } from 'react-router'
import { isUndefinedOrNull } from 'util/checks'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { SolicitacaoExameQueryModel } from '../../objetivo/resultados-exames/model'
import { showCodigoProcedimento } from '../../objetivo/resultados-exames/util-resultadosExames'
import { SolicitacaoExameModel } from './model'
import SolicitarExamesFormModal, { SolicitarExamesUrlParams } from './SolicitarExamesFormModal'

interface SolicitarExamesReplicarModalProps extends Omit<FormProps<SolicitacaoExameModel>, 'initialValues' | 'render'> {
  cidadao: CidadaoAtendimento
  existentes: SolicitacaoExameQueryModel[]
  onClose(): void
}

export default function SolicitarExamesReplicarModal(props: SolicitarExamesReplicarModalProps) {
  const { cidadao, onSubmit, onClose, existentes } = props

  const { id } = useParams<SolicitarExamesUrlParams>()
  const { atendimentoProfissional } = useAtendimentoContext()

  const solicitacao = existentes?.find((item) => item.id === id)
  const foundSolicitacaoExistente = !isUndefinedOrNull(solicitacao)
  const examesIds = solicitacao?.exames?.flatMap((resultado) => resultado.exame.id) ?? []

  const { data, loading } = useProcedimentoSelectFieldQuery({
    skip: !foundSolicitacaoExistente,
    variables: {
      input: {
        ids: examesIds,
        filtro: {
          tipoExame: solicitacao?.tipoExame,
          gruposProcedimento: [GrupoProcedimentoEnum.FINALIDADE_DIAGNOSTICA],
          filtroPadrao: true,
          sexo: isEmpty(cidadao?.identidadeGeneroDbEnum) ? cidadao?.sexo : undefined,
          dataNascimento: cidadao?.dataNascimento,
          dataAtendimento: atendimentoProfissional.iniciadoEm,
        },
        pageParams: {
          fetchPageInfo: false,
          size: examesIds.length,
        },
      },
    },
  })

  const procedimentosValidosIds = data?.procedimentos?.content
    ?.filter((item) => examesIds?.includes(item.id))
    ?.flatMap((item) => item.id)

  const [examesValidos, examesInvalidos] = partition(solicitacao?.exames, (item) =>
    procedimentosValidosIds?.includes(item.exame.id)
  )

  const alertMessage = getExamesInvalidosMessage(examesInvalidos)

  const initialValues = {
    tipoExame: solicitacao?.tipoExame,
    examesSolicitados: examesValidos.map((item) => ({
      _id: item.exame.id,
      exame: item.exame,
    })),
  } as SolicitacaoExameModel

  return (
    foundSolicitacaoExistente &&
    !loading && (
      <SolicitarExamesFormModal
        cidadao={cidadao}
        editing={false}
        initialValues={initialValues}
        alertMessage={alertMessage}
        onSubmit={onSubmit}
        onClose={onClose}
      />
    )
  )
}

const getExamesInvalidosMessage = (examesInvalidos: SolicitacaoExameQueryModel['exames']) => {
  if (isEmpty(examesInvalidos)) return null

  return (examesInvalidos.length > 1 ? 'Os exames' : 'O exame')
    .concat(
      examesInvalidos.map((item) => ` ${item.exame.descricao} - ${showCodigoProcedimento(item.exame.codigo)}`).join()
    )
    .concat(examesInvalidos.length > 1 ? ' não serão replicados.' : ' não será replicado.')
}
