import { Button, Heading, HFlow, Icon, Modal, ModalBody, ModalFooter, Tooltip, VFlow } from 'bold-ui'
import { alert } from 'components/alert'
import { Form, SubmitButton, TextField } from 'components/form'
import { FormApi } from 'final-form'
import { useAlterarTipoServicoMutation } from 'graphql/hooks.generated'
import { TipoServicoUpdateInput } from 'graphql/types.generated'
import React, { useState } from 'react'
import { FormRenderProps } from 'react-final-form'
import { createValidator, maxLength, minLength, required } from 'util/validation'

import { TipoServicoItem } from '../TipoServicoTable'

export interface TipoServicoEditComponentProps {
  tipoServico: TipoServicoItem
  onEdit(): void
}

const validate = createValidator<TipoServicoUpdateInput>({
  nome: [required, minLength(3), maxLength(30)],
})

export function TipoServicoEditComponent(props: TipoServicoEditComponentProps) {
  const { tipoServico, onEdit } = props

  const [open, setOpen] = useState(false)

  const [alterarTipoServico] = useAlterarTipoServicoMutation()

  const renderForm = (formProps: FormRenderProps) => {
    return (
      <Modal open={open} onClose={() => setOpen(false)} size='small'>
        <ModalBody>
          <VFlow vSpacing={2}>
            <Heading level={3}>Editar tipo de serviço</Heading>
            <TextField name='nome' label='Nome do tipo de serviço' required />
          </VFlow>
        </ModalBody>
        <ModalFooter>
          <HFlow justifyContent='flex-end'>
            <Button onClick={handleCancel(formProps)}>Cancelar</Button>
            <SubmitButton kind='primary' handleSubmit={formProps.handleSubmit}>
              Salvar
            </SubmitButton>
          </HFlow>
        </ModalFooter>
      </Modal>
    )
  }

  const handleCancel = (formProps: FormRenderProps) => () => {
    setTimeout(formProps.form.reset)
    setOpen(false)
  }

  const handleSubmit = (values: TipoServicoUpdateInput, formApi: FormApi) => {
    return alterarTipoServico({ variables: { input: values } }).then((success) => {
      alert('success', `Tipo de serviço ${values.nome} salvo com sucesso.`)
      setTimeout(formApi.reset)
      setOpen(false)
      onEdit()
    })
  }

  return (
    <>
      <Tooltip text='Editar'>
        <Button size='small' skin='ghost' onClick={() => setOpen(true)}>
          <Icon icon='penOutline' />
        </Button>
      </Tooltip>
      <Form
        render={renderForm}
        onSubmit={handleSubmit}
        initialValues={{ id: tipoServico.id, nome: tipoServico.nome }}
        validate={validate}
      />
    </>
  )
}
