import { FlagsContextModel } from 'config/useFlagsContext'
import {
  AtendimentoIndividualInput,
  LembreteSaveInput,
  MedicamentoInterromperInput,
  MedicoesInput,
  TipoAtendimentoProfissional,
} from 'graphql/types.generated'
import { parseNumber } from 'util/number'

import { convertRegistroVacinacaoToInput } from '../atendimento-vacinacao/registros/converter'
import { LembreteFormModel } from '../detail/components/modals/lembretes/components/LembreteForm'
import { MedicamentoInterromperFormModel } from '../detail/components/modals/medicamentos/InterromperTratamentoModal'
import { MedicaoAnteriorFormModel } from '../detail/components/modals/medicoes/MedicaoAnteriorForm'
import { convertAntecedentesFormToInput } from '../detail/soap/antecedentes/converter'
import { convertLPCProblemaCondicaoFormToInput } from '../detail/soap/avaliacao/components/problemas-condicoes/converter-problemasCondicoes'
import { convertAvaliacaoFormToInput } from '../detail/soap/avaliacao/converter'
import { convertFinalizacaoFormToInput } from '../detail/soap/finalizacao'
import { convertObjetivoFormToInput } from '../detail/soap/objetivo'
import { convertPlanoFormToInput } from '../detail/soap/plano/converter'
import { convertPreNatalFormToInput } from '../detail/soap/pre-natal/converter-preNatal'
import { convertSubjetivoFormToInput } from '../detail/soap/subjetivo'
import { SoapState } from './model'

export const convertAtendimentoIndividualModelToInput = (
  atendimentoId: ID,
  values: SoapState,
  dataNascimentoCidadao: number,
  dataReferencia: number,
  tipoAtendProf: TipoAtendimentoProfissional,
  isAtendimentoObservacao: boolean,
  isResponsavelPelaObservacao: boolean,
  flags: FlagsContextModel
): AtendimentoIndividualInput => {
  const hasAvaliacao = isAtendimentoObservacao
    ? isResponsavelPelaObservacao
    : tipoAtendProf !== TipoAtendimentoProfissional.ATENDIMENTO_PROCEDIMENTOS
  const isObservacaoAndHasPuericultura =
    isAtendimentoObservacao && tipoAtendProf === TipoAtendimentoProfissional.PUERICULTURA

  return {
    id: atendimentoId,
    antecedentes: convertAntecedentesFormToInput(values.antecedentes),
    subjetivo: convertSubjetivoFormToInput(values.subjetivo),
    objetivo: convertObjetivoFormToInput(
      values.objetivo,
      isObservacaoAndHasPuericultura,
      dataNascimentoCidadao,
      dataReferencia
    ),
    avaliacao: hasAvaliacao ? convertAvaliacaoFormToInput(values.avaliacao) : null,
    plano: convertPlanoFormToInput(values.plano, flags),
    finalizacao: convertFinalizacaoFormToInput(values.finalizacao),
    medicoesAnteriores: values.medicoesAnteriores?.map(convertMedicaoAnterior),
    medicamentoInterromper: values.medicamentosInterromper?.map(convertMedicamentoInterrupcao),
    lembretes: values.lembretes?.map(convertLembreteToSaveInput),
    preNatal: convertPreNatalFormToInput(values.preNatal),
    imprimirAtendimento: values.imprimirAtendimento,
    registrosVacinacao: convertRegistroVacinacaoToInput(values.vacinacao?.registroVacinacao),
    problemasECondicoes: values.problemasECondicoes?.map(convertLPCProblemaCondicaoFormToInput),
  }
}

export const convertMedicaoAnterior = (medicao: MedicaoAnteriorFormModel): MedicoesInput => ({
  dataMedicao: medicao.data,
  altura: parseNumber(medicao.altura),
  perimetroCefalico: parseNumber(medicao.perimetroCefalico),
  peso: parseNumber(medicao.peso),
})

export const convertMedicamentoInterrupcao = (
  medicamento: MedicamentoInterromperFormModel
): MedicamentoInterromperInput => ({
  id: medicamento.id,
  justificativa: medicamento.justificativa,
})

const convertLembreteToSaveInput = (lembrete: LembreteFormModel): LembreteSaveInput => ({
  id: lembrete.lembreteId,
  descricao: lembrete.descricao,
  ativo: lembrete.ativo,
  visibilidadeLembrete: lembrete.visibilidadeLembrete,
})
