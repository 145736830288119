import css from '@emotion/css'
import { HFlow, Icon, TableFooter, Tag, Text, Tooltip, VFlow } from 'bold-ui'
import { ColorScale } from 'bold-ui/lib/styles/colors'
import { AccordionDataTable } from 'components/accordion/accordion-data-table/AccordionDataTable'
import useSession from 'components/auth/useSession'
import { DateTime } from 'components/date'
import { Ellipsis } from 'components/Ellipsis'
import { HideOnScreenSize } from 'components/responsive'
import { TableBox, usePagedTableProps } from 'components/table'
import theme from 'config/theme'
import { differenceInHours } from 'date-fns'
import { Action, StatusCuidadoCompartilhadoEnum } from 'graphql/types.generated'
import { useServerTime } from 'hooks/useServerTime'
import React, { Fragment } from 'react'
import Permissions from 'types/Permissions'
import { humanizeAge } from 'util/date/humanize-age'
import StatusSquare from 'view/atendimentos/detail/historico/components/StatusSquare'
import {
  ClassificacaoPrioridadeCuidadoRecord,
  CuidadoCompartilhadoItem,
  CuidadoCompartilhadoItemActions,
  CuidadoCompartilhadoListingQueryPayload,
  ListaCuidadoCompartilhadoFilterModel,
  StatusCuidadoRecord,
} from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'

import { ListaCuidadoCompartilhadoTableActions } from './ListaCuidadoCompartilhadoTableActions'
import { ListaCuidadoCompartilhadoTablePanel } from './ListaCuidadoCompartilhadoTablePanel'

interface ListaCuidadoCompartilhadoTableProps {
  loading: boolean
  queryPayload?: CuidadoCompartilhadoListingQueryPayload
  isGerente: boolean
  setFilter: React.Dispatch<React.SetStateAction<ListaCuidadoCompartilhadoFilterModel>>
  resolveDiscutirAction(actions: CuidadoCompartilhadoItemActions): Action
}

export default function ListaCuidadoCompartilhadoTable(props: ListaCuidadoCompartilhadoTableProps) {
  const { loading, queryPayload, isGerente, setFilter, resolveDiscutirAction } = props

  const { getServerTimeNow } = useServerTime()
  const { hasAuthorization } = useSession()
  const hasVisualizarDiscussao = hasAuthorization(Permissions.cuidadoCompartilhado.visualizarDiscussao)
  const tooltip = isGerente ? 'Gerente da unidade não tem acesso às informações da discussão' : ''

  const { onSortChange, ...tableProps } = usePagedTableProps<CuidadoCompartilhadoListingQueryPayload, any>({
    loading,
    result: queryPayload,
    onChange: setFilter,
  })

  const renderActions = (item: CuidadoCompartilhadoItem) => (
    <ListaCuidadoCompartilhadoTableActions item={item} resolveDiscutirAction={resolveDiscutirAction} />
  )

  return (
    <TableBox>
      <AccordionDataTable<CuidadoCompartilhadoItem>
        loading={loading}
        disableRow={() => isGerente || !hasVisualizarDiscussao}
        disableRowMessage={tooltip}
        columns={[
          {
            name: 'dataInicio',
            header: 'Data de início',
            render: (item) => renderDataInicio(item, getServerTimeNow()),
            size: 3,
            lg: 2,
            style: [styles.column, styles.dataColumn],
          },
          {
            name: 'cidadao',
            header: 'Cidadão',
            render: renderCidadao,
            size: 2,
            xl: 2,
            style: [styles.column, styles.extendedColumn],
          },
          {
            name: 'prioridade',
            header: 'Prioridade',
            render: renderPrioridade,
            size: 2,
            xl: 1,
            style: [styles.column, styles.prioridadeColumn],
          },
          {
            name: 'solicitante',
            header: 'Solicitante',
            render: renderSolicitante,
            size: 2,
            xl: 3,
            style: [styles.column, styles.extendedColumn],
          },
          {
            name: 'executante',
            header: 'Executante',
            render: renderExecutante,
            size: 2,
            xl: 3,
            style: [styles.column, styles.executanteColumn],
          },
          {
            name: 'actions',
            render: renderActions,
            size: 1,
            lg: 2,
            xl: 1,
            style: [styles.column, styles.actionsColumn],
          },
        ]}
        components={{ AccordionPanel: ListaCuidadoCompartilhadoTablePanel }}
        {...tableProps}
      />
      <TableFooter
        {...tableProps}
        pageSize={queryPayload?.pageInfo.size}
        sizeOptions={[5, 10, 30, 50]}
        style={styles.tableFooter}
      />
    </TableBox>
  )
}

const renderDataInicio = (item: CuidadoCompartilhadoItem, serverTime: Date) => (
  <VFlow vSpacing={0.25}>
    <DateTime format='DD/MM/YYYY - HH:mm' value={item.iniciadoEm} />
    {renderStatus(item, serverTime)}
  </VFlow>
)

const renderCidadao = (item: CuidadoCompartilhadoItem) => {
  const nome = (item.cidadao.nomeSocial ?? item.cidadao.nome).titleCase()

  return (
    <VFlow vSpacing={0}>
      <Ellipsis title={nome} maxLines={2}>
        <Text fontWeight='bold'>{nome}</Text>
      </Ellipsis>
      <Text>{humanizeAge(item.cidadao.dataNascimento)}</Text>
    </VFlow>
  )
}

const renderPrioridade = (item: CuidadoCompartilhadoItem) => (
  <Fragment>
    <HideOnScreenSize down='lg'>
      <StatusSquare
        color={ClassificacaoPrioridadeCuidadoRecord[item.classificacaoPrioridadeAtual].cor}
        description={ClassificacaoPrioridadeCuidadoRecord[item.classificacaoPrioridadeAtual].descricao}
      />
    </HideOnScreenSize>
    <HideOnScreenSize up='lg'>
      <HFlow justifyContent='center' alignItems='center'>
        <StatusSquare
          color={ClassificacaoPrioridadeCuidadoRecord[item.classificacaoPrioridadeAtual].cor}
          description={ClassificacaoPrioridadeCuidadoRecord[item.classificacaoPrioridadeAtual].descricao}
          descriptionAsTooltip
        />
      </HFlow>
    </HideOnScreenSize>
  </Fragment>
)

const renderSolicitante = (item: CuidadoCompartilhadoItem) => {
  const nome = item.lotacaoSolicitante.profissional.nome.titleCase()
  const cbo = item.lotacaoSolicitante.cbo.nome.titleCase()
  const equipe = item.lotacaoSolicitante.equipe?.nome

  return (
    <VFlow vSpacing={0}>
      <Ellipsis title={nome} maxLines={1}>
        <Text fontWeight='bold'>{nome}</Text>
      </Ellipsis>
      <Ellipsis title={cbo} maxLines={2}>
        <Text>
          <strong>CBO </strong>
          <Text>{cbo}</Text>
        </Text>
      </Ellipsis>
      <Ellipsis title={equipe} maxLines={1}>
        <Text>{equipe}</Text>
      </Ellipsis>
    </VFlow>
  )
}

const renderExecutante = (item: CuidadoCompartilhadoItem) => {
  const nome = item.lotacaoExecutanteAtual ? item.lotacaoExecutanteAtual.profissional?.nome.titleCase() : null
  const equipe = item.lotacaoExecutanteAtual ? item.lotacaoExecutanteAtual?.equipe?.nome : null
  const cbo = item.cboExecutanteAtual.nome.titleCase()
  const unidadeSaude = item.unidadeSaudeExecutanteAtual.nome.titleCase()

  return (
    <VFlow vSpacing={0}>
      {nome ? (
        <Ellipsis title={nome} maxLines={1}>
          <Text fontWeight='bold'>{nome}</Text>
        </Ellipsis>
      ) : (
        <HFlow hSpacing={0.25} alignItems='center'>
          <Icon icon='addProfessionalFilled' size={1} style={styles.textColor} />
          <Ellipsis title={nome} maxLines={1}>
            <Text style={styles.textColor}>Ainda sem profissional</Text>
          </Ellipsis>
        </HFlow>
      )}

      <Ellipsis title={cbo} maxLines={1}>
        <Text>
          <strong>CBO </strong>
          <Text>{cbo}</Text>
        </Text>
      </Ellipsis>
      {equipe && (
        <Ellipsis title={equipe} maxLines={1}>
          <Text>{equipe}</Text>
        </Ellipsis>
      )}

      <HideOnScreenSize down='lg'>
        <HFlow hSpacing={0.25} alignItems='center'>
          <Icon icon='hospital' size={1} />
          <Ellipsis title={unidadeSaude} maxLines={1}>
            <Text>{unidadeSaude}</Text>
          </Ellipsis>
        </HFlow>
      </HideOnScreenSize>

      <HideOnScreenSize up='lg'>
        <Tooltip text={unidadeSaude}>
          <Icon icon='hospital' size={1} />
        </Tooltip>
      </HideOnScreenSize>
    </VFlow>
  )
}

const renderStatus = (item: CuidadoCompartilhadoItem, serverTime: Date) => {
  const statusConfig = StatusCuidadoRecord[item.status]

  return (
    <Tag icon={statusConfig.icon} style={styles.tag(statusConfig)}>
      <HFlow hSpacing={0.25}>
        <strong>{statusConfig.descricao}</strong>

        {item.status === StatusCuidadoCompartilhadoEnum.AGUARDANDO &&
          `há ${differenceInHours(serverTime, item.dataUltimaEvolucao)}h`}
      </HFlow>
    </Tag>
  )
}

const styles = {
  column: css`
    flex: 1;
  `,
  extendedColumn: css`
    flex: 2;
  `,
  dataColumn: css`
    ${theme.breakpoints.down('lg')} {
      flex: 2;
    }
    min-width: 11rem;
    max-width: 11rem;
  `,
  prioridadeColumn: css`
    ${theme.breakpoints.down('lg')} {
      text-align: center;
    }
    ${theme.breakpoints.up('lg')} {
      text-align: left;
    }
  `,
  executanteColumn: css`
    flex: 3;
  `,
  actionsColumn: css`
    ${theme.breakpoints.down('lg')} {
      max-width: 3rem;
    }
    ${theme.breakpoints.up('lg')} {
      max-width: none;
    }
  `,
  tableFooter: css`
    border-top: 0;
  `,
  textColor: css`
    color: ${theme.pallete.gray.c40};
  `,
  tag: (statusConfig: { descricao: string; cor?: ColorScale }) => css`
    background-color: ${statusConfig.cor.c90};
    color: ${statusConfig.cor.c40};
    font-weight: normal;
  `,
}
