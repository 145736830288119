import React from 'react'

import { TermosDeUsoHeadingSection } from '../../components/TermosDeUsoHeadingSection'

export const TransferenciaInternacionalDeDadosSection = () => {
  return (
    <TermosDeUsoHeadingSection
      title='10. Transferência Internacional de Dados'
      id='transferência_internacional_de_dados'
    >
      <p>O Serviço não faz transferência internacional de dados.</p>
    </TermosDeUsoHeadingSection>
  )
}
