import { Cell, DateRange, Grid } from 'bold-ui'
import { RadioField, RadioFieldProps } from 'components/form'
import { OpcaoSelecionadaRelatorioGerencialEnum, UnidadePeriodoRelatorioGerencialEnum } from 'graphql/types.generated'
import React from 'react'
import { MetaPath } from 'util/metaPath'

import { relatorioGerencialPeriodoRecord } from '../../common/model-relatorioGerencial'
import { RelatorioGerencialRangeField } from './RelatorioGerencialRangeField'

interface PeriodoRadioFieldProps extends Pick<RadioFieldProps, 'disabled' | 'onChange'> {
  unidadeTempoValue: UnidadePeriodoRelatorioGerencialEnum
  opcaoSelecionadaValue: OpcaoSelecionadaRelatorioGerencialEnum
  nameOpcaoSelecionada: MetaPath<OpcaoSelecionadaRelatorioGerencialEnum>
  namePeriodo: MetaPath<DateRange>
  isModal?: Boolean
}

export function RelatorioGerencialPeriodoRadioField(props: PeriodoRadioFieldProps) {
  const {
    unidadeTempoValue,
    opcaoSelecionadaValue,
    nameOpcaoSelecionada,
    namePeriodo,
    disabled,
    onChange,
    isModal,
  } = props
  const opcoes = relatorioGerencialPeriodoRecord[unidadeTempoValue]

  return (
    <Grid alignItems='center'>
      <Cell>
        <RadioField
          name={nameOpcaoSelecionada}
          value={OpcaoSelecionadaRelatorioGerencialEnum.PRIMEIRA}
          label={opcoes[OpcaoSelecionadaRelatorioGerencialEnum.PRIMEIRA].label}
          key={OpcaoSelecionadaRelatorioGerencialEnum.PRIMEIRA}
          onChange={onChange}
          disabled={disabled}
        />
      </Cell>
      <Cell>
        <RadioField
          name={nameOpcaoSelecionada}
          value={OpcaoSelecionadaRelatorioGerencialEnum.SEGUNDA}
          label={opcoes[OpcaoSelecionadaRelatorioGerencialEnum.SEGUNDA].label}
          key={OpcaoSelecionadaRelatorioGerencialEnum.SEGUNDA}
          onChange={onChange}
          disabled={disabled}
        />
      </Cell>
      <Cell>
        <RadioField
          name={nameOpcaoSelecionada}
          value={OpcaoSelecionadaRelatorioGerencialEnum.TERCEIRA}
          label={opcoes[OpcaoSelecionadaRelatorioGerencialEnum.TERCEIRA].label}
          key={OpcaoSelecionadaRelatorioGerencialEnum.TERCEIRA}
          onChange={onChange}
          disabled={disabled}
        />
      </Cell>
      {!isModal && (
        <Cell>
          <RadioField
            name={nameOpcaoSelecionada}
            value={OpcaoSelecionadaRelatorioGerencialEnum.OUTRO}
            label={opcoes[OpcaoSelecionadaRelatorioGerencialEnum.OUTRO].label}
            key={OpcaoSelecionadaRelatorioGerencialEnum.OUTRO}
            onChange={onChange}
            disabled={disabled}
          />
        </Cell>
      )}
      {!isModal && (
        <Cell>
          <RelatorioGerencialRangeField
            unidadePeriodoSelecionada={unidadeTempoValue}
            name={namePeriodo}
            disabled={disabled || opcaoSelecionadaValue !== OpcaoSelecionadaRelatorioGerencialEnum.OUTRO}
            onChange={onChange}
          />
        </Cell>
      )}
    </Grid>
  )
}
