import { Text, VFlow } from 'bold-ui'
import React from 'react'

import { NewsHeadingSection } from './NewsHeadingSection'

export function NewsInternalEstabilizacaoV52() {
  return (
    <VFlow>
      <NewsHeadingSection title='Versão 5.2.33' id='estabilizacao-5-2-33'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Correção de erro onde, por questões de fuso horário, os relatórios gerenciais contabilizavam atendimentos do
            dia 1 como do mês anterior.
          </Text>
          <Text component='li'>Funcionalidade para impressão de comprovantes de agendamentos do cidadão.</Text>
          <Text component='li'>
            Correção de cenário que apresentava erro de conexão de servidor ao acessar o Acompanhamento de Condições de
            Saúde.
          </Text>
          <Text component='li'>
            Criação do fluxo que envia um e-mail para o cidadão quando um agendamento for registrado.
          </Text>
          <Text component='li'>
            Correção da dose de Reforço do imunobiológico "Meningo C", que não estava sendo apresentada no Calendário
            Vacinal do grupo-alvo "Criança".
          </Text>
          <Text component='li'>
            Disponibilização do registro de aplicações do imunobiológico COVID-19 MODERNA - SPIKEVAX.
          </Text>
          <Text component='li'>
            Ajustes de cenário onde o Marcador de Consumo Alimentar registrado no PEC não vinculava ao atendimento em
            consultas de pré-natal com agendamentos.
          </Text>
          <Text component='li'>Atualização da tabela de procedimentos SIGTAP para a competência de 09/2023.</Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 5.2.32' id='estabilizacao-5-2-32'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Correção de erro onde reservas estavam sendo salvas com o motivo "Outros" independentemente da opção
            selecionada.
          </Text>
          <Text component='li'>
            Atualização das regras de cadastro do cidadão no módulo de Cidadão e na Ficha de cadastro individual para os
            campos de Orientação Sexual e Identidade de Gênero.
          </Text>
          <Text component='li'>
            Correção de erro onde fichas de Atendimento individual ou odontológico importadas de outros sistemas não
            eram apresentadas no Histórico clínico do cidadão.
          </Text>
          <Text component='li'>
            Inclusão do campo de Anotações na visualização do Histórico de visita domiciliar quando a origem for
            aplicativo e-SUS Território.
          </Text>
          <Text component='li'>
            Correção de cenário em que o botão de Buscar cidadãos não estava funcionando no Acompanhamento de condições
            de saúde.
          </Text>
          <Text component='li'>Criação dos recursos de acesso para o módulo do Cuidado compartilhado.</Text>
          <Text component='li'>
            Correção de tela branca no Histórico de vacinação caso o atendimento tenha sido importado de outros
            sistemas.
          </Text>
          <Text component='li'>
            Correção de erro onde não estava sendo possível recadastrar o CNS de um profissional sem lotações ativas.
          </Text>
          <Text component='li'>
            Atualizações globais de regras de vacinação para compatibilidade com as políticas nacionais vigentes.
          </Text>
          <Text component='li'>
            Correção de cenário no módulo de Acompanhamento de território, onde ocorria duplicação na visualização
            devido a logradouros com nomes muito semelhantes.
          </Text>
          <Text component='li'>
            Correção de cenário onde um erro era mostrado no alerta ao tentar salvar um Atendimento de observação com
            alguma prescrição de medicamentos cadastrada.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 5.2.31' id='estabilizacao-5-2-31'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>Inclusão do imunobiológico "Influenza Trivalente" nos envios à RNDS via RIA-C.</Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 5.2.30' id='estabilizacao-5-2-30'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Correção de cenário onde algumas lotações estavam sendo inativadas incorretamente ao importar o CNES.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 5.2.29' id='estabilizacao-5-2-29'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Correção de erro onde algumas famílias apareciam duplicadas no módulo de Acompanhamento do território.
          </Text>
          <Text component='li'>
            Inclusão do modo assíncrono nos Relatórios gerenciais de atendimento, cuidado compartilhado e vacinação,
            para que seja possível sair da página e utilizar o sistema normalmente enquanto a busca é realizada.
          </Text>
          <Text component='li'>
            Correção de cenário em que, ao tentar finalizar um atendimento de pré-natal, era apresentado o erro de
            resultados não únicos.
          </Text>
          <Text component='li'>Melhorias no módulo de CDS na Ficha de cadastro individual visando performance.</Text>
          <Text component='li'>
            Correção de erro que causava lentidão e travamento no processamento e envio de dados em algumas instalações,
            sendo necessário reiniciá-las.
          </Text>
          <Text component='li'>
            Remoção da conduta "Agendamento para NASF" e inclusão da nova opção "Agendamento para eMulti" nos desfechos
            de Atendimentos individuais e odontológicos.
          </Text>
          <Text component='li'>
            Correção de cenário onde um erro era apresentado ao tentar atualizar o endereço de um cidadão estrangeiro.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 5.2.28' id='estabilizacao-5-2-28'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Refatoração na aba de "Recebimento" do módulo de "Transmissão de dados", possibilitando a visualização da
            origem dos lotes recebidos e a filtragem do histórico de lotes por período e por tipo origem de recebimento.
          </Text>
          <Text component='li'>Adição de perguntas e respostas no topo do Termo de uso.</Text>
          <Text component='li'>
            Correção de cenário que fazia com que registros do Histórico clínico do cidadão ficassem indisponíveis
            durante o processamento de relatórios.
          </Text>
          <Text component='li'>
            Inclusão de novas doses de vacinação, grupo de atendimento, regras e imunobiológicos COVID-19 Moderna -
            Spikevax Bivalente e VPC15, além da inativação de imunobiológicos não mais aplicados na APS.
          </Text>
          <Text component='li'>
            Correção de cenário onde não estava sendo exibido o nome do exame da ficha de Síndrome neurológica por Zika
            / Microcefalia no Histórico clínico do cidadão.
          </Text>
          <Text component='li'>
            Correção de um cenário em que, mesmo após a atualização das credenciais de acesso ao serviço de documentos
            digitais, o PEC continuava utilizando as credenciais antigas para comunicação com este serviço.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 5.2.27' id='estabilizacao-5-2-27'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Correção de cenário que impedia a exclusão de agendamentos no módulo de Registro Tardio.
          </Text>
          <Text component='li'>
            Adicionada restrição para que apenas estabelecimentos da atenção primária possam acessar a Busca ativa de
            vacinação.
          </Text>
          <Text component='li'>
            Correção de cenário que impedia a geração de Prescrição digital para cidadãos naturalizados.
          </Text>
          <Text component='li'>
            Correção de cenário que impedia a finalização do atendimento, indicando a existência de uma CIAP2
            cadastrada.
          </Text>
          <Text component='li'>
            Disponibilização do imunobiológico "104 - Vacina dengue atenuada (DNG)" para registro via CDS e Atendimentos
            de vacinação.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 5.2.26' id='estabilizacao-5-2-26'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Adição do campo “Produções”, que possibilita visualizar separadamente o que é produção do próprio
            profissional e da sua equipe, nos Relatórios gerenciais de atendimentos, vacinação e absenteísmo.
          </Text>
          <Text component='li'>
            Correção de erro onde, ao tentar iniciar uma gestação, era apresentado um erro indicando que já existia uma
            condição no prontuário.
          </Text>
          <Text component='li'>
            Possibilidade de profissionais que pertencem à eMulti pesquisarem, em uma única consulta, a sua produção em
            todos os estabelecimentos nos relatórios Gerenciais de atendimentos e absenteísmo.
          </Text>
          <Text component='li'>
            Adicionadas as possibilidades de realizar um Atendimento no registro tardio, mesmo que haja um Atendimento
            de vacinação ou uma Escuta inicial com data posterior a ele.
          </Text>
          <Text component='li'>
            Correção de cenário em que, ao tentar finalizar um Atendimento de pré-natal, era apresentado o erro de
            resultados não únicos.
          </Text>
          <Text component='li'>
            Correção de erro que causava lentidão e travamento em algumas instalações, sendo necessário reiniciá-las.
          </Text>
          <Text component='li'>
            Correção de cenário em que alguns dados de Registros tardios desapareciam da impressão do Histórico clínico
            do cidadão após o processamento.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 5.2.24' id='estabilizacao-5-2-24'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Ao selecionar um imunobiológico na Busca ativa de vacinação, apenas as doses pertinentes a ele aparecerão no
            campo "Dose" ao lado.
          </Text>
          <Text component='li'>
            Correção de cenário em que um cidadão era cadastrado através da edição de um cidadão no Registro tardio e o
            cidadão editado não era atualizado automaticamente.
          </Text>
          <Text component='li'>
            Implementação de registro de auditoria para a exclusão de Atendimentos de vacinação.
          </Text>
          <Text component='li'>
            Correção de cenário em que o relatório CSV da Busca ativa de vacinação não apresentava todos os
            imunobiológicos dos cidadãos ao aplicar o filtro avançado "Grupo de atendimento".
          </Text>
          <Text component='li'>
            Correção de cenário em que uma pessoa grávida estava em Atendimento de pré-natal e, ao iniciar um
            Atendimento de observação, não era possível concluí-lo.
          </Text>
          <Text component='li'>Habilitados os envios de Prescrições de medicamentos e de Atestados para a RNDS.</Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 5.2.23' id='estabilizacao-5-2-23'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Correção de erro que ocorria ao incluir um cidadão recém-criado na lista de registro tardio.
          </Text>
          <Text component='li'>
            Correção de cenário onde os dados do relatório CSV não correspondiam aos filtros aplicados na Busca ativa de
            vacinação.
          </Text>
          <Text component='li'>
            Implementação da ordenação por imunobiológico e dose no relatório CSV da Busca ativa de vacinação.
          </Text>
          <Text component='li'>Correção de cenários que impediam a finalização de atendimentos de pré-natal.</Text>
          <Text component='li'>
            Adição das opções "Aplicação", "Glóbulo" e "UI" (unidade internacional) no campo de unidade de medida da
            Prescrição de medicamentos.
          </Text>
          <Text component='li'>
            Correção de cenário em que uma tela branca era apresentada ao acessar um histórico de pré-natal sem CID10.
          </Text>
          <Text component='li'>
            Adição da opção "Visualizar informações do cidadão" na Lista de atendimento e na Agenda permitindo que os
            agentes comunitários de saúde tenham acesso a alguns dados do prontuário.
          </Text>
          <Text component='li'>
            Inclusão dos registros de evoluções do Cuidado compartilhado no Histórico clínico do cidadão.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 5.2.22' id='estabilizacao-5-2-22'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Correção de cenário onde não estava sendo possível finalizar atendimentos em algumas situações.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 5.2.21' id='estabilizacao-5-2-21'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Correção de cenário em que os cidadãos pertencentes a equipes vinculadas não eram apresentados nos
            Relatórios operacionais.
          </Text>
          <Text component='li'>
            Correção da ordenação dos dados do cidadão no relatório em CSV das vacinas do calendário vacinal e de
            Covid-19 da Busca ativa de vacinação.
          </Text>
          <Text component='li'>
            Adicionada a funcionalidade de marcar todos os medicamentos para impressão nas Prescrições de medicamentos.
          </Text>
          <Text component='li'>
            Ajustes na ordenação da lista de medicamentos da Prescrição para apresentar os medicamentos mais recentes no
            topo.
          </Text>
          <Text component='li'>
            Correção de cenário onde ao atender uma cidadã com cadastro unificado que constava na lista de atendimentos
            não era possível iniciar uma gestação.
          </Text>
          <Text component='li'>
            Implementação de indicativo de medicamentos com termo de uso e consentimento na Prescrição de medicamentos.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 5.2.20' id='estabilizacao-5-2-20'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Correção de erro onde não era possível visualizar imóveis do logradouro no Acompanhamento do território.
          </Text>
          <Text component='li'>
            Implementação da funcionalidade que permite visualizar os registros não finalizados no Registro tardio de
            atendimento.
          </Text>
          <Text component='li'>
            Correção de cenário onde não era possível processar relatórios de atendimentos com evoluções registradas no
            Cuidado compartilhado em algumas instalações.
          </Text>
          <Text component='li'>
            Restrição adicionada no Registro tardio de atendimento para não ser possível adicionar ou editar um cidadão
            se este já possuir um atendimento mais recente registrado.
          </Text>
          <Text component='li'>
            Correção na ordenação da lista de cidadãos encontrados para considerar o nome social na Busca ativa de
            vacinação.
          </Text>
          <Text component='li'>
            Disponibilização do filtro de idade mínima de zero anos no campo da faixa etária da Busca ativa de
            vacinação.
          </Text>
          <Text component='li'>
            Implementação de mensagens e de marcadores para identificar atendimentos não finalizados no Registro tardio
            de atendimento.
          </Text>
          <Text component='li'>
            Correção de erro em que o processamento e o envio de atendimentos individuais e odontológicos contendo
            prescrições de medicamentos não estavam sendo realizados adequadamente.
          </Text>
        </VFlow>
      </NewsHeadingSection>
    </VFlow>
  )
}
