/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, HeadingSection, HFlow, Theme, useTheme, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { ErrorField, FormProps, SubmitButton } from 'components/form'
import { CboCuidadoCompartilhadoSelectField } from 'components/form/field/select/CboCuidadoCompartilhadoSelectField/CboCuidadoCompartilhadoSelectField'
import { usePecField } from 'components/form/final-form/hooks/useField'
import { useFlags } from 'config/useFlagsContext'
import { FORM_ERROR, FormApi } from 'final-form'
import { LotacaoContextModel } from 'hooks/atendimento-context/model'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { useMemo, useState } from 'react'
import { FormRenderProps } from 'react-final-form'
import { emptyArray } from 'util/array'
import { metaPath } from 'util/metaPath'
import { meta } from 'view/atendimentos/atendimento-individual/model'

import { CuidadoCompartilhadoPlanoModel, TipoCompartilhamentoCuidadoPlanoEnum } from '../model-cuidadocompartilhado'
import { CuidadoCompartilhadoForm } from './CuidadoCompartilhadoForm'
import { CuidadoCompartilhadoFormFields } from './CuidadoCompartilhadoFormFields'

interface AdicionarCuidadoCompartilhadoFormProps {
  onSubmit: FormProps<CuidadoCompartilhadoPlanoModel>['onSubmit']
  lotacaoAtual: LotacaoContextModel
}

const path = metaPath<CuidadoCompartilhadoPlanoModel>()

export const AdicionarCuidadoCompartilhadoForm = (props: AdicionarCuidadoCompartilhadoFormProps) => {
  const { onSubmit, lotacaoAtual } = props

  const alert = useAlert()
  const { analytics } = useFirebase()
  const { PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED } = useFlags()
  const theme = useTheme()
  const localStyles = createStyles(theme)

  const [disabled, setDisabled] = useState<boolean>(PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED)

  const {
    input: { value: cuidadoCompartilhadoAtual },
  } = usePecField({ name: meta.plano.cuidadosCompartilhado.absolutePath(), subscription: { value: true } })

  const encaminhamentos = useMemo(
    () =>
      Array.isArray(cuidadoCompartilhadoAtual)
        ? cuidadoCompartilhadoAtual?.filter(
            (item) => item.tipo === TipoCompartilhamentoCuidadoPlanoEnum.GUIA_ENCAMINHAMENTO
          )
        : emptyArray,
    [cuidadoCompartilhadoAtual]
  )

  const handleSubmit = (values: CuidadoCompartilhadoPlanoModel, form: FormApi<CuidadoCompartilhadoPlanoModel>) => {
    onSubmit({ ...values, lotacaoSolicitante: lotacaoAtual }, form)
  }

  const onSubmitSucceeded = () => {
    alert('success', 'Cuidado compartilhado salvo com sucesso.')
    analytics.logEvent('CC_adicionado')
  }

  const renderForm = (renderProps: FormRenderProps<CuidadoCompartilhadoPlanoModel>) => {
    const onCancel = () => {
      renderProps.form.reset()
      renderProps.form.getRegisteredFields().forEach((item) => renderProps.form.resetFieldState(item))
    }

    return PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED ? (
      <VFlow vSpacing={0.5}>
        <HeadingSection level={4} css={localStyles.heading} title='Compartilhamento de cuidado' />
        <div css={localStyles.wrapper}>
          <div css={localStyles.header}>
            <Grid>
              <Cell size={6}>
                <CboCuidadoCompartilhadoSelectField name={path.cbo} label='Compartilhar com a CBO' required />
              </Cell>
            </Grid>
          </div>
          <div css={localStyles.children}>
            <VFlow>
              <ErrorField name={FORM_ERROR} inline />
              <CuidadoCompartilhadoFormFields path={path} disabled={disabled} setDisabled={setDisabled} />
              <HFlow justifyContent='flex-end'>
                <Button size='small' onClick={onCancel}>
                  Cancelar
                </Button>
                <SubmitButton kind='primary' size='small' handleSubmit={renderProps.handleSubmit} disabled={disabled}>
                  Adicionar
                </SubmitButton>
              </HFlow>
            </VFlow>
          </div>
        </div>
      </VFlow>
    ) : (
      <Grid>
        <Cell size={12}>
          <CuidadoCompartilhadoFormFields path={path} />
        </Cell>
        <Cell size={12}>
          <HFlow justifyContent='flex-end'>
            <Button size='small' onClick={onCancel}>
              Cancelar
            </Button>
            <SubmitButton kind='primary' size='small' handleSubmit={renderProps.handleSubmit}>
              Adicionar
            </SubmitButton>
          </HFlow>
        </Cell>
      </Grid>
    )
  }

  return (
    <CuidadoCompartilhadoForm
      path={path}
      render={renderForm}
      onSubmit={handleSubmit}
      onSubmitSucceeded={onSubmitSucceeded}
      encaminhamentos={encaminhamentos}
    />
  )
}

const createStyles = (theme: Theme) => ({
  header: css`
    background-color: ${theme.pallete.gray.c90};
    border-bottom: 1px solid ${theme.pallete.divider};
    padding: 1rem;
  `,
  children: css`
    padding: 1rem;
  `,
  wrapper: css`
    border: 1px solid ${theme.pallete.divider};
  `,
  heading: css`
    margin-bottom: 0;
  `,
})
