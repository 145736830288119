import { FORM_ERROR } from 'final-form'
import {
  conditionalRequired,
  createValidator,
  empty,
  ErrorObject,
  oneRequired,
  required,
  requiredRichText,
  richTextMaxLength,
} from 'util/validation'

import { CuidadoCompartilhadoPlanoModel } from '../model-cuidadocompartilhado'

export const cuidadoCompartilhadoFormValidator = (
  isSomenteCiap: boolean,
  unidadeSaudeId: ID,
  flag: boolean,
  encaminhamentos: readonly CuidadoCompartilhadoPlanoModel[]
) => {
  return createValidator<CuidadoCompartilhadoPlanoModel>(
    {
      cbo: [required],
      prioridade: [required],
      problemasECondicoes: [required],
      discussao: [requiredRichText],
    },
    (values: CuidadoCompartilhadoPlanoModel, errors: ErrorObject<CuidadoCompartilhadoPlanoModel>) => {
      const isCuidadoCompartilhado = values?.cbo?.isVinculado
      const isGuiaEncaminhamento = values?.cbo && !values?.cbo?.isVinculado
      const isEMulti = !flag || (isCuidadoCompartilhado && unidadeSaudeId === values.unidadeSaude?.id)
      const isTicRequired = !flag || isCuidadoCompartilhado

      if (isCuidadoCompartilhado) {
        if (isEMulti) errors.lotacao = required(values.lotacao)
        if (flag) errors.unidadeSaude = required(values.unidadeSaude)
        errors.cidadaoAceitaAtendTic = conditionalRequired(isTicRequired)(values.cidadaoAceitaAtendTic)
        errors.discussao = errors.discussao ?? richTextMaxLength(1000)(values.discussao)

        if (isSomenteCiap) {
          errors.problemasECondicoes = {
            ciap: required(values.problemasECondicoes?.ciap),
            cid10: empty(values.problemasECondicoes?.cid10),
          }
        } else {
          errors.problemasECondicoes = oneRequired([
            values.problemasECondicoes?.ciap,
            values.problemasECondicoes?.cid10,
          ])
        }
      } else if (isGuiaEncaminhamento) {
        errors.unidadeSaude = empty(values.unidadeSaude)
        errors.lotacao = empty(values.lotacao)
        errors.cidadaoAceitaAtendTic = empty(values.cidadaoAceitaAtendTic)
        errors.discussao = errors.discussao ?? richTextMaxLength(2000)(values.discussao)
        isEncaminhamentoDuplicado(encaminhamentos, values, errors)

        if (isSomenteCiap) {
          errors.problemasECondicoes = {
            ciap: required(values.problemasECondicoes?.ciap),
            cid10: required(values.problemasECondicoes?.cid10),
          }
        } else {
          errors.problemasECondicoes = {
            ciap: empty(values.problemasECondicoes?.ciap),
            cid10: required(values.problemasECondicoes?.cid10),
          }
        }
      }

      return errors
    }
  )
}

const isEncaminhamentoDuplicado = (
  encaminhamentos: readonly CuidadoCompartilhadoPlanoModel[],
  values: CuidadoCompartilhadoPlanoModel,
  errors: ErrorObject<CuidadoCompartilhadoPlanoModel>
) => {
  return encaminhamentos.some((encaminhamento) => {
    if (
      encaminhamento._id !== values._id &&
      encaminhamento.cbo.cbo.id === values.cbo.cbo.id &&
      encaminhamento.problemasECondicoes.cid10?.id === values.problemasECondicoes?.cid10?.id
    ) {
      errors[FORM_ERROR] = 'Já existe uma Guia de encaminhamento cadastrada para este CBO e Problemas/condições (CID10)'
      return true
    }
    return false
  })
}
