import { HFlow, VFlow } from 'bold-ui'
import { DateField, ErrorField } from 'components/form'
import { SemanasDiasField } from 'components/form/field/SemanasDiasField'
import { max } from 'lodash'
import React from 'react'
import { MetaPath } from 'util/metaPath'

import { FactoryResultadoExameEspecificoFieldProps, ResultadoEspecificoPrenatal } from '../../model'

interface ResultadoExamePrenatalFieldProps
  extends Pick<FactoryResultadoExameEspecificoFieldProps, 'dataSolicitacao' | 'dataRealizado'> {
  name: MetaPath<ResultadoEspecificoPrenatal>
}

export const ResultadoExamePrenatalField = (props: ResultadoExamePrenatalFieldProps) => {
  const { name, dataSolicitacao, dataRealizado } = props

  return (
    <VFlow vSpacing={0.5}>
      <HFlow hSpacing={1.5}>
        <SemanasDiasField name={name.idadeGestacional} label='Idade gestacional eco' />
        <DateField name={name.dpp} label='DPP eco' minDate={max([dataSolicitacao, dataRealizado])} />
      </HFlow>

      <ErrorField name={name.error} />
    </VFlow>
  )
}
