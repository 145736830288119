import { PivotTableProps } from 'components/pivot-table/PivotTable'
import {
  TempoEntreAgendamentoEConsultaSelectFieldDocument,
  TipoDeFaltaRelatorioGerencialAbsenteismoSelectFieldDocument,
} from 'graphql/hooks.generated'

import { KeyMapping, keyMappings, VisibilidadeModeloPersonalizadoEnum } from '../common/keyMapping-relatorioGerencial'
import { RelatorioGerencialAbsenteismoModel } from './model-relatorioGerencialAbsenteismo'

const INDISPONIVEL = 'Indisponível'

const nomeSituacaoAgendamento: KeyMapping = {
  keyName: 'Tipo de falta',
  formatter: (s: string) => s.capitalize(),
  visibility: VisibilidadeModeloPersonalizadoEnum.PUBLICO,
  queryDocument: TipoDeFaltaRelatorioGerencialAbsenteismoSelectFieldDocument,
}

const intervaloAgendamento: KeyMapping = {
  keyName: 'Tempo entre agendamento e consulta',
  formatter: (s: string) => s.capitalize(),
  ordenator: (a: string, b: string) => {
    if (a === INDISPONIVEL) {
      return -1
    } else if (b === INDISPONIVEL) {
      return 1
    } else {
      const num1 = Number(a.split(' ')[0])
      const num2 = Number(b.split(' ')[0])
      return num1 - num2
    }
  },
  visibility: VisibilidadeModeloPersonalizadoEnum.PUBLICO,
  queryDocument: TempoEntreAgendamentoEConsultaSelectFieldDocument,
}

export const absenteismoKeyMapping: PivotTableProps<RelatorioGerencialAbsenteismoModel>['keyMapping'] = new Map<
  keyof RelatorioGerencialAbsenteismoModel,
  KeyMapping
>([
  ['categoriaProfissional', keyMappings.categoriaProfissional],
  ['equipe', keyMappings.equipe],
  ['municipio', keyMappings.municipio],
  ['nomeProfissional', keyMappings.nomeProfissional],
  ['nomeSituacaoAgendamento', nomeSituacaoAgendamento],
  ['unidadeSaude', keyMappings.unidadeSaude],
  ['turno', keyMappings.turno],
  ['intervaloAgendamento', intervaloAgendamento],
  ['faixaEtaria', keyMappings.faixaEtaria],
  ['cbo', keyMappings.cbo],
  ['producoes', keyMappings.producoes],
])
