import { HFlow, Text } from 'bold-ui'
import { AsyncSelectFieldProps, useAsyncQuerySelect } from 'components/form/field/select/useAsyncQuerySelect'
import { SelectField } from 'components/form/final-form'
import { EstabelecimentosCuidadoCompartilhadoSelectFieldDocument } from 'graphql/hooks.generated'
import {
  EstabelecimentosCuidadoCompartilhadoSelectFieldQuery,
  EstabelecimentosCuidadoCompartilhadoSelectFieldQueryVariables,
} from 'graphql/types.generated'
import React from 'react'

export type EstabelecimentosCuidadoCompartilhadoSelectModel = EstabelecimentosCuidadoCompartilhadoSelectFieldQuery['estabelecimentosCuidadoCompartilhado']['content'][0]

export interface EstabelecimentosCuidadoCompartilhadoSelectFieldProps
  extends AsyncSelectFieldProps<EstabelecimentosCuidadoCompartilhadoSelectModel> {
  disabled?: boolean
  cboId: ID
  isVinculado: boolean
}

export function EstabelecimentosCuidadoCompartilhadoSelectField(
  props: EstabelecimentosCuidadoCompartilhadoSelectFieldProps
) {
  const { cboId, isVinculado, ...rest } = props

  const { selectProps } = useAsyncQuerySelect<
    EstabelecimentosCuidadoCompartilhadoSelectModel,
    EstabelecimentosCuidadoCompartilhadoSelectFieldQuery,
    EstabelecimentosCuidadoCompartilhadoSelectFieldQueryVariables
  >({
    query: EstabelecimentosCuidadoCompartilhadoSelectFieldDocument,
    extractItems: (data) =>
      data && data.estabelecimentosCuidadoCompartilhado && data.estabelecimentosCuidadoCompartilhado.content,
    variables: (inputQuery) => ({
      input: {
        query: inputQuery,
        cboId,
        isVinculado,
      },
    }),
    skip: () => props.disabled,
    debounceTime: 500,
  })

  return (
    <SelectField<EstabelecimentosCuidadoCompartilhadoSelectModel>
      itemToString={itemToString}
      renderItem={renderItem}
      {...selectProps}
      {...rest}
    />
  )
}

const itemToString = (option: EstabelecimentosCuidadoCompartilhadoSelectModel) => option && option.nome

const renderItem = (item: EstabelecimentosCuidadoCompartilhadoSelectModel) => (
  <>
    <Text fontWeight='bold'>
      {item.nome}
      {!item.ativo && ' (Inativa)'}
    </Text>
    <HFlow hSpacing={0.5}>
      <Text fontWeight='bold'>CNES:</Text>
      {item.cnes}
    </HFlow>
  </>
)
