/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, Button, Icon, VFlow } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { useErrorHandler } from 'components/error'
import { PrivateRoute } from 'components/route'
import { differenceInCalendarDays } from 'date-fns'
import {
  useListaAtendimentoPollingEnabledQuery,
  useListaAtendimentoPollingQuery,
  useListaAtendimentoQuery,
  usePossuiAtendimentosNaoFinalizadosQuery,
  useUltimoAtendimentoProfissionalCidadaoQuery,
} from 'graphql/hooks.generated'
import { PageParams, StatusAtendimento } from 'graphql/types.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import useAtmosphere from 'hooks/useAtmosphere'
import { useServerTime } from 'hooks/useServerTime'
import { useLocalStorageState } from 'hooks/useStorage'
import { useUrlQueryParams } from 'hooks/useUrlQueryParams'
import { isEmpty } from 'lodash'
import { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { useRouteMatch } from 'react-router'
import Permissions from 'types/Permissions'
import AtendimentoListing from 'view/atendimentos/components/AtendimentoListing'
import { ExibindoSomenteNaoFinalizadosAlert } from 'view/atendimentos/list/alerts/ExibindoSomenteNaoFinalizadosAlert'
import { ExistemAtendimentosNaoFinalizadosAlert } from 'view/atendimentos/list/alerts/ExistemAtendimentosNaoFinalizadosAlert'
import { useListaAtendimentosNaoFinalizadosAlert } from 'view/atendimentos/list/hooks/useListaAtendimentosNaoFinalizadosAlert'
import { usePeriodoParaAtendimentosNaoFinalizados } from 'view/atendimentos/list/hooks/usePeriodoParaAtendimentosNaoFinalizados'
import { ListaAtendimentoFooter } from 'view/atendimentos/list/ListaAtendimentoFooter'
import {
  AtendimentosRegistroTardioFilterModel,
  useListaRegistroTardioFilterDefault,
} from 'view/registro-tardio/filter/useListaRegistroTardioFilterDefault'

import { EditarRegistroTardioModalForm } from './components/modal/EditarRegistroTardioModalForm'
import { RegistroTardioAtendimentoPosteriorModal } from './components/modal/RegistroTardioAtendimentoPosteriorModal'
import { ListaRegistroTardioFilter, ListaRegistroTardioFilterModel } from './filter/ListaRegistroTardioFilter'
import { ListaRegistroTardioFilterPopperModel } from './filter/ListaRegistroTardioFilterPopperForm'
import ListaRegistroTardioForm from './ListaRegistroTardioForm'
import { ListaRegistroTardioItem } from './ListaRegistroTardioItem'
import { convertListaRegistroTardioStateToInput } from './util-registroTardio'

type AtendimentoTopicModel = { idUbs: number }

interface RegistroTardioUrlParams {
  cidadaoId: string
}

export function ListaRegistroTardioView() {
  const handleRejection = useErrorHandler()
  const { getServerTimeNow } = useServerTime()
  const { acesso } = useAcessoLotacaoOrEstagio()
  const { analytics } = useFirebase()
  const match = useRouteMatch()

  const acessoId = acesso?.id

  const { cidadaoId: cidadaoIdFromURL } = useUrlQueryParams<RegistroTardioUrlParams>()

  const filterDefault = useListaRegistroTardioFilterDefault()

  const currentServerTime = getServerTimeNow().getTime()

  const lastUpdateRef = useRef<Instant>()

  const [atendimentoMaisRecenteConflitante, setAtendimentoMaisRecenteConflitante] = useState(null)

  const { refetch: fetchAtendimentoMaisRecente } = useUltimoAtendimentoProfissionalCidadaoQuery({ skip: true })

  const verificarExisteAtendimentoMaisRecente = async (dataAtendimento: Instant, cidadaoId: ID) => {
    const ultimoAtendimento = await fetchAtendimentoMaisRecente({
      input: { cidadaoId, onlyAtendimentosEvoluemProntuario: true },
    })
    const possuiAtendimentoMaisRecente =
      ultimoAtendimento?.data?.ultimoAtendimentoProfissionalCidadao?.finalizadoEm > dataAtendimento
    if (possuiAtendimentoMaisRecente) {
      setAtendimentoMaisRecenteConflitante(ultimoAtendimento?.data?.ultimoAtendimentoProfissionalCidadao)
    }

    return !possuiAtendimentoMaisRecente
  }

  const [state, setState] = useLocalStorageState<AtendimentosRegistroTardioFilterModel>(
    `${acessoId}/registro-tardio-state`,
    { defaultValue: filterDefault }
  )

  const isSomenteNaoFinalizados = state?.filter?.isSomenteNaoFinalizados

  const [backupFilterStorage, setBackupFilterStorage, deleteBackupFilterStorage] = useLocalStorageState<
    ListaRegistroTardioFilterPopperModel
  >(`${acessoId}/registro-tardio-filter-backup`)

  const [adicionarMode, setAdicionarMode] = useState(!!cidadaoIdFromURL)

  const { data: pollingEnabled } = useListaAtendimentoPollingEnabledQuery()

  const { refetch: refetchPolling } = useListaAtendimentoPollingQuery({
    variables: {
      time: new Date(state.lastUpdate).valueOf(),
    },
    skip: !pollingEnabled?.atendimentoPollingEnabled,
  })

  const {
    marcouAlertComoVisto,
    deveMostrarAlertNovamente,
    setAlertComoVisto,
  } = useListaAtendimentosNaoFinalizadosAlert(state.lastDailyUpdate, true)

  const periodoParaNaoFinalizados = usePeriodoParaAtendimentosNaoFinalizados(state.lastUpdate)

  const { data: possuiAtendimentosNaoFinalizados } = usePossuiAtendimentosNaoFinalizadosQuery({
    variables: {
      input: {
        startDate: periodoParaNaoFinalizados.startDate,
        endDate: periodoParaNaoFinalizados.endDate,
        isRegistroTardio: true,
      },
    },
    skip: marcouAlertComoVisto && !deveMostrarAlertNovamente,
  })

  const { data, refetch } = useListaAtendimentoQuery({
    variables: {
      input: convertListaRegistroTardioStateToInput(state, filterDefault.filter.periodo),
    },
  })

  const refetchLista = useCallback(() => {
    refetch()
      .then(() => {
        setState((prevState) => ({
          ...prevState,
          lastUpdate: currentServerTime,
        }))
      })
      .catch(handleRejection)
  }, [currentServerTime, handleRejection, refetch, setState])

  useAtmosphere<AtendimentoTopicModel>({
    topic: `registroTardio/${acesso?.unidadeSaude.id}`,
    onMessage: (data) => data?.idUbs && refetchLista(),
  })

  const handleAdicionarCidadaoClick = () => {
    setAdicionarMode(!adicionarMode)
    analytics.logEvent('adicionar_cidadao_LRT')
  }

  const onChangeFilter = useCallback(
    (filter: ListaRegistroTardioFilterModel) => {
      setState((prevState) => ({
        ...prevState,
        filter,
        pageParams: {
          ...prevState.pageParams,
          page: 0,
        },
        lastUpdate: currentServerTime,
      }))
    },
    [currentServerTime, setState]
  )

  const onChangeFooter = useCallback(
    (pageParams: PageParams) => {
      setState((prevState) => ({ ...prevState, pageParams }))
    },
    [setState]
  )

  const handleClear = useCallback(() => {
    deleteBackupFilterStorage()
    onChangeFilter(filterDefault.filter)
  }, [deleteBackupFilterStorage, filterDefault.filter, onChangeFilter])

  const onClickSomenteRegistrosNaoFinalizados = useCallback(() => {
    onChangeFilter({
      ...filterDefault.filter,
      isSomenteNaoFinalizados: true,
      periodo: { startDate: undefined, endDate: undefined },
      statusAtendimento: [StatusAtendimento.EM_ATENDIMENTO],
    })

    setAlertComoVisto()
  }, [filterDefault.filter, onChangeFilter, setAlertComoVisto])

  const resetFilterToInitialState = useCallback(() => {
    setState((prevState) => {
      return {
        ...prevState,
        filter: {
          ...prevState?.filter,
          query: undefined,
        },
      } as AtendimentosRegistroTardioFilterModel
    })
  }, [setState])

  useEffect(() => {
    deleteBackupFilterStorage()
    resetFilterToInitialState()
  }, [resetFilterToInitialState, deleteBackupFilterStorage])

  useEffect(() => {
    lastUpdateRef.current = state.lastUpdate
  }, [state.lastUpdate])

  useEffect(() => {
    if (pollingEnabled && pollingEnabled.atendimentoPollingEnabled) {
      let shouldFetch = true

      const timeout = setInterval(() => {
        if (shouldFetch) {
          shouldFetch = false
          refetchPolling({ time: new Date(lastUpdateRef.current).valueOf() })
            .then(({ data }) => data.atendimentoPolling && refetchLista())
            .finally(() => (shouldFetch = true))
            .catch(handleRejection)
        }
      }, 2 * 1000)

      return () => {
        clearInterval(timeout)
      }
    }
  }, [handleRejection, pollingEnabled, refetchLista, refetchPolling])

  useEffect(() => {
    if (!state.lastDailyUpdate || differenceInCalendarDays(currentServerTime, new Date(state.lastDailyUpdate)) > 0) {
      if (!isEmpty(backupFilterStorage)) {
        setBackupFilterStorage({ ...backupFilterStorage, periodo: filterDefault.filter.periodo })
      }

      setState((prevValue) => ({
        ...prevValue,
        filter: {
          ...prevValue.filter,
          periodo: filterDefault.filter.periodo,
        },
        lastDailyUpdate: currentServerTime,
      }))
    }
  }, [backupFilterStorage, filterDefault.filter.periodo, currentServerTime, setBackupFilterStorage, setState, state])

  const renderHeading = (
    <VFlow>
      <Alert type='info'>
        Registre os atendimentos na ordem cronológica em que ocorreram. Registros anteriores ao último atendimento não
        serão possíveis.
      </Alert>
      {!!isSomenteNaoFinalizados && (
        <ExibindoSomenteNaoFinalizadosAlert atendimentosOuRegistros='registros' onClear={handleClear} />
      )}
      {possuiAtendimentosNaoFinalizados?.possuiAtendimentosNaoFinalizados &&
        !isSomenteNaoFinalizados &&
        (!marcouAlertComoVisto || deveMostrarAlertNovamente) && (
          <ExistemAtendimentosNaoFinalizadosAlert
            atendimentosOuRegistros='registros'
            onClickExibirSomenteNaoFinalizados={onClickSomenteRegistrosNaoFinalizados}
            onClickManterFiltroAtual={setAlertComoVisto}
          />
        )}
      <CheckPermission permission={Permissions.visualizarListaDeAtendimento.cadastrarEditarEExcluir}>
        <VFlow>
          <Button kind='primary' size='medium' onClick={handleAdicionarCidadaoClick}>
            <Icon
              icon={adicionarMode ? 'angleUp' : 'angleDown'}
              style={css`
                margin-right: 0.5rem;
              `}
            />
            {adicionarMode ? 'Cancelar adição' : 'Adicionar cidadão'}
          </Button>
          {adicionarMode && (
            <ListaRegistroTardioForm
              onSubmit={verificarExisteAtendimentoMaisRecente}
              serverTime={new Date(currentServerTime)}
              cidadaoIdFromURL={cidadaoIdFromURL}
            />
          )}
        </VFlow>
      </CheckPermission>
    </VFlow>
  )

  const renderFilter = (
    <ListaRegistroTardioFilter
      filter={state.filter}
      filterBackup={backupFilterStorage}
      filterDefault={filterDefault.filter}
      onChangeFilter={onChangeFilter}
      updateFilterBackup={setBackupFilterStorage}
      deleteFilterBackup={() => deleteBackupFilterStorage()}
      onClear={handleClear}
    />
  )

  return (
    <Fragment>
      <PrivateRoute
        path={`${match.url}/editar/:atendimentoId(\\d+)`}
        permission={Permissions.visualizarListaDeAtendimento.cadastrarEditarEExcluir}
      >
        <EditarRegistroTardioModalForm
          cidadaoIdFromURL={cidadaoIdFromURL}
          onSubmit={verificarExisteAtendimentoMaisRecente}
        />
      </PrivateRoute>
      <AtendimentoListing
        title='Registro tardio de atendimento'
        heading={renderHeading}
        filter={renderFilter}
        footer={
          <ListaAtendimentoFooter
            pageInfo={data.atendimentos?.pageInfo}
            pageParams={state.pageParams}
            onChange={onChangeFooter}
          />
        }
      >
        {!!atendimentoMaisRecenteConflitante && (
          <RegistroTardioAtendimentoPosteriorModal
            open
            onClose={() => setAtendimentoMaisRecenteConflitante(null)}
            atendimento={atendimentoMaisRecenteConflitante}
          />
        )}
        {data.atendimentos && data.atendimentos.content.length !== 0 ? (
          data.atendimentos.content.map((atend, key) => (
            <ListaRegistroTardioItem onClick={verificarExisteAtendimentoMaisRecente} key={key} atend={atend} />
          ))
        ) : (
          <div style={{ marginTop: '0.5rem' }}> Nenhum resultado encontrado.</div>
        )}
      </AtendimentoListing>
    </Fragment>
  )
}
