import { Text } from 'bold-ui'
import { AsyncProcessingNameEnum } from 'graphql/types.generated'
import { asyncProcessingFinishMessageInfoRecord } from 'hooks/async/model-asyncProcessing'
import React from 'react'
import { Link } from 'react-router-dom'

interface AsyncProcessingFinishMessageProps {
  errorMessage?: string
  asyncProcessingName: AsyncProcessingNameEnum
  onLinkClick: () => void
}

export function AsyncProcessingFinishMessage({
  errorMessage = null,
  asyncProcessingName,
  onLinkClick,
}: AsyncProcessingFinishMessageProps) {
  const { name, path } = asyncProcessingFinishMessageInfoRecord[asyncProcessingName]

  return (
    <Text component='p'>
      O processamento do {name.toLowerCase()} foi finalizado com{' '}
      {errorMessage ? `o seguinte erro: ${errorMessage}` : 'sucesso'}. Clique{' '}
      <Link to={path} onClick={onLinkClick}>
        <Text fontWeight='bold'>aqui</Text>
      </Link>{' '}
      para retornar à tela.
    </Text>
  )
}
