import { AlertType } from 'bold-ui'
import { TipoAlerta } from 'types/enums'

export interface CompartilharVideochamadaAlertData {
  type: AlertType
  message: string
}

export enum AlertVideochamadaType {
  LINK_COMPARTILHADO = 'LINK_COMPARTILHADO',
  ENVIO_EMAIL_SUCESSO = 'ENVIO_EMAIL_SUCESSO',
  ENVIO_EMAIL_ERRO = 'ENVIO_EMAIL_ERRO',
  ENVIO_EMAIL_PROFISSIONAL_SUCESSO = 'ENVIO_EMAIL_PROFISSIONAL_SUCESSO',
  ENVIO_EMAIL_PROFISSIONAL_ERRO = 'ENVIO_EMAIL_PROFISSIONAL_ERRO',
}

export const compartilharChamadaAlertRecord: Record<AlertVideochamadaType, CompartilharVideochamadaAlertData> = {
  LINK_COMPARTILHADO: { type: TipoAlerta.SUCCESS, message: 'Link da chamada copiado com sucesso.' },
  ENVIO_EMAIL_SUCESSO: { type: TipoAlerta.SUCCESS, message: 'Link da chamada enviado com sucesso.' },
  ENVIO_EMAIL_ERRO: { type: TipoAlerta.DANGER, message: 'Envio de link por e-mail falhou.' },
  ENVIO_EMAIL_PROFISSIONAL_SUCESSO: {
    type: TipoAlerta.SUCCESS,
    message: 'E-mail enviado para o profissional convidado com sucesso.',
  },
  ENVIO_EMAIL_PROFISSIONAL_ERRO: {
    type: TipoAlerta.DANGER,
    message: 'Tente novamente, ocorreu uma falha no envio do email.',
  },
}

export const SMTP_NAO_CONFIGURADO_MESSAGE =
  'A funcionalidade de envio de e-mail precisa ser configurada pelo administrador da instalação.'
