import { Cell } from 'bold-ui'
import { TextField } from 'components/form'
import React from 'react'
import { MetaRoot } from 'util/metaPath'

import { MedicamentoFormModel } from '../model'

export interface MedicamentoNaoCadastradoSectionProps {
  path: MetaRoot<MedicamentoFormModel>
}

export default function MedicamentoNaoCadastradoSection({ path }: MedicamentoNaoCadastradoSectionProps) {
  return (
    <>
      <Cell size={7} data-cy='PrincipioAtivo'>
        <TextField
          name={path.principioAtivoText}
          label='Princípio ativo'
          maxLength={200}
          required
          data-cy='PrincipioAtivoTextField'
        />
      </Cell>
      <Cell size={5}>
        <TextField name={path.concentracaoText} label='Concentração' maxLength={100} data-cy='ConcentracaoTextField' />
      </Cell>
    </>
  )
}
