/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, HFlow, Icon } from 'bold-ui'
import { AgeRangeType } from 'components/date/AgeRange'
import { RadioGroupField, SubmitButton } from 'components/form'
import { AgeRangeField } from 'components/form/field/AgeRangeField'
import { EquipeResponsavelField } from 'components/form/field/select/EquipeResponsavelSelectField/EquipeResponsavelField'
import { MicroareaSelectField } from 'components/form/field/select/MicroareaSelectField/MicroareaSelectField'
import { InfoIcon } from 'components/InfoIcon'
import { PopperButton, PopperControls } from 'components/popper'
import { useFlags } from 'config/useFlagsContext'
import {
  Equipe,
  GrupoAlvoBuscaAtivaVacinacaoEnum,
  StatusVacinacaoEnum,
  TipoVisualizacaoBuscaAtivaVacinacaoEnum,
  UnidadeSaude,
} from 'graphql/types.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { Fragment, memo, useEffect, useState } from 'react'
import { FormRenderProps } from 'react-final-form'
import { UnidadeResponsavelLabel } from 'view/acompanhamento-cidadaos-vinculados/components/UnidadeResponsavelLabel'

import BuscaAtivaVacinacaoFilterPopper from './component/BuscaAtivaVacinacaoFilterPopper'
import { GrupoAlvoVacinacaoSelectField } from './component/GrupoAlvoVacinacaoSelectField'
import { items as statusDoseEnumRecord, StatusDoseImunoSelectField } from './component/StatusDoseImunoSelectField'
import {
  BuscaAtivaVacinacaoFilterPopperFormModel,
  BuscaAtivaVacinacaoFormModel,
  metaBuscaAtivaVacinacao,
  tipoVisualizacaoRecord,
} from './model'
import {
  getFirstPlaceholderByGrupoAlvoToAgeRangeField,
  getSecondPlaceholderByGrupoAlvoToAgeRangeField,
  onClearField,
} from './utils'

interface BuscaAtivaVacinacaoFormProps extends FormRenderProps<BuscaAtivaVacinacaoFormModel> {
  unidadeSaude: Pick<UnidadeSaude, 'id' | 'nome'>
  equipe: Equipe
  isCoordenador: boolean
}

function BuscaAtivaVacinacaoForm(props: BuscaAtivaVacinacaoFormProps) {
  const { handleSubmit, hasValidationErrors, form, unidadeSaude, equipe, isCoordenador, values } = props
  const { BUSCA_ATIVA_VACINACAO_ENABLED } = useFlags()
  const [counterFiltrosAvancadosAplicados, setCounterFiltrosAvancadosAplicados] = useState(0)
  const { analytics } = useFirebase()

  const isAbleToBuscar = isCoordenador || equipe?.id
  const isTipoBuscaAtivaCovid = values.tipoVisualizacao === TipoVisualizacaoBuscaAtivaVacinacaoEnum.COVID_19

  const statusField = form.getFieldState(metaBuscaAtivaVacinacao.status.absolutePath())

  const shouldCenterInfoIcon = statusField?.touched && statusField?.invalid

  const handleOnTipoVisualizacaoChange = () => {
    form.resetFieldState(metaBuscaAtivaVacinacao.grupoAlvo.absolutePath())
  }

  const itemsToStatusDoseSelect = () => {
    const statusAplicada = [statusDoseEnumRecord[StatusVacinacaoEnum.APLICADA]]
    const statusAtrasada = [statusDoseEnumRecord[StatusVacinacaoEnum.ATRASADA]]
    const statusNoPrazo = [statusDoseEnumRecord[StatusVacinacaoEnum.NO_PRAZO]]

    if (isTipoBuscaAtivaCovid) {
      return statusAplicada
    }

    if (values.grupoAlvo?.enum === GrupoAlvoBuscaAtivaVacinacaoEnum.GESTANTES) {
      return statusAtrasada
    }

    if (
      values.grupoAlvo?.enum === GrupoAlvoBuscaAtivaVacinacaoEnum.ADULTOS ||
      values.grupoAlvo?.enum === GrupoAlvoBuscaAtivaVacinacaoEnum.IDOSOS
    ) {
      return statusNoPrazo
    }

    return [...statusNoPrazo, ...statusAtrasada]
  }

  const cleanFilterPopperValues = () => {
    form.change(metaBuscaAtivaVacinacao.filtrosAvancados.imunobiologicos.absolutePath(), null)
    form.change(metaBuscaAtivaVacinacao.filtrosAvancados.doses.absolutePath(), null)
    form.change(metaBuscaAtivaVacinacao.filtrosAvancados.gruposAtendimento.absolutePath(), null)
    form.change(metaBuscaAtivaVacinacao.filtrosAvancados.bairro.absolutePath(), null)
  }

  const applyFilterPopperValues = (formValues: BuscaAtivaVacinacaoFilterPopperFormModel) => {
    analytics.logEvent('aplicar_filtros_avancados_busca_ativa_vacinacao')

    form.change(metaBuscaAtivaVacinacao.filtrosAvancados?.imunobiologicos.absolutePath(), formValues.imunobiologicos)
    form.change(metaBuscaAtivaVacinacao.filtrosAvancados?.doses.absolutePath(), formValues.doses)
    form.change(
      metaBuscaAtivaVacinacao.filtrosAvancados?.gruposAtendimento.absolutePath(),
      formValues.gruposAtendimento
    )
    form.change(metaBuscaAtivaVacinacao.filtrosAvancados?.bairro.absolutePath(), formValues.bairro)
  }

  const renderPopper = ({ close }: PopperControls) => {
    const initialValues: BuscaAtivaVacinacaoFilterPopperFormModel = {
      imunobiologicos: values.filtrosAvancados?.imunobiologicos,
      doses: values.filtrosAvancados?.doses,
      gruposAtendimento: values.filtrosAvancados?.gruposAtendimento,
      bairro: values.filtrosAvancados?.bairro,
    }

    return (
      <BuscaAtivaVacinacaoFilterPopper
        isTipoVisualizacaoCovid={isTipoBuscaAtivaCovid}
        formValues={initialValues}
        grupoAlvoId={values.grupoAlvo?.id}
        closeFilterPopper={close}
        clearFilterPopper={cleanFilterPopperValues}
        applyFilterPopper={applyFilterPopperValues}
      />
    )
  }

  useEffect(() => {
    setCounterFiltrosAvancadosAplicados(
      (values.filtrosAvancados?.imunobiologicos?.length ?? 0) +
        (values.filtrosAvancados?.doses?.length ?? 0) +
        (values.filtrosAvancados?.gruposAtendimento?.length ?? 0) +
        (values.filtrosAvancados?.bairro ? 1 : 0)
    )
  }, [values.filtrosAvancados, BUSCA_ATIVA_VACINACAO_ENABLED])

  const handleOnSubmit = () => {
    analytics.logEvent('buscar_cidadaos_busca_ativa_vacinacao')
    handleSubmit()
    !hasValidationErrors && form.getRegisteredFields().forEach((field) => form.resetFieldState(field))
  }

  const shouldDisable =
    isTipoBuscaAtivaCovid ||
    values.grupoAlvo?.enum === GrupoAlvoBuscaAtivaVacinacaoEnum.GESTANTES ||
    values.grupoAlvo?.enum === GrupoAlvoBuscaAtivaVacinacaoEnum.ADULTOS ||
    values.grupoAlvo?.enum === GrupoAlvoBuscaAtivaVacinacaoEnum.IDOSOS

  return (
    <Fragment>
      <Cell size={3} style={styles.cell}>
        <UnidadeResponsavelLabel nomeUnidadeSaude={unidadeSaude.nome} />
      </Cell>
      <Cell size={isCoordenador ? 4 : 3} style={styles.cell}>
        <EquipeResponsavelField
          name={metaBuscaAtivaVacinacao.equipe}
          equipe={equipe}
          unidadeSaudeId={unidadeSaude.id}
          hasAccessToTodasEquipes={isCoordenador}
          placeholder='Selecione uma equipe'
          required={isCoordenador}
          onClear={() => onClearField(form, metaBuscaAtivaVacinacao.equipe.absolutePath())}
        />
      </Cell>
      <Cell size={3} style={styles.cell}>
        <MicroareaSelectField
          name={metaBuscaAtivaVacinacao.microareas}
          label='Microárea'
          metaEquipe={metaBuscaAtivaVacinacao.equipe}
          placeholder='Todas as microáreas'
        />
      </Cell>
      <Cell size={12}>
        <RadioGroupField
          name={metaBuscaAtivaVacinacao.tipoVisualizacao}
          required
          label='Tipo de visualização'
          onChange={handleOnTipoVisualizacaoChange}
          options={Object.values(TipoVisualizacaoBuscaAtivaVacinacaoEnum).map((tipoVisualizacao) => ({
            label: tipoVisualizacaoRecord[tipoVisualizacao],
            value: tipoVisualizacao,
          }))}
        />
      </Cell>
      <Cell size={4}>
        <GrupoAlvoVacinacaoSelectField
          name={metaBuscaAtivaVacinacao.grupoAlvo}
          label='Grupo-alvo'
          placeholder='Selecione um grupo-alvo'
          required
          isTipoBuscaAtivaCovid={isTipoBuscaAtivaCovid}
          onClear={() => onClearField(form, metaBuscaAtivaVacinacao.grupoAlvo.absolutePath())}
        />
      </Cell>
      <Cell size={5}>
        <AgeRangeField
          name={metaBuscaAtivaVacinacao.faixaEtaria}
          label='Faixa etária'
          disabled={!values.grupoAlvo}
          maxLength={values.faixaEtaria?.periodo === AgeRangeType.DIAS ? 4 : 3}
          firstInputPlaceholder={getFirstPlaceholderByGrupoAlvoToAgeRangeField[values.grupoAlvo?.enum]}
          secondInputPlaceholder={
            getSecondPlaceholderByGrupoAlvoToAgeRangeField(values.faixaEtaria?.periodo)[values.grupoAlvo?.enum]
          }
          rangeTypeOptions={
            values.grupoAlvo?.enum !== GrupoAlvoBuscaAtivaVacinacaoEnum.CRIANCAS
              ? [AgeRangeType.ANOS]
              : Object.values(AgeRangeType)
          }
        />
      </Cell>
      <Cell size={12} />
      <Cell size={3}>
        <HFlow alignItems={shouldCenterInfoIcon ? 'center' : 'flex-end'}>
          <StatusDoseImunoSelectField
            name={metaBuscaAtivaVacinacao.status}
            label='Status da vacina'
            placeholder='Selecione um status'
            isTipoBuscaAtivaCovid={isTipoBuscaAtivaCovid}
            required
            items={itemsToStatusDoseSelect()}
            disabled={shouldDisable}
            onClear={() => onClearField(form, metaBuscaAtivaVacinacao.status.absolutePath())}
          />
          <InfoIcon
            icon='infoCircleFilled'
            text='Único(s) status disponível(is)'
            style={!shouldCenterInfoIcon && styles.centerButton}
          />
        </HFlow>
      </Cell>
      <Cell size={12}>
        <PopperButton
          kind='primary'
          size='small'
          skin='outline'
          placement='bottom-start'
          renderPopper={renderPopper}
          closeOnBackdropClick
        >
          <Icon icon='filterOutline' />
          {`Filtros avançados ${counterFiltrosAvancadosAplicados > 0 ? ` (${counterFiltrosAvancadosAplicados}) ` : ''}`}
        </PopperButton>
      </Cell>
      <Cell size={12} style={styles.cell}>
        <HFlow justifyContent='flex-end'>
          <Button onClick={form.reset} disabled={!isAbleToBuscar}>
            Limpar filtros
          </Button>
          <SubmitButton handleSubmit={handleOnSubmit} kind='primary' disabled={!isAbleToBuscar}>
            <HFlow hSpacing={0.5}>
              <Icon icon='zoomOutline' />
              Buscar cidadãos
            </HFlow>
          </SubmitButton>
        </HFlow>
      </Cell>
    </Fragment>
  )
}

const styles = {
  cell: css`
    margin-top: 1rem;
  `,
  centerButton: css`
    margin-bottom: 0.5rem;
  `,
}

export default memo(BuscaAtivaVacinacaoForm)
