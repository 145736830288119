import { cpf, createValidator, required, senha } from 'util/validation'

import { AlterarSenhaModel, NovaSenhaModel } from './model'

export const novaSenhaInputValidator = createValidator<NovaSenhaModel>(
  {
    novaSenha: [required, senha],
    confirmacaoSenha: [required, senha],
  },
  (values: NovaSenhaModel, errors) => {
    if (!errors.novaSenha && !errors.confirmacaoSenha && values.confirmacaoSenha !== values.novaSenha) {
      return {
        confirmacaoSenha: 'A confirmação da senha deve ser igual à nova senha.',
      }
    }
  }
)

export const alterarSenhaValidator = (options: {
  senhaAtualRequired: boolean
  tokenRequired: boolean
  usuarioRequired: boolean
}) =>
  createValidator<AlterarSenhaModel>({
    novaSenhaInput: novaSenhaInputValidator,
    senhaAtual: options.senhaAtualRequired ? [required, senha] : [],
    token: options.tokenRequired ? [required] : [],
    usuario: options.usuarioRequired ? [required, cpf] : [],
  })
