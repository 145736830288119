import { Icon } from 'bold-ui'
import { useErrorHandler } from 'components/error'
import { useFirebase } from 'hooks/firebase/useFirebase'
import React from 'react'
import { EventoAgendaLotacao } from 'view/agenda/model-agenda'

import { downloadImpressaoAgendamentoCidadao } from '../../impressao/downloadImpressaoAgendamentos'
import MenuAcoesAgendamentoButton from '../MenuAcoesAgendamentoButton'
import { AcaoAgendamentoButtonProps } from '../model'

interface ImprimirAgendamentoButtonProps extends Omit<AcaoAgendamentoButtonProps, 'event'> {
  event: Pick<EventoAgendaLotacao, 'id' | 'nomeCidadao'>
}

export function ImprimirAgendamentoButton(props: ImprimirAgendamentoButtonProps) {
  const { event, tooltip, isDropdown = false } = props

  const { analytics } = useFirebase()
  const handleError = useErrorHandler()

  const handleClickImprimirAgendamento = () => {
    analytics.logEvent('impressao_agendamento_cidadao')
    return downloadImpressaoAgendamentoCidadao({ agendamentoId: event.id, nomeCidadao: event.nomeCidadao }).catch(
      handleError
    )
  }

  return (
    <MenuAcoesAgendamentoButton
      text='Imprimir agendamento'
      tooltip={tooltip}
      isDropdown={isDropdown}
      onClick={handleClickImprimirAgendamento}
      icon={<Icon icon='printerOutline' />}
    />
  )
}
