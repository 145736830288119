import { Cell, Grid, Theme, VFlow } from 'bold-ui'
import { useScrollPosition } from 'bold-ui/lib/hooks'
import CheckPermission from 'components/auth/CheckPermission'
import { IdadeGestacionalAcompanhamentoPreNatal } from 'graphql/types.generated'
import { useCabecalhoFixo } from 'hooks/useCabecalhoFixo'
import React, { CSSProperties, forwardRef, useMemo } from 'react'
import { isCidadaoIdoso } from 'util/isCidadaoIdoso'
import { useLocalStyles } from 'view/atendimentos/atendimento-individual/AtendimentoIndividualView'
import { CiapCidPreNatal, meta } from 'view/atendimentos/atendimento-individual/model'
import { MedicoesPanelModel } from 'view/atendimentos/components/MedicoesPanel/MedicoesPanel'
import { LotacaoAtendimento } from 'view/atendimentos/types/AtendimentoProfissionalModel'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { LembreteFormModel } from '../../components/modals/lembretes/components/LembreteForm'
import { grupoCboListaProblemasCondicoes } from '../../components/modals/lista-problemas/acessos'
import { PeriodoGestacaoModel } from '../../components/modals/types/PeriodoGestacaoModel'
import { grupoCboAlergias } from '../avaliacao/acessos'
import { AlergiaReacaoModel } from '../avaliacao/components/alergias-reacoes/model'
import { ProblemaCondicaoModel } from '../avaliacao/components/problemas-condicoes/model-problemasCondicoes'
import { grupoCboMedicoes, grupoCboResultadosExames } from '../objetivo/acessos'
import { PuericulturaModel } from '../objetivo/puericultura/model'
import { ResultadosExamesModel } from '../objetivo/resultados-exames/model'
import { grupoCboLembretes } from '../plano/acessos'
import { grupoCboAcompanhamentoPreNatal } from '../pre-natal/acessos'
import { PreNatalFormModel } from '../pre-natal/model-preNatal'
import { grupoCboAcompanhamentoIdoso } from './acompanhamento-idoso/acessos'
import { AcompanhamentoIdosoSection } from './acompanhamento-idoso/AcompanhamentoIdosoSection'
import { AcompanhamentoPuericulturaSection } from './acompanhamento-puericultura/AcompanhamentoPuericulturaSection'
import { AlergiasSection } from './alergias/AlergiasSection'
import { CondicoesAutorreferidasSection } from './condicoes-autorreferidas/CondicoesAutorreferidasSection'
import { LembretesSection } from './lembretes/LembretesSection'
import { ProblemasSection } from './lista-problemas/ProblemasSection'
import { MedicamentosSection } from './medicamentos/MedicamentosSection'
import { MedicoesSection } from './medicoes/MedicoesSection'
import { PreNatalSection } from './pre-natal/components/PreNatalSection'
import { ResultadosExamesSection } from './resultados-exames/ResultadosExamesSection'

interface AsideViewProps {
  lotacao?: LotacaoAtendimento
  prontuarioId: ID
  atendimentoProfissionalId?: ID
  atendimentoId?: ID
  dataAtendimento: Instant
  cidadao: CidadaoAtendimento
  isGestante: boolean
  gestacoes: PeriodoGestacaoModel[]
  ciapCidPreNatal?: CiapCidPreNatal
  somenteCiap?: boolean
  problemasAvaliacao?: ProblemaCondicaoModel[]
  problemasLPC?: ProblemaCondicaoModel[]
  alergiasAtendimentoAtual?: AlergiaReacaoModel[]
  medicoesAtendimentoAtual?: MedicoesPanelModel
  lembretesCache?: LembreteFormModel[]
  vacinacaoEmDia?: boolean
  puericultura?: PuericulturaModel
  preNatal?: PreNatalFormModel
  dum?: string
  resultadosExames?: ResultadosExamesModel
  readOnlyMedicoesSection?: boolean
  isGravidezAltoRisco?: boolean
  width: number
  headerHeight: number
  idadeGestacional?: IdadeGestacionalAcompanhamentoPreNatal
  hasPermissionPreNatal?: boolean
  isAtendimentoProcedimentos?: boolean
  isAtendimentoObservacao?: boolean
  isObservacaoAndAuxiliar?: boolean
  readOnly?: boolean
}

export const AsideView = forwardRef<HTMLDivElement, AsideViewProps>((props, ref) => {
  const {
    prontuarioId,
    atendimentoId,
    atendimentoProfissionalId,
    dataAtendimento,
    cidadao,
    isGestante,
    gestacoes,
    problemasAvaliacao,
    problemasLPC,
    alergiasAtendimentoAtual,
    medicoesAtendimentoAtual,
    lembretesCache,
    vacinacaoEmDia,
    puericultura,
    preNatal,
    dum,
    resultadosExames,
    readOnlyMedicoesSection,
    isGravidezAltoRisco,
    ciapCidPreNatal,
    somenteCiap,
    lotacao,
    width,
    headerHeight,
    idadeGestacional,
    hasPermissionPreNatal = false,
    isAtendimentoProcedimentos = false,
    isAtendimentoObservacao = false,
    isObservacaoAndAuxiliar = false,
    readOnly = false,
  } = props

  const { scrollY } = useScrollPosition()
  const isCabecalhoFixo = useCabecalhoFixo()
  const { classes } = useLocalStyles(createStyles, isCabecalhoFixo, scrollY, headerHeight, width)

  const accessAcompanhamentoPuericultura = cidadao.idadeEmAnos < 19

  const renderSections = useMemo(() => {
    return (
      <div ref={ref}>
        <VFlow>
          <Grid>
            <Cell size={12}>
              {accessAcompanhamentoPuericultura && (
                <AcompanhamentoPuericulturaSection
                  prontuarioId={prontuarioId}
                  vacinacaoEmDia={vacinacaoEmDia}
                  puericultura={puericultura}
                  cidadao={cidadao}
                  dataReferencia={dataAtendimento}
                  atendimentoId={atendimentoId}
                />
              )}
              {isCidadaoIdoso(cidadao.idadeEmAnos) && (
                <CheckPermission permission={grupoCboAcompanhamentoIdoso}>
                  <AcompanhamentoIdosoSection prontuarioId={prontuarioId} cidadaoId={cidadao.id} />
                </CheckPermission>
              )}
              {isGestante && (
                <CheckPermission permission={grupoCboAcompanhamentoPreNatal}>
                  <PreNatalSection
                    prontuarioId={prontuarioId}
                    cidadaoId={cidadao.id}
                    isAltoRiscoAtendimentoAtual={isGravidezAltoRisco}
                    preNatalAtendimentoAtual={preNatal}
                    dumAtendimentoAtual={dum}
                    resultadosExamesAtendimentoAtual={resultadosExames}
                    dataAtendimento={dataAtendimento}
                    atendimentoId={atendimentoId}
                    readOnly={readOnly || !hasPermissionPreNatal}
                    idadeGestacional={idadeGestacional}
                  />
                </CheckPermission>
              )}
              <CheckPermission permission={grupoCboAlergias.visualizar}>
                <AlergiasSection
                  prontuarioId={prontuarioId}
                  alergiasAtendimentoAtual={alergiasAtendimentoAtual ?? []}
                  fieldName={meta.avaliacao.alergias}
                />
              </CheckPermission>
              <CheckPermission permission={grupoCboListaProblemasCondicoes.visualizar}>
                <ProblemasSection
                  cidadao={cidadao}
                  ciapCidPreNatal={ciapCidPreNatal}
                  problemasAvaliacao={problemasAvaliacao}
                  problemasLPC={problemasLPC}
                  dataReferencia={dataAtendimento}
                  prontuarioId={prontuarioId}
                  somenteCiap={somenteCiap}
                  isAtendimentoObservacao={isAtendimentoObservacao}
                  readOnly={readOnly || isAtendimentoProcedimentos}
                />
              </CheckPermission>
              <CheckPermission permission={grupoCboMedicoes.visualizar}>
                <MedicoesSection
                  prontuarioId={prontuarioId}
                  fieldName={meta.medicoesAnteriores}
                  medicoesAtendimentoAtual={medicoesAtendimentoAtual}
                  cidadao={{
                    id: cidadao.id,
                    dataNascimento: cidadao.dataNascimento,
                    sexo: cidadao.sexo,
                    identidadeGeneroDbEnum: cidadao?.identidadeGeneroDbEnum,
                  }}
                  isGestante={isGestante}
                  gestacoes={gestacoes}
                  preNatalAtendimentoAtual={preNatal}
                  dumAtendimentoAtual={dum}
                  resultadosExamesAtendimentoAtual={resultadosExames}
                  dataAtendimento={dataAtendimento}
                  readOnly={readOnly || readOnlyMedicoesSection}
                  isAtendimentoObservacao={isAtendimentoObservacao}
                />
              </CheckPermission>
              <MedicamentosSection
                editable={!readOnly && !isObservacaoAndAuxiliar}
                prontuarioId={prontuarioId}
                dataReferencia={dataAtendimento}
                isCidadaoIdoso={isCidadaoIdoso(cidadao.idadeEmAnos)}
                atendimentoProfissionalId={atendimentoProfissionalId}
                isAtendimentoObservacao={isAtendimentoObservacao}
              />
              <CondicoesAutorreferidasSection cidadaoId={cidadao.id} />
              <CheckPermission permission={grupoCboLembretes.visualizar}>
                <LembretesSection
                  lotacao={lotacao}
                  prontuarioId={prontuarioId}
                  lembretesCache={lembretesCache}
                  name={!readOnly && meta.lembretes}
                  isAtendimentoObservacao={isAtendimentoObservacao}
                />
              </CheckPermission>
              <CheckPermission permission={grupoCboResultadosExames.visualizar}>
                <ResultadosExamesSection
                  prontuarioId={prontuarioId}
                  atendimentoProfissionalId={atendimentoProfissionalId}
                  isAtendimentoObservacao={isAtendimentoObservacao}
                />
              </CheckPermission>
            </Cell>
          </Grid>
        </VFlow>
      </div>
    )
  }, [
    ref,
    accessAcompanhamentoPuericultura,
    prontuarioId,
    vacinacaoEmDia,
    puericultura,
    cidadao,
    dataAtendimento,
    atendimentoId,
    isGestante,
    isGravidezAltoRisco,
    preNatal,
    dum,
    resultadosExames,
    readOnly,
    hasPermissionPreNatal,
    idadeGestacional,
    alergiasAtendimentoAtual,
    ciapCidPreNatal,
    problemasAvaliacao,
    problemasLPC,
    somenteCiap,
    isAtendimentoObservacao,
    isAtendimentoProcedimentos,
    medicoesAtendimentoAtual,
    gestacoes,
    readOnlyMedicoesSection,
    isObservacaoAndAuxiliar,
    atendimentoProfissionalId,
    lotacao,
    lembretesCache,
  ])

  return <aside className={classes.aside}>{renderSections}</aside>
})

const createStyles = (
  theme: Theme,
  isCabecalhoFixo: boolean,
  scrollY: number,
  headerHeight: number,
  width: number
) => ({
  aside: {
    width: width ?? '100%',
    paddingRight: '1rem',
    position: isCabecalhoFixo ? 'fixed' : 'static',
    top: isCabecalhoFixo && headerHeight,
    marginTop: isCabecalhoFixo && '1rem',
    height: `calc(100% - ${isCabecalhoFixo ? calcAsideHeightDiff(scrollY) + 'rem' : '0px'})`,
    overflow: isCabecalhoFixo ? 'hidden auto' : 'hidden',
    '::-webkit-scrollbar': {
      width: '0.3rem',
      height: '0.3rem',
    },
    '::-webkit-scrollbar-thumb': {
      background: theme.pallete.gray.c40,
      borderRadius: theme.radius.tag,
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: theme.pallete.gray.c60,
    },
    '::-webkit-scrollbar-track': {
      background: theme.pallete.surface.main,
      borderRadius: theme.radius.tag,
      boxShadow: `inset 0.4rem 0.625rem 0.75rem ${theme.pallete.divider}`,
    },
  } as CSSProperties,
})

function calcAsideHeightDiff(scrollY: number): number {
  const distBottom = window.document.body.offsetHeight - (scrollY + window.innerHeight)
  return Math.max(0, 6.1 - distBottom / 16) + 9
}
