import { PivotTableProps } from 'components/pivot-table/PivotTable'
import {
  CondutaRelatorioGerencialCuidadoCompartilhadoSelectFieldDocument,
  PrimeiraRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldDocument,
  PrioridadeRelatorioGerencialCuidadoCompartilhadoSelectFieldDocument,
  RealizadoPorRelatorioGerencialCuidadoCompartilhadoSelectFieldDocument,
  TempoDeEsperaRelatorioGerencialCuidadoCompartilhadoSelectFieldDocument,
  TempoDeRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldDocument,
} from 'graphql/hooks.generated'

import { KeyMapping, keyMappings, VisibilidadeModeloPersonalizadoEnum } from '../common/keyMapping-relatorioGerencial'
import { RelatorioGerencialCuidadoCompartilhadoModel } from './model-relatorioGerencialCuidadoCompartilhado'

const PRIORIDADE = new Map([
  ['Baixa', 1],
  ['Média', 2],
  ['Alta', 3],
  ['Muito alta', 4],
  ['Não informado', 5],
])

const conduta: KeyMapping = {
  keyName: 'Conduta',
  formatter: (s: string) => s.capitalize(),
  visibility: VisibilidadeModeloPersonalizadoEnum.PUBLICO,
  queryDocument: CondutaRelatorioGerencialCuidadoCompartilhadoSelectFieldDocument,
}

const prioridade: KeyMapping = {
  keyName: 'Prioridade',
  formatter: (s: string) => s.capitalize(),
  ordenator: (a: string, b: string) => {
    return PRIORIDADE.get(a) > PRIORIDADE.get(b) ? 1 : -1
  },
  visibility: VisibilidadeModeloPersonalizadoEnum.PUBLICO,
  queryDocument: PrioridadeRelatorioGerencialCuidadoCompartilhadoSelectFieldDocument,
}

const tempoEspera: KeyMapping = {
  keyName: 'Tempo de espera',
  visibility: VisibilidadeModeloPersonalizadoEnum.PUBLICO,
  queryDocument: TempoDeEsperaRelatorioGerencialCuidadoCompartilhadoSelectFieldDocument,
}

const tempoResposta: KeyMapping = {
  keyName: 'Tempo de resposta',
  visibility: VisibilidadeModeloPersonalizadoEnum.PUBLICO,
  queryDocument: TempoDeRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldDocument,
}

const realizadoPor: KeyMapping = {
  keyName: 'Realizado por',
  visibility: VisibilidadeModeloPersonalizadoEnum.PUBLICO,
  queryDocument: RealizadoPorRelatorioGerencialCuidadoCompartilhadoSelectFieldDocument,
}

const primeiraResposta: KeyMapping = {
  keyName: 'Primeira resposta',
  visibility: VisibilidadeModeloPersonalizadoEnum.PUBLICO,
  queryDocument: PrimeiraRespostaRelatorioGerencialCuidadoCompartilhadoSelectFieldDocument,
}

export const cuidadoCompartilhadoKeyMapping: PivotTableProps<
  RelatorioGerencialCuidadoCompartilhadoModel
>['keyMapping'] = new Map<keyof RelatorioGerencialCuidadoCompartilhadoModel, KeyMapping>([
  ['conduta', conduta],
  ['prioridade', prioridade],
  ['turno', keyMappings.turno],
  ['mes', keyMappings.mes],
  ['dia', keyMappings.dia],
  ['diaDaSemana', keyMappings.diaDaSemana],
  ['hora', keyMappings.hora],
  ['semana', keyMappings.semana],
  ['sexo', keyMappings.sexo],
  ['faixaEtaria', keyMappings.faixaEtaria],
  ['tempoEspera', tempoEspera],
  ['tempoResposta', tempoResposta],
  ['producoes', keyMappings.producoes],
  ['realizadoPor', realizadoPor],
  ['primeiraResposta', primeiraResposta],
  ['municipio', keyMappings.municipio],
  ['unidadeSaude', keyMappings.unidadeSaude],
  ['equipe', keyMappings.equipe],
  ['nomeProfissional', keyMappings.nomeProfissional],
  ['cbo', keyMappings.cbo],
  ['categoriaProfissional', keyMappings.categoriaProfissional],
])
