/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableLoadingRow,
  TablePlaceholderRow,
  TableRow,
  Text,
  Theme,
  Tooltip,
  useTheme,
} from 'bold-ui'
import { gray } from 'bold-ui/lib/styles/colors'
import { TableBox } from 'components/table'
import { useUnidadesSaudeByTipoVinculacaoServicoQuery } from 'graphql/hooks.generated'
import { UnidadeSaude, VinculacaoServicoTipoEstabelecimentoEnum } from 'graphql/types.generated'
import { TextFilterType } from 'hooks/filter/model'
import { useFilter } from 'hooks/filter/useFilter'
import { ChangeEvent, Fragment, useCallback, useMemo, useState } from 'react'
import { emptyArray } from 'util/array'
import { isUndefinedOrNull } from 'util/checks'
import { MetaPath, metaPath } from 'util/metaPath'

import { EstabelecimentosReceptoresTableFieldFilter } from './EstabelecimentosReceptoresTableFieldFilter'
import { EstabelecimentosReceptoresTableFieldFilterModel } from './model-vincularEstabelecimentoReferencia'

interface EstabelecimentosReceptoresTableFieldProps {
  name: MetaPath<UnidadeSaude[]>
  disabled: boolean
  estabelecimentosSelecionados: ReadonlyArray<UnidadeSaude>
  formChangeValue: (name: string, value?: UnidadeSaude[]) => void
}

export function EstabelecimentosReceptoresTableField(props: EstabelecimentosReceptoresTableFieldProps) {
  const { name, disabled, estabelecimentosSelecionados, formChangeValue } = props

  const theme = useTheme()
  const styles = createStyles(theme)

  const [filter, setFilter] = useState<EstabelecimentosReceptoresTableFieldFilterModel>({
    query: null,
    mostrarSomenteSemVinculo: false,
  })

  const { data, loading } = useUnidadesSaudeByTipoVinculacaoServicoQuery({
    variables: {
      input: {
        tipoEstabelecimento: VinculacaoServicoTipoEstabelecimentoEnum.VINCULADOS,
      },
    },
  })
  const { unidadesSaudeByTipoVinculacaoServico } = data ?? {}
  const content = (unidadesSaudeByTipoVinculacaoServico ?? []) as UnidadeSaude[]
  const filteredItems = useFilter<UnidadeSaude, EstabelecimentosReceptoresTableFieldFilterModel>({
    items: content,
    filter,
    filtersType: [filterTextTypeConfig],
  })

  const filterSemVinculos = useCallback(() => {
    if (filter.mostrarSomenteSemVinculo) {
      const estabelecimentosSelecionadosId = estabelecimentosSelecionados.map(({ id }) => id)
      return filteredItems.filter(({ id }) => !estabelecimentosSelecionadosId.includes(id))
    } else {
      return filteredItems
    }
  }, [filter.mostrarSomenteSemVinculo, estabelecimentosSelecionados, filteredItems])

  const changeValue = useCallback(
    (
      name: MetaPath<UnidadeSaude[]>,
      joinPrevValues: (prevValue: ReadonlyArray<UnidadeSaude>) => UnidadeSaude[]
    ): void => formChangeValue(name.alias, joinPrevValues(estabelecimentosSelecionados)),
    [estabelecimentosSelecionados, formChangeValue]
  )

  const addItem = useCallback(
    (value) => {
      changeValue(name, (prevValue) => [...prevValue, value])
    },
    [changeValue, name]
  )

  const removeItem = useCallback(
    (value) => {
      changeValue(name, (prevValue) => prevValue.filter((item) => item.id !== value.id))
    },
    [changeValue, name]
  )

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, estabelecimento: UnidadeSaude) => {
      if (event.target.checked) {
        addItem(estabelecimento)
      } else {
        removeItem(estabelecimento)
      }
    },
    [addItem, removeItem]
  )

  const checkAllItems = useCallback(
    (estabelecimentos: UnidadeSaude[], isAllSelected: boolean) => {
      changeValue(name, (prevValues: UnidadeSaude[]): UnidadeSaude[] => {
        if (isAllSelected) {
          const newValuesIds = estabelecimentos ? estabelecimentos.map(({ id }) => id) : emptyArray

          return prevValues.filter((valueItem) => !newValuesIds.includes(valueItem.id))
        } else {
          const prevValuesIds = prevValues ? prevValues.map(({ id }) => id) : []
          const newValues = estabelecimentos.filter(({ id }) => !prevValuesIds.includes(id))

          return [...prevValues, ...newValues]
        }
      })
    },
    [changeValue, name]
  )

  const rows = filterSemVinculos()
  const totalElements = content.length
  const qtdItemsFiltrados = rows.length
  const qtdItemsSelecionados = estabelecimentosSelecionados.length
  const isNotEmpty = useMemo(() => rows?.length > 0, [rows])
  const isAllSelected = useMemo(() => {
    if (estabelecimentosSelecionados && !isUndefinedOrNull(rows)) {
      const estabelecimentosSelecionadosId = estabelecimentosSelecionados.map(({ id }) => id)

      return rows.every(({ id }) => estabelecimentosSelecionadosId.includes(id))
    } else {
      return false
    }
  }, [estabelecimentosSelecionados, rows])

  return (
    <TableBox header={<EstabelecimentosReceptoresTableFieldFilter initialValues={filter} onChange={setFilter} />}>
      <Table hovered>
        <Tooltip text={disabled && 'Selecione um estabelecimento referência'}>
          <TableBody>
            {loading ? (
              <TableLoadingRow />
            ) : (
              <Fragment>
                {totalElements > 0 && (
                  <TableRow style={{ backgroundColor: gray.c90 }}>
                    <TableCell>
                      <Text>
                        <b>
                          {qtdItemsSelecionados} {qtdItemsSelecionados > 1 ? 'selecionadas' : 'selecionada'}
                        </b>{' '}
                        {totalElements > 1
                          ? `das ${totalElements} unidades básicas de saúde disponíveis do município.`
                          : `de ${totalElements} unidade básica de saúde disponível do município.`}
                      </Text>
                    </TableCell>
                  </TableRow>
                )}
                {isNotEmpty ? (
                  <Fragment>
                    <TableRow style={{ backgroundColor: gray.c90 }}>
                      <TableCell>
                        <Checkbox
                          disabled={disabled}
                          label={<Text fontWeight='bold'>Selecionar todas as unidades básicas</Text>}
                          checked={isAllSelected}
                          onChange={() => checkAllItems(rows, isAllSelected)}
                        />
                      </TableCell>
                    </TableRow>
                    {rows.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell>
                          <Checkbox
                            key={row.id}
                            disabled={disabled}
                            label={
                              <Text fontWeight='bold'>
                                {row.nome} - {row.cnes}
                              </Text>
                            }
                            checked={estabelecimentosSelecionados
                              .map((estabelecimento) => estabelecimento.id)
                              .includes(row.id)}
                            onChange={(event) => handleChange(event, row)}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </Fragment>
                ) : (
                  <TablePlaceholderRow />
                )}
              </Fragment>
            )}
          </TableBody>
        </Tooltip>
      </Table>
      <Text style={styles.tableFooter}>{'resultado'.pluralizeAndConcatValue(qtdItemsFiltrados)}</Text>
    </TableBox>
  )
}

const metaRow = metaPath<UnidadeSaude>()
const metaFilter = metaPath<EstabelecimentosReceptoresTableFieldFilterModel>()

const filterTextTypeConfig: TextFilterType = {
  filterField: metaFilter.query,
  searchFields: [metaRow.cnes, metaRow.nome],
  removeTagsOnFilter: true,
}

const createStyles = (theme: Theme) => ({
  tableFooter: css`
    height: 2.5rem;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    border: 1px solid ${theme.pallete.divider};
    border-top: 0;
    font-weight: bold;
  `,
})
