import { Cell, Text, VFlow } from 'bold-ui'
import { MedicamentoCatmatSelectField } from 'components/form/field/select/MedicamentoCatMatSelectField'
import React from 'react'
import { MetaRoot } from 'util/metaPath'

import { handleKeyEnter } from '../../../util'
import { MedicamentoFormModel } from '../model'

export interface MedicamentoSectionProps {
  path: MetaRoot<MedicamentoFormModel>
  values: MedicamentoFormModel
  onChange(): void
}

export default function MedicamentoSection({ onChange, path, values }: MedicamentoSectionProps) {
  return (
    <>
      <Cell size={7} data-cy='PrincipioAtivo'>
        <MedicamentoCatmatSelectField
          name={path.principioAtivoCombo}
          label='Princípio ativo / medicamento'
          onKeyUp={handleKeyEnter}
          required
          onChange={onChange}
          data-cy='PrincipioAtivoCombobox'
        />
      </Cell>
      <Cell size={5} data-cy='Concentracao'>
        <VFlow vSpacing={0.2}>
          <Text fontWeight='bold'>Concentração</Text>
          <Text data-cy='ConcentracaoLabel'>{values?.principioAtivoCombo?.medicamento?.concentracao ?? '-'} </Text>
        </VFlow>
      </Cell>
    </>
  )
}
