/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, Button, HFlow, ModalFooter, Text, VFlow } from 'bold-ui'
import { useFlags } from 'config/useFlagsContext'
import { useCategoriasProibidasQuery, useTiposReceitaProibidosQuery } from 'graphql/hooks.generated'
import { TipoReceitaEnum } from 'graphql/types.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { useForceRender } from 'hooks/useForceRender'
import { Fragment, useRef, useState } from 'react'
import { FieldArray } from 'react-final-form-arrays'
import { metaPath } from 'util/metaPath'

import { EmailUpdateForm } from '../../../components/EmailUpdateForm'
import { MedicamentoFormModel } from '../../model'
import { PrescricaoMedicamentoPlanoModel } from '../../PrescricaoMedicamentoView'
import { filtrarMedicamentos, resolveTipoReceita } from '../../utils/converter-prescricao'
import { EncaminharPrescricaoMedicamentoItem } from '../components/EncaminharPrescricaoMedicamentoItem'
import { GerarPrescricaoDigitalButton } from './GerarPrescricaoDigitalButton'
import { PrescricaoDigitalFormModel, PrescricaoDigitalIntegracaoModel } from './model-prescricaoDigital'
import { PrescricaoDigitalIntegracaoMessage } from './PrescricaoDigitalIntegracaoMessage'
import { PrescricaoDigitalMedicamentosNaoPermitidosList } from './PrescricaoDigitalMedicamentosNaoPermitidosList'

const path = metaPath<PrescricaoMedicamentoPlanoModel>()

interface GerarPrescricaoDigitalFieldArrayProps {
  cidadaoId: ID
  cidadaoEmail: string
  prescricaoDigital: PrescricaoDigitalFormModel
  medicamentos: MedicamentoFormModel[]
  medicamentosSelected: MedicamentoFormModel[]
  setMedicamentosSelected: (medicamentos: MedicamentoFormModel[]) => void
  tipoReceitaSelected: TipoReceitaEnum
  setTipoReceitaSelected: (tipoReceita: TipoReceitaEnum) => void
  statusMessage: PrescricaoDigitalIntegracaoModel
  setStatusMessage: (statusMessage: PrescricaoDigitalIntegracaoModel) => void
  isGerandoPrescricao: boolean
  onSubmit: () => void
  onModalClose: () => void
}

export function GerarPrescricaoDigitalFieldArray(props: GerarPrescricaoDigitalFieldArrayProps) {
  const {
    cidadaoId,
    cidadaoEmail,
    prescricaoDigital,
    medicamentos,
    medicamentosSelected,
    setMedicamentosSelected,
    tipoReceitaSelected,
    setTipoReceitaSelected,
    statusMessage,
    setStatusMessage,
    isGerandoPrescricao,
    onModalClose,
    onSubmit,
  } = props
  const { analytics } = useFirebase()
  const alertUpdateEmailSucceedRef = useRef(false)
  const forceRender = useForceRender()
  const { PRESCRICAO_CONTROLADA } = useFlags()
  const {
    data: { tiposReceitaProibidos },
  } = useTiposReceitaProibidosQuery({ fetchPolicy: 'cache-first' })
  const {
    data: { categoriasProibidas },
  } = useCategoriasProibidasQuery({ fetchPolicy: 'cache-first' })
  const [isEditingEmail, setIsEditingEmail] = useState(false)
  const { medicamentosPermitidos, medicamentosNaoPermitidos } = filtrarMedicamentos(
    medicamentos,
    categoriasProibidas,
    tiposReceitaProibidos
  )

  const handleEmailChange = (newEmail: string) => {
    analytics.logEvent('click_atualizar_email_prescricao_digital')
    if (newEmail !== cidadaoEmail) {
      alertUpdateEmailSucceedRef.current = true
    }
  }

  const handleAlertEmailClose = () => {
    alertUpdateEmailSucceedRef.current = false
    forceRender()
  }

  const handleAlertStatusClose = () => {
    setStatusMessage(null)
  }

  const handleOnEditarEmailClick = () => {
    analytics.logEvent('click_editar_email_prescricao_digital')
  }

  const handleOnChangeMedicamentosSelected = (checked: boolean, medicamento: MedicamentoFormModel) => {
    if (checked) {
      const tipoReceita = resolveTipoReceita(medicamento)
      setTipoReceitaSelected(tipoReceita)
      setMedicamentosSelected([...medicamentosSelected, medicamento])
    } else {
      const medicamentosFiltrados = medicamentosSelected.filter((item) => item.id !== medicamento.id)
      setMedicamentosSelected(medicamentosFiltrados)
      if (medicamentosFiltrados.isEmpty()) {
        setTipoReceitaSelected(null)
      }
    }
  }

  return (
    <FieldArray
      name={path.medicamentos.absolutePath()}
      render={() => (
        <Fragment>
          <div css={styles.mainSection}>
            <VFlow vSpacing={0.5}>
              {!!statusMessage && (
                <PrescricaoDigitalIntegracaoMessage {...statusMessage} onAlertClose={handleAlertStatusClose} />
              )}
              {!!alertUpdateEmailSucceedRef.current && (
                <Alert inline type='success' onCloseClick={handleAlertEmailClose}>
                  E-mail atualizado com sucesso.
                </Alert>
              )}
              <Alert inline type='info'>
                A prescrição será assinada eletronicamente e será enviada ao cidadão através do e-mail cadastrado. A
                responsabilidade dos dados enviados a este e-mail é do prescritor. Verifique atentamente os dados da
                prescrição antes de assinar e enviar.
              </Alert>
              {PRESCRICAO_CONTROLADA && (
                <Text>
                  Selecione os medicamentos que deseja incluir na prescrição digital.{' '}
                  <Text fontWeight='bold'>Só podem ser incluídos medicamentos com o mesmo tipo de receita.</Text>
                </Text>
              )}
              <EmailUpdateForm
                cidadaoId={cidadaoId}
                cidadaoEmail={cidadaoEmail}
                onSubmitSuccess={handleEmailChange}
                editing={isEditingEmail}
                onEditingChange={setIsEditingEmail}
                onEditarClick={handleOnEditarEmailClick}
                initialValues={{ email: cidadaoEmail }}
              />
              <EncaminharPrescricaoMedicamentoItem
                prescricaoDigital={prescricaoDigital}
                medicamentosToPrint={PRESCRICAO_CONTROLADA ? medicamentosPermitidos : medicamentos}
                medicamentoIdsSelecionado={medicamentosSelected.map((item) => item.id)}
                onChange={handleOnChangeMedicamentosSelected}
                disableReceitaControlada
                isPrescricaoDigital
                tipoReceitaSelecionado={tipoReceitaSelected}
              />
              {PRESCRICAO_CONTROLADA && medicamentosNaoPermitidos?.isNotEmpty() && (
                <PrescricaoDigitalMedicamentosNaoPermitidosList medicamentosNaoPermitidos={medicamentosNaoPermitidos} />
              )}
            </VFlow>
          </div>
          <ModalFooter>
            <HFlow justifyContent='flex-end'>
              <Button onClick={onModalClose} style={styles.button} data-cy='CancelarPrescricaoDigitalButton'>
                Cancelar
              </Button>
              <GerarPrescricaoDigitalButton
                medicamentosSelectedLength={medicamentosSelected.length}
                hasEmail={!!cidadaoEmail}
                isGerandoPrescricao={isGerandoPrescricao}
                onClick={onSubmit}
                isEditingEmail={isEditingEmail}
                style={styles.button}
              />
            </HFlow>
          </ModalFooter>
        </Fragment>
      )}
    />
  )
}

const styles = {
  mainSection: css`
    margin-top: -1.5rem;
    padding: 0 2.25rem;
  `,
  button: css`
    width: 10rem;
    height: 3rem;
  `,
}
