import { HeadingSection, VFlow } from 'bold-ui'
import { DefaultFormFooter, Form, FormPrompt, FormProps, FormRenderProps } from 'components/form'
import createDecorator from 'final-form-calculate'
import React, { useMemo } from 'react'
import { useHistory } from 'react-router'
import { createValidator } from 'util/validation'
import { validate as validateDadosCidadaoAD } from 'view/ad/lista/components/CidadaoADDadosFieldGroup'
import { validate as validateCuidador } from 'view/ad/lista/components/CuidadorDadosFieldGroup'
import { validate as validateResponsavel } from 'view/ad/lista/components/ResponsavelDadosFieldGroup'

import { createCidadaoAdCalculations } from './CidadaoADCalculator'
import CidadaoADDadosFieldGroup from './CidadaoADDadosFieldGroup'
import CidadaoADFormModel, { path } from './CidadaoADFormModel'
import CuidadorDadosFieldGroup from './CuidadorDadosFieldGroup'
import ResponsavelDadosFieldGroup from './ResponsavelDadosFieldGroup'

export const validate = () =>
  createValidator<CidadaoADFormModel>(
    {
      responsavel: validateResponsavel,
      cuidador: validateCuidador,
    },
    validateDadosCidadaoAD()
  )

interface CidadaoADDadosFormProps extends FormProps<CidadaoADFormModel> {
  edit?: boolean
}

export default function CidadaoADDadosForm(props: CidadaoADDadosFormProps) {
  const { edit } = props
  const history = useHistory()

  const renderForm = (formProps: FormRenderProps<CidadaoADFormModel>) => {
    return (
      <form noValidate={false}>
        <FormPrompt />
        <VFlow vSpacing={2}>
          <CidadaoADDadosFieldGroup name={path} formProps={formProps} edit={edit} />

          <HeadingSection level={2} title='Responsável'>
            <ResponsavelDadosFieldGroup name={path.responsavel} />
          </HeadingSection>

          <HeadingSection level={2} title='Cuidador'>
            <CuidadorDadosFieldGroup name={path.cuidador} formProps={formProps} />
          </HeadingSection>
          <DefaultFormFooter
            handleSubmit={formProps.handleSubmit}
            onCancel={() => {
              history.push('/ad/lista')
            }}
          />
        </VFlow>
      </form>
    )
  }

  const decorator = useMemo(() => createDecorator(...createCidadaoAdCalculations()), [])

  return (
    <Form<CidadaoADFormModel>
      render={renderForm}
      validateOnBlur={false}
      validate={validate()}
      decorators={[decorator]}
      {...props}
    />
  )
}
