import { Cell, Grid, isEmpty, Tooltip } from 'bold-ui'
import { DateField, FormRenderProps, TextField } from 'components/form'
import { DeclaracaoObitoField } from 'components/form/field/DeclaracaoObitoField'
import { CidSelectField } from 'components/form/field/select/CidSelectField/CidSelectField'
import { resolveValue } from 'components/form/final-form/hooks/useField'
import { AtencaoDomiciliarCondutaDesfecho, AtencaoDomiciliarModalidade } from 'graphql/types.generated'
import moment from 'moment'
import React from 'react'
import { MetaPath, MetaRoot } from 'util/metaPath'
import { createValidator, dataNasc, required } from 'util/validation'

import { AtencaoDomiciliarCondutaDesfechoSelectField } from '../selectFields/AtencaoDomiciliarCondutaDesfechoSelectField'
import { AtencaoDomiciliarModalidadeSelectField } from '../selectFields/AtencaoDomiciliarModalidadeSelectField'
import { AtencaoDomiciliarOrigemSelectField } from '../selectFields/AtencaoDomiciliarOrigemSelectField'
import { CidadaoADSelectField } from '../selectFields/CidadaoADSelectField'
import { CidadaoListaADSelectField } from '../selectFields/CidadaoListaADSelectField'
import CidadaoADFormModel from './CidadaoADFormModel'

export const validate = () =>
  createValidator<CidadaoADFormModel>(
    {
      cidadao: [required],
      dataAdmissao: [required, dataNasc],
      modalidade: [required],
      cid10Principal: [required],
      dataObito: [dataNasc],
      dataSaida: [dataNasc],
    },
    (values: CidadaoADFormModel, errors) => {
      if (!errors.dataAdmissao && !errors.cidadao) {
        if (moment(values?.dataAdmissao).isBefore(values?.cidadao?.dataNascimento, 'date')) {
          errors.dataAdmissao = 'Deve ser maior que a Data de nascimento do Cidadão.'
        }
      }

      if (!errors.dataAdmissao && moment(values?.dataAdmissao).isAfter(values?.dataUltimaVisita)) {
        errors.dataAdmissao = `Deve ser menor ou igual à data da última visita, que foi realizada em ${moment(
          values.dataUltimaVisita
        ).format('DD/MM/YYYY')}.`
      }

      if (values?.modalidade === AtencaoDomiciliarModalidade.POS_OBITO) {
        errors.cidadaoAdEmObito = required(values.cidadaoAdEmObito)
        if (
          !errors.cidadao &&
          !errors.cidadaoAdEmObito &&
          values?.cidadao?.id === values?.cidadaoAdEmObito?.cidadao.id
        ) {
          const msgError = 'Cidadão em AD não pode ser o mesmo que o Cidadão em óbito.'
          errors.cidadao = msgError
          errors.cidadaoAdEmObito = msgError
        }
        if (
          !errors.cidadaoAdEmObito &&
          !errors.dataAdmissao &&
          moment(values?.cidadaoAdEmObito?.cidadao.dataObito).isAfter(values?.dataAdmissao)
        ) {
          errors.dataAdmissao = 'Deve ser maior ou igual que a Data de óbito do Cidadão em óbito.'
        }
      } else {
        errors.procedencia = required(values.procedencia)
      }

      if (values?.id && !errors.condutaDesfecho) {
        errors.condutaDesfecho = required(values.condutaDesfecho)
        if (values?.condutaDesfecho !== AtencaoDomiciliarCondutaDesfecho.PERMANENCIA) {
          errors.dataSaida = required(values.dataSaida)
          if (!errors.dataSaida && moment(values?.dataSaida).isBefore(values?.dataUltimaVisita)) {
            errors.dataSaida = `Deve ser maior ou igual à data da última visita, que foi realizada em ${moment(
              values.dataUltimaVisita
            ).format('DD/MM/YYYY')}.`
          }
          if (!errors.dataSaida && moment().isBefore(values?.dataSaida)) {
            errors.dataSaida = 'Deve ser menor ou igual à data atual.'
          }
          if (!errors.dataAdmissao && !errors.dataSaida && moment(values?.dataAdmissao).isAfter(values?.dataSaida)) {
            errors.dataSaida = 'Deve ser maior ou igual à Data de admissão.'
            errors.dataAdmissao = 'Deve ser menor ou igual à Data de saída.'
          }
          if (values?.condutaDesfecho === AtencaoDomiciliarCondutaDesfecho.OBITO) {
            errors.dataObito = required(values.dataObito)
            if (!errors.dataObito) {
              if (values?.dataObito && moment().isBefore(values?.dataObito)) {
                errors.dataObito = 'Deve ser menor ou igual à data atual.'
              }
              if (!errors.dataAdmissao && moment(values?.dataObito).isBefore(values?.dataAdmissao)) {
                errors.dataObito = 'Deve ser maior ou igual à Data de admissão.'
                errors.dataAdmissao = 'Deve ser menor ou igual à Data de óbito.'
              }
              if (moment(values?.dataObito).isBefore(values?.dataUltimaVisita)) {
                errors.dataObito = `Deve ser maior ou igual à data da última visita, que foi realizada em ${moment(
                  values.dataUltimaVisita
                ).format('DD/MM/YYYY')}.`
              }
            }
          }
        }
      }

      if (!errors.cid10Principal && !errors.cid10Secundario2 && !errors.cid10Secundario1) {
        if (values?.cid10Principal) {
          if (values?.cid10Principal?.id === values?.cid10Secundario1?.id) {
            const msgError = 'CID10 Principal e CID10 Secundário 1 não podem ser iguais.'
            errors.cid10Principal = msgError
            errors.cid10Secundario1 = msgError
          }

          if (values?.cid10Principal?.id === values?.cid10Secundario2?.id) {
            const msgError = 'CID10 Principal e CID10 Secundário 2 não podem ser iguais.'
            errors.cid10Principal = msgError
            errors.cid10Secundario2 = msgError
          }
        }

        if (values?.cid10Secundario1) {
          if (values?.cid10Secundario1?.id === values?.cid10Secundario2?.id) {
            const msgError = 'CID10 Secundário 1 e CID10 Secundário 2 não podem ser iguais.'
            errors.cid10Secundario1 = msgError
            errors.cid10Secundario2 = msgError
          }
        }
      }

      return errors
    }
  )

export interface CidadaoADDadosFieldGroupProps {
  name: MetaPath<CidadaoADFormModel> | MetaRoot<CidadaoADFormModel>
  edit?: boolean
  formProps: FormRenderProps<CidadaoADFormModel>
}

export default function CidadaoADDadosFieldGroup(props: CidadaoADDadosFieldGroupProps) {
  const { name, edit, formProps } = props
  const values = resolveValue(formProps.values, name) || ({} as CidadaoADFormModel)
  const modalidadePosObito = values.modalidade === AtencaoDomiciliarModalidade.POS_OBITO
  const hasCidadaoEmObito = !!formProps.initialValues.cidadaoAdEmObito
  const dataAtual = moment().toDate()

  return (
    <Grid wrap>
      <Cell size={6}>
        <Tooltip text={edit && 'Cidadão não pode ser editado'}>
          <CidadaoListaADSelectField
            name={name.cidadao}
            label='Cidadão'
            required
            disabled={edit}
            obito={false}
            resetFieldState={() =>
              formProps.form.getRegisteredFields().forEach((item) => formProps.form.resetFieldState(item))
            }
          />
        </Tooltip>
      </Cell>
      <Cell size={6} />
      <Cell md={3} lg={2}>
        <DateField name={name.dataAdmissao} label='Data de admissão' required maxDate={dataAtual} />
      </Cell>
      <Cell md={3} lg={2}>
        <Tooltip text={modalidadePosObito && (edit || hasCidadaoEmObito) && 'Modalidade não pode ser editada'}>
          <AtencaoDomiciliarModalidadeSelectField
            name={name.modalidade}
            label='Modalidade'
            required
            disabled={modalidadePosObito && (edit || hasCidadaoEmObito)}
            removePosObito={edit && !modalidadePosObito}
          />
        </Tooltip>
      </Cell>
      <Cell size={6} />
      {modalidadePosObito && (
        <>
          <Cell size={6}>
            <Tooltip text={(edit || hasCidadaoEmObito) && 'Cidadão em óbito não pode ser editado'}>
              <CidadaoADSelectField
                name={name.cidadaoAdEmObito}
                label='Cidadão em óbito'
                required
                disabled={edit || hasCidadaoEmObito}
                condutaDesfecho={AtencaoDomiciliarCondutaDesfecho.OBITO}
              />
            </Tooltip>
          </Cell>
          <Cell size={6} />
        </>
      )}
      <Cell size={3}>
        <Tooltip text={modalidadePosObito && 'Procedência não pode ser editada'}>
          <AtencaoDomiciliarOrigemSelectField
            name={name.procedencia}
            label='Procedência'
            disabled={modalidadePosObito}
            required={!modalidadePosObito}
          />
        </Tooltip>
      </Cell>
      <Cell size={3}>
        <TextField name={name.especifique} label='Especifique' maxLength={100} />
      </Cell>
      <Cell size={6} />
      <Cell size={6}>
        <CidSelectField
          name={name.cid10Principal}
          label='CID10 Principal'
          required
          sexo={isEmpty(values.cidadao?.identidadeGeneroDbEnum) ? values.cidadao?.sexo : undefined}
          renderGrupoCiapRelacionada={false}
        />
      </Cell>
      <Cell size={6} />
      <Cell size={6}>
        <CidSelectField
          name={name.cid10Secundario1}
          label='CID10 Secundário 1'
          sexo={isEmpty(values.cidadao?.identidadeGeneroDbEnum) ? values.cidadao?.sexo : undefined}
          renderGrupoCiapRelacionada={false}
        />
      </Cell>
      <Cell size={6} />
      <Cell size={6}>
        <CidSelectField
          name={name.cid10Secundario2}
          label='CID10 Secundário 2'
          sexo={isEmpty(values.cidadao?.identidadeGeneroDbEnum) ? values.cidadao?.sexo : undefined}
          renderGrupoCiapRelacionada={false}
        />
      </Cell>
      <Cell size={6} />

      {edit && (
        <>
          <Cell size={3}>
            <AtencaoDomiciliarCondutaDesfechoSelectField
              name={name.condutaDesfecho}
              label='Conduta/Desfecho'
              required
            />
          </Cell>
          {values?.condutaDesfecho && values?.condutaDesfecho === AtencaoDomiciliarCondutaDesfecho.OBITO && (
            <>
              <Cell size={2}>
                <DateField name={name.dataObito} label='Data de óbito' required maxDate={dataAtual} />
              </Cell>
              <Cell size={6} />
              <Cell size={3}>
                <DeclaracaoObitoField name={name.numeroObito} label='Número da declaração de óbito' />
              </Cell>
            </>
          )}
          {values?.condutaDesfecho && values?.condutaDesfecho !== AtencaoDomiciliarCondutaDesfecho.PERMANENCIA && (
            <Cell size={2}>
              <DateField name={name.dataSaida} label='Data de saída' required maxDate={dataAtual} />
            </Cell>
          )}
        </>
      )}
    </Grid>
  )
}
