/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, Tabs, VFlow } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { PageContainer } from 'components/layout/PageContainer'
import { TabLink } from 'components/route'
import { useFlags } from 'config/useFlagsContext'
import { Fragment } from 'react'
import { useRouteMatch } from 'react-router'

import { grupoEstabelecimentoEspecializadoCC } from '../acesso-cuidadoCompartilhado'
import { LCC_TABS_ROUTE } from '../CuidadosCompartilhadosRootView'

interface ListaCuidadoCompartilhadoTabsProps {
  isGerente?: boolean
}

export const ListaCuidadoCompartilhadoHeader = (props: ListaCuidadoCompartilhadoTabsProps) => {
  const { isGerente = false } = props
  const { hasEstabelecimentoAuth } = useSession()
  const { PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED } = useFlags()
  const match = useRouteMatch()
  const url = `${match.path.substring(0, match.path.lastIndexOf('/'))}`

  return (
    <PageContainer fluid style={styles.pageContainer}>
      <VFlow>
        <Heading level={1}>Cuidados compartilhados</Heading>
        {!isGerente && (
          <Tabs>
            {PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED && hasEstabelecimentoAuth(grupoEstabelecimentoEspecializadoCC) ? (
              <Fragment>
                <TabLink to={`${url}/${LCC_TABS_ROUTE['EXECUTANTE']}`}>Compartilhados comigo</TabLink>
                <TabLink to={`${url}/${LCC_TABS_ROUTE['UNIDADE']}`}>Compartilhados com a unidade</TabLink>
              </Fragment>
            ) : (
              <Fragment>
                <TabLink to={`${url}/${LCC_TABS_ROUTE['SOLICITANTE']}`}>Solicitados por mim</TabLink>
                <TabLink to={`${url}/${LCC_TABS_ROUTE['EXECUTANTE']}`}>Compartilhados comigo</TabLink>
              </Fragment>
            )}
          </Tabs>
        )}
      </VFlow>
    </PageContainer>
  )
}

const styles = {
  pageContainer: css`
    padding: 1rem 2rem 0rem 2rem;
  `,
}
