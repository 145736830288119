import {
  AtendimentoObservacaoViewQuery,
  AtendimentoProfissionalPreNatal,
  LotacaoOuEstagio,
  Medicao,
  PreNatal,
  StatusAtendimento,
  TipoAtendimentoProfissional,
  TipoEstabelecimentoEnum,
} from 'graphql/types.generated'
import { isCboEnfermeiro, isCboMedico } from 'util/atendimento/cboHelper'
import { isUndefinedOrNull } from 'util/checks'
import { convertAvaliacaoQueryToForm } from 'view/atendimentos/detail/soap/avaliacao/converter'
import { convertFinalizacaoQueryToForm } from 'view/atendimentos/detail/soap/finalizacao'
import { convertObjetivoQueryToForm } from 'view/atendimentos/detail/soap/objetivo'
import { convertPlanoQueryToForm } from 'view/atendimentos/detail/soap/plano/converter'
import { PreNatalViewModel } from 'view/atendimentos/detail/soap/pre-natal/model-preNatal'
import { convertSubjetivoQueryToForm } from 'view/atendimentos/detail/soap/subjetivo'

import { SoapEditableItem, SoapState } from '../model'
import { isSoapEditableItem, SoapEditableModules } from '../util'
import {
  AtendimentoObservacaoModel,
  AtendimentoObservacaoResponsavelModel,
  AtendimentoProfissionalObservacaoInput,
  OBSERVACAO_DISABLE_REMOVE_TOOLTIP_TEXT,
} from './model-atendObservacao'

interface AtendimentoProfissionalPreNatalModel extends Omit<AtendimentoProfissionalPreNatal, 'preNatal'> {
  preNatal?: Omit<PreNatal, 'problema' | 'ultimaDum'>
}

type AtendimentProfissionalObservacaoModel = AtendimentoObservacaoViewQuery['atendimento']['atendimentoProfissional']

interface MedicaoModel extends Omit<Medicao, 'atendimentoProfissional' | 'dataMedicao'> {}

const LISTA_ATENDIMENTO_OBSERVACAO_STATUS = [StatusAtendimento.AGUARDANDO_OBSERVACAO, StatusAtendimento.EM_OBSERVACAO]

export const getResponsavelPelaObservacao = (
  lotacaoId: ID,
  atendObsResponsaveis: AtendimentoObservacaoResponsavelModel[],
  estagioId: ID,
  isEmProcessoRevisao: boolean
): AtendimentoObservacaoResponsavelModel => {
  if (isUndefinedOrNull(lotacaoId) || isUndefinedOrNull(atendObsResponsaveis)) {
    return null
  }

  return atendObsResponsaveis.find(
    (atendObsResponsavel) =>
      (isEmProcessoRevisao && estagioId ? estagioId : lotacaoId) === atendObsResponsavel.responsavel.id
  )
}

export const getAtendimentoObservacaoModel = (
  atendimentoProfissional: AtendimentoProfissionalObservacaoInput,
  isEmProcessoRevisao: boolean,
  isAcessoEstagio: boolean
): AtendimentoObservacaoModel => {
  const isAtendimentoObservacao = !isUndefinedOrNull(atendimentoProfissional?.atendimentoProfissionalObservacao)

  const lotacaoId = atendimentoProfissional?.lotacao?.id
  const estagioId = atendimentoProfissional?.estagio?.id
  const atendObsResponsaveis = isAtendimentoObservacao
    ? atendimentoProfissional.atendimentoProfissionalObservacao.atendimentoObservacao.responsaveis
    : null
  const responsavelObservacao = getResponsavelPelaObservacao(
    lotacaoId,
    atendObsResponsaveis,
    estagioId,
    isEmProcessoRevisao
  )

  const isObservacaoAndResponsavel =
    isAtendimentoObservacao && !isAcessoEstagio && !isUndefinedOrNull(responsavelObservacao)
  const isObservacaoAndAuxiliar = isAtendimentoObservacao && !isObservacaoAndResponsavel
  const ultimoResponsavelObservacao =
    atendimentoProfissional?.atendimentoProfissionalObservacao?.atendimentoObservacao?.ultimoResponsavelObservacao
  const responsaveisObservacao = atendObsResponsaveis?.map((value) => value.responsavel)
  const atendeuComoResponsavel = isObservacaoAndResponsavel ? responsavelObservacao.atendeuComoResponsavel : null
  const isObservacaoEmAndamento = isAtendimentoObservacao && !isUndefinedOrNull(atendimentoProfissional?.finalizadoEm)

  return {
    isAtendimentoObservacao,
    isObservacaoAndResponsavel,
    isObservacaoAndAuxiliar,
    isObservacaoEmAndamento,
    ultimoResponsavelObservacao,
    responsaveisObservacao,
    atendeuComoResponsavel,
  }
}

export const isAtendimentoObservacaoByStatusAtendimento = (statusAtendimento: StatusAtendimento): boolean => {
  return LISTA_ATENDIMENTO_OBSERVACAO_STATUS.includes(statusAtendimento)
}

export const getEditableItemRemoveTooltipText = <T extends SoapEditableItem>(
  item: T,
  defaultTooltipText: string = 'Excluir',
  editableItemTooltipText: string = OBSERVACAO_DISABLE_REMOVE_TOOLTIP_TEXT
): { isEditable: boolean; tooltipText: string } => {
  const isEditable = isSoapEditableItem(item)
  const tooltipText = isEditable ? editableItemTooltipText : defaultTooltipText

  return {
    isEditable,
    tooltipText,
  }
}

export const extractObservacaoEditableModules = (
  atendimentoProfissional: AtendimentProfissionalObservacaoModel
): SoapEditableModules => {
  return {
    evolucaoSubjetivo: atendimentoProfissional?.evolucaoSubjetivo,
    evolucaoObjetivo: atendimentoProfissional?.evolucaoObjetivo,
    evolucaoAvaliacao: atendimentoProfissional?.evolucaoAvaliacao,
    evolucaoPlano: atendimentoProfissional?.evolucaoPlano,
    orientacoes: atendimentoProfissional?.orientacoes,
    atestados: atendimentoProfissional?.atestados,
    encaminhamentos: atendimentoProfissional?.encaminhamentos,
    encaminhamentosEspecializados: atendimentoProfissional?.encaminhamentosEspecializados,
    marcadorConsumoAlimentar: atendimentoProfissional?.marcadorConsumoAlimentar,
    resultadosExames: atendimentoProfissional?.examesResultados,
    solicitacoesExames: atendimentoProfissional?.requisicoesExames,
    receitasMedicamento: atendimentoProfissional?.receitaMedicamento,
    evolucoesProblema: atendimentoProfissional?.evolucaoProblema,
    finalizacao: {
      tipoAtendimento: atendimentoProfissional?.tipoAtendimento,
      condutas: atendimentoProfissional?.condutas,
      racionalidadeSaude: atendimentoProfissional?.racionalidadeSaude,
      lotacaoAtendimentoCompartilhado: atendimentoProfissional?.lotacaoAtendimentoCompartilhado,
      notificacoesCasoSuspeito: atendimentoProfissional?.notificacoesCasoSuspeito,
      procedimentosAtendimento: atendimentoProfissional?.procedimentosAtendimento,
    },
  }
}

export const extractPreNatalFromAtendimentoProfissional = (atendimentoProfissional: {
  medicoes?: MedicaoModel[]
  atendimentoProfissionalPreNatal?: AtendimentoProfissionalPreNatalModel
}): PreNatalViewModel => {
  const medicaoDesteAtendimento = atendimentoProfissional?.medicoes?.find((medicao) => !medicao.medicaoAnterior)

  return {
    id: atendimentoProfissional?.atendimentoProfissionalPreNatal?.preNatal.id,
    alturaUterina: medicaoDesteAtendimento?.valorAlturaUterina,
    batimentoCardiacoFetal: medicaoDesteAtendimento?.valorBatimentoCardiacoFetal,
    edema: atendimentoProfissional?.atendimentoProfissionalPreNatal?.tipoEdema,
    gravidezPlanejada: atendimentoProfissional?.atendimentoProfissionalPreNatal?.gravidezPlanejada,
    movimentacaoFetal: atendimentoProfissional?.atendimentoProfissionalPreNatal?.movimentacaoFetal,
    tipoGravidez: atendimentoProfissional?.atendimentoProfissionalPreNatal?.preNatal?.tipoGravidez?.tipoGravidez,
    isAltoRisco: atendimentoProfissional?.atendimentoProfissionalPreNatal?.preNatal?.altoRisco,
  }
}

export const initialValueObservacao = (
  prevValue: Partial<SoapState>,
  dataReferencia: Instant,
  tipoAtendimento: TipoAtendimentoProfissional,
  data: SoapEditableModules
): Partial<SoapState> => {
  const {
    evolucaoSubjetivo,
    evolucaoObjetivo,
    evolucaoAvaliacao,
    evolucaoPlano,
    finalizacao,
    evolucoesProblema,
    resultadosExames,
    solicitacoesExames,
    orientacoes,
    receitasMedicamento,
    encaminhamentos,
    encaminhamentosEspecializados,
    atestados,
    marcadorConsumoAlimentar,
  } = data

  return {
    ...prevValue,
    subjetivo: convertSubjetivoQueryToForm(prevValue.subjetivo, evolucaoSubjetivo),
    objetivo: convertObjetivoQueryToForm(prevValue.objetivo, tipoAtendimento, {
      evolucaoObjetivo,
      resultadosExames,
      marcadorConsumoAlimentar,
    }),
    avaliacao: convertAvaliacaoQueryToForm(prevValue.avaliacao, {
      evolucaoAvaliacao,
      evolucoesProblema,
    }),
    plano: convertPlanoQueryToForm(prevValue.plano, {
      evolucaoPlano,
      solicitacoesExames,
      orientacoes,
      receitasMedicamento,
      encaminhamentos,
      encaminhamentosEspecializados,
      atestados,
      dataReferencia,
    }),
    finalizacao: convertFinalizacaoQueryToForm(prevValue.finalizacao, finalizacao),
  }
}

export const podeFinalizarAtendimentoObservacao = (
  acesso: LotacaoOuEstagio,
  tipoEstabelecimento: TipoEstabelecimentoEnum
): boolean => {
  const isMedico = isCboMedico(acesso.cbo.cbo2002)
  const isEnfermeiro = isCboEnfermeiro(acesso.cbo.cbo2002)

  const isUpa = tipoEstabelecimento === TipoEstabelecimentoEnum.UPA
  const cboPodeLiberarEmUpa = isUpa && isMedico
  const cboPodeLiberar = !isUpa && (isMedico || isEnfermeiro)

  return cboPodeLiberarEmUpa || cboPodeLiberar
}
