/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, HFlow, Icon, InfoLabel, Text, VFlow } from 'bold-ui'
import theme from 'config/theme'
import { ExameEspecificoEnum } from 'graphql/types.generated'
import { useEffect, useState } from 'react'
import { formatDate } from 'util/date/formatDate'
import { isObjectDeepEmpty } from 'util/object'
import { ResultadoExameQueryModel } from 'view/atendimentos/detail/soap/objetivo/resultados-exames/model'
import { getResultadoExameEspecificoExtractValueFromQuery } from 'view/atendimentos/detail/soap/objetivo/resultados-exames/util-resultadosExames'

interface HistoricoResultadosExamesListItemBodyProps {
  items: ResultadoExameQueryModel[]
  isExpanded: boolean
  collapseItem(): void
}

const DEFAULT_ITEMS_TO_SHOW = 2

export const HistoricoResultadosExamesListItemBody = (props: HistoricoResultadosExamesListItemBodyProps) => {
  const { items, isExpanded, collapseItem } = props

  const [showingItems, setShowingItems] = useState<number>(
    items.length > DEFAULT_ITEMS_TO_SHOW ? DEFAULT_ITEMS_TO_SHOW : items.length
  )
  const [itemsToShowNext, setItemsToShowNext] = useState<number>()

  const handleClickShowMore = () => {
    setShowingItems((prevValue) => prevValue + itemsToShowNext)
  }

  useEffect(() => {
    setShowingItems((prevValue) => (items.length < prevValue ? items.length : prevValue))
  }, [items.length])

  useEffect(() => {
    const dry = items.length - showingItems
    setItemsToShowNext(dry > 5 ? 5 : dry)
  }, [items.length, showingItems])

  useEffect(() => {
    if (!isExpanded) {
      setShowingItems(2)
    }
  }, [isExpanded])

  return (
    <VFlow vSpacing={0} style={classes.wrapper} onClick={(e) => e.stopPropagation()}>
      <VFlow vSpacing={0} style={classes.list}>
        {items.map((item: ResultadoExameQueryModel, index: number) => {
          if (index + 1 > showingItems) {
            return null
          }

          const { exame, especifico, resultado, dataRealizacao, dataResultado } = item

          const isEspecifico = !isObjectDeepEmpty(especifico)
          const isPreNatal = isEspecifico && especifico.exameEspecificoEnum === ExameEspecificoEnum.PRENATAL
          const extractValueFromQuery = getResultadoExameEspecificoExtractValueFromQuery(exame)
          const resultadoValue = isEspecifico ? extractValueFromQuery(item) : resultado

          return (
            <VFlow vSpacing={0.5} style={classes.listItem} key={item.id}>
              <HFlow hSpacing={0.5} style={classes.hFlow}>
                <Icon
                  icon='calendarFilled'
                  fill='primary'
                  size={1}
                  style={css`
                    margin-top: 0.125rem;
                  `}
                />

                <VFlow vSpacing={0.5}>
                  <VFlow vSpacing={0}>
                    <span>
                      <Text fontWeight='bold'>Realizado em</Text>{' '}
                      <Text>{dataRealizacao ? formatDate(dataRealizacao) : '-'}</Text>
                      {' | '}
                      <Text>Resultado em {dataResultado ? formatDate(dataResultado) : '-'}</Text>
                    </span>
                  </VFlow>

                  {isEspecifico && resultado ? (
                    <VFlow vSpacing={0.5}>
                      <InfoLabel title={!isPreNatal && 'Resultado'}>{resultadoValue}</InfoLabel>
                      <InfoLabel
                        title='Descrição'
                        childStyles={css`
                          word-break: break-word;
                          white-space: pre-line;
                        `}
                      >
                        <Text dangerouslySetInnerHTML={{ __html: resultado }} />
                      </InfoLabel>
                    </VFlow>
                  ) : (
                    <InfoLabel
                      title={!isPreNatal && 'Resultado'}
                      childStyles={css`
                        word-break: break-word;
                        white-space: pre-line;
                      `}
                    >
                      {isEspecifico ? resultadoValue : <Text dangerouslySetInnerHTML={{ __html: resultadoValue }} />}
                    </InfoLabel>
                  )}

                  <VFlow vSpacing={0}>
                    {(item.solicitacao || item.dataSolicitacao) && (
                      <Text style={classes.bottomInfos}>
                        <strong>
                          Solicitado{' '}
                          {item.solicitacao?.atendimentoProfissional.lotacao.profissional.nome ? 'por' : 'em'}
                          {': '}
                        </strong>
                        {item.solicitacao?.atendimentoProfissional.lotacao.profissional.nome &&
                          `${item.solicitacao.atendimentoProfissional.lotacao.profissional.nome} - ${item.solicitacao.atendimentoProfissional.lotacao.cbo.nome} em `}{' '}
                        {formatDate(item.solicitacao?.data || item.dataSolicitacao)}
                      </Text>
                    )}

                    <Text style={classes.bottomInfos}>
                      <strong>Avaliado por:</strong> {item.atendimentoProfissional.lotacao.profissional.nome} {' - '}
                      {item.atendimentoProfissional.lotacao.cbo.nome} em{' '}
                      {formatDate(item.atendimentoProfissional.iniciadoEm)}
                    </Text>
                  </VFlow>
                </VFlow>
              </HFlow>
            </VFlow>
          )
        })}
      </VFlow>

      {showingItems < items.length && (
        <Button
          kind='primary'
          skin='ghost'
          size='small'
          onClick={handleClickShowMore}
          style={css`
            margin: 0.25rem;
          `}
        >
          Ver +{'resultado'.pluralizeAndConcatValue(itemsToShowNext)}
        </Button>
      )}

      {showingItems > DEFAULT_ITEMS_TO_SHOW && (
        <HFlow
          justifyContent='center'
          style={css`
            margin-bottom: -1px;
          `}
        >
          <Button kind='normal' size='small' onClick={collapseItem} style={classes.resetButton}>
            <Icon icon='arrowUp' />
            Minimizar
          </Button>
        </HFlow>
      )}
    </VFlow>
  )
}

const classes = {
  wrapper: css`
    position: relative;
  `,
  hFlow: css`
    grid-auto-columns: auto 1fr;
  `,
  list: css`
    & > div:not(:last-of-type) {
      border-bottom: 1px solid ${theme.pallete.gray.c80};
    }
  `,
  listItem: css`
    padding: 0.5rem 1rem;
  `,
  resetButton: css`
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  `,
  bottomInfos: css`
    color: ${theme.pallete.gray.c40};
  `,
}
