import { PagedTable, Text } from 'bold-ui'
import { DateTime } from 'components/date'
import { Cpf, Telefone } from 'components/label'
import { TableBox } from 'components/table/TableBox'
import { usePagedTableProps } from 'components/table/usePagedTableProps'
import { BuscaCidadaoListagemQuery, Endereco } from 'graphql/types.generated'
import React from 'react'
import { conformToMask, conformToMaskResult } from 'react-text-mask'
import { masks } from 'util/mask'
import { capitalize, titleCase } from 'util/strConversor'

export interface AcompanhamentoCondicaoSaudeListingProps {
  resultCidadaos: BuscaCidadaoListagemQuery['acompanhamentoCondicaoSaude']
  loading: boolean
  onChange: (filter) => void
}

export interface CidadaoItemTableDto {
  id: string
  nome: string
  nomeSocial: string
  cpf: string
  telefoneResidencial: string
  telefoneCelular: string
  telefoneContato: string
  endereco: Endereco
  dataUltimoAtendimento: Date
}

export default function AcompanhamentoCondicaoSaudeListing(props: AcompanhamentoCondicaoSaudeListingProps) {
  const { loading, resultCidadaos, onChange } = props

  const renderCidadao = (row: CidadaoItemTableDto) => {
    return (
      <>
        <span>
          {titleCase(row.nomeSocial ? row.nomeSocial : row.nome)} <br />
          {row.cpf ? <Cpf value={row.cpf} /> : <em>Cidadão sem CPF</em>}
        </span>
      </>
    )
  }

  const renderEndereco = (row: CidadaoItemTableDto) => {
    return buildEndereco(row)
  }

  const renderTelefone = (row: CidadaoItemTableDto) => {
    return !row?.telefoneCelular && !row?.telefoneContato && !row?.telefoneResidencial ? (
      <Text>-</Text>
    ) : (
      <>
        <span>
          {row.telefoneResidencial && (
            <>
              <Telefone value={row.telefoneResidencial} /> <br />
            </>
          )}
          {row.telefoneCelular && (
            <>
              <Telefone value={row.telefoneCelular} /> <br />
            </>
          )}
          {row.telefoneContato && (
            <>
              <Telefone value={row.telefoneContato} /> <br />
            </>
          )}
        </span>
      </>
    )
  }

  const renderUltimoAtend = (row: CidadaoItemTableDto) => {
    return !row?.dataUltimoAtendimento ? (
      <Text>-</Text>
    ) : (
      <> {row.dataUltimoAtendimento && <DateTime value={row.dataUltimoAtendimento} format='DD/MM/YYYY' />} </>
    )
  }

  const tableProps = usePagedTableProps({
    loading,
    result: resultCidadaos,
    onChange,
  })

  const buildEndereco = (row: CidadaoItemTableDto) => {
    const maskedCep: conformToMaskResult =
      row?.endereco?.cep && conformToMask(row.endereco.cep, masks.cep, { guide: false })
    return !row?.endereco?.tipoLogradouro &&
      !row?.endereco?.logradouro &&
      !row?.endereco?.numero &&
      !row?.endereco?.complemento &&
      !row?.endereco?.bairro &&
      !row?.endereco?.municipio &&
      !row?.endereco?.uf &&
      !row?.endereco?.cep ? (
      <Text>-</Text>
    ) : (
      `
    ${row.endereco.cep ? maskedCep.conformedValue + ' | ' : ''}
    ${row.endereco.tipoLogradouro ? capitalize(row.endereco.tipoLogradouro.nome) : ''} 
    ${row.endereco.logradouro ? titleCase(row.endereco.logradouro) + ', ' : ''} 
    ${row.endereco.numero ? row?.endereco?.numero?.toUpperCase() + '.' : ''}
    ${row.endereco.complemento ? titleCase(row.endereco.complemento) + ' - ' : ''} 
    ${row.endereco.bairro ? titleCase(row.endereco.bairro) + ', ' : ''} 
    ${row.endereco.municipio ? titleCase(row.endereco.municipio.nome) + ' - ' : ''} 
    ${row.endereco.uf ? row.endereco.uf.sigla : ''} 
  `
    )
  }

  return (
    <TableBox>
      <PagedTable<CidadaoItemTableDto>
        {...tableProps}
        columns={[
          {
            name: 'cidadao',
            header: 'Cidadão',
            sortable: true,
            render: renderCidadao,
            style: { width: '18rem', padding: '1rem' },
          },
          {
            name: 'endereco',
            header: 'Endereço',
            sortable: true,
            render: renderEndereco,
            style: { width: '26rem' },
          },
          {
            name: 'telefone',
            header: 'Telefone',
            sortable: false,
            render: renderTelefone,
            style: { width: '9rem' },
          },
          {
            name: 'dataAtendimento',
            header: 'Último atendimento',
            sortable: true,
            render: renderUltimoAtend,
            style: { width: '4.65rem' },
          },
        ]}
      />
    </TableBox>
  )
}
