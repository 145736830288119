import { Tag } from 'bold-ui'
import React from 'react'

interface RiscoGravidezTagProps {
  isAltoRisco: boolean
}

export function RiscoGravidezTag(props: RiscoGravidezTagProps) {
  const { isAltoRisco } = props

  return isAltoRisco ? <Tag type='danger'>Alto</Tag> : <Tag type='info'>Habitual</Tag>
}
