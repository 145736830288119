import { InfoLabel } from 'bold-ui'
import { SemanasDiasFieldModel } from 'components/form/field/SemanasDiasField'
import React from 'react'
import { isUndefinedOrNull } from 'util/checks'
import { formatDate } from 'util/date/formatDate'

import { ResultadoEspecificoPrenatal } from '../../model'

interface ResultadoExamePrenatalViewProps {
  value: ResultadoEspecificoPrenatal
}

export const ResultadoExamePrenatalView = (props: ResultadoExamePrenatalViewProps) => {
  const { value } = props

  return <InfoLabel title='Resultado'>{mountIdadeGestacionalValue(value)}</InfoLabel>
}

export const mountIdadeGestacionalValue = ({
  dpp,
  idadeGestacional,
}: {
  dpp: LocalDate
  idadeGestacional: SemanasDiasFieldModel
}): string => {
  const { semanas, dias } = idadeGestacional || {}

  const hasSemanas = !isUndefinedOrNull(semanas)
  const hasDias = !isUndefinedOrNull(dias)

  const separator = dpp && hasSemanas && hasDias ? ' - ' : undefined

  return `${
    hasSemanas
      ? `Idade gestacional eco ${'semana'.pluralizeAndConcatValue(semanas)} ${
          hasDias ? `e ${'dia'.pluralizeAndConcatValue(dias)}` : ''
        }`
      : ''
  }

  ${separator || ''}

  ${dpp ? `DPP eco ${formatDate(dpp)}` : ''}`
}
