import { Icon, Tooltip } from 'bold-ui'
import { OrigemAtendimento } from 'graphql/types.generated'
import React from 'react'

interface OrigemAtendimentoObservacaoIconProps {
  origemAtendimento: OrigemAtendimento
}

export const OrigemAtendimentoObservacaoIcon = (props: OrigemAtendimentoObservacaoIconProps) => {
  const { origemAtendimento } = props

  const tooltipText =
    origemAtendimento === OrigemAtendimento.CDS
      ? 'Registros de origem CDS não possuem data nem horário de inicio e fim da observação'
      : origemAtendimento === OrigemAtendimento.RAS_PEC
      ? 'Registros de outras instalações não possuem data nem horário de inicio e fim da observação'
      : origemAtendimento === OrigemAtendimento.SISTEMA_TERCEIRO
      ? 'Registros de sistemas terceiros não possuem data nem horário de inicio e fim da observação'
      : ''

  return (
    <Tooltip text={tooltipText}>
      <Icon icon='infoCircleOutline' fill='primary' size={1} />
    </Tooltip>
  )
}
