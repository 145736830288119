import { Heading, VFlow } from 'bold-ui'
import { resolveName } from 'components/form/final-form/hooks/useField'
import { LoadingIndicator } from 'components/loading'
import { sub } from 'date-fns'
import { useSolicitacoesExamesPagedQuery } from 'graphql/hooks.generated'
import { IdentidadeGeneroEnum, SexoEnum } from 'graphql/types.generated'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { keyBy } from 'lodash'
import React, { useMemo } from 'react'
import { useField } from 'react-final-form'
import { MetaPath } from 'util/metaPath'
import { isObjectDeepEmpty } from 'util/object'
import { getEditAtendProfId } from 'view/atendimentos/atendimento-individual/util'

import { AdicionarResultadosExamesModalView } from './adicionar/AdicionarEditarResultadosExamesView'
import { ResultadoExameQueryModel, ResultadosExamesModel, SolicitacaoExameQueryModel } from './model'
import { addPrefix } from './util-resultadosExames'
import { ResultadosExamesList } from './visualizar/ResultadosExamesList'

interface ResultadosExamesRootViewProps {
  name: MetaPath<ResultadosExamesModel>
  prontuarioId: ID
  cidadaoSexo: SexoEnum
  cidadaoIdentidadeGeneroDbEnum: IdentidadeGeneroEnum
  cidadaoDataNascimento: LocalDate
}

export const ResultadosExamesRootView = (props: ResultadosExamesRootViewProps) => {
  const { name, prontuarioId, cidadaoSexo, cidadaoIdentidadeGeneroDbEnum, cidadaoDataNascimento } = props

  const {
    atendimentoProfissional: { id: atendimentoProfissionalId, iniciadoEm: dataFim },
    observacao: { isAtendimentoObservacao },
  } = useAtendimentoContext()

  const dataInicio = sub(dataFim, { years: 1 })

  const editAtendProfId = getEditAtendProfId(isAtendimentoObservacao, atendimentoProfissionalId)

  const {
    input: { value: fieldValue, onChange },
  } = useField<ResultadosExamesModel>(resolveName(name), { subscription: { value: true } })

  const {
    data: { solicitacoesExamePaged: solicitacoesExame },
    loading,
  } = useSolicitacoesExamesPagedQuery({ variables: { input: { prontuarioId, editAtendProfId, dataInicio, dataFim } } })

  const solicitacoes: SolicitacaoExameQueryModel[] = useMemo(
    () =>
      solicitacoesExame?.content
        .map((solicitacao) => ({
          ...solicitacao,
          exames: solicitacao.exames
            .filter(
              (resultadoExame) =>
                !resultadoExame.conferido ||
                (isAtendimentoObservacao && resultadoExame.atendimentoProfissional.id === atendimentoProfissionalId)
            )
            .sort((a, b) => a.exame.descricao.localeCompare(b.exame.descricao)),
        }))
        .filter((solicitacao) => solicitacao?.exames?.length > 0) || [],
    [atendimentoProfissionalId, isAtendimentoObservacao, solicitacoesExame]
  )

  const resultadosSolicitadosArray: ResultadoExameQueryModel[] = useMemo(
    () => solicitacoes?.map(({ exames }) => exames).flat(),
    [solicitacoes]
  )

  const resultadosSolicitadosRecord: Record<ID, ResultadoExameQueryModel> = useMemo(
    () => keyBy(resultadosSolicitadosArray, 'id'),
    [resultadosSolicitadosArray]
  )

  const resultadosSolicitadosIds: ID[] = fieldValue?.resultadosComSolicitacao
    ? Object.keys(fieldValue.resultadosComSolicitacao)
    : []

  const isEditing: boolean =
    resultadosSolicitadosIds?.length > 0 || Object.values(fieldValue?.resultadosSemSolicitacao ?? {})?.length > 0

  const numberOfResultadosNaoAvaliados: number = useMemo(
    () => resultadosSolicitadosArray?.filter(({ id }) => !resultadosSolicitadosIds.includes(addPrefix(id)))?.length,
    [resultadosSolicitadosArray, resultadosSolicitadosIds]
  )

  return !loading ? (
    <VFlow vSpacing={0.5}>
      <Heading level={3}>Resultados de exames</Heading>

      <AdicionarResultadosExamesModalView
        editing={isEditing}
        solicitacoes={solicitacoes}
        resultadosSolicitados={resultadosSolicitadosRecord}
        cidadaoSexo={cidadaoSexo}
        cidadaoIdentidadeGeneroDbEnum={cidadaoIdentidadeGeneroDbEnum}
        cidadaoDataNascimento={cidadaoDataNascimento}
        numberOfResultadosNaoAvaliados={numberOfResultadosNaoAvaliados}
        initialValues={fieldValue}
        prontuarioId={prontuarioId}
        onSubmit={onChange}
      />

      {(!isObjectDeepEmpty(fieldValue?.resultadosComSolicitacao) ||
        Object.values(fieldValue?.resultadosSemSolicitacao ?? {})?.length > 0) && (
        <ResultadosExamesList value={fieldValue} resultadosSolicitados={resultadosSolicitadosRecord} />
      )}
    </VFlow>
  ) : (
    <LoadingIndicator message='Carregando' />
  )
}
