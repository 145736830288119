/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, HFlow, Icon, Theme, useTheme, VFlow } from 'bold-ui'
import { useMemo } from 'react'
import { formatDate, toDate } from 'util/date/formatDate'
import { MetaPath } from 'util/metaPath'

import { ReplicateDatesModel, ResultadosExamesSolicitadosModel, SolicitacaoExameQueryModel } from '../../model'
import { addPrefix } from '../../util-resultadosExames'
import { getCardResultadoExameStatus } from '../components/CardResultadoExameContainer'
import { ReplicarDatasSolicitacaoField } from '../components/ReplicarDatasSolicitacaoField'
import { ResultadoExameComSolicitacaoField } from './ResultadoExameComSolicitacaoField'

interface SolicitacaoExameFieldProps {
  name: MetaPath<ResultadosExamesSolicitadosModel>
  replicateDatesFieldName: MetaPath<Record<ID, ReplicateDatesModel>>
  solicitacao: SolicitacaoExameQueryModel
  value: ResultadosExamesSolicitadosModel
  errors: { ignoringTouchedAndDirtyErrors: ID[]; allErrors: ID[] }
  dataInicioAtendimento: Date
  changeValue(name: MetaPath<any>, prevValue: any): void
}

export const SolicitacaoExameField = (props: SolicitacaoExameFieldProps) => {
  const { name, replicateDatesFieldName, solicitacao, dataInicioAtendimento, changeValue, value, errors } = props

  const theme = useTheme()
  const classes = useMemo(() => styles(theme), [theme])

  const resultadosIdsDestaSolicitacao: ID[] = useMemo(() => solicitacao.exames.map(({ id }) => addPrefix(id)), [
    solicitacao.exames,
  ])

  return (
    <VFlow vSpacing={0.5}>
      <HFlow hSpacing={0.25} alignItems='center' style={classes.header}>
        <Icon icon='calendarFilled' fill='primary' size={1} />

        <Heading
          level={4}
          style={css`
            font-weight: normal;
          `}
        >
          <strong>Solicitação de {formatDate(solicitacao.data)}</strong> por{' '}
          {solicitacao.atendimentoProfissional.lotacao.profissional.nome}
          {' | '}
          {solicitacao.atendimentoProfissional.lotacao.cbo.nome}
          {' | '}
          {solicitacao.atendimentoProfissional.lotacao.unidadeSaude.nome}
        </Heading>
      </HFlow>

      <ReplicarDatasSolicitacaoField
        name={replicateDatesFieldName[addPrefix(solicitacao.id)]}
        nameFieldToChange={name}
        resultadosIds={resultadosIdsDestaSolicitacao}
        changeValue={changeValue}
        minDate={toDate(solicitacao.data)}
        maxDate={dataInicioAtendimento}
      />

      <VFlow vSpacing={0.5}>
        {solicitacao.exames.map((exame) => (
          <ResultadoExameComSolicitacaoField
            key={exame.id}
            resultadoExame={exame}
            name={name[addPrefix(exame.id)]}
            value={value?.[addPrefix(exame.id)]}
            status={getCardResultadoExameStatus(
              value?.[addPrefix(exame.id)],
              errors?.allErrors?.includes(addPrefix(exame.id))
            )}
            ignoringTouchedAndDirtyStatus={getCardResultadoExameStatus(
              value?.[addPrefix(exame.id)],
              errors?.ignoringTouchedAndDirtyErrors?.includes(addPrefix(exame.id))
            )}
          />
        ))}
      </VFlow>
    </VFlow>
  )
}

const styles = (theme: Theme) => ({
  header: css`
    padding: 0.25rem 0.5rem;
    border-radius: 2px;
    background: ${theme.pallete.primary.c90};
  `,
})
