/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { ImpressaoAtendimentoInput } from 'api/ImpressaoAtendimentoIndividual'
import { ImpressaoCadernetaVacinacaoInput } from 'api/impressaoCadernetaVacinacao'
import { isApolloError } from 'apollo-client'
import {
  Alert,
  Button,
  ButtonGroup,
  Heading,
  HeadingSection,
  HFlow,
  Icon,
  Theme,
  Tooltip,
  useTheme,
  VFlow,
} from 'bold-ui'
import { gray } from 'bold-ui/lib/styles/colors'
import { useAlert } from 'components/alert'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import useSession from 'components/auth/useSession'
import { DateTime } from 'components/date'
import { handleValidationError, isValidationError, useErrorHandler } from 'components/error'
import { CheckboxField, Form, FormRenderProps, FormValueSpy } from 'components/form'
import { PageContent } from 'components/layout/PageContent'
import { confirm } from 'components/modals/confirm'
import { useApolloClient } from 'graphql/hooks'
import {
  useCancelarAtendimentoMutation,
  useSalvarAtendimentoVacinacaoMutation,
  useSalvarAtendimentoVacinacaoParaAprovacaoMutation,
} from 'graphql/hooks.generated'
import {
  StatusRevisaoAtendimento,
  TipoAtendimentoProfissional,
  TipoEstabelecimentoEnum,
  TipoRegistroVacinacaoEnum,
} from 'graphql/types.generated'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { useServerTime } from 'hooks/useServerTime'
import { Dispatch, Fragment, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { isCboAcs } from 'types/NivelCbo'
import { sexoByIdentidadeGenero } from 'util/sexoByIdentidadeGenero'
import { createValidator } from 'util/validation'
import { useVerificarAgendamentosConflitantes } from 'view/agenda/hooks/useVerificarAgendamentosConflitantes'
import { SoapState } from 'view/atendimentos/atendimento-individual/model'
import { convertAtendimentoVacinacaoModelToInput } from 'view/atendimentos/atendimento-vacinacao/graphql/converter'
import {
  AtendimentoVacinacaoState,
  meta,
  RegistroVacinacaoFormModel,
} from 'view/atendimentos/atendimento-vacinacao/model'
import {
  getDescricaoTituloCalendario,
  getGrupoAlvoVacinacao,
} from 'view/atendimentos/atendimento-vacinacao/vacinacaoUtils'
import { validateCpfCns, validateFinalizarAtendimento } from 'view/atendimentos/atendimento-vacinacao/validator'
import { AgendamentoDia } from 'view/atendimentos/types/AgendamentoDia'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'
import { computeFinalizarAtendimentoButtonLabel } from 'view/atendimentos/utils/computeFinalizarAtendimentoButtonLabel'
import { CidadaoTabSectionModel } from 'view/cidadao/types/CidadaoTabSectionModel'
import { CheckJustificativaAcessoProntuario } from 'view/prontuario/justificativa/CheckJustificativaAcessoProntuario'
import { REGISTRO_TARDIO_PATH } from 'view/registro-tardio/RegistroTardioRootView'

import { atendimentoFooterMessage } from '../atendimentoFooterMessage'
import { atendimentoName } from '../atendimentoName'
import { PeriodoGestacaoModel } from '../components/modals/types/PeriodoGestacaoModel'
import { downloadAtendimentoVacinacao } from '../components/vacinacao/downloadAtendimentoVacinacao'
import { downloadCadernetaVacinacao } from '../components/vacinacao/downloadCadernetaVacinacao'
import {
  convertAgendarConsultasToAgendamentosConflitantesInput,
  FinalizacaoAtendimentoFormModel,
} from '../soap/finalizacao'
import { AgendarConsultaPanel } from '../soap/finalizacao/components/AgendarConsultaPanel'
import { DesfechoAtendimentoPanel } from '../soap/finalizacao/components/DesfechoAtendimentoPanel'
import { agendarConsultaAvulsaValidator } from '../soap/finalizacao/validator/validator-agendamento'
import { DeclaracaoComparecimentoButton } from '../soap/plano/atestados/DeclaracaoComparecimentoBox'
import { createVacinacaoCalculator } from './calculator-vacinacao'
import { VacinacaoCalendarioReadOnlyView } from './VacinacaoCalendarioReadOnlyView'
import { createVacinacaoErrorModal, VacinacaoCalendarioView } from './VacinacaoCalendarioView'

interface VacinacaoViewProps {
  atendimentoId: ID
  prontuarioId: ID
  cidadao: CidadaoAtendimento
  dataAtendimento: Instant
  tipoAtendimentoProfissional: TipoAtendimentoProfissional
  agendamentosDia?: AgendamentoDia[]
  gestante?: boolean
  puerpera?: boolean
  gestacoes: PeriodoGestacaoModel[]
  initialValues?: AtendimentoVacinacaoState
  clearCache(updateState?: boolean): void
  updateCache?: Dispatch<SetStateAction<AtendimentoVacinacaoState | SoapState>>
  cacheState?: AtendimentoVacinacaoState | SoapState
  tipoEstabelecimento: TipoEstabelecimentoEnum
  lastSaved?: Date
  canAccessAgenda?: boolean
  isAtendimentoVacinacao?: boolean
  isAtendimentoObservacao?: boolean
  isObservacaoAndResponsavel?: boolean
  isObservacaoAndAuxiliar?: boolean
  statusRevisaoAtendimento?: StatusRevisaoAtendimento
}

const atendimentoVacinacaoValidator = (canAccessAgenda: boolean, getServerTimeNow: () => Date) =>
  createValidator<AtendimentoVacinacaoState>({
    finalizacaoVacinacao: createValidator<FinalizacaoAtendimentoFormModel>({
      agendamentoConsultas: canAccessAgenda && agendarConsultaAvulsaValidator(getServerTimeNow),
    }),
  })

export type EstadoBotaoCalendario = 'CALENDARIO_VACINAL' | 'OUTROS_IMUNOS'

// type guard para poder acessar valores dependendo do state que foi provido no parametro
const isAtendimentoVacinacaoState = (state: any): state is AtendimentoVacinacaoState => {
  return state?.registroVacinacao !== undefined
}

const disableImprimirAtendimento = (registros: RegistroVacinacaoFormModel[]) =>
  !registros.find(
    (item) => item.tipoRegistroVacinacao === TipoRegistroVacinacaoEnum.APLICACAO && !item.isRegistroAnterior
  )

export function VacinacaoView(props: VacinacaoViewProps) {
  const { cacheState, ...propsValues } = props

  const [initialValuesVacinacao] = useState(
    isAtendimentoVacinacaoState(cacheState) ? cacheState : cacheState?.vacinacao
  ) // usado pra setar os valores iniciais do Form apenas na primeira vez que renderizar

  return (
    <div>
      <VacinacaoForm {...propsValues} initialValues={initialValuesVacinacao} cacheState={cacheState} />
    </div>
  )
}

const gestantePuerperaCarregadosText = (gestante: boolean, puerpera: boolean) =>
  gestante
    ? 'Possui gestação ativa na Lista de problemas / condições, registrada em atendimento anterior.'
    : puerpera
    ? 'Possui condição de puerpério na Lista de problemas / condições, registrada em atendimento anterior.'
    : undefined

const generateUrlToCadastroCidadao = (
  cidadaoId: ID,
  atendimentoId: ID,
  registroTardio: boolean,
  isAtendimentoVacinacao: boolean
) => {
  const baseUrl = `/cidadao/${cidadaoId}/edit`

  const callbackUrl = registroTardio
    ? 'registroTardio'
    : isAtendimentoVacinacao
    ? 'lista-atendimento/atendimento-vacinacao'
    : 'lista-atendimento/atendimento'

  return `${baseUrl}?callbackUrl=${callbackUrl}&callbackParams=${atendimentoId}`
}

export const VacinacaoForm = (props: VacinacaoViewProps) => {
  const {
    prontuarioId,
    initialValues,
    cidadao,
    dataAtendimento,
    agendamentosDia,
    atendimentoId,
    clearCache,
    updateCache,
    gestante,
    puerpera,
    tipoEstabelecimento,
    lastSaved,
    cacheState,
    canAccessAgenda,
    isAtendimentoVacinacao = true,
    tipoAtendimentoProfissional,
    isAtendimentoObservacao,
    isObservacaoAndResponsavel,
    isObservacaoAndAuxiliar,
    statusRevisaoAtendimento,
  } = props

  const [estadoBotaoCalendario, setEstadoBotaoCalendario] = useState<EstadoBotaoCalendario>('CALENDARIO_VACINAL')
  const [enviarParaAprovacao] = useSalvarAtendimentoVacinacaoParaAprovacaoMutation()
  const [cancelar] = useCancelarAtendimentoMutation()
  const [finalizar] = useSalvarAtendimentoVacinacaoMutation()
  const { verificarAgendamentosConflitantes } = useVerificarAgendamentosConflitantes()

  const { isRegistroTardio } = useAtendimentoContext() || {}

  const apollo = useApolloClient()

  const [openDosesAnterioresModal, setOpenDosesAnterioresModal] = useState(false)

  const handleRejection = useErrorHandler()
  const grupoAlvoCidadao = getGrupoAlvoVacinacao(cidadao.dataNascimento, dataAtendimento)
  const sexoIdentidadeGenero = sexoByIdentidadeGenero(cidadao.sexo, cidadao.identidadeGeneroDbEnum)
  const isSexoFeminino = sexoIdentidadeGenero === 'FEMININO'
  const gestantePuerperaCarregados = useMemo(() => gestantePuerperaCarregadosText(gestante, puerpera), [
    gestante,
    puerpera,
  ])

  const { isEstagio } = useSession()

  const tipoAtendimentoText = atendimentoName(tipoAtendimentoProfissional, isObservacaoAndAuxiliar)
  const finalizarAtendimentoButtonLabel = computeFinalizarAtendimentoButtonLabel({
    isEstagio,
    statusRevisaoAtendimento,
    tipoAtendimentoText,
  })

  const [mostrarCalendarioCompleto, setMostrarCalendarioCompleto] = useState<boolean>(false)

  const theme = useTheme()
  const style = useMemo(() => styles(theme), [theme])

  const history = useHistory()
  const alert = useAlert()
  const { getServerTimeNow } = useServerTime()

  const handleClickAtualizarCadastroCidadao = useCallback(() => {
    const url = generateUrlToCadastroCidadao(cidadao.id, atendimentoId, isRegistroTardio, isAtendimentoVacinacao)
    history.push(url)
  }, [atendimentoId, cidadao.id, history, isAtendimentoVacinacao, isRegistroTardio])

  const errorCpfCnsRequired = useMemo(
    () => validateCpfCns(cidadao.cns || cidadao.cpf, handleClickAtualizarCadastroCidadao, isAtendimentoVacinacao),
    [cidadao.cns, cidadao.cpf, handleClickAtualizarCadastroCidadao, isAtendimentoVacinacao]
  )
  useEffect(() => errorCpfCnsRequired?.hasError && createVacinacaoErrorModal(errorCpfCnsRequired), [
    errorCpfCnsRequired,
  ])

  const [formInitialized, setFormInitialized] = useState(false)
  useEffect(() => {
    setFormInitialized(true)
  }, [])

  const printAtendimentoVacinacao = useCallback(
    (itemToPrint: ImpressaoAtendimentoInput) => downloadAtendimentoVacinacao(itemToPrint),
    []
  )
  const printCadernetaVacinacao = (itemToPrint: ImpressaoCadernetaVacinacaoInput) => {
    downloadCadernetaVacinacao(itemToPrint)
  }

  const calculators = useMemo(() => createVacinacaoCalculator(apollo, tipoEstabelecimento), [
    apollo,
    tipoEstabelecimento,
  ])

  const handleFormChange = useCallback(
    (values: AtendimentoVacinacaoState) => {
      /* Avoids the error of updating a component while rendering a different component. */

      // TODO (NP) rever relacoes com o atendimento individual
      if (formInitialized) {
        if (isAtendimentoVacinacaoState(cacheState)) {
          values.lastSaved = lastSaved
          updateCache(values)
        } else {
          updateCache({ ...cacheState, lastSaved: lastSaved, vacinacao: values })
        }
      }
    },
    [cacheState, formInitialized, lastSaved, updateCache]
  )
  const { analytics } = useFirebase()

  const footerMessages = atendimentoFooterMessage(
    tipoAtendimentoProfissional,
    isAtendimentoObservacao,
    isObservacaoAndResponsavel
  )

  const handleCancelar = useCallback(() => {
    confirm({
      title: footerMessages.confirmTitle,
      body: footerMessages.body,
      confirmLabel: 'Sim',
      cancelLabel: 'Não',
      onConfirm: () => {
        cancelar({ variables: { atendimentoId } })
          .then(() => {
            clearCache(false)
            history.push(isRegistroTardio ? REGISTRO_TARDIO_PATH : '/lista-atendimento')
            analytics.logEvent('cancelar_atendimento', { tipo_de_atendimento: 'vacinação' })
          })
          .catch(handleRejection)
      },
    })()
  }, [
    footerMessages.confirmTitle,
    footerMessages.body,
    cancelar,
    atendimentoId,
    handleRejection,
    clearCache,
    history,
    analytics,
    isRegistroTardio,
  ])

  const handleFinalizar = useCallback(
    async (values: AtendimentoVacinacaoState) => {
      if (isEstagio) {
        const responseEnviarParaAprovacao = await enviarParaAprovacao({
          variables: {
            input: {
              atendimentoInput: convertAtendimentoVacinacaoModelToInput(atendimentoId, values),
              rascunho: JSON.stringify(values),
            },
          },
        })

        if (responseEnviarParaAprovacao.data.salvarAtendimentoVacinacaoParaAprovacao) {
          alert('success', `Atendimento de vacinação enviado para aprovação com sucesso.`)
          clearCache(false)
          history.push('/lista-atendimento')
        }
      } else {
        if (values.finalizacaoVacinacao?.agendamentoConsultas) {
          const confirmouHorariosConflitantes = await verificarAgendamentosConflitantes(
            cidadao.id,
            convertAgendarConsultasToAgendamentosConflitantesInput(values.finalizacaoVacinacao?.agendamentoConsultas),
            cidadao.nomeSocial ?? cidadao.nome
          )

          if (!confirmouHorariosConflitantes) return
        }

        /*

        // TODO (NP) removido temporariamente

        if (!isAtendimentoVacinacao) {
          return finalizarIndividual({
            variables: {
              input: converAtendimentoIndividualModelToInput(atendimentoId, cacheState, getServerTimeNow()),
            },
          }).then((response) => {
            values.imprimirAtendimento &&
              printAtendimentoIndividual({
                atendimentoProfissionalId: response.data.finalizarAtendimentoIndividual.id,
                isImpressaoHistorico: false,
              })
            alert('success', `Atendimento individual finalizado com sucesso.`)
            analytics.logEvent('finalizar_atendimento', { tipo_de_atendimento: 'vacinação' })
            history.push('/lista-atendimento')
            clearCache()
          })
        } else { */

        const error = validateFinalizarAtendimento(
          { registrosAplicadosAtendimento: values.registroVacinacao },
          cidadao.cns || cidadao.cpf,
          handleClickAtualizarCadastroCidadao,
          isAtendimentoVacinacao
        )

        if (error.hasError) {
          createVacinacaoErrorModal(error)
        } else
          try {
            const responseFinalizar = await finalizar({
              variables: {
                input: convertAtendimentoVacinacaoModelToInput(atendimentoId, values),
              },
            })

            values.imprimirAtendimento &&
              printAtendimentoVacinacao({
                atendimentoProfissionalId: responseFinalizar.data.salvarAtendimentoVacinacao.atendimentoProfissional.id,
              })
            alert('success', `Atendimento de vacinação finalizado com sucesso.`)
            analytics.logEvent('finalizar_atendimento', { Tipo_de_atendimento: 'vacinação' })
            history.push('/lista-atendimento')
            clearCache(false)
          } catch (error) {
            if (error instanceof Error && isApolloError(error) && isValidationError(error.graphQLErrors[0]))
              // eslint-disable-next-line no-console
              console.error(
                `Erro ao finalizar atendimento ${tipoAtendimentoText}`,
                JSON.stringify(handleValidationError(error.graphQLErrors[0]))
              )

            throw error
          }
      }
    },
    [
      isEstagio,
      enviarParaAprovacao,
      atendimentoId,
      alert,
      clearCache,
      history,
      cidadao.cns,
      cidadao.cpf,
      cidadao.id,
      cidadao.nomeSocial,
      cidadao.nome,
      handleClickAtualizarCadastroCidadao,
      isAtendimentoVacinacao,
      verificarAgendamentosConflitantes,
      finalizar,
      printAtendimentoVacinacao,
      analytics,
      tipoAtendimentoText,
    ]
  )

  const renderVacinacao = useCallback(
    (formProps: FormRenderProps<AtendimentoVacinacaoState>) => {
      const { handleSubmit, values } = formProps
      const disableImpressao = disableImprimirAtendimento(values.registroVacinacao)

      return (
        <Fragment>
          <FormValueSpy<AtendimentoVacinacaoState> onChange={handleFormChange} />
          {isAtendimentoVacinacao && (
            <HeadingSection level={2} title='Condições' style={style.headingSection}>
              {gestantePuerperaCarregados && (
                <Alert inline style={{ marginBottom: '1rem' }} type='info'>
                  {gestantePuerperaCarregados}
                </Alert>
              )}

              <div css={isSexoFeminino && style.box}>
                <HFlow>
                  {isSexoFeminino && (
                    <Fragment>
                      <CheckboxField name={meta.condicoesVacinacao.gestante} label='Gestante' />
                      <CheckboxField label='Puérpera' name={meta.condicoesVacinacao.puerpera} style={style.separator} />
                    </Fragment>
                  )}
                  <CheckboxField name={meta.condicoesVacinacao.viajante} label='Viajante' />
                </HFlow>
              </div>
            </HeadingSection>
          )}
          <HeadingSection level={2} title='Vacinação'>
            <VFlow>
              {!(cidadao.cpf || cidadao.cns) && (
                <Alert type='warning'>
                  {`É necessário cadastrar um CPF ou CNS para o cidadão. É obrigatório um dos documentos para finalizar o
                  ${isAtendimentoVacinacao ? 'atendimento de vacinação.' : 'atendimento.'}`}
                </Alert>
              )}
              <Alert type='info' style={{ padding: '5px' }}>
                {isRegistroTardio ? (
                  'Registros de vacinação devem ser realizados pela transcrição de caderneta através da lista de atendimentos.'
                ) : (
                  <Fragment>
                    Para imprimir todos os registros salvos em atendimentos anteriores, clique em "Imprimir caderneta de
                    vacinação".
                    <br />
                    {`Para imprimir os registros realizados neste atendimento, selecione a opção "Imprimir atendimento
                          ao finalizar"${!isAtendimentoVacinacao ? ' na aba SOAP.' : '.'}`}
                  </Fragment>
                )}
              </Alert>
              <HFlow justifyContent='space-between'>
                <TipoCalendarioImunosButtonGroup
                  estadoBotaoCalendario={estadoBotaoCalendario}
                  setEstadoBotaoCalendario={setEstadoBotaoCalendario}
                  calendarioVacinalTitle={
                    mostrarCalendarioCompleto
                      ? 'Calendário vacinal completo'
                      : `Calendário vacinal ${getDescricaoTituloCalendario(grupoAlvoCidadao)} ${
                          formProps.values.condicoesVacinacao.gestante ? ' / gestante' : ''
                        }`
                  }
                />
                <Button
                  kind='normal'
                  size='small'
                  skin='default'
                  style={style.printCadernetaButton}
                  onClick={() => printCadernetaVacinacao({ prontuarioId, atendimentoId })}
                >
                  <Icon icon='printerFilled' style={style.printBtn} />
                  Imprimir caderneta de vacinação
                </Button>
              </HFlow>
              {!isAtendimentoVacinacao && !isRegistroTardio && (
                <Button
                  kind='primary'
                  size='small'
                  skin='default'
                  style={css`
                    width: 16rem;
                  `}
                  onClick={() => setOpenDosesAnterioresModal(true)}
                >
                  Adicionar transcrições de caderneta
                </Button>
              )}
              <VacinacaoCalendarioView
                cidadao={cidadao}
                condicoesVacinacao={formProps.values.condicoesVacinacao}
                openDosesAnterioresModal={openDosesAnterioresModal}
                closeDosesAnterioresModal={() => setOpenDosesAnterioresModal(false)}
                dataAtendimento={dataAtendimento}
                estadoBotaoCalendario={estadoBotaoCalendario}
                grupoAlvoCidadao={grupoAlvoCidadao}
                prontuarioId={prontuarioId}
                registros={formProps.values.registroVacinacao}
                formVacinacao={formProps.form}
                mostrarCalendarioCompleto={mostrarCalendarioCompleto}
                setMostrarCalendarioCompleto={setMostrarCalendarioCompleto}
                isAcompanhamentoVacinal={!isAtendimentoVacinacao}
                sexoIdentidadeGenero={sexoIdentidadeGenero}
              />
              {isAtendimentoVacinacao && (
                <Fragment>
                  <div css={style.desfechoContainer}>
                    <span css={style.desfechoInitialIcon}>
                      <Icon
                        icon='checkCircleFilled'
                        size={1.5}
                        style={css`
                          height: 100%;
                        `}
                      />
                    </span>
                    <Heading level={2} style={style.desfechoTitle}>
                      Finalização do atendimento
                    </Heading>
                  </div>
                  <VFlow
                    style={css`
                      padding-top: 1rem;
                    `}
                  >
                    <DeclaracaoComparecimentoButton atendimentoId={atendimentoId} />
                    <DesfechoAtendimentoPanel
                      name={meta.finalizacaoVacinacao.desfechoAtendimento}
                      agendamentosDia={agendamentosDia}
                    />
                    <AgendarConsultaPanel
                      name={meta.finalizacaoVacinacao.agendamentoConsultas}
                      hasPainelLateral={false}
                    />
                  </VFlow>
                </Fragment>
              )}
            </VFlow>
          </HeadingSection>
          <div css={style.actions}>
            <div css={style.sideBar}></div>
            <HFlow justifyContent='space-between'>
              <Alert inline type='success' styles={{ wrapper: style.draftAlertWrapper }}>
                Rascunho salvo automaticamente às <DateTime format='HH:mm' value={lastSaved} />
              </Alert>

              <HFlow>
                {isAtendimentoVacinacao && !isEstagio && (
                  <Tooltip
                    text={disableImpressao && 'Para habilitar a opção de impressão, realize um registro de aplicação.'}
                  >
                    <span>
                      <CheckboxField
                        name={meta.imprimirAtendimento}
                        label='Imprimir atendimento ao finalizar'
                        style={style.print}
                        disabled={disableImpressao}
                        onClick={() => analytics.logEvent('click_imprimir_finalizar')}
                      />
                    </span>
                  </Tooltip>
                )}
                <Button kind='normal' onClick={handleCancelar}>
                  {footerMessages.labelButton}
                </Button>
                {isAtendimentoVacinacao && (
                  <Button kind='primary' onClick={handleSubmit}>
                    {finalizarAtendimentoButtonLabel}
                  </Button>
                )}
              </HFlow>
            </HFlow>
          </div>
        </Fragment>
      )
    },
    [
      handleFormChange,
      isAtendimentoVacinacao,
      style.headingSection,
      style.box,
      style.separator,
      style.printCadernetaButton,
      style.printBtn,
      style.desfechoContainer,
      style.desfechoInitialIcon,
      style.desfechoTitle,
      style.actions,
      style.sideBar,
      style.draftAlertWrapper,
      style.print,
      gestantePuerperaCarregados,
      sexoIdentidadeGenero,
      isSexoFeminino,
      cidadao,
      isRegistroTardio,
      estadoBotaoCalendario,
      mostrarCalendarioCompleto,
      grupoAlvoCidadao,
      openDosesAnterioresModal,
      dataAtendimento,
      prontuarioId,
      atendimentoId,
      agendamentosDia,
      lastSaved,
      isEstagio,
      handleCancelar,
      footerMessages.labelButton,
      finalizarAtendimentoButtonLabel,
      analytics,
    ]
  )

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        min-height: 60vh;
      `}
    >
      <PageContent fluid type='filled'>
        <Form<AtendimentoVacinacaoState>
          render={renderVacinacao}
          onSubmit={handleFinalizar}
          decorators={[calculators]}
          initialValues={initialValues}
          validate={atendimentoVacinacaoValidator(canAccessAgenda, getServerTimeNow)}
        />
      </PageContent>
    </div>
  )
}

interface TipoCalendarioImunosButtonGroupProps {
  estadoBotaoCalendario: EstadoBotaoCalendario
  setEstadoBotaoCalendario: Dispatch<SetStateAction<EstadoBotaoCalendario>>
  calendarioVacinalTitle: string
}

const TipoCalendarioImunosButtonGroup = ({
  estadoBotaoCalendario,
  setEstadoBotaoCalendario,
  calendarioVacinalTitle,
}: TipoCalendarioImunosButtonGroupProps) => {
  return (
    <ButtonGroup>
      <Button
        kind={estadoBotaoCalendario === 'CALENDARIO_VACINAL' ? 'primary' : 'normal'}
        size='small'
        skin='default'
        onClick={() => setEstadoBotaoCalendario('CALENDARIO_VACINAL')}
      >
        {calendarioVacinalTitle}
      </Button>
      <Button
        kind={estadoBotaoCalendario === 'OUTROS_IMUNOS' ? 'primary' : 'normal'}
        size='small'
        skin='default'
        onClick={() => setEstadoBotaoCalendario('OUTROS_IMUNOS')}
      >
        Outros imunobiológicos
      </Button>
    </ButtonGroup>
  )
}

interface VacinacaoReadOnlyViewProps {
  cidadao: CidadaoTabSectionModel
  dataReferencia: Instant
}

export const VacinacaoReadOnlyView = (props: VacinacaoReadOnlyViewProps) => {
  const { cidadao, dataReferencia } = props
  const grupoAlvoCidadao = getGrupoAlvoVacinacao(cidadao.dataNascimento, dataReferencia)
  const sexoIdentidadeGenero = sexoByIdentidadeGenero(cidadao.sexo, cidadao.identidadeGeneroDbEnum)
  const [mostrarCalendarioCompleto, setMostrarCalendarioCompleto] = useState<boolean>(false)
  const [estadoBotaoCalendario, setEstadoBotaoCalendario] = useState<EstadoBotaoCalendario>('CALENDARIO_VACINAL')
  const match = useRouteMatch()

  const theme = useTheme()
  const style = styles(theme)

  const { acesso } = useAcessoLotacaoOrEstagio()

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        min-height: 60vh;
      `}
    >
      <PageContent fluid type='filled'>
        <CheckJustificativaAcessoProntuario
          cidadaoId={cidadao.id}
          prontuarioId={cidadao.prontuario.id}
          basePath={match.path.substring(0, match.path.lastIndexOf('/'))}
          disabled={isCboAcs(acesso.cbo.cbo2002)}
        >
          <HeadingSection level={2} title='Vacinação'>
            <VFlow>
              <HFlow justifyContent='space-between'>
                <TipoCalendarioImunosButtonGroup
                  estadoBotaoCalendario={estadoBotaoCalendario}
                  setEstadoBotaoCalendario={setEstadoBotaoCalendario}
                  calendarioVacinalTitle={
                    mostrarCalendarioCompleto
                      ? 'Calendário vacinal completo'
                      : 'Calendário vacinal' +
                        getDescricaoTituloCalendario(grupoAlvoCidadao) +
                        (cidadao.prontuario.preNatalAtivo ? ' / gestante' : '')
                  }
                />
                <Button
                  kind='normal'
                  size='small'
                  skin='default'
                  style={style.printCadernetaButton}
                  onClick={() => downloadCadernetaVacinacao({ prontuarioId: cidadao.prontuario.id })}
                >
                  <Icon icon='printerFilled' style={style.printBtn} />
                  Imprimir caderneta de vacinação
                </Button>
              </HFlow>
              <VacinacaoCalendarioReadOnlyView
                cidadao={cidadao}
                dataReferencia={dataReferencia}
                grupoAlvoCidadao={grupoAlvoCidadao}
                prontuarioId={cidadao?.prontuario?.id}
                mostrarCalendarioCompleto={mostrarCalendarioCompleto}
                estadoBotaoCalendario={estadoBotaoCalendario}
                setMostrarCalendarioCompleto={setMostrarCalendarioCompleto}
                sexoIdentidadeGenero={sexoIdentidadeGenero}
              />
            </VFlow>
          </HeadingSection>
        </CheckJustificativaAcessoProntuario>
      </PageContent>
    </div>
  )
}

const styles = (theme: Theme) => {
  return {
    container: css`
      display: grid;
      grid-template-columns: 0.4fr 1fr;
      grid-template-rows: auto;
      grid-template-areas: 'sidebar main' 'footer footer';
    `,

    aside: css`
      width: 100%;
      max-width: 27rem;
    `,

    print: css`
      border: 0.063rem solid ${theme.pallete.gray.c80};
      border-radius: 0.125rem;
      padding: 0.75rem 1rem;
    `,

    actions: css`
      height: 5.125rem;
      position: relative;
      grid-area: footer;
      padding-top: 1rem;
      margin-bottom: 1rem;
    `,

    draftAlertWrapper: css`
      background-color: transparent;
      border-width: 0;
    `,

    headingDesfecho: css`
      padding: 0.5rem 0 0.5rem 0;
      background-color: ${theme.pallete.gray.c90};
    `,

    separator: css`
      border-right: 1px solid ${theme.pallete.divider};
      padding-right: 1rem;
    `,

    box: css`
      padding: 1rem;
      width: 22rem;
      border: 1px solid ${theme.pallete.gray.c80};
    `,

    sideBar: css`
      width: 100vw;
      position: relative;
      left: 50%;
      right: 50%;
      margin-left: -53vw;
      margin-right: -50vw;
      margin-bottom: 1rem;
      border-bottom: 1px solid ${theme.pallete.divider};
    `,

    desfechoContainer: css`
      margin: 1rem 0 0 0;
      display: flex;
      border-radius: 2;
      background: ${theme.pallete.gray.c90};
      &:hover {
        background-color: ${theme.pallete.gray.c80};
      }
    `,

    desfechoInitialIcon: css`
      background: ${gray.c40};
      color: ${theme.pallete.gray.c100};
      border-radius: 4px;
      padding: 0.2rem 0.4rem;
    `,

    desfechoTitle: css`
      flex: 1;
      padding: 0.25rem 0.75rem;
    `,

    headingSection: css`
      margin-bottom: 1rem;
    `,
    printBtn: css`
      margin-right: 7px;
      font-size: 1.1rem;
    `,
    printCadernetaButton: css`
      color: #0069d0;
      border-color: #0069d0;
    `,
  }
}
