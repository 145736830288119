import { AsyncProcessingNameEnum } from 'graphql/types.generated'
import {
  RELATORIO_GERENCIAL_ATENDIMENTOS_NAME,
  RELATORIO_GERENCIAL_ATENDIMENTOS_PATH,
  RELATORIO_GERENCIAL_VACINACAO_NAME,
  RELATORIO_GERENCIAL_VACINACAO_PATH,
} from 'view/relatorio/relatoriosgerenciais/common/model-relatorioGerencial'

interface AsyncProcessingConfiguration {
  /** O nome do evento customizado que serah jogado para avisar o componente do modulo que o processamento terminou */
  finishProcessingEventName: string
}

export const asyncProcessingConfigurationRecord: Record<AsyncProcessingNameEnum, AsyncProcessingConfiguration> = {
  [AsyncProcessingNameEnum.RELATORIO_GERENCIAL_ATENDIMENTO]: {
    finishProcessingEventName: 'onProcessingRelatorioGerencialAtendimentoEnd',
  },
  [AsyncProcessingNameEnum.RELATORIO_GERENCIAL_VACINACAO]: {
    finishProcessingEventName: 'onProcessingRelatorioGerencialVacinacaoEnd',
  },
}

interface AsyncProcessingFinishMessageInfo {
  path: string
  name: string
}

export const asyncProcessingFinishMessageInfoRecord: Record<
  AsyncProcessingNameEnum,
  AsyncProcessingFinishMessageInfo
> = {
  [AsyncProcessingNameEnum.RELATORIO_GERENCIAL_ATENDIMENTO]: {
    path: RELATORIO_GERENCIAL_ATENDIMENTOS_PATH,
    name: RELATORIO_GERENCIAL_ATENDIMENTOS_NAME,
  },
  [AsyncProcessingNameEnum.RELATORIO_GERENCIAL_VACINACAO]: {
    path: RELATORIO_GERENCIAL_VACINACAO_PATH,
    name: RELATORIO_GERENCIAL_VACINACAO_NAME,
  },
}
