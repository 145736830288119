import { Button } from 'bold-ui'
import { usePecField } from 'components/form/final-form/hooks/useField'
import React from 'react'
import { MetaPath } from 'util/metaPath'

import { MedicamentoFormModel } from '../model'

interface CheckButtonFieldProps {
  name: MetaPath<MedicamentoFormModel>
  originalValue?: number
  children: any
  disabled?: boolean
  dataTestId?: string
}

export default function CheckButtonField({
  name,
  originalValue,
  children,
  disabled,
  dataTestId,
}: CheckButtonFieldProps) {
  const {
    input: { value, onChange },
  } = usePecField({ name })

  return (
    <div>
      <Button
        onClick={() => onChange(originalValue)}
        kind={value === originalValue ? 'primary' : 'normal'}
        skin='default'
        size='small'
        disabled={disabled}
        data-cy={dataTestId}
      >
        {children}
      </Button>
    </div>
  )
}
