import { isEmpty } from 'bold-ui'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { Breadcrumb } from 'components/breadcrumb'
import { parseDateFromLocalStorage } from 'components/form'
import { PageLoading } from 'components/loading'
import { PecSwitch, PrivateRoute } from 'components/route'
import {
  useAgendamentosDiaQuery,
  useAtendimentoVacinacaoViewQuery,
  useIdadeGestacionalQuery,
} from 'graphql/hooks.generated'
import { SituacaoAgendadoEnum, StatusAtendimento, TipoAtendimentoProfissional } from 'graphql/types.generated'
import { useLocalStorageState } from 'hooks/useStorage'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { useRouteMatch } from 'react-router'
import { Redirect, Route } from 'react-router-dom'
import { emptyArray } from 'util/array'
import { calculateAge } from 'util/date/calculateAge'
import { dateAsYyyyMmDd } from 'util/date/formatDate'

import Permissions from '../../../types/Permissions'
import { AgendamentosCidadaoAtendimentoView } from '../detail/agendamentos/AgendamentosCidadaoAtendimentoView'
import { CidadaoDetailView } from '../detail/cadastro-cidadao/CidadaoDetailView'
import { FolhaRostoView } from '../detail/folha-rosto/FolhaRostoView'
import { AtendimentoHeader } from '../detail/header/AtendimentoHeader'
import { grupoCboAcompanhamentoPreNatal } from '../detail/soap/pre-natal/acessos'
import { VacinacaoView } from '../detail/vacinacao/VacinacaoView'
import { CidadaoAtendimento } from '../types/CidadaoAtendimento'
import { isResponsavelAtendimentoProfissional } from '../util'
import { AtendimentoVacinacaoState, initialValue } from './model'

interface AtendimentoVacinacaoViewProps {
  atendimentoId: ID
}

const SITUACOES_AGENDAMENTOS = [SituacaoAgendadoEnum.AGENDADO, SituacaoAgendadoEnum.CIDADAO_PRESENTE_NA_UNIDADE]

export function AtendimentoVacinacaoView({ atendimentoId }: AtendimentoVacinacaoViewProps) {
  const { acesso, tipoEstabelecimento, hasCboAuth, hasAuthorization } = useAcessoLotacaoOrEstagio()

  const match = useRouteMatch()

  const {
    data: { atendimento },
    loading: loadingAtendimento,
  } = useAtendimentoVacinacaoViewQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      atendimentoId,
    },
  })

  const { cidadao, prontuario, atendimentoProfissional, isRegistroTardio } = atendimento || {}

  const [cacheState, setCacheState, deleteCacheState] = useLocalStorageState<AtendimentoVacinacaoState>(
    `${acesso?.id}/atendimento/vacinacao/${atendimentoId}`,
    { modifier: cacheParser, checkVersionCompatibility: true }
  )

  const dataAtendimento = atendimentoProfissional?.iniciadoEm

  const canAccess = hasAuthorization(Permissions.visualizarListaDeAtendimento.cadastrarEditarEExcluir)
  const canAccessAgenda = hasAuthorization(Permissions.visualizarAgenda.agendar)

  const [initialStateLoaded, setInitialStateLoaded] = useState(!!cacheState)

  const idadeEmAnos = useMemo(
    () => cidadao?.dataNascimento && calculateAge(cidadao.dataNascimento, atendimentoProfissional?.iniciadoEm).years,
    [atendimentoProfissional, cidadao]
  )

  const cidadaoComIdadeEmAnos: CidadaoAtendimento = { ...cidadao, idadeEmAnos }

  const today = moment().startOf('day').toDate()
  const { data: agendamentosDiaQueryResults, loading: loadingAgendamento } = useAgendamentosDiaQuery({
    variables: {
      input: {
        cidadaoId: cidadao?.id,
        dataAgendadoInicio: today,
        dataAgendadoFim: today,
        situacao: SITUACOES_AGENDAMENTOS,
        unidadeSaudeId: acesso?.unidadeSaude.id,
        isForaDaUbs: false,
        isOrigemAtencaoDomiciliar: false,
      },
    },
    skip: !cidadao?.id,
  })

  const agendamentosDia = agendamentosDiaQueryResults?.agendados?.content?.filter(
    (agendado) => agendado.id !== atendimento.agendado?.id || agendado?.lotacaoAgendada?.id !== acesso?.id
  )

  const isLoading = loadingAtendimento || loadingAgendamento
  const isGestante = !isEmpty(prontuario?.preNatalAtivo)
  const rascunhoAtendimento = atendimentoProfissional?.rascunho

  useEffect(() => {
    if (!isLoading) {
      const agendadoSelecionado = cacheState?.finalizacaoVacinacao.desfechoAtendimento?.atendimento?.agendado
      if (agendadoSelecionado && !agendamentosDia?.find((agendamentos) => agendamentos.id === agendadoSelecionado)) {
        cacheState.finalizacaoVacinacao.desfechoAtendimento.atendimento.agendado = undefined
      }

      if (!initialStateLoaded) {
        !!rascunhoAtendimento
          ? setCacheState(cacheParser(JSON.parse(rascunhoAtendimento) as AtendimentoVacinacaoState))
          : setCacheState(
              initialValue(prontuario?.puerpera, isGestante, atendimento?.tiposServico, canAccess, agendamentosDia)
            )
        setInitialStateLoaded(true)
      }
    }
  }, [
    agendamentosDia,
    atendimento,
    cacheState,
    canAccess,
    initialStateLoaded,
    isGestante,
    isLoading,
    prontuario,
    rascunhoAtendimento,
    setCacheState,
  ])

  const statusInvalido =
    atendimento?.statusAtendimento === StatusAtendimento.ATENDIMENTO_REALIZADO ||
    atendimento?.statusAtendimento === StatusAtendimento.NAO_AGUARDOU

  const tipoValido = atendimento?.atendimentoProfissional?.tipo === TipoAtendimentoProfissional.VACINACAO
  const prontuarioId = prontuario?.id
  const preNatalAtivo = prontuario?.preNatalAtivo
  const ultimaDumPreNatalAtivo = preNatalAtivo?.ultimaDum

  const { loading: loadingIdadeGestacional, data: dataIdadeGestacional } = useIdadeGestacionalQuery({
    variables: {
      input: { prontuarioId, dataAtendimento: dateAsYyyyMmDd(dataAtendimento), dum: ultimaDumPreNatalAtivo },
    },
    skip: !prontuarioId || !dataAtendimento || !ultimaDumPreNatalAtivo || !hasCboAuth(grupoCboAcompanhamentoPreNatal),
  })
  const idadeGestacional = dataIdadeGestacional?.idadeGestacional

  if (isLoading || !initialStateLoaded || loadingIdadeGestacional) {
    return (
      <>
        <Breadcrumb title='Atendimento de vacinação' />
        <PageLoading message='Carregando dados do atendimento de vacinação...' />
      </>
    )
  } else if (statusInvalido || !tipoValido) {
    return <Redirect to='/404' />
  } else if (!isResponsavelAtendimentoProfissional(acesso, atendimento?.atendimentoProfissional)) {
    return <Redirect to='/403' />
  }

  const renderFolhaRostoView = () => (
    <FolhaRostoView
      isRegistroTardio={isRegistroTardio}
      cidadao={cidadaoComIdadeEmAnos}
      lotacao={atendimentoProfissional?.lotacao}
      prontuarioId={prontuarioId}
      dataReferencia={atendimentoProfissional?.iniciadoEm}
      isGestante={isGestante}
      gestacoes={prontuario?.gestacoes ?? emptyArray}
      isAtendimentoAgendado={!!atendimento.agendado}
      atendimentoId={atendimentoId}
      tipoAtendimentoProfissional={atendimentoProfissional?.tipo}
      clearCache={deleteCacheState}
      idadeGestacional={idadeGestacional}
    />
  )

  const renderVacinacaoView = () => {
    return (
      <VacinacaoView
        cacheState={cacheState}
        atendimentoId={atendimentoId}
        prontuarioId={prontuarioId}
        cidadao={cidadaoComIdadeEmAnos}
        dataAtendimento={atendimentoProfissional?.iniciadoEm}
        clearCache={deleteCacheState}
        updateCache={setCacheState}
        agendamentosDia={agendamentosDia}
        gestante={isGestante}
        gestacoes={prontuario?.gestacoes || []}
        puerpera={prontuario?.puerpera}
        tipoEstabelecimento={tipoEstabelecimento}
        lastSaved={cacheState?.lastSaved}
        canAccessAgenda={canAccessAgenda}
        statusRevisaoAtendimento={atendimento?.statusRevisaoAtendimento}
        tipoAtendimentoProfissional={atendimentoProfissional?.tipo}
      />
    )
  }

  const renderCidadaoDetail = () => (
    <CidadaoDetailView
      atendimentoId={atendimentoId}
      cidadaoId={cidadao?.id}
      clearCache={deleteCacheState}
      tipoAtendimentoProfissional={atendimentoProfissional?.tipo}
      callbackUrl='lista-atendimento/atendimento-vacinacao'
    />
  )

  const renderAgendamentos = () => (
    <AgendamentosCidadaoAtendimentoView
      atendimentoId={atendimentoId}
      tipoAtendimentoProfissional={atendimentoProfissional.tipo}
      isRegistroTardio={isRegistroTardio}
      clearCache={deleteCacheState}
    />
  )

  return (
    <>
      <Breadcrumb title='Atendimento de vacinação' />
      <AtendimentoHeader
        cidadaoId={cidadao?.id}
        renderFolhaRosto={false}
        renderSoap={false}
        renderHistorico={false}
        renderVacinacao
        cnsProfissional={atendimento?.atendimentoProfissional.lotacao.profissional.cns}
        cnsCidadao={cidadao?.cns}
        cnes={atendimento?.atendimentoProfissional.lotacao.unidadeSaude.cnes}
        municipioId={atendimento?.atendimentoProfissional.lotacao.municipio.id}
        dataAtendimento={atendimentoProfissional?.iniciadoEm}
      />
      <PecSwitch>
        <Redirect exact path={match.url} to={`${match.url}/vacinacao`} />
        <PrivateRoute
          path={`${match.url}/folha-rosto`}
          render={renderFolhaRostoView}
          permission={Permissions.visualizarProntuario}
        />
        <Route path={`${match.url}/vacinacao`} render={renderVacinacaoView} />
        <Route path={`${match.url}/cadastro-cidadao`} render={renderCidadaoDetail} />
        <Route path={`${match.path}/agendamentos/:cidadaoId`} render={renderAgendamentos} />
      </PecSwitch>
    </>
  )
}

const cacheParser = (cacheState: AtendimentoVacinacaoState): AtendimentoVacinacaoState =>
  cacheState
    ? {
        ...cacheState,
        finalizacaoVacinacao: {
          ...cacheState.finalizacaoVacinacao,
          agendamentoConsultas: cacheState.finalizacaoVacinacao?.agendamentoConsultas
            ? {
                ...cacheState.finalizacaoVacinacao.agendamentoConsultas,
                avulsa: cacheState.finalizacaoVacinacao.agendamentoConsultas.avulsa
                  ? {
                      ...cacheState.finalizacaoVacinacao.agendamentoConsultas.avulsa,
                      horario: parseDateFromLocalStorage(
                        cacheState.finalizacaoVacinacao.agendamentoConsultas.avulsa.horario
                      ),
                    }
                  : undefined,
              }
            : undefined,
        },
      }
    : undefined
