/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, HFlow, Icon, Tooltip, VFlow } from 'bold-ui'
import { DateField } from 'components/form'
import { resolveName } from 'components/form/final-form/hooks/useField'
import { getFormGroupErrors } from 'components/form/final-form/util'
import theme from 'config/theme'
import { format } from 'date-fns'
import { FormApi } from 'final-form'
import { IdentidadeGeneroEnum, Procedimento, SexoEnum } from 'graphql/types.generated'
import { useServerTime } from 'hooks/useServerTime'
import { omit } from 'lodash'
import { useCallback } from 'react'
import { useField } from 'react-final-form'
import { isUndefinedOrNull } from 'util/checks'
import { toDate } from 'util/date/formatDate'
import { MetaPath } from 'util/metaPath'
import { isObjectDeepEmpty } from 'util/object'
import { v4 as uuidv4 } from 'uuid'

import {
  ReplicateDatesModel,
  ResultadoExameEspecificoModel,
  ResultadoExameSemSolicitacaoModel,
  ResultadosExamesNaoSolicitadosModel,
} from '../../model'
import { getCardResultadoExameStatus } from '../components/CardResultadoExameContainer'
import { ExamesSelect } from '../components/ExamesSelect'
import { ResultadoExameSemSolicitacaoField } from './ResultadoExameSemSolicitacaoField'

interface ExamesSemSolicitacaoFieldProps {
  name: MetaPath<ResultadosExamesNaoSolicitadosModel>
  replicateDatePath: MetaPath<ReplicateDatesModel>
  cidadaoSexo: SexoEnum
  cidadaoIdentidadeGeneroEnum: IdentidadeGeneroEnum
  dataInicioAtendimento: Date
  formApi: FormApi
  changeValue(name: MetaPath<any>, prevValue: any): void
}

enum ReplicateDateEnum {
  REALIZADO = 'REALIZADO',
  RESULTADO = 'RESULTADO',
}

export const ExamesSemSolicitacaoField = (props: ExamesSemSolicitacaoFieldProps) => {
  const {
    name,
    replicateDatePath,
    cidadaoSexo,
    cidadaoIdentidadeGeneroEnum,
    dataInicioAtendimento,
    formApi,
    changeValue,
  } = props

  const { getServerTimeNow } = useServerTime()

  const {
    input: { value },
  } = useField<ResultadosExamesNaoSolicitadosModel>(resolveName(name), { subscription: { value: true } })

  const {
    input: { value: replicateDateValue },
  } = useField<ReplicateDatesModel>(resolveName(replicateDatePath), { subscription: { value: true } })

  const items = Object.entries(value).sort(([, a], [, b]) => b.createdAt - a.createdAt)

  const push = useCallback(
    (item: Procedimento) => {
      changeValue(name, (prevValue) => ({
        ...prevValue,
        [uuidv4()]: {
          exame: item,
          dataRealizado: replicateDateValue?.dataRealizado,
          dataResultado: replicateDateValue?.dataResultado,
          createdAt: getServerTimeNow().valueOf(),
        } as ResultadoExameSemSolicitacaoModel & ResultadoExameEspecificoModel,
      }))
    },
    [changeValue, getServerTimeNow, name, replicateDateValue]
  )

  const remove = useCallback(
    (id: string) => {
      changeValue(name, (prevValue: ResultadosExamesNaoSolicitadosModel) => omit(prevValue, id))
    },
    [changeValue, name]
  )

  const onReplicateDate = (date: Date, typeOfDate: ReplicateDateEnum) => {
    if (!isUndefinedOrNull(date)) {
      const formattedDate = format(date, 'yyyy-MM-dd')

      changeValue(name, (prevValue: ResultadosExamesNaoSolicitadosModel) => {
        return Object.fromEntries(
          Object.entries(prevValue ?? {}).map(([key, value]) => {
            let newValue = null

            if (typeOfDate === ReplicateDateEnum.REALIZADO) {
              newValue = isUndefinedOrNull(value.dataRealizado) ? { ...value, dataRealizado: formattedDate } : value
            } else {
              newValue = isUndefinedOrNull(value.dataResultado) ? { ...value, dataResultado: formattedDate } : value
            }

            return [key, newValue]
          })
        )
      })
    }
  }

  return (
    <VFlow vSpacing={0.25}>
      <HFlow hSpacing={0.25} alignItems='center'>
        <Heading level={5}>Adicionar exame sem solicitação</Heading>

        <Tooltip text='Insira exames NÃO solicitados nesta instalação do e-SUS APS PEC' placement='right'>
          <Icon icon='infoCircleFilled' fill='normal' size={1} style={styles.iconTooltip} />
        </Tooltip>
      </HFlow>

      <VFlow>
        <ExamesSelect
          sexo={cidadaoSexo}
          identidadeGeneroDbEnum={cidadaoIdentidadeGeneroEnum}
          placeholder='Pesquise por exame para inserir o resultado'
          onChange={(item) => push(item)}
        />

        {items.isNotEmpty() && (
          <HFlow hSpacing={0.5}>
            <HFlow>
              <DateField
                name={replicateDatePath.dataRealizado}
                label='Exames realizados em'
                maxDate={
                  !isUndefinedOrNull(replicateDateValue?.dataResultado)
                    ? toDate(replicateDateValue.dataResultado)
                    : dataInicioAtendimento
                }
                inline
                style={styles.replicateDateField}
                onChange={(dateToReplicate: Date) => onReplicateDate(dateToReplicate, ReplicateDateEnum.REALIZADO)}
              />

              <DateField
                name={replicateDatePath.dataResultado}
                label='Resultados em'
                maxDate={dataInicioAtendimento}
                minDate={
                  !isUndefinedOrNull(replicateDateValue?.dataRealizado)
                    ? toDate(replicateDateValue.dataRealizado)
                    : null
                }
                inline
                style={styles.replicateDateField}
                onChange={(dateToReplicate: Date) => onReplicateDate(dateToReplicate, ReplicateDateEnum.RESULTADO)}
              />
            </HFlow>
            <Tooltip
              text='As datas de realização e resultados só serão replicadas para os exames sem solicitação'
              placement='top'
            >
              <Icon icon='infoCircleFilled' fill='normal' size={1} style={styles.iconTooltip} />
            </Tooltip>
          </HFlow>
        )}

        {items?.map(([id, item]: [string, ResultadoExameSemSolicitacaoModel & ResultadoExameEspecificoModel]) => {
          const itemName = name[id]
          const itemValue = value?.[id]
          const status = getCardResultadoExameStatus(
            itemValue,
            !isObjectDeepEmpty(getFormGroupErrors(itemName, formApi))
          )
          const ignoringTouchedAndDirtyStatus = getCardResultadoExameStatus(
            itemValue,
            !isObjectDeepEmpty(getFormGroupErrors(itemName, formApi, true))
          )

          return (
            <ResultadoExameSemSolicitacaoField
              key={id}
              name={itemName}
              exame={item.exame}
              value={itemValue}
              onRemove={() => remove(id)}
              status={status}
              ignoringTouchedAndDirtyStatus={ignoringTouchedAndDirtyStatus}
            />
          )
        })}
      </VFlow>
    </VFlow>
  )
}

const styles = {
  iconTooltip: css`
    margin-top: 0.5rem;
    &:hover {
      fill: ${theme.pallete.primary.main};
    }
  `,
  replicateDateField: css`
    width: 11rem;
  `,
}
