import { HFlow, Text } from 'bold-ui'
import { AsyncSelectFieldProps, useAsyncQuerySelect } from 'components/form/field/select/useAsyncQuerySelect'
import { SelectField } from 'components/form/final-form'
import { UnidadesSaudeByTipoVinculacaoServicoDocument } from 'graphql/hooks.generated'
import {
  UnidadesSaudeByTipoVinculacaoServicoQuery,
  UnidadesSaudeByTipoVinculacaoServicoQueryVariables,
  VinculacaoServicoTipoEstabelecimentoEnum,
} from 'graphql/types.generated'
import React from 'react'

export type UnidadesSaudeByTipoVinculacaoServicoSelectModel = UnidadesSaudeByTipoVinculacaoServicoQuery['unidadesSaudeByTipoVinculacaoServico'][0]

export interface UnidadeSaudeByTipoVinculacaoServicoSelectFieldProps
  extends AsyncSelectFieldProps<UnidadesSaudeByTipoVinculacaoServicoSelectModel> {
  tipoEstabelecimento: VinculacaoServicoTipoEstabelecimentoEnum
}

export function UnidadeSaudeByTipoVinculacaoServicoSelectField(
  props: UnidadeSaudeByTipoVinculacaoServicoSelectFieldProps
) {
  const { tipoEstabelecimento, ...rest } = props

  const { selectProps } = useAsyncQuerySelect<
    UnidadesSaudeByTipoVinculacaoServicoSelectModel,
    UnidadesSaudeByTipoVinculacaoServicoQuery,
    UnidadesSaudeByTipoVinculacaoServicoQueryVariables
  >({
    query: UnidadesSaudeByTipoVinculacaoServicoDocument,
    extractItems: (data) => data && data.unidadesSaudeByTipoVinculacaoServico,
    variables: (inputQuery) => ({
      input: {
        query: inputQuery,
        tipoEstabelecimento,
      },
    }),
  })

  return (
    <SelectField<UnidadesSaudeByTipoVinculacaoServicoSelectModel>
      itemToString={itemToString}
      renderItem={renderItem}
      {...selectProps}
      {...rest}
    />
  )
}

const itemToString = (option: UnidadesSaudeByTipoVinculacaoServicoSelectModel) => option && option.nome

const renderItem = (item: UnidadesSaudeByTipoVinculacaoServicoSelectModel) => (
  <>
    <Text fontWeight='bold'>{item.nome}</Text>
    <HFlow hSpacing={0.5}>
      <Text fontWeight='bold'>CNES:</Text>
      {item.cnes}
    </HFlow>
  </>
)
