import { FlagsContextModel } from 'config/useFlagsContext'
import { ReactComponent as Acompanhamentos } from 'images/side-menu/acompanhamentos.svg'
import { ReactComponent as Agenda } from 'images/side-menu/agenda.svg'
import { ReactComponent as AtendaoDomiciliar } from 'images/side-menu/atencao-domiciliar.svg'
import { ReactComponent as Auditoria } from 'images/side-menu/auditoria.svg'
import { ReactComponent as BuscaAtiva } from 'images/side-menu/busca-ativa.svg'
import { ReactComponent as CadastroLote } from 'images/side-menu/cadastro-de-lote.svg'
import { ReactComponent as Cbo } from 'images/side-menu/cbo.svg'
import { ReactComponent as Cds } from 'images/side-menu/cds.svg'
import { ReactComponent as Cidadao } from 'images/side-menu/cidadao.svg'
import { ReactComponent as ConfiguracoesAD } from 'images/side-menu/configuracoes-ad.svg'
import { ReactComponent as Configuracoes } from 'images/side-menu/configuracoes.svg'
import { ReactComponent as GarantiaAcesso } from 'images/side-menu/garantia-acesso.svg'
import { ReactComponent as GestaoMunicipal } from 'images/side-menu/gestao-municipal.svg'
import { ReactComponent as ImportacaoCidadao } from 'images/side-menu/importacao-cidadao.svg'
import { ReactComponent as ImportacaoCnes } from 'images/side-menu/importacao-de-cnes.svg'
import { ReactComponent as ListaAtendimento } from 'images/side-menu/lista-de-atendimento.svg'
import { ReactComponent as GestaoCadastros } from 'images/side-menu/merge-do-cidadao.svg'
import { ReactComponent as Perfis } from 'images/side-menu/perfis.svg'
import { ReactComponent as Profissionais } from 'images/side-menu/profissionais.svg'
import { ReactComponent as RegistroTardio } from 'images/side-menu/registro-tardio.svg'
import { ReactComponent as Relatorios } from 'images/side-menu/relatorios.svg'
import { ReactComponent as TransmissaoDados } from 'images/side-menu/transmissao-de-dados.svg'
import { ReactComponent as UnidadeSaude } from 'images/side-menu/unidade-de-saude.svg'
import { ReactComponent as VideochamadaIcon } from 'images/side-menu/videochamadas.svg'
import React from 'react'
import { CBOS_ACESSO_CC, NIVEL_SUPERIOR } from 'types/NivelCbo'
import Permissions from 'types/Permissions'
import { getTiposEstabelecimentoCuidadoCompartilhado } from 'view/cuidados-compartilhados/util-cuidadoCompartilhado'
import {
  RELATORIO_GERENCIAL_ATENDIMENTOS_PATH,
  RELATORIO_GERENCIAL_VACINACAO_PATH,
  RELATORIOS_GERENCIAIS_PATH,
} from 'view/relatorio/relatoriosgerenciais/common/model-relatorioGerencial'

import { SideMenuItemConfig } from './SideMenu'

const ACOMPANHAMENTOS_PATH = '/acompanhamentos'
export const ACOMPANHAMENTO_CIDADAOS_VINCULADOS_PATH = `${ACOMPANHAMENTOS_PATH}/cidadaos-vinculados`
export const ACOMPANHAMENTO_CONDICOES_SAUDE_PATH = `${ACOMPANHAMENTOS_PATH}/condicoes-saude`
export const ACOMPANHAMENTO_TERRITORIO_PATH = `${ACOMPANHAMENTOS_PATH}/territorio`
export const GARANTIA_ACESSO_PATH = '/gestao-filas/garantia-acesso'

export interface SideMenuConfiguration {
  videochamadasEnabled: boolean
}

export const getSideMenuItems = (flags: FlagsContextModel, config: SideMenuConfiguration): SideMenuItemConfig[] => {
  return [
    {
      title: 'Acompanhamentos',
      to: ACOMPANHAMENTOS_PATH,
      icon: <Acompanhamentos />,
      permission: Permissions.acompanhamentos,
      children: [
        {
          items: [
            {
              title: 'Condições de saúde',
              to: ACOMPANHAMENTO_CONDICOES_SAUDE_PATH,
              permission: Permissions.acompanhamentos.condicoesDeSaude,
            },
            {
              title: 'Cidadãos vinculados',
              to: ACOMPANHAMENTO_CIDADAOS_VINCULADOS_PATH,
              permission: Permissions.acompanhamentos.cidadaosVinculados,
            },
            flags.VISUALIZACAO_TERRITORIO_ENABLED && {
              title: 'Território',
              to: ACOMPANHAMENTO_TERRITORIO_PATH,
              permission: Permissions.acompanhamentos.territorio,
            },
          ],
        },
      ],
    },
    {
      title: 'Agenda',
      to: '/agenda',
      icon: <Agenda />,
      permission: Permissions.visualizarAgenda,
    },
    {
      title: 'Atenção domiciliar',
      to: '/ad',
      icon: <AtendaoDomiciliar />,
      permission: Permissions.atencaoDomiciliar,
      children: [
        {
          items: [
            {
              title: 'Agenda',
              to: '/ad/agenda',
              permission: Permissions.atencaoDomiciliar.visualizarAgendaDeAdDaEquipe,
            },
            {
              title: 'Lista',
              to: '/ad/lista',
              permission: Permissions.atencaoDomiciliar.visualizarListaDeAdDaEquipe,
            },
            {
              title: 'Relatório',
              to: '/ad/relatorio',
              permission: Permissions.atencaoDomiciliar.gerarRelatorioDeAdDaEquipe,
            },
          ],
        },
      ],
    },
    {
      title: 'Auditoria',
      to: '/auditoria',
      icon: <Auditoria />,
      permission: Permissions.gerarTrilhaDeAuditoria,
    },
    {
      title: 'Busca ativa',
      to: '/busca-ativa',
      icon: <BuscaAtiva />,
      permission: Permissions.buscaAtivaDeVacinacao,
      onlyAtencaoPrimaria: true,
      children: [
        {
          items: [
            {
              title: 'Vacinação',
              to: '/busca-ativa/vacinacao',
              permission: Permissions.buscaAtivaDeVacinacao,
              onlyAtencaoPrimaria: true,
            },
          ],
        },
      ],
    },
    {
      title: 'CBO',
      to: '/cbo',
      icon: <Cbo />,
      permission: Permissions.visualizarCbo,
    },
    {
      title: 'CDS',
      to: '/cds',
      icon: <Cds />,
      permission: Permissions.cds,
      children: [
        {
          items: [
            {
              title: 'Atendimento domiciliar',
              to: '/cds/atendimento-domiciliar',
              permission: Permissions.cds.fichaDeAtendimentoDomiciliar,
            },
            {
              title: 'Atendimento individual',
              to: '/cds/atendimento-individual',
              permission: Permissions.cds.fichaDeAtendimentoIndividual,
            },
            {
              title: 'Atendimento odontológico individual',
              to: '/cds/atendimento-odontologico',
              permission: Permissions.cds.fichaDeAtendimentoOdontologico,
            },
            {
              title: 'Atividade coletiva',
              to: '/cds/atividade-coletiva',
              permission: Permissions.cds.fichaDeAtividadeColetiva,
            },
            {
              title: 'Avaliação de elegibilidade e admissão',
              to: '/cds/avaliacao-elegibilidade-admissao',
              permission: Permissions.cds.fichaDeAvaliacaoDeElegibilidade,
            },
            {
              title: 'Cadastro domiciliar e territorial',
              to: '/cds/cadastro-domiciliar',
              permission: Permissions.cds.fichaDeCadastroDomiciliar,
            },
            {
              title: 'Cadastro individual',
              to: '/cds/cadastro-individual',
              permission: Permissions.cds.fichaDeCadastroIndividual,
            },
            {
              title: 'Marcadores de consumo alimentar',
              to: '/cds/consumo-alimentar',
              permission: Permissions.cds.fichaDeConsumoAlimentar,
            },
            {
              title: 'Procedimentos',
              to: '/cds/procedimentos',
              permission: Permissions.cds.fichaDeProcedimentos,
            },
            {
              title: 'Síndrome neurológica por Zika / microcefalia',
              to: '/cds/ficha-complementar',
              permission: Permissions.cds.fichaComplementarSindromeNeurologicaPorZikaOuMicrocefalia,
            },
            {
              title: 'Vacinação',
              to: '/cds/ficha-vacinacao',
              permission: Permissions.cds.fichaDeVacinacao,
            },
            {
              title: 'Visita domiciliar e territorial',
              to: '/cds/visita-domiciliar',
              permission: Permissions.cds.fichaDeVisitaDomiciliar,
            },
          ],
        },
      ],
    },
    {
      title: 'Cidadão',
      to: '/cidadao',
      icon: <Cidadao />,
      permission: Permissions.gestaoDeCadastrosDeCidadao.visualizarCidadao,
    },
    {
      title: 'CNES',
      to: '/importarCnes',
      icon: <ImportacaoCnes />,
      permission: Permissions.importarCnes,
    },
    {
      title: 'Configurações da instalação',
      to: '/configuracoes/instalacao',
      icon: <Configuracoes />,
      permission: Permissions.configuracaoDeInstalacao,
    },
    {
      title: 'Configurações de AD',
      to: '/configuracoesAd',
      icon: <ConfiguracoesAD />,
      permission: Permissions.configurarAtencaoDomiciliar,
    },
    {
      title: 'Gestão de cadastros',
      to: '/gestaoCadastros',
      icon: <GestaoCadastros />,
      permission: Permissions.gestaoDeCadastrosDeCidadao,
      children: [
        {
          items: [
            {
              title: 'Unificação de cadastros',
              to: '/gestaoCadastros/unificacaoCadastro',
              permission: Permissions.gestaoDeCadastrosDeCidadao.unificacaoDeCadastros,
            },
            flags.UNIFICACAO_BASE_ENABLED && {
              title: 'Unificação de bases - Cadastros duplicados',
              to: '/gestaoCadastros/unificacaoBaseCadastrosDuplicados',
              permission: Permissions.gestaoDeCadastrosDeCidadao.unificacaoDeBases,
            },
            flags.TERRITORIO_ENABLED && {
              title: 'Reterritorialização',
              to: '/gestaoCadastros/reterritorializacao',
              permission: Permissions.gestaoDeCadastrosDeCidadao.reterritorializacao,
            },
          ],
        },
      ],
    },
    {
      title: 'Gestão municipal',
      to: '/configuracoes/gestaomunicipal',
      icon: <GestaoMunicipal />,
      permission: Permissions.configuracaoMunicipal,
    },
    {
      title: 'Importar cidadão',
      to: '/importarCidadao',
      icon: <ImportacaoCidadao />,
      permission: Permissions.importarCadastrosDeCidadao,
    },
    {
      title: 'Gestão de filas',
      to: '/gestao-filas',
      icon: <GarantiaAcesso />,
      permission: Permissions.visualizarListaDeAtendimento,
      children: [
        {
          items: [
            {
              title: 'Garantia do acesso',
              to: GARANTIA_ACESSO_PATH,
              permission: Permissions.visualizarListaDeAtendimento,
            },
            flags.CUIDADO_COMPARTILHADO_ENABLED && {
              title: 'Cuidados compartilhados',
              to: '/gestao-filas/cuidados-compartilhados',
              permission: Permissions.cuidadoCompartilhado,
              tiposEstabelecimento: getTiposEstabelecimentoCuidadoCompartilhado(
                flags.PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED
              ),
              hasAnyCbo: [CBOS_ACESSO_CC],
            },
          ],
        },
      ],
    },
    {
      title: 'Lista de atendimentos',
      to: '/lista-atendimento',
      icon: <ListaAtendimento />,
      permission: Permissions.visualizarListaDeAtendimento,
    },
    {
      title: 'Lotes de imunobiológico',
      to: '/lotesImunobiologico',
      icon: <CadastroLote />,
      permission: Permissions.cadastrarEditarEExcluirLotesDeImunobiologico,
    },
    {
      title: 'Registro tardio de atendimento',
      to: '/registroTardio',
      icon: <RegistroTardio />,
      acceptEstagio: false,
      permission: Permissions.visualizarListaDeAtendimento,
    },
    {
      title: 'Perfis',
      to: '/perfil',
      icon: <Perfis />,
      permission: Permissions.visualizarPerfis,
    },
    {
      title: 'Profissionais',
      to: '/profissional',
      icon: <Profissionais />,
      permission: Permissions.visualizarProfissionais,
    },
    {
      title: 'Relatórios',
      to: '/relatorios',
      icon: <Relatorios />,
      permission: Permissions.relatorios,
      children: [
        {
          items: [
            {
              title: 'Configuração de impressão',
              to: '/relatorios/configuracao-impressao',
              permission: Permissions.relatorios.processarRelatorios,
            },
            {
              title: 'Processamento',
              to: '/relatorios/processamento',
              permission: Permissions.relatorios.processarRelatorios,
            },
          ],
        },
        {
          group: 'Gerenciais',
          items: [
            {
              title: 'Absenteísmo',
              to: `${RELATORIOS_GERENCIAIS_PATH}/absenteismo`,
              permission: Permissions.relatorios.gerenciais.absenteismo,
            },
            {
              title: 'Atendimentos',
              to: RELATORIO_GERENCIAL_ATENDIMENTOS_PATH,
              permission: Permissions.relatorios.gerenciais.atendimentos,
            },
            {
              title: 'Cuidado compartilhado',
              to: `${RELATORIOS_GERENCIAIS_PATH}/cuidado-compartilhado`,
              permission: Permissions.relatorios.gerenciais.cuidadoCompartilhado,
            },
            {
              title: 'Vacinação',
              to: RELATORIO_GERENCIAL_VACINACAO_PATH,
              permission: Permissions.relatorios.gerenciais.vacinacao,
            },
          ],
        },
        {
          group: 'Consolidados',
          items: [
            {
              title: 'Cadastro domiciliar e territorial',
              to: '/relatorios/consolidados/cadastro-domiciliar',
              permission: Permissions.relatorios.consolidados.cadastroDomiciliarETerritorial,
            },
            {
              title: 'Cadastro individual',
              to: '/relatorios/consolidados/cadastro-individual',
              permission: Permissions.relatorios.consolidados.cadastroIndividual,
            },
            {
              title: 'Situação do território',
              to: '/relatorios/consolidados/situacao-territorio',
              permission: Permissions.relatorios.consolidados.situacaoDoTerritorio,
            },
          ],
        },
        {
          group: 'Operacionais',
          items: [
            {
              title: 'Cadastro territorial',
              to: '/relatorios/operacionais/cadastro-territorial',
              permission: Permissions.relatorios.operacional.cadastroDoTerritorio,
            },
            {
              title: 'Crianças menores de 5 anos',
              to: '/relatorios/operacionais/crianca',
              permission: Permissions.relatorios.operacional.crianca,
              logEventParameter: 'Relatório de criança menores de 5 anos',
            },
            {
              title: 'Gestante / puérpera',
              to: '/relatorios/operacionais/gestante-puerpera',
              permission: Permissions.relatorios.operacional.gestante,
              logEventParameter: 'Relatório de gestante/puérpera',
            },
            {
              title: 'Risco cardiovascular',
              to: '/relatorios/operacionais/risco-cardiovascular',
              permission: Permissions.relatorios.operacional.riscoCardiovascular,
              logEventParameter: 'Relatório de risco cardiovascular',
            },
          ],
        },
        {
          group: 'Produção',
          items: [
            {
              title: 'Atendimento domiciliar',
              to: '/relatorios/producao/atendimento-domiciliar',
              permission: Permissions.relatorios.producao.atendimentoDomiciliar,
            },
            {
              title: 'Atendimento individual',
              to: '/relatorios/producao/atendimento-individual',
              permission: Permissions.relatorios.producao.atendimentoIndividual,
            },
            {
              title: 'Atendimento odontológico individual',
              to: '/relatorios/producao/atendimento-odontologico',
              permission: Permissions.relatorios.producao.atendimentoOdontologicoIndividual,
            },
            {
              title: 'Atividade coletiva',
              to: '/relatorios/producao/atividade-coletiva',
              permission: Permissions.relatorios.producao.atividadeColetiva,
            },
            {
              title: 'Avaliação de elegibilidade e admissão',
              to: '/relatorios/producao/avaliacao-elegibilidade-admissao',
              permission: Permissions.relatorios.producao.avaliacaoDeElegibilidadeEAdmissao,
            },
            {
              title: 'Marcadores de consumo alimentar',
              to: '/relatorios/producao/marcadores-consumo-alimentar',
              permission: Permissions.relatorios.producao.marcadoresDeConsumoAlimentar,
            },
            {
              title: 'Procedimentos consolidados',
              to: '/relatorios/producao/procedimentos-consolidados',
              permission: Permissions.relatorios.producao.procedimentosConsolidados,
            },
            {
              title: 'Procedimentos individualizados',
              to: '/relatorios/producao/procedimentos-individualizados',
              permission: Permissions.relatorios.producao.procedimentos,
            },
            {
              title: 'Resumo de produção',
              to: '/relatorios/producao/resumo-producao',
              permission: Permissions.relatorios.producao.resumoDeProducao,
            },
            {
              title: 'Síndrome neurológica por Zika / microcefalia',
              to: '/relatorios/producao/sindrome-neurologica-zika',
              permission: Permissions.relatorios.producao.sindromeNeurologicaPorZikaOuMicrocefalia,
            },
            {
              title: 'Vacinação',
              to: '/relatorios/producao/vacinacao',
              permission: Permissions.relatorios.producao.vacinacao,
            },
            {
              title: 'Visita domiciliar e territorial',
              to: '/relatorios/producao/visita-domiciliar',
              permission: Permissions.relatorios.producao.visitaDomiciliar,
            },
          ],
        },
      ],
    },
    {
      title: 'Transmissão de dados',
      to: '/transmissao',
      icon: <TransmissaoDados />,
      permission: Permissions.transmissaoDeDados,
    },
    {
      title: 'Unidades de saúde',
      to: '/unidadeSaude',
      icon: <UnidadeSaude />,
      permission: Permissions.visualizarUnidadesDeSaude,
    },
    ...(config.videochamadasEnabled
      ? [
          {
            title: 'Videochamadas',
            to: '/videochamada',
            icon: <VideochamadaIcon />,
            acceptEstagio: false,
            hasAnyPermission: [Permissions.videochamada.realizarVideochamada],
            hasAnyCbo: [NIVEL_SUPERIOR],
          },
        ]
      : []),
  ]
}
