/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Alert, Button, ButtonGroup, Heading, VFlow } from 'bold-ui'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { usePecField } from 'components/form/final-form/hooks/useField'
import { useFlags } from 'config/useFlagsContext'
import { isEmpty } from 'lodash'
import { MetaPath } from 'util/metaPath'
import {
  AgendarConsultaCuidadoCompartilhadoFieldsModel,
  CondutaGroupEnum,
  CuidadoCompartilhadoModel,
  CuidadoCompartilhadoVideochamadaState,
  DiscussaoCasoExecutanteFieldsModel,
  DiscussaoCasoSolicitanteFieldsModel,
  EnviarGarantiaAcessoCuidadoCompartilhadoFieldsModel,
  TipoAgendamentoCuidadoCompartilhado,
} from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'
import { getLotacoesConsultaCompartilhadaCuidadoCompartilhado } from 'view/cuidados-compartilhados/util-cuidadoCompartilhado'

import { AgendarCunsultaCompartilhadaCuidadoCompartilhadoPanel } from './AgendarCunsultaCompartilhadaCuidadoCompartilhadoPanel'
import { AgendarCunsultaPresencialCuidadoCompartilhadoPanel } from './AgendarCunsultaPresencialCuidadoCompartilhadoPanel'
import { CondutaGroupCuidadoCompartilhadoRadioFieldContainer } from './CondutaGroupCuidadoCompartilhadoRadioFieldContainer'
import { EnviarGarantiaAcessoCuidadoCompartilhadoPanel } from './EnviarGarantiaAcessoCuidadoCompartilhadoPanel'

interface AgendarConsultaCuidadoCompartilhadoFieldProps {
  path: MetaPath<DiscussaoCasoExecutanteFieldsModel | DiscussaoCasoSolicitanteFieldsModel>
  cuidadoCompartilhado: CuidadoCompartilhadoModel
  videochamadaUuidState: CuidadoCompartilhadoVideochamadaState
}

export const AgendarConsultaCuidadoCompartilhadoField = (props: AgendarConsultaCuidadoCompartilhadoFieldProps) => {
  const { path, cuidadoCompartilhado, videochamadaUuidState } = props

  const { PILOTO_CUIDADO_COMPARTILHADO_1_ENABLED, PILOTO_VIDEOCHAMADA_2_ENABLED } = useFlags()

  const {
    input: { value: garantiaAcessoValue },
  } = usePecField({ name: path.garantiaAcesso, subscription: { value: true } })

  const garantiaAcessoInput = garantiaAcessoValue as EnviarGarantiaAcessoCuidadoCompartilhadoFieldsModel

  const isGarantiaAcesso = PILOTO_CUIDADO_COMPARTILHADO_1_ENABLED && !isEmpty(garantiaAcessoInput)

  const { acesso: lotacao } = useAcessoLotacaoOrEstagio()

  const {
    input: { value: tipoAgendamentoValue, onChange: onChangeTipoAgendamento },
  } = usePecField({ name: path.agendamento.tipoAgendamento, subscription: { value: true } })

  const isConsultaCidadaoPresencial = tipoAgendamentoValue === TipoAgendamentoCuidadoCompartilhado.CONSULTA_COM_CIDADAO
  const isConsultaCompartilhada = tipoAgendamentoValue === TipoAgendamentoCuidadoCompartilhado.CONSULTA_COMPARTILHADA

  const { lotacaoResponsavel } = getLotacoesConsultaCompartilhadaCuidadoCompartilhado(lotacao.id, cuidadoCompartilhado)

  const isVideoChamadaFlagEnabled = PILOTO_CUIDADO_COMPARTILHADO_1_ENABLED && PILOTO_VIDEOCHAMADA_2_ENABLED
  const isProfissionalExecutante = cuidadoCompartilhado.lotacaoExecutanteAtual.id === lotacaoResponsavel.id
  const areFlagsEnabledAndIsExecutante = PILOTO_CUIDADO_COMPARTILHADO_1_ENABLED && isProfissionalExecutante

  return (
    <CondutaGroupCuidadoCompartilhadoRadioFieldContainer<
      AgendarConsultaCuidadoCompartilhadoFieldsModel | EnviarGarantiaAcessoCuidadoCompartilhadoFieldsModel
    >
      name={isGarantiaAcesso ? path.garantiaAcesso : path.agendamento}
      pathCondutaGroup={path.condutaGroup}
      value={isGarantiaAcesso ? CondutaGroupEnum.ENVIAR_GARANTIA_ACESSO : CondutaGroupEnum.AGENDAR_CONSULTA}
    >
      <VFlow>
        {isVideoChamadaFlagEnabled ? (
          <ButtonGroup>
            <Button
              kind={isConsultaCidadaoPresencial ? 'primary' : 'normal'}
              size='small'
              onClick={() => onChangeTipoAgendamento(TipoAgendamentoCuidadoCompartilhado.CONSULTA_COM_CIDADAO)}
              disabled={isGarantiaAcesso}
            >
              Consulta com o cidadão
            </Button>
            <Button
              kind={isConsultaCompartilhada ? 'primary' : 'normal'}
              size='small'
              onClick={() => onChangeTipoAgendamento(TipoAgendamentoCuidadoCompartilhado.CONSULTA_COMPARTILHADA)}
              disabled={isGarantiaAcesso}
            >
              Consulta entre profissionais
            </Button>
          </ButtonGroup>
        ) : (
          <Heading level={3}>Consulta com o cidadão</Heading>
        )}

        {!isGarantiaAcesso ? (
          <VFlow>
            {areFlagsEnabledAndIsExecutante && (
              <Alert type='info' inline style={{ paddingTop: '0.25rem', paddingBottom: '0.25rem' }}>
                É possível enviar demandas de agendamentos com a <strong>presença do cidadão</strong> para o módulo de
                Garantia do acesso, caso não haja disponibilidade de agenda no momento.
              </Alert>
            )}
            {isConsultaCidadaoPresencial && (
              <AgendarCunsultaPresencialCuidadoCompartilhadoPanel
                path={path}
                cuidadoCompartilhado={cuidadoCompartilhado}
              />
            )}
            {isVideoChamadaFlagEnabled && isConsultaCompartilhada && (
              <AgendarCunsultaCompartilhadaCuidadoCompartilhadoPanel
                path={path}
                cuidadoCompartilhado={cuidadoCompartilhado}
                videochamadaUuidState={videochamadaUuidState}
              />
            )}
          </VFlow>
        ) : (
          <EnviarGarantiaAcessoCuidadoCompartilhadoPanel
            path={path}
            garantiaAcessoInput={garantiaAcessoValue}
            cuidadoCompartilhado={cuidadoCompartilhado}
          />
        )}
      </VFlow>
    </CondutaGroupCuidadoCompartilhadoRadioFieldContainer>
  )
}
