/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, HeadingSection, VFlow } from 'bold-ui'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import { useLoadImoveisQuery } from 'graphql/hooks.generated'
import { ImoveisQueryInput, LoadImoveisQuery } from 'graphql/types.generated'
import { useState } from 'react'
import { v4 } from 'uuid'

import { ImoveisTerritorioTable } from './ImoveisTerritorioTable'
import { ReterritorializacaoBuscaFormModel } from './model'
import { ReterritorializacaoBuscaForm } from './ReterritorializacaoBuscaForm'

export type ImoveisQueryResult = LoadImoveisQuery['imoveis']
const foraDeArea = 'FA'

const progressToken = v4()

export function ReterritorializacaoView() {
  const [imoveisInput, setImoveisInput] = useState<ImoveisQueryInput>()
  const [renderState, setRenderState] = useState({
    renderTable: false,
    processingState: false,
    sendQuery: false,
  })
  const [isResetBuscaForm, setIsResetBuscaForm] = useState(false)
  const [checkedRowIdToCidadaoCountMap, setCheckedRowIdToCidadaoCountMap] = useState<Map<string, number>>(new Map())
  const { acesso } = useAcessoLotacaoOrEstagio()

  const onBuscarImoveis = (formValues: ReterritorializacaoBuscaFormModel) => {
    const { unidadeSaude, equipe, microarea, ...values } = formValues
    setImoveisInput({
      ...values,
      ine: equipe?.ine,
      cnes: unidadeSaude?.cnes,
      microarea: microarea !== foraDeArea ? microarea : undefined,
      foraDeArea: microarea === foraDeArea,
      pageParams: {
        page: 0,
        size: 10,
        sort: ['tipoLogradouro', 'logradouro', 'numero', 'complemento', 'bairro', 'microarea', 'id'],
      },
    })
    setRenderState({
      renderTable: true,
      processingState: false,
      sendQuery: true,
    })
    setIsResetBuscaForm(false)
    setCheckedRowIdToCidadaoCountMap(new Map())
  }

  const resetPageState = () => {
    setIsResetBuscaForm(true)
    setRenderState({
      renderTable: false,
      processingState: false,
      sendQuery: false,
    })
    setCheckedRowIdToCidadaoCountMap(new Map())
  }

  const changeToProcessingState = () => {
    setRenderState({
      renderTable: true,
      processingState: true,
      sendQuery: true,
    })
  }

  const { data } = useLoadImoveisQuery({
    variables: {
      input: imoveisInput,
    },
    skip: !renderState.sendQuery,
  })

  const imoveis = data?.imoveis
  const numeroImoveis = imoveis?.pageInfo.totalElements

  return (
    <PageContent fluid type='filled'>
      <Breadcrumb title='Reterritorialização' />
      <HeadingSection level={1} title='Reterritorialização'>
        <Alert type='info'>
          Fichas geradas por esta funcionalidade não são contabilizadas nos Relatórios de Produção.
        </Alert>
      </HeadingSection>
      <VFlow
        vSpacing={5}
        style={css`
          margin-bottom: 2rem;
        `}
      >
        <ReterritorializacaoBuscaForm
          onSubmit={onBuscarImoveis}
          resetToInitialValues={isResetBuscaForm}
          resetPageState={resetPageState}
          hasCheckedImoveis={checkedRowIdToCidadaoCountMap.size > 0}
          disabled={renderState.processingState}
        />
        {imoveis && renderState.renderTable && (
          <ImoveisTerritorioTable
            resetBuscaOnAtualizacaoImoveis={resetPageState}
            changeToProcessingState={changeToProcessingState}
            pageResult={imoveis}
            unidadeSaude={acesso?.unidadeSaude}
            onPageChange={setImoveisInput}
            numeroImoveis={numeroImoveis}
            checkedRowIdToCidadaoCountMap={checkedRowIdToCidadaoCountMap}
            checkedRowIdToCidadaoCountMapSetter={setCheckedRowIdToCidadaoCountMap}
            progressToken={progressToken}
            processingState={renderState.processingState}
          />
        )}
      </VFlow>
    </PageContent>
  )
}
