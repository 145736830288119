import { HFlow, InfoLabel } from 'bold-ui'
import { DateTime } from 'components/date'
import { formatDistanceToNowStrict } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import React from 'react'
import { CuidadoCompartilhadoModel } from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'

interface DateTimeInfoCuidadoCompartilhadoProps {
  cuidadoCompartilhado: CuidadoCompartilhadoModel
}

export function DateTimeInfoCuidadoCompartilhado(props: DateTimeInfoCuidadoCompartilhadoProps) {
  const {
    cuidadoCompartilhado: { iniciadoEm, dataUltimaEvolucao },
  } = props
  return (
    <HFlow hSpacing={2}>
      <InfoLabel title='Data de início'>
        <DateTime value={iniciadoEm} format='DD/MM/YYYY' />
      </InfoLabel>
      <InfoLabel title='Horário de início'>
        <DateTime value={iniciadoEm} format='HH:mm' />
      </InfoLabel>
      <InfoLabel title='Tempo de espera'>
        {formatDistanceToNowStrict(dataUltimaEvolucao, {
          locale: ptBR,
          unit: 'hour',
        })}
      </InfoLabel>
    </HFlow>
  )
}
