import { Modal } from 'bold-ui'
import { useAlert } from 'components/alert'
import { useModeloPersonalizadoRelatorioGerencialQuery } from 'graphql/hooks.generated'
import { TipoModuloEnum } from 'graphql/types.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import React, { useCallback, useEffect, useRef } from 'react'
import { useHistory, useParams, useRouteMatch } from 'react-router'
import { isUndefinedOrNull } from 'util/checks'

import { KeyMapping } from '../../../common/keyMapping-relatorioGerencial'
import { ModeloPersonalizadoRelatorioGerencialForm } from '../form/ModeloPersonalizadoRelatorioGerencialForm'

export const MODELO_PERSONALIZADO_RELATORIO_GERENCIAL_MODAL_URL = '/modelo-personalizado'

interface UrlParams {
  id: ID
}

export interface ModeloPersonalizadoRelatorioGerencialModalProps {
  modulo: TipoModuloEnum
  keyMapping: Map<string, KeyMapping>
}

export function ModeloPersonalizadoRelatorioGerencialModal(props: ModeloPersonalizadoRelatorioGerencialModalProps) {
  const { modulo, keyMapping } = props
  const { url } = useRouteMatch()
  const history = useHistory()
  const alert = useAlert()
  const { id } = useParams<UrlParams>()
  const { analytics } = useFirebase()

  const isCadastro = isUndefinedOrNull(id)
  const tituloModal = (isCadastro ? 'Cadastrar' : 'Editar') + ' modelo de relatório'

  analytics.logEvent('abrir_modal_modelo_personalizado', { isCadastro: isCadastro })

  const formHasBeenTouched = useRef(false)

  const setFormHasBeenTouched = (state) => {
    if (state) {
      formHasBeenTouched.current = state
    }
  }

  const handleCloseModalAndSendMetrics = () => {
    handleCloseModal()
    analytics.logEvent('fechar_modal_modelo_personalizado', {
      isCadastro,
      formHasBeenTouched: formHasBeenTouched.current,
    })
  }

  const urlToReplace = MODELO_PERSONALIZADO_RELATORIO_GERENCIAL_MODAL_URL + (id ? '/' + id : '')

  const handleCloseModal = useCallback(() => {
    history.push(url.replace(urlToReplace, ''))
  }, [history, url, urlToReplace])

  const {
    data: { modeloPersonalizadoRelatorioGerencialById: modeloPersonalizado },
    loading,
  } = useModeloPersonalizadoRelatorioGerencialQuery({ variables: { id }, skip: isCadastro })

  useEffect(() => {
    const queryReturnedNull = !isCadastro && !loading && isUndefinedOrNull(modeloPersonalizado)

    if (queryReturnedNull) {
      alert('danger', 'Você não tem permissão para editar este modelo personalizado')
      handleCloseModal()
    } else if (isUndefinedOrNull(keyMapping)) {
      alert('danger', 'Não existe modelo personalizado para esse tipo de relatório')
      handleCloseModal()
    }
  }, [alert, handleCloseModal, id, isCadastro, keyMapping, loading, modeloPersonalizado])

  return (
    <Modal open={!loading} onClose={handleCloseModalAndSendMetrics}>
      <ModeloPersonalizadoRelatorioGerencialForm
        keyMapping={keyMapping}
        editValues={modeloPersonalizado}
        tipoModulo={modulo}
        tituloModal={tituloModal}
        isCadastro={isCadastro}
        handleCloseModal={handleCloseModal}
        handleCloseModalAndSendMetrics={handleCloseModalAndSendMetrics}
        setFormHasBeenTouched={setFormHasBeenTouched}
      />
    </Modal>
  )
}
