/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, HeadingSection, Theme, useStyles, VFlow } from 'bold-ui'
import { FormSectionAccordion } from 'components/accordion/FormSectionAccordion'
import { useFlags } from 'config/useFlagsContext'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { Fragment } from 'react'
import { FormSpy } from 'react-final-form'
import { isCboPodeRegistrarCid } from 'util/atendimento/cboHelper'
import { MetaPath } from 'util/metaPath'
import { SoapState } from 'view/atendimentos/atendimento-individual/model'
import { useEditableListField } from 'view/atendimentos/detail/components/EditableList'
import { AtendimentoProfissional } from 'view/atendimentos/types/AtendimentoProfissionalModel'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { ChecklistEncaminhamento } from './ChecklistEncaminhameto'
import EncaminhamentoExternoEspecializadoForm from './EncaminhamentoExternoEspecializadoForm'
import EncaminhamentoExternoTable from './list/EncaminhamentoExternoTable'
import { EncaminhamentoExternoEspecializadoPlanoModel } from './model'

export interface EncaminhamentoExternoViewProps {
  name: MetaPath<EncaminhamentoExternoEspecializadoPlanoModel>
  prontuarioId: ID
  atendimentoId: ID
  cidadao: CidadaoAtendimento
  cboAtendProf: string
  atendimentoProfissional: AtendimentoProfissional
  isAtendimentoOdonto: boolean
}

export default function EncaminhamentoExternoView(props: EncaminhamentoExternoViewProps) {
  const {
    name,
    prontuarioId,
    atendimentoId,
    cidadao,
    cboAtendProf,
    atendimentoProfissional,
    isAtendimentoOdonto,
  } = props

  const { PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED } = useFlags()

  const {
    observacao: { isAtendimentoObservacao },
  } = useAtendimentoContext()

  const { handleSubmit, removeItem } = useEditableListField({
    name: name.encaminhamentosEspecializadosRecentes,
  })

  const { theme } = useStyles()
  const classes = createStyles(theme)

  const renderCiapForm = !isCboPodeRegistrarCid(cboAtendProf)

  return (
    <Fragment>
      <FormSpy<SoapState>>
        {({ values }) => (
          <Fragment>
            <HeadingSection level={4} title='Encaminhamentos' />

            {PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED ? (
              <VFlow vSpacing={1.5}>
                <Alert
                  type='warning'
                  style={{ padding: '0.5rem', inlineSize: '-webkit-fill-available' }}
                  styles={{ container: { alignItems: 'center' } }}
                  inline
                >
                  <strong>Alteração nos encaminhamentos.</strong> <br />
                  Os encaminhamentos devem ser registrados na aba de compartilhamento de cuidado. O histórico dos
                  encaminhamentos anteriores ainda está disponível para acesso.
                </Alert>

                <EncaminhamentoExternoTable
                  prontuarioId={prontuarioId}
                  atendimentoId={atendimentoId}
                  encaminhamentosCache={values?.plano?.encaminhamentoExterno}
                  dataReferencia={atendimentoProfissional.iniciadoEm}
                  lotacao={atendimentoProfissional.lotacao}
                  isAtendimentoObservacao={isAtendimentoObservacao}
                  atendimentoProfissionalId={atendimentoProfissional.id}
                  removeItem={removeItem}
                />
              </VFlow>
            ) : (
              <Fragment>
                <ChecklistEncaminhamento name={name.encaminhamentosRecentes} />
                <FormSectionAccordion
                  name={name.openedAccordions}
                  title='Serviço especializado'
                  uuid='encaminhamentoServicoEspecializado'
                  style={classes.accordionEspecializados}
                  buttonStyle={classes.accordionEspecializadosButton}
                >
                  <div css={classes.wrapper}>
                    <EncaminhamentoExternoEspecializadoForm
                      prontuarioId={prontuarioId}
                      onSubmit={handleSubmit}
                      isAtendimentoOdonto={isAtendimentoOdonto}
                      cidadao={cidadao}
                      renderCiap={renderCiapForm}
                      encaminhamentosCache={values?.plano?.encaminhamentoExterno}
                    />
                  </div>
                </FormSectionAccordion>
                <EncaminhamentoExternoTable
                  prontuarioId={prontuarioId}
                  atendimentoId={atendimentoId}
                  encaminhamentosCache={values?.plano?.encaminhamentoExterno}
                  dataReferencia={atendimentoProfissional.iniciadoEm}
                  lotacao={atendimentoProfissional.lotacao}
                  isAtendimentoObservacao={isAtendimentoObservacao}
                  atendimentoProfissionalId={atendimentoProfissional.id}
                  removeItem={removeItem}
                />
              </Fragment>
            )}
          </Fragment>
        )}
      </FormSpy>
    </Fragment>
  )
}

const createStyles = (theme: Theme) => ({
  wrapper: css`
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
  `,
  accordionEspecializados: css`
    margin-top: 0.5rem;
  `,
  accordionEspecializadosButton: css`
    padding: 0.5rem 1rem;
  `,
})
