import { useAlertStore } from 'components/alert'
import useSession from 'components/auth/useSession'
import { AsyncProcessingNameEnum } from 'graphql/types.generated'
import { useCallback } from 'react'
import { useLocation } from 'react-router'

import useAtmosphere from '../useAtmosphere'
import { AsyncProcessingFinishMessage } from './AsyncProcessingFinishMessage'
import { asyncProcessingConfigurationRecord, asyncProcessingFinishMessageInfoRecord } from './model-asyncProcessing'

interface AsyncProcessingAtmosphereResponse {
  asyncProcessingName: AsyncProcessingNameEnum
  isFinished: boolean
  errorMessage?: string
}

const ALERT_TIMEOUT_MILLIS = 10000

export function AsyncProcessingTopicListener() {
  const { data } = useSession()
  const acessoId = data?.acesso?.id

  const { pathname } = useLocation()
  const { alert, remove } = useAlertStore()

  const showSuccessfullyFinishedAlert = useCallback(
    (asyncProcessingName: AsyncProcessingNameEnum) => {
      const { id } = alert(
        'success',
        AsyncProcessingFinishMessage({
          asyncProcessingName,
          onLinkClick: () => {
            remove(id)
          },
        }),
        ALERT_TIMEOUT_MILLIS
      )
    },
    [alert, remove]
  )

  const showUnsuccessfullyFinishedAlert = useCallback(
    (asyncProcessingName: AsyncProcessingNameEnum, errorMessage: string) => {
      const { id } = alert(
        'danger',
        AsyncProcessingFinishMessage({
          errorMessage,
          asyncProcessingName,
          onLinkClick: () => {
            remove(id)
          },
        }),
        ALERT_TIMEOUT_MILLIS
      )
    },
    [alert, remove]
  )

  const handleMessage = useCallback(
    ({ asyncProcessingName, isFinished, errorMessage }: AsyncProcessingAtmosphereResponse) => {
      if (isFinished) {
        const { finishProcessingEventName } = asyncProcessingConfigurationRecord[asyncProcessingName]
        const isAtModuloPage = pathname === asyncProcessingFinishMessageInfoRecord[asyncProcessingName].path

        if (isAtModuloPage) {
          document.dispatchEvent(new CustomEvent(finishProcessingEventName, { detail: { errorMessage } }))
        } else {
          if (errorMessage) {
            showUnsuccessfullyFinishedAlert(asyncProcessingName, errorMessage)
          } else {
            showSuccessfullyFinishedAlert(asyncProcessingName)
          }
        }
      }
    },
    [pathname, showSuccessfullyFinishedAlert, showUnsuccessfullyFinishedAlert]
  )

  useAtmosphere<AsyncProcessingAtmosphereResponse>({
    topic: `async/status/${acessoId}`,
    onMessage: handleMessage,
  })

  return null
}
