/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, ButtonGroup, Text, Theme, useTheme } from 'bold-ui'
import { Box } from 'components/Box'
import { useFlags } from 'config/useFlagsContext'
import { useState } from 'react'

import { LotacaoAgendaModel, TipoAgendamento } from '../model-agenda'
import { AgendamentoConsultaCompartilhadaForm } from './AgendamentoConsultaCompartilhadaForm'
import { AgendamentoConsultaForm } from './AgendamentoConsultaForm'
import { AgendamentoReservaForm } from './AgendamentoReservaForm'

export interface AgendamentoCadastroViewProps {
  lotacao: LotacaoAgendaModel
  isAtencaoDomiciliar?: boolean
  onSubmit?(): void
  onClose?(): void
  getServerTimeNow(): Date
  start: number
  end: number
  defaultTab?: TipoAgendamento.CONSULTA | TipoAgendamento.RESERVA | TipoAgendamento.CONSULTA_COMPARTILHADA
}

export const AgendamentoCadastroView = (props: AgendamentoCadastroViewProps) => {
  const {
    lotacao,
    onSubmit,
    onClose,
    getServerTimeNow,
    start,
    end,
    isAtencaoDomiciliar = false,
    defaultTab = TipoAgendamento.CONSULTA,
  } = props

  const theme = useTheme()
  const styles = createStyles(theme)
  const { PILOTO_VIDEOCHAMADA_2_ENABLED } = useFlags()

  const [activeTab, setActiveTab] = useState(defaultTab)
  const isActiveTabAsConsulta = activeTab === TipoAgendamento.CONSULTA
  const isActiveTabAsReserva = activeTab === TipoAgendamento.RESERVA
  const isActiveTabAsConsultaCompartilhada = activeTab === TipoAgendamento.CONSULTA_COMPARTILHADA

  return (
    <Box style={styles.box}>
      <Text component='p' variant='h3' style={styles.lotacao}>
        {lotacao.profissional.nome.titleCase()}{' '}
        <Text variant='h3' fontWeight='normal'>
          | {lotacao.cbo?.nome.capitalize()}
          {lotacao.equipe ? ` | ${lotacao.equipe.nome}` : ''}
        </Text>
      </Text>
      <ButtonGroup>
        <Button
          kind={isActiveTabAsConsulta ? 'primary' : 'normal'}
          size='small'
          onClick={() => setActiveTab(TipoAgendamento.CONSULTA)}
        >
          Consulta
        </Button>
        <Button
          kind={isActiveTabAsReserva ? 'primary' : 'normal'}
          size='small'
          onClick={() => setActiveTab(TipoAgendamento.RESERVA)}
        >
          Reserva
        </Button>
        {PILOTO_VIDEOCHAMADA_2_ENABLED && !isAtencaoDomiciliar && (
          <Button
            kind={isActiveTabAsConsultaCompartilhada ? 'primary' : 'normal'}
            size='small'
            onClick={() => setActiveTab(TipoAgendamento.CONSULTA_COMPARTILHADA)}
          >
            Entre profissionais
          </Button>
        )}
      </ButtonGroup>
      {isActiveTabAsReserva && (
        <AgendamentoReservaForm
          start={start}
          end={end}
          isAtencaoDomiciliar={isAtencaoDomiciliar}
          getServerTimeNow={getServerTimeNow}
          onClose={onClose}
          lotacao={lotacao}
          onSubmit={onSubmit}
        />
      )}
      {isActiveTabAsConsulta && (
        <AgendamentoConsultaForm
          start={start}
          isAtencaoDomiciliar={isAtencaoDomiciliar}
          onClose={onClose}
          getServerTimeNow={getServerTimeNow}
          lotacao={lotacao}
          onSubmit={onSubmit}
        />
      )}
      {PILOTO_VIDEOCHAMADA_2_ENABLED && !isAtencaoDomiciliar && isActiveTabAsConsultaCompartilhada && (
        <AgendamentoConsultaCompartilhadaForm
          start={start}
          getServerTimeNow={getServerTimeNow}
          onClose={onClose}
          lotacao={lotacao}
          onSubmit={onSubmit}
        />
      )}
    </Box>
  )
}

const createStyles = (theme: Theme) => ({
  box: css`
    border: 1px solid ${theme.pallete.gray.c80};
    border-radius: ${theme.radius.popper}px;
    box-sizing: border-box;
    box-shadow: ${theme.shadows.outer[40]};
    width: 47.75rem;
  `,
  lotacao: css`
    margin-bottom: 1rem;
  `,
})
