import { PageLoading } from 'components/loading'
import { css } from 'emotion'
import React from 'react'

export interface ContentCardSectionProps {
  children: React.ReactNode
  isLoading?: boolean
  messageLoading?: string
  canRender?: boolean
}

export function ContentCardSection({ canRender = true, ...props }: ContentCardSectionProps) {
  return (
    <>
      {props.isLoading && <PageLoading message={props.messageLoading || 'Carregando dados'} />}
      {!props.isLoading && (
        <div
          className={css`
            padding: 0.5rem;
            display: ${canRender ? 'block' : 'none'};
          `}
        >
          {props.children}
        </div>
      )}
    </>
  )
}

export const getAsideCardStyles = () => ({
  ellipsesText: css`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
  `,
})
