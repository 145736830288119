/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { focusBoxShadow, HFlow, Icon, Text, Theme, Tooltip, useTheme } from 'bold-ui'
import { turquoise } from 'bold-ui/lib/styles/colors'
import { Ellipsis } from 'components/Ellipsis'
import { format } from 'date-fns'
import { ReactComponent as AgendamentoCompartilhadoIcon } from 'images/agenda/calendar-add-filled.svg'
import { useMemo, useState } from 'react'

import { AcoesAgendamentoReserva } from '../acoes-agendamento/AcoesAgendamentoReserva'
import { CompartilharVideochamadaDetalhesAgendamentoCompartilhadoModal } from './CompartilharVideochamadaDetalhesAgendamentoCompartilhadoModal'
import { EventoAgendamentoProps } from './EventoAgendamento'
import { EventoAgendamentoPopperButton } from './EventoAgendamentoPopperButton'

const formatoHorario = 'HH:mm'

export function EventoAgendamentoReserva({
  event,
  eventHasOverlap,
  isAgendaOnlineAtiva,
  lotacaoAgendadaId,
}: EventoAgendamentoProps) {
  const theme = useTheme()
  const styles = useMemo(() => createStyles(theme, eventHasOverlap), [eventHasOverlap, theme])
  const motivoReserva = event.motivoReserva
  const detalheReserva = event.nomeProfissionalParticipante ? ' | Agendamento entre profissionais' : ' | Outros'

  const [isEnviarEmailModalOpen, setIsEnviarEmailOpen] = useState<boolean>(false)

  const emailDestinatario = useMemo(
    () =>
      event.lotacaoProfissionalAnfitriaoId === lotacaoAgendadaId
        ? event.emailProfissionalParticipante
        : event.emailProfissionalAnfitriao,
    [
      event.emailProfissionalAnfitriao,
      event.emailProfissionalParticipante,
      event.lotacaoProfissionalAnfitriaoId,
      lotacaoAgendadaId,
    ]
  )

  return (
    <div css={styles.container}>
      <CompartilharVideochamadaDetalhesAgendamentoCompartilhadoModal
        lotacaoAgendadaId={lotacaoAgendadaId}
        videochamadaUuid={event.videochamadaUuid}
        emailDestinatario={emailDestinatario}
        open={isEnviarEmailModalOpen}
        onClose={() => setIsEnviarEmailOpen(false)}
      />
      <EventoAgendamentoPopperButton
        isAgendaOnlineAtiva={isAgendaOnlineAtiva}
        event={event}
        closeOnBackdropClick={!isEnviarEmailModalOpen}
        style={styles.popperButton}
        onEnviarEmailButtonClick={() => setIsEnviarEmailOpen(true)}
      >
        {eventHasOverlap ? (
          <Ellipsis title={motivoReserva}>
            <Text fontWeight='bold' style={styles.motivo}>
              {motivoReserva}
            </Text>
          </Ellipsis>
        ) : (
          <HFlow style={styles.eventoSemOverlapWrapper}>
            {event.nomeProfissionalParticipante ? (
              <Tooltip text='Agendamento entre profissionais'>
                <AgendamentoCompartilhadoIcon css={styles.iconeAgendCompartilhado} />
              </Tooltip>
            ) : (
              <Icon style={styles.iconeBookmark} size={1} icon='bookmarkFilled' />
            )}
            <div css={styles.internalContainer}>
              <Ellipsis title={motivoReserva + ' | Outros'} style={styles.motivo}>
                <Text fontWeight='bold'>{motivoReserva}</Text>
                {(event.nomeProfissionalParticipante || event.isOutroMotivoReserva) && (
                  <Text style={styles.outros}>{detalheReserva}</Text>
                )}
              </Ellipsis>

              <Ellipsis style={styles.observacoes}>
                <Text fontWeight='bold' style={styles.horarioObservacoesText}>
                  {format(event.start, formatoHorario)} até {format(event.end, formatoHorario)}
                  {event.observacoes && ' | Observações:'}
                </Text>
                <Ellipsis>&nbsp;{event.observacoes}</Ellipsis>
              </Ellipsis>
            </div>
          </HFlow>
        )}
      </EventoAgendamentoPopperButton>
      <AcoesAgendamentoReserva event={event} />
    </div>
  )
}

const createStyles = (theme: Theme, eventHasOverlap: boolean) => ({
  container: css`
    height: calc(100% - 0.25rem);
    display: flex;
    width: 100%;
    background-color: ${turquoise.c90};
    border: 1px solid ${turquoise.c60};
    border-radius: 2px;
    margin: 0;
    min-height: 3rem;
    box-shadow: ${theme.shadows.outer[10]};
    &:focus-within {
      outline: none;
      box-shadow: ${focusBoxShadow(theme)};
    }
  `,

  internalContainer: css`
    padding: 0.25rem 0;
    align-items: flex-start;
    justify-content: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;

    span {
      white-space: nowrap;
    }
  `,

  motivo: css`
    display: flex;
    color: ${turquoise.c40};
  `,
  observacoes: css`
    display: flex;
    align-items: center;
  `,

  outros: css`
    margin-left: 0.25rem;
    color: ${turquoise.c40};
    white-space: nowrap;
  `,

  iconeBookmark: css`
    margin-top: 0.25rem;
    height: 1.5rem;
    margin-right: -0.4rem;
    color: ${turquoise.c40};
  `,
  iconeAgendCompartilhado: css`
    margin-top: 0.5rem;
    height: 1rem;
    width: 1rem;
    margin-right: -0.4rem;
    fill: ${turquoise.c40};
  `,
  popperButton: eventHasOverlap
    ? css`
        padding-left: 1.5rem;
        overflow: hidden;
        padding-right: 0.75rem;
      `
    : css`
        padding: 0.25rem 0 0.25rem 1rem;
        width: 95%;
      `,
  horarioObservacoesText: css`
    display: flex;
    align-items: flex-start;
  `,
  eventoSemOverlapWrapper: css`
    width: 100%;
    grid-template-columns: repeat(3, auto);
    justify-content: flex-start;
  `,
})
