/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, TableFooter, Text, VFlow } from 'bold-ui'
import AccordionList from 'components/accordion/AccordionList'
import { useAccordionAutoOpenUniqueItem } from 'components/accordion/useAccordionAutoOpenUniqueItem'
import { useAccordionControl } from 'components/accordion/useAccordionControl'
import { NormalizedHighlighter } from 'components/highlight/NormalizedHighlighter'
import { usePagination } from 'components/table/usePagination'
import theme from 'config/theme'
import { maxBy } from 'lodash'
import { formatDate } from 'util/date/formatDate'
import {
  ExameQueryModel,
  ResultadoExameQueryModel,
} from 'view/atendimentos/detail/soap/objetivo/resultados-exames/model'
import { showCodigoProcedimentoRef } from 'view/atendimentos/detail/soap/objetivo/resultados-exames/util-resultadosExames'

import { HistoricoResultadosExamesListItemBody } from './HistoricoResultadosExamesListItemBody'

export interface HistoricoResultadosExamesItemModel {
  id: ID
  exame: ExameQueryModel
  items: ResultadoExameQueryModel[]
}

interface HistoricoResultadosExamesListProps {
  items: HistoricoResultadosExamesItemModel[]
  loading: boolean
  searchWords: string
}

export const HistoricoResultadosExamesList = (props: HistoricoResultadosExamesListProps) => {
  const { items, loading, searchWords } = props

  const { resetExpandedItems, handleAccordionItemClick, isExpanded } = useAccordionControl({
    allowMultipleExpanded: true,
  })

  const { paginatedItems, tableProps } = usePagination({
    items,
    onChange: resetExpandedItems,
  })

  useAccordionAutoOpenUniqueItem({
    items: paginatedItems,
    extractUuid: (item) => item.id,
    handleAccordionItemClick,
    isExpanded,
  })

  return (
    <VFlow vSpacing={0.5}>
      <AccordionList<HistoricoResultadosExamesItemModel>
        data={{ content: paginatedItems, pageInfo: {} }}
        paginate={false}
        accordionItemHeader={(item) => accordionItemHeader(item, searchWords)}
        accordionItemBody={({ id, items }) => (
          <HistoricoResultadosExamesListItemBody
            items={items}
            isExpanded={isExpanded(id)}
            collapseItem={() => handleAccordionItemClick(id)}
          />
        )}
        loading={loading}
        controls={{ handleAccordionItemClick, isExpanded }}
      />
      {!loading && <TableFooter {...tableProps} />}
    </VFlow>
  )
}

const accordionItemHeader = ({ items }: HistoricoResultadosExamesItemModel, searchWords: string) => {
  const ultimoResultado = items[0]
  const ultimaRealizacao = ultimoResultado.dataRealizacao
  const ultimaAvaliacao = maxBy(items, (item) => item.atendimentoProfissional.iniciadoEm).atendimentoProfissional
    .iniciadoEm

  return (
    <HFlow
      justifyContent='space-between'
      style={css`
        width: 100%;
      `}
    >
      <HFlow alignItems='center'>
        <div css={classes.circle}>
          <Text fontWeight='bold'>{items.length}</Text>
        </div>

        <div>
          <Text fontWeight='bold'>
            <NormalizedHighlighter
              searchWords={searchWords}
              textToHighlight={showCodigoProcedimentoRef(ultimoResultado.exame)}
            />
          </Text>
        </div>
      </HFlow>

      <VFlow vSpacing={0}>
        <HFlow justifyContent='flex-end' hSpacing={0.25}>
          <Text fontWeight='bold'>Realizado em</Text>
          <Text style={classes.dateValueContainer}>{ultimaRealizacao ? formatDate(ultimaRealizacao) : '-'}</Text>
        </HFlow>
        <HFlow justifyContent='flex-end' hSpacing={0.25}>
          <Text fontWeight='bold'>Última avaliação em</Text>
          <Text style={classes.dateValueContainer}>{ultimaAvaliacao ? formatDate(ultimaAvaliacao) : '-'}</Text>
        </HFlow>
      </VFlow>
    </HFlow>
  )
}

const classes = {
  circle: css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.5rem;
    border-radius: 1.5rem;
    background: ${theme.pallete.gray.c80};
    padding: 0 0.5rem;
  `,
  dateValueContainer: css`
    width: 4.65rem;
  `,
}
