import { differenceInHours, format, toDate } from 'date-fns'

export const agendamentoRegistradoEarlierThanOneHour = (dataCriacao: Instant, serverTime: Date) =>
  differenceInHours(serverTime, toDate(dataCriacao)) < 1

export const createJustificativaProfissional = (nomeProfissional: string, dataCancelamento: Date): string =>
  `Cancelado por ${nomeProfissional} em ${format(dataCancelamento, 'dd/MM/yyyy')} às ${format(
    dataCancelamento,
    'HH:mm'
  )}`

export const excluirAgendamentoTooltip = (
  isPassado?: boolean,
  isOutroProfissionalDeOutraEquipe?: boolean,
  isAtencaoDomiciliar?: boolean
): string => {
  return isPassado
    ? 'Não é possível cancelar agendamentos passados'
    : isOutroProfissionalDeOutraEquipe
    ? 'Não é possível cancelar agendamentos de profissionais de outras equipes'
    : isAtencaoDomiciliar
    ? 'Não é possível cancelar agendamentos de atenção domiciliar'
    : 'Cancelar'
}
