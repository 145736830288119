import { Button, HFlow } from 'bold-ui'
import { SubmitButton } from 'components/form'
import React, { Fragment } from 'react'
import { EditableListFormRenderProps } from 'view/atendimentos/detail/components/EditableListForm'

import { ProblemaCondicaoModel } from '../model-problemasCondicoes'

interface ProblemasCondicoesFormFooterButtonsProps {
  edicao: boolean
  onSubmit: EditableListFormRenderProps<ProblemaCondicaoModel>['handleSubmit']
  onCancel(): void
}

export const ProblemasCondicoesFormFooterButtons = (props: ProblemasCondicoesFormFooterButtonsProps) => {
  const { edicao, onCancel, onSubmit } = props

  return (
    <HFlow justifyContent='flex-end'>
      {edicao ? (
        <Fragment>
          <Button size='small' onClick={onCancel}>
            Cancelar
          </Button>
          <SubmitButton size='small' handleSubmit={onSubmit}>
            Salvar
          </SubmitButton>
        </Fragment>
      ) : (
        <SubmitButton size='small' handleSubmit={onSubmit}>
          Adicionar
        </SubmitButton>
      )}
    </HFlow>
  )
}
