import {
  HistoricoCuidadoCompartilhadoEvolucaoDetalhesQuery,
  HistoricoCuidadoCompartilhadoEvolucaoHeaderFragment,
  HistoricoCuidadoCompartilhadoHeaderFragment,
} from 'graphql/types.generated'
import React, { useMemo } from 'react'
import { isUndefinedOrNull } from 'util/checks'
import {
  HistoricoDetalhesModel,
  IdadeOrigemAtendimento,
} from 'view/atendimentos/detail/historico/model/model-historico'

import { HistoricoCuidadoCompartilhadoDwPanel } from './HistoricoCuidadoCompartilhadoDwPanel'
import { HistoricoCuidadoCompartilhadoPecPanel } from './HistoricoCuidadoCompartilhadoPecPanel'

interface HistoricoCuidadoCompartilhadoPanelProps {
  cuidadoCompartilhado: HistoricoCuidadoCompartilhadoHeaderFragment
  cuidadoCompartilhadoEvolucao: HistoricoCuidadoCompartilhadoEvolucaoHeaderFragment
  historicoDetalhes: HistoricoDetalhesModel
  idadeOrigemAtendimento: IdadeOrigemAtendimento
}

export type CuidadoCompartilhadoEvolucaoDetalhes = HistoricoCuidadoCompartilhadoEvolucaoDetalhesQuery['cuidadoCompartilhadoEvolucao']

export const HistoricoCuidadoCompartilhadoPanel = (props: HistoricoCuidadoCompartilhadoPanelProps) => {
  const { cuidadoCompartilhado, cuidadoCompartilhadoEvolucao, historicoDetalhes, idadeOrigemAtendimento } = props

  const selectContent = useMemo(() => {
    return isUndefinedOrNull(historicoDetalhes.codigoUnicoRegistro) ? (
      <HistoricoCuidadoCompartilhadoPecPanel
        cuidadoCompartilhadoHeader={cuidadoCompartilhado}
        cuidadoCompartilhadoEvolucaoHeader={cuidadoCompartilhadoEvolucao}
        idadeOrigemAtendimento={idadeOrigemAtendimento}
      />
    ) : (
      <HistoricoCuidadoCompartilhadoDwPanel
        historicoDetalhes={historicoDetalhes}
        idadeOrigemAtendimento={idadeOrigemAtendimento}
      />
    )
  }, [cuidadoCompartilhado, cuidadoCompartilhadoEvolucao, historicoDetalhes, idadeOrigemAtendimento])

  return selectContent
}
