import { Calculation } from 'final-form-calculate'
import { UnidadePeriodoRelatorioGerencialEnum } from 'graphql/types.generated'
import { isNull } from 'lodash'
import { MetaRoot } from 'util/metaPath'

import { calculatePeriodoRelatorioGerencial } from '../../form/calculator-relatorioGerencial'
import { ModeloPersonalizadoRelatorioGerencialFormModel } from './model-modeloPersonalizadoRelatorioGerencialForm'

export const createModeloPersonalizadoRelatoriosGerenciaisPeriodoFormCalculator = (
  name: MetaRoot<ModeloPersonalizadoRelatorioGerencialFormModel>
): Calculation[] => [
  {
    field: [name.opcaoSelecionadaModal.absolutePath(), name.unidadePeriodoModal.absolutePath()],
    updates: {
      [name.periodo.absolutePath()]: (
        _,
        { opcaoSelecionadaModal, unidadePeriodoModal, periodo }: ModeloPersonalizadoRelatorioGerencialFormModel
      ) => calculatePeriodoRelatorioGerencial(unidadePeriodoModal, opcaoSelecionadaModal, periodo),
    },
  },
  {
    field: [name.unidadePeriodoModal.absolutePath()],
    updates: {
      [name.unidadePeriodoModal.absolutePath()]: (
        unidade: UnidadePeriodoRelatorioGerencialEnum,
        _,
        { unidadePeriodoModal: prevUnidade }: ModeloPersonalizadoRelatorioGerencialFormModel
      ) => {
        return isNull(unidade) ? prevUnidade : unidade
      },
    },
  },
]
