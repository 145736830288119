import { Alert, DateRange, VFlow } from 'bold-ui'
import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import { PageHeaderSection } from 'components/PageHeaderSection'
import { PivotTable, PivotTableInitialValues } from 'components/pivot-table/PivotTable'
import { useTreeBuilder } from 'components/pivot-table/tree-builder/useTreeBuilder'
import { AsyncProcessingNameEnum, TipoModuloEnum } from 'graphql/types.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import React, { useCallback, useEffect, useState } from 'react'
import { isUndefinedOrNull } from 'util/checks'

import {
  ASYNC_LOADING_MESSAGE,
  RELATORIO_GERENCIAL_ATENDIMENTO_FILTER_STORAGE_KEY,
  RELATORIO_GERENCIAL_ATENDIMENTOS_NAME,
  RELATORIOS_GERENCIAIS_ANALYTICS_SELECIONOU_RELATORIO_EVENTO,
} from '../common/model-relatorioGerencial'
import { useAsyncRelatorioGerencial } from '../common/useAsyncRelatorioGerencial'
import { convertCiapCidFilter } from '../components/form/converter-relatorioGerencial'
import { RelatorioGerencialFiltrosFormModel } from '../components/form/model-relatorioGerencialFiltrosForm'
import { RelatorioGerencialTableWrapper } from '../components/RelatorioGerencialTableWrapper'
import { atendimentoProfissionalKeyMapping } from './keyMapping-relatorioGerencialAtendimento'
import { RelatorioGerencialAtendimentoProfissionalModel } from './model-relatorioGerencialAtendimentoProfissional'

const OPERATIONS = {
  build: '/gerenciais/atendimento-profissional/build',
  meta: '/gerenciais/atendimento-profissional/meta',
}

export function RelatorioGerencialAtendimentosProfissionalView() {
  const [dateRangeFilter, setDateRangeFilter] = useState<DateRange>()
  const [pivotTableInitialValues, setPivotTableInitialValues] = useState<
    PivotTableInitialValues<RelatorioGerencialAtendimentoProfissionalModel>
  >()

  const { analytics } = useFirebase()

  const {
    metaState,
    meta,
    setDateFilter,
    setCiapCidFilter,
    setIsSearchingMeta,
    treeBuilderStatus: { isBuilding, isSearchingMeta },
    isMetaEmpty,
    ...rest
  } = useTreeBuilder<RelatorioGerencialAtendimentoProfissionalModel>({
    keyMapping: atendimentoProfissionalKeyMapping,
    operations: OPERATIONS,
  })

  const onGetMeta = useCallback(
    ({ periodo, unidade, ciapsCids, gruposCondicoesPrioritarios }: RelatorioGerencialFiltrosFormModel) => {
      setDateFilter(periodo, unidade)
      setCiapCidFilter(convertCiapCidFilter(ciapsCids, gruposCondicoesPrioritarios))
      setDateRangeFilter(periodo)
    },
    [setCiapCidFilter, setDateFilter]
  )

  const { handleSubmit, filtros, isAsyncProcessingRunning, clearFiltros } = useAsyncRelatorioGerencial({
    asyncProcessingName: AsyncProcessingNameEnum.RELATORIO_GERENCIAL_ATENDIMENTO,
    filterStorageKey: RELATORIO_GERENCIAL_ATENDIMENTO_FILTER_STORAGE_KEY,
    isSearchingMeta,
    hasMetaState: !isUndefinedOrNull(metaState),
    meta,
    onGetMeta,
    setIsSearchingMeta,
  })

  useEffect(() => {
    analytics.logEvent(RELATORIOS_GERENCIAIS_ANALYTICS_SELECIONOU_RELATORIO_EVENTO, {
      relatorio: RELATORIO_GERENCIAL_ATENDIMENTOS_NAME,
    })
  }, [analytics])

  if (filtros && !isSearchingMeta && isUndefinedOrNull(metaState)) {
    clearFiltros()
  }

  return (
    <>
      <Breadcrumb title={RELATORIO_GERENCIAL_ATENDIMENTOS_NAME} />
      <PageHeaderSection title={RELATORIO_GERENCIAL_ATENDIMENTOS_NAME} />

      <PageContent type='filled'>
        <VFlow>
          <Alert inline type='info'>
            O relatório gerencial de atendimentos apresenta a contagem dos atendimentos individuais e odontológicos
            realizados. A origem dos atendimentos deste relatório são o CDS e o PEC. Os atendimentos do tipo "Escuta
            inicial / Orientação" não serão apresentados.
          </Alert>
          <RelatorioGerencialTableWrapper<RelatorioGerencialAtendimentoProfissionalModel>
            loading={isSearchingMeta || isAsyncProcessingRunning}
            loadingMessage={ASYNC_LOADING_MESSAGE}
            isEmpty={isMetaEmpty}
            onSubmit={handleSubmit}
            filtrosLocalStorage={filtros}
            setPivotTableInitialValues={setPivotTableInitialValues}
            hasFiltroCiapCidGrupoCondicoes
            modulo={TipoModuloEnum.RELATORIO_GERENCIAL_ATENDIMENTO}
            keyMapping={atendimentoProfissionalKeyMapping}
          >
            {metaState && (
              <PivotTable<RelatorioGerencialAtendimentoProfissionalModel>
                title={RELATORIO_GERENCIAL_ATENDIMENTOS_NAME}
                treeMeta={metaState}
                keyMapping={atendimentoProfissionalKeyMapping}
                dateRangeFilter={dateRangeFilter}
                isBuilding={isBuilding}
                initialValues={pivotTableInitialValues}
                {...rest}
              />
            )}
          </RelatorioGerencialTableWrapper>
        </VFlow>
      </PageContent>
    </>
  )
}
