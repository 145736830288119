import { VFlow } from 'bold-ui'
import { CondutaCuidadoCompartilhadoEnum } from 'graphql/types.generated'
import React from 'react'
import { isUndefinedOrNull } from 'util/checks'
import { LabelEvolucaoCuidadoCompartilhadoRecord } from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'

import HistoricoDetailTable from '../../../table/HistoricoDetailTable'
import { HistoricoCuidadoCompartilhadoAgendamento } from './conduta/HistoricoCuidadoCompartilhadoAgendamento'
import { HistoricoCuidadoCompartilhadoCondutaPadrao } from './conduta/HistoricoCuidadoCompartilhadoCondutaPadrao'
import { HistoricoCuidadoCompartilhadoGarantiaAcesso } from './conduta/HistoricoCuidadoCompartilhadoGarantiaAcesso'
import { HistoricoCuidadoCompartilhadoTrocaProfissionalReferencia } from './conduta/HistoricoCuidadoCompartilhadoTrocaProfissionalReferencia'
import { CuidadoCompartilhadoEvolucaoDetalhes } from './HistoricoCuidadoCompartilhadoPanel'

interface HistoricoCuidadoCompartilhadoCondutaProps {
  cuidadoCompartilhadoEvolucao: CuidadoCompartilhadoEvolucaoDetalhes
}

const DEVOLUTIVA_TITLE = 'Devolutiva de discussão de caso clínico'
const AGENDAMENTO_TITLE = 'Agendar consulta'

export function HistoricoCuidadoCompartilhadoConduta(props: HistoricoCuidadoCompartilhadoCondutaProps) {
  const { cuidadoCompartilhadoEvolucao } = props
  const { conduta } = cuidadoCompartilhadoEvolucao

  const isEvolucaoComCondutaTrocaProfissional =
    conduta === CondutaCuidadoCompartilhadoEnum.TROCA_PROFISSIONAL_REFERENCIA

  const isDevolutiva = isDevolutivaDiscussao(conduta)
  const isAgendamento = isCondutaAgendamento(conduta)
  const isCcFromDw = isUndefinedOrNull(cuidadoCompartilhadoEvolucao.id)
  const isGarantiaAcesso = conduta === CondutaCuidadoCompartilhadoEnum.ENVIAR_GARANTIA_ACESSO

  const title = isDevolutiva
    ? DEVOLUTIVA_TITLE
    : isAgendamento
    ? AGENDAMENTO_TITLE
    : LabelEvolucaoCuidadoCompartilhadoRecord[conduta]

  return (
    <HistoricoDetailTable title={title} hasLines>
      {!isCcFromDw &&
        (isEvolucaoComCondutaTrocaProfissional ? (
          <VFlow>
            <HistoricoCuidadoCompartilhadoTrocaProfissionalReferencia
              cuidadoCompartilhadoEvolucaoId={cuidadoCompartilhadoEvolucao.id}
              descricao={cuidadoCompartilhadoEvolucao.descricao}
            />
          </VFlow>
        ) : isAgendamento ? (
          <VFlow>
            <HistoricoCuidadoCompartilhadoAgendamento
              conduta={cuidadoCompartilhadoEvolucao.conduta}
              agenda={cuidadoCompartilhadoEvolucao.agenda}
            />
          </VFlow>
        ) : isGarantiaAcesso ? (
          <VFlow>
            <HistoricoCuidadoCompartilhadoGarantiaAcesso
              enviarGarantiaAcesso={cuidadoCompartilhadoEvolucao.enviarGarantiaAcesso}
              descricao={cuidadoCompartilhadoEvolucao.descricao}
            />
          </VFlow>
        ) : (
          <VFlow>
            <HistoricoCuidadoCompartilhadoCondutaPadrao cuidadoCompartilhadoEvolucao={cuidadoCompartilhadoEvolucao} />
          </VFlow>
        ))}
    </HistoricoDetailTable>
  )
}

function isCondutaAgendamento(conduta: CondutaCuidadoCompartilhadoEnum): boolean {
  return (
    conduta === CondutaCuidadoCompartilhadoEnum.AGENDADO_CONSULTA_CIDADAO_PRESENCIAL ||
    conduta === CondutaCuidadoCompartilhadoEnum.AGENDADO_INTERCONSULTA_COM_CIDADAO ||
    conduta === CondutaCuidadoCompartilhadoEnum.AGENDADO_INTERCONSULTA_SEM_CIDADAO ||
    conduta === CondutaCuidadoCompartilhadoEnum.AGENDADO_TELEINTERCONSULTA_COM_CIDADAO ||
    conduta === CondutaCuidadoCompartilhadoEnum.AGENDADO_TELEINTERCONSULTA_SEM_CIDADAO
  )
}

function isDevolutivaDiscussao(conduta: CondutaCuidadoCompartilhadoEnum): boolean {
  return (
    // executante
    conduta === CondutaCuidadoCompartilhadoEnum.PEDIDO_ESCLARECIMENTO ||
    conduta === CondutaCuidadoCompartilhadoEnum.TELECONSULTORIA ||
    conduta === CondutaCuidadoCompartilhadoEnum.RECONDUCAO_CUIDADO ||
    conduta === CondutaCuidadoCompartilhadoEnum.PARECER_TECNICO_CONCLUSIVO ||
    // solicitante
    conduta === CondutaCuidadoCompartilhadoEnum.NOVA_PERGUNTA
  )
}
