/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import {
  Button,
  Cell,
  FormControl,
  Grid,
  Heading,
  HFlow,
  ModalBody,
  ModalFooter,
  Theme,
  useTheme,
  VFlow,
} from 'bold-ui'
import useSession from 'components/auth/useSession'
import { useErrorHandler } from 'components/error'
import { ErrorField, RadioField, TextField } from 'components/form'
import { CiapCidSelectField } from 'components/form/field/select/CiapCidSelectField/CiapCidSelectField'
import { GrupoCondicaoSelectField } from 'components/form/field/select/GrupoCondicaoSelectField'
import { confirm } from 'components/modals/confirm'
import { KEY_NOT_DEPENDENT_AGGREGATORS } from 'components/pivot-table/components/aggregator/util-aggregator'
import { FORM_ERROR } from 'final-form'
import createDecorator from 'final-form-calculate'
import {
  ModeloPersonalizadoRelatorioGerencialDocument,
  ModelosByTipoDocument,
  useEditarModeloPersonalizadoRelatorioGerencialMutation,
  useExcluirModeloPersonalizadoRelatorioGerencialMutation,
  useSalvarModeloPersonalizadoRelatorioGerencialMutation,
} from 'graphql/hooks.generated'
import {
  AggregatorRelatorioGerencialEnum,
  ModeloPersonalizadoRelatorioGerencial,
  OpcaoSelecionadaRelatorioGerencialEnum,
  TipoModuloEnum,
  UnidadePeriodoRelatorioGerencialEnum,
} from 'graphql/types.generated'
import { Fragment, useMemo } from 'react'
import { Form, FormRenderProps } from 'react-final-form'
import { metaPath } from 'util/metaPath'
import { isObjectDeepEmpty } from 'util/object'

import { KeyMapping } from '../../../common/keyMapping-relatorioGerencial'
import { RelatorioGerencialPeriodoLabel } from '../../form/RelatorioGerencialPeriodoLabel'
import { RelatorioGerencialPeriodoRadioField } from '../../form/RelatorioGerencialPeriodoRadioField'
import { RelatorioGerencialUnidadePeriodoSelectField } from '../../form/RelatorioGerencialUnidadePeriodoSelectField'
import { relatorioGerencialFormModelToJsonModel } from '../converter-modeloPersonalizadoRelatorioGerencial'
import { isCampoInconsistente } from '../utils-modeloPersonalizado'
import { createModeloPersonalizadoRelatoriosGerenciaisPeriodoFormCalculator } from './calculator-modeloPersonalizadoRelatorioGerencial'
import { ModeloPersonalizadoRelatorioGerencialFormModel } from './model-modeloPersonalizadoRelatorioGerencialForm'
import { ModeloPersonalizadoRelatorioGerencialCamposForm } from './ModeloPersonalizadoRelatorioGerencialCamposForm'
import { modeloPersonalizadoRelatorioGerencialFormValidator } from './validator-modeloPersonalizadoRelatorioGerencial'

const meta = metaPath<ModeloPersonalizadoRelatorioGerencialFormModel>()

interface ModeloPersonalizadoRelatorioGerencialFormProps {
  keyMapping: Map<string, KeyMapping>
  editValues: ModeloPersonalizadoRelatorioGerencial
  tipoModulo: TipoModuloEnum
  tituloModal: string
  isCadastro: boolean
  handleCloseModal: () => void
  handleCloseModalAndSendMetrics: () => void
  setFormHasBeenTouched: (state: boolean) => void
}

export const ModeloPersonalizadoRelatorioGerencialForm = (props: ModeloPersonalizadoRelatorioGerencialFormProps) => {
  const {
    keyMapping,
    editValues,
    tipoModulo,
    tituloModal,
    isCadastro,
    handleCloseModal,
    handleCloseModalAndSendMetrics,
    setFormHasBeenTouched,
  } = props

  const theme = useTheme()
  const styles = createStyles(theme)
  const { hasAuthorization, isGestorEstadual } = useSession()
  const handleException = useErrorHandler()

  const initialValues = useMemo<ModeloPersonalizadoRelatorioGerencialFormModel>(
    () => ({
      id: editValues?.id,
      nome: editValues?.nome,
      modulo: editValues?.tipoModulo ?? tipoModulo,
      opcaoSelecionadaModal: editValues?.modelo.opcaoSelecionada ?? OpcaoSelecionadaRelatorioGerencialEnum.PRIMEIRA,
      unidadePeriodoModal: editValues?.modelo.unidadePeriodo ?? UnidadePeriodoRelatorioGerencialEnum.MES,
      gruposCondicoes: editValues?.modelo.gruposCondicoes,
      ciapsCids: [...(editValues?.modelo.ciaps ?? []), ...(editValues?.modelo.cids ?? [])],
      campos:
        editValues?.modelo.campos.map((campo, index) => ({
          _id: index,
          key: campo.key,
          posicao: campo.posicao,
          items: campo.items,
          isInconsistente: isCampoInconsistente(campo.key, keyMapping, tipoModulo, isGestorEstadual, hasAuthorization),
        })) ?? [],
      aggregatorModal: editValues?.modelo.aggregator ?? AggregatorRelatorioGerencialEnum.CONTAGEM,
      isPublico: editValues?.isPublico ?? false,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tipoModulo]
  )

  const hasCampoInconsistente = useMemo(() => initialValues.campos.some((campo) => campo.isInconsistente), [
    initialValues,
  ])

  const [salvarModeloPersonalizadoRelatorioGerencial] = useSalvarModeloPersonalizadoRelatorioGerencialMutation()
  const [editarModeloPersonalizadoRelatorioGerencial] = useEditarModeloPersonalizadoRelatorioGerencialMutation()
  const [excluirModeloPersonalizadoRelatorioGerencial] = useExcluirModeloPersonalizadoRelatorioGerencialMutation()

  const onSubmit = (values: ModeloPersonalizadoRelatorioGerencialFormModel) => {
    if (isCadastro) {
      salvarModeloPersonalizadoRelatorioGerencial({
        variables: {
          input: {
            isPublico: values.isPublico,
            modeloPersonalizado: relatorioGerencialFormModelToJsonModel(values),
            nome: values.nome,
            tipoModulo: values.modulo,
          },
        },
        refetchQueries: [{ query: ModelosByTipoDocument, variables: { tipoModulo } }],
      })
        .then(() => {
          handleCloseModal()
        })
        .catch(handleException)
    } else {
      editarModeloPersonalizadoRelatorioGerencial({
        variables: {
          input: {
            id: values.id,
            modeloPersonalizado: relatorioGerencialFormModelToJsonModel(values),
            nome: values.nome,
            tipoModulo: values.modulo,
          },
        },
        refetchQueries: [
          { query: ModelosByTipoDocument, variables: { tipoModulo } },
          { query: ModeloPersonalizadoRelatorioGerencialDocument, variables: { id: values.id } },
        ],
      })
        .then(() => {
          handleCloseModal()
        })
        .catch(handleException)
    }
  }

  const handleExcluir = () => {
    return confirm({
      title: `Deseja excluir o modelo de relatório?`,
      type: 'danger',
      confirmLabel: 'Excluir',
      onConfirm: async () => {
        excluirModeloPersonalizadoRelatorioGerencial({
          variables: {
            input: {
              id: editValues?.id,
              tipoModulo,
            },
          },
          refetchQueries: [{ query: ModelosByTipoDocument, variables: { tipoModulo } }],
        })
        handleCloseModal()
      },
    })()
  }

  const decorators = useMemo(
    () => [createDecorator(...createModeloPersonalizadoRelatoriosGerenciaisPeriodoFormCalculator(meta))],
    []
  )

  const hasFiltroCiapCid = tipoModulo === TipoModuloEnum.RELATORIO_GERENCIAL_ATENDIMENTO

  const renderForm = ({
    values,
    handleSubmit,
    errors,
    submitFailed,
    touched,
  }: FormRenderProps<ModeloPersonalizadoRelatorioGerencialFormModel>) => {
    setFormHasBeenTouched(!isObjectDeepEmpty(touched))
    return (
      <Fragment>
        <ModalBody>
          <VFlow>
            <Heading level={1}>{tituloModal}</Heading>
            <Grid wrap>
              <Cell size={6}>
                <TextField name={meta.nome} label='Nome do modelo' required size={6} />
              </Cell>
              <Cell size={12}>
                <VFlow>
                  <RelatorioGerencialPeriodoLabel />
                  <ErrorField name={FORM_ERROR} />
                  <VFlow css={styles.periodoContainer}>
                    <Grid wrap alignItems='center'>
                      <Cell size={6}>
                        <RelatorioGerencialUnidadePeriodoSelectField
                          name={meta.unidadePeriodoModal}
                          style={styles.unidadePeriodoSelectField}
                        />
                      </Cell>
                      <Cell>
                        <RelatorioGerencialPeriodoRadioField
                          nameOpcaoSelecionada={meta.opcaoSelecionadaModal}
                          namePeriodo={meta.periodo}
                          opcaoSelecionadaValue={values.opcaoSelecionadaModal}
                          unidadeTempoValue={values.unidadePeriodoModal}
                          isModal
                        />
                      </Cell>
                    </Grid>
                  </VFlow>
                  {hasFiltroCiapCid && (
                    <Grid alignItems='stretch' direction='row'>
                      <Cell size={6}>
                        <CiapCidSelectField
                          label='CIAP2 e CID10'
                          placeholder='Selecione outros CIAP2 e CID10'
                          name={meta.ciapsCids}
                          multiple
                        />
                      </Cell>
                      <Cell size={6}>
                        <GrupoCondicaoSelectField
                          label='Grupos de condições prioritários'
                          placeholder='Selecione grupos de condições prioritários'
                          name={meta.gruposCondicoes}
                          multiple
                        />
                      </Cell>
                    </Grid>
                  )}
                  <div style={styles.divider}></div>
                  <ModeloPersonalizadoRelatorioGerencialCamposForm
                    keyMapping={keyMapping}
                    meta={meta.campos}
                    campos={values?.campos}
                    isPublico={values?.isPublico}
                    errors={submitFailed && errors}
                    modulo={tipoModulo}
                    hasCampoInconsistente={hasCampoInconsistente}
                    setFormHasBeenTouched={setFormHasBeenTouched}
                  />
                  <FormControl label='Forma de apresentação' required error={errors.aggregator}>
                    <HFlow>
                      {KEY_NOT_DEPENDENT_AGGREGATORS.map((f) => (
                        <RadioField name={meta.aggregatorModal} label={f.label} value={f.id} key={'modal_' + f.label} />
                      ))}
                    </HFlow>
                  </FormControl>
                </VFlow>
              </Cell>
            </Grid>
          </VFlow>
        </ModalBody>
        <ModalFooter>
          <HFlow justifyContent='space-between'>
            {!isCadastro ? (
              <Button kind='danger' hidden={isCadastro} onClick={handleExcluir}>
                Excluir modelo
              </Button>
            ) : (
              <div></div>
            )}
            <HFlow justifyContent='flex-end'>
              <Button kind='normal' onClick={handleCloseModalAndSendMetrics}>
                Cancelar
              </Button>
              <Button kind='primary' onClick={handleSubmit}>
                Salvar
              </Button>
            </HFlow>
          </HFlow>
        </ModalFooter>
      </Fragment>
    )
  }
  return (
    <Form<ModeloPersonalizadoRelatorioGerencialFormModel>
      render={renderForm}
      onSubmit={onSubmit}
      initialValues={initialValues}
      decorators={decorators}
      validate={modeloPersonalizadoRelatorioGerencialFormValidator(hasFiltroCiapCid)}
    />
  )
}

const createStyles = (theme: Theme) => ({
  periodoContainer: css`
    position: relative;
    padding: 1rem;
    border: 1px solid ${theme.pallete.gray.c80};
  `,

  unidadePeriodoSelectField: css`
    position: absolute;
    margin-left: -0.5rem;
    top: -0.7rem;
    padding: 0 0.5rem;
    background-color: ${theme.pallete.surface.main};
  `,

  divider: {
    borderBottom: `1px solid ${theme.pallete.divider}`,
  },
})
