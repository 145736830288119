import { simNaoEnumToBoolean } from 'components/form/field/SimNaoRadioGroupField'
import { CompartilhamentoCuidadoPlanoInput, CuidadoCompartilhadoPlanoInput } from 'graphql/types.generated'

import { CuidadoCompartilhadoPlanoModel } from './model-cuidadocompartilhado'

export const convertCuidadoCompartilhadoPlanoToInput = (
  items: CuidadoCompartilhadoPlanoModel[]
): CuidadoCompartilhadoPlanoInput[] => {
  return (
    items?.map((item) => ({
      lotacaoExecutanteId: item.lotacao?.id,
      discussao: item.discussao,
      classificacaoPrioridade: item.prioridade,
      ciap: item.problemasECondicoes.ciap?.id,
      cid10: item.problemasECondicoes.cid10?.id,
      cidadaoAceitaAtendTic: simNaoEnumToBoolean[item.cidadaoAceitaAtendTic],
    })) ?? []
  )
}

export const convertCompartilhamentoCuidadoPlanoToInput = (
  items: CuidadoCompartilhadoPlanoModel[]
): CompartilhamentoCuidadoPlanoInput[] => {
  return (
    items?.map((item) => ({
      lotacaoExecutanteId: item.lotacao?.id,
      cboId: item.cbo.cbo.id,
      unidadeSaudeId: item.unidadeSaude?.id,
      cid10: item.problemasECondicoes.cid10?.id,
      ciap: item.problemasECondicoes.ciap?.id,
      classificacaoPrioridade: item.prioridade,
      discussao: item.discussao,
      cidadaoAceitaAtendTic: item.cidadaoAceitaAtendTic && simNaoEnumToBoolean[item.cidadaoAceitaAtendTic],
      isEncaminhamento: !item.cbo.isVinculado,
    })) ?? []
  )
}
