/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Text, VFlow } from 'bold-ui'
import { format } from 'date-fns'
import { useMemo } from 'react'
import { toDate } from 'util/date/formatDate'
import { isObjectDeepEmpty } from 'util/object'

import { ResultadoExameQueryModel } from '../../objetivo/resultados-exames/model'
import {
  getResultadoExameEspecificoExtractValueFromQuery,
  showCodigoProcedimentoRef,
} from '../../objetivo/resultados-exames/util-resultadosExames'
import { ContentCardSection, getAsideCardStyles } from '../components/ContentCardSection'

interface ResultadosExamesCardProps {
  filteredResultado: ResultadoExameQueryModel[]
  loading: boolean
  canRender: boolean
}

export const ResultadosExamesCard = (props: ResultadosExamesCardProps) => {
  const { filteredResultado, loading, canRender } = props

  return (
    <ContentCardSection isLoading={loading} messageLoading='Carregando dados dos resultados' canRender={canRender}>
      {filteredResultado?.length > 0 && (
        <VFlow vSpacing={0.5}>
          {filteredResultado.map((resultadoExame) => (
            <ResultadoExameCardItem item={resultadoExame} key={resultadoExame.id} />
          ))}
        </VFlow>
      )}
    </ContentCardSection>
  )
}

const ResultadoExameCardItem = ({ item }: { item: ResultadoExameQueryModel }) => {
  const { exame, especifico, resultado, dataRealizacao } = item

  const isEspecifico = !isObjectDeepEmpty(especifico)
  const extractValueFromQuery = getResultadoExameEspecificoExtractValueFromQuery(exame)

  const cardStyles = useMemo(() => getAsideCardStyles(), [])

  const resultadoValue = isEspecifico ? extractValueFromQuery(item, true) : resultado

  return (
    <VFlow vSpacing={0}>
      <Text fontWeight='bold'>{showCodigoProcedimentoRef(exame, true).capitalize()}</Text>

      <Text component='p'>{`Realizado em ${dataRealizacao ? format(toDate(dataRealizacao), 'dd/MM/yyyy') : '-'}`}</Text>
      <Text
        component='p'
        style={[
          cardStyles.ellipsesText,
          css`
            white-space: pre-line;
          `,
        ]}
        dangerouslySetInnerHTML={{ __html: resultadoValue }}
      />
    </VFlow>
  )
}
