import { messages as ptBr } from './messages'

export type MessageKey =
  | 'arrayMaxLength'
  | 'beforeEqual130Years'
  | 'beforeEqualToday'
  | 'afterEqualToday'
  | 'beforeEqualTo'
  | 'afterEqualTo'
  | 'beforeTo'
  | 'afterTo'
  | 'afterBirthdate'
  | 'empty'
  | 'invalid'
  | 'length'
  | 'minLength'
  | 'maxLength'
  | 'match'
  | 'microarea'
  | 'nome'
  | 'simpleName'
  | 'nomeOneLetter'
  | 'nomeTwoLetters'
  | 'range'
  | 'minRange'
  | 'maxRange'
  | 'required'
  | 'senha'
  | 'oneRequired'
  | 'nonWhitespaces'
  | 'microarea'
  | 'maxYearsPeriod'
  | 'maxWeeksPeriod'
  | 'addressNumberPattern'

export type ValidationMessages = { [key in MessageKey]: (value: any, ...args: any[]) => string }

export const msg = (key: MessageKey, value: any, ...args: any[]): string => ptBr[key](value, ...args)
