/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, FormControl, Grid, HFlow, Icon, Text, Theme, Tooltip, useTheme } from 'bold-ui'
import { CboSelectField } from 'components/form/field/select/CboSelectField/CboSelectField'
import { LotacaoAndEstagioSelectField } from 'components/form/field/select/LotacaoAndEstagioSelectField/LotacaoAndEstagioSelectField'
import { RichTextField } from 'components/form/final-form/RichTextField/RichTextField'
import { Cbo } from 'graphql/types.generated'
import { useField } from 'react-final-form'
import { MetaPath } from 'util/metaPath'
import {
  CondutaGroupEnum,
  CUIDADO_COMPARTILHADO_CONDUTAS_PLACEHOLDER,
  CuidadoCompartilhadoLotacaoSolicitanteModel,
  DiscussaoCasoExecutanteFieldsModel,
  DiscussaoCasoSolicitanteFieldsModel,
  TrocaExecutanteCuidadoCompartilhadoFieldsModel,
} from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'

import { CondutaGroupCuidadoCompartilhadoRadioFieldContainer } from './CondutaGroupCuidadoCompartilhadoRadioFieldContainer'

interface TrocaExecutanteCuidadoCompartilhadoFieldProps {
  path: MetaPath<DiscussaoCasoExecutanteFieldsModel | DiscussaoCasoSolicitanteFieldsModel>
  lotacaoSolicitante: CuidadoCompartilhadoLotacaoSolicitanteModel
  profissionalExecutanteId: ID
}

export const TrocaExecutanteCuidadoCompartilhadoField = (props: TrocaExecutanteCuidadoCompartilhadoFieldProps) => {
  const { path, lotacaoSolicitante, profissionalExecutanteId } = props

  const theme = useTheme()
  const styles = createStyles(theme)

  const name = path.trocaExecutante
  const excludeProfissionaisIds = [lotacaoSolicitante.profissional.id, profissionalExecutanteId]
  const equipeSolicitanteId = lotacaoSolicitante.equipe?.id

  const {
    input: { value: cbo },
  } = useField<Cbo>(name.cbo.absolutePath(), { subscription: { value: true } })

  return (
    <CondutaGroupCuidadoCompartilhadoRadioFieldContainer<TrocaExecutanteCuidadoCompartilhadoFieldsModel>
      name={name}
      pathCondutaGroup={path.condutaGroup}
      value={CondutaGroupEnum.TROCA_PROFISSIONAL_REFERENCIA}
    >
      <Grid>
        <Cell size={12}>
          <Text>
            A troca de profissional de referência pode ser feita para qualquer profissional vinculado com a equipe do
            profissional solicitante.
          </Text>
        </Cell>
        <Cell size={6}>
          <CboSelectField
            name={name.cbo}
            label='CBO'
            mostrarIndisponivelLotacao={false}
            apenasEquipeCuidadoCompartilhado
            equipeVinculadaId={equipeSolicitanteId}
            nivelSuperior
            excludeProfissionaisIds={excludeProfissionaisIds}
          />
        </Cell>
        <Cell size={6}>
          <FormControl label='Profissional' required>
            <HFlow hSpacing={0.5} alignItems='baseline' style={styles.hFlow}>
              <LotacaoAndEstagioSelectField
                name={name.lotacao}
                unidadeSaudeId={lotacaoSolicitante.unidadeSaude.id}
                cboId={cbo?.id}
                canWriteAtendimento={false}
                nivelSuperior
                excludeProfissionaisIds={excludeProfissionaisIds}
                apenasEquipeCuidadoCompartilhado
                equipeVinculadaId={equipeSolicitanteId}
                required
              />
              <Tooltip
                text='O campo só traz profissionais de equipes NASF e eMulti vinculados a essa unidade.'
                placement='top'
              >
                <Icon icon='infoCircleFilled' fill='normal' size={1} style={styles.iconTooltip} />
              </Tooltip>
            </HFlow>
          </FormControl>
        </Cell>
        <Cell size={12}>
          <RichTextField
            name={name.motivo}
            label='Motivo'
            placeholder={CUIDADO_COMPARTILHADO_CONDUTAS_PLACEHOLDER}
            maxLength={1000}
            required
          />
        </Cell>
      </Grid>
    </CondutaGroupCuidadoCompartilhadoRadioFieldContainer>
  )
}

const createStyles = (theme: Theme) => ({
  hFlow: css`
    grid-auto-columns: 1fr auto;
  `,
  iconTooltip: css`
    &:hover {
      fill: ${theme.pallete.primary.main};
    }
  `,
})
