/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Text, Theme, useTheme, VFlow } from 'bold-ui'
import { PageLoading } from 'components/loading'
import { ReactElement, useCallback, useState } from 'react'

import { RelatorioGerencialFiltrosFormModel } from './form/model-relatorioGerencialFiltrosForm'
import { RelatorioGerencialFiltrosForm, RelatorioGerencialFiltrosFormProps } from './form/RelatorioGerencialFiltrosForm'
import { ModeloPersonalizadoRelatorioGerencialRootView } from './modelo-personalizado/modal/ModeloPersonalizadoRelatorioGerencialRootView'

export interface RelatorioGerencialTableWrapperProps<T>
  extends Omit<RelatorioGerencialFiltrosFormProps<T>, 'hideChildren'> {
  loadingMessage: string
  isEmpty: boolean
  children: ReactElement
}

export function RelatorioGerencialTableWrapper<T>(props: RelatorioGerencialTableWrapperProps<T>) {
  const {
    loading,
    loadingMessage,
    isEmpty,
    children,
    modulo,
    keyMapping,
    onSubmit,
    setPivotTableInitialValues,
    ...rest
  } = props

  const [shouldRenderChildren, setShouldRenderChildren] = useState<boolean>(true)

  const theme = useTheme()
  const { container, loadingContainer } = createStyles(theme)

  const handleSubmit = (filtros: RelatorioGerencialFiltrosFormModel) => {
    setShouldRenderChildren(true)
    onSubmit(filtros)
  }

  const hideChildren = useCallback(() => setShouldRenderChildren(false), [])

  return (
    <VFlow style={container}>
      <ModeloPersonalizadoRelatorioGerencialRootView modulo={modulo} keyMapping={keyMapping} />
      <RelatorioGerencialFiltrosForm<T>
        loading={loading}
        modulo={modulo}
        keyMapping={keyMapping}
        onSubmit={handleSubmit}
        hideChildren={hideChildren}
        setPivotTableInitialValues={setPivotTableInitialValues}
        {...rest}
      />

      {loading ? (
        <div css={loadingContainer}>
          <PageLoading message={loadingMessage} />
        </div>
      ) : isEmpty ? (
        <Text>Nenhum dado para o período</Text>
      ) : (
        shouldRenderChildren && children
      )}
    </VFlow>
  )
}

const createStyles = (theme: Theme) => ({
  container: css`
    color: ${theme.pallete.gray.c20};
  `,
  loadingContainer: css`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  `,
})
