/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, HFlow } from 'bold-ui'
import { Box } from 'components/Box'
import { Form, FormRenderProps, SubmitButton, TextField } from 'components/form'
import { DoseImunobiologicoVacinacaoSelectField } from 'components/form/field/select/DoseImunobiologicoVacinacaoSelectField'
import { GrupoAtendimentoSelectField } from 'components/form/field/select/GrupoAtendimentoSelectField'
import { useFlags } from 'config/useFlagsContext'
import { useState } from 'react'
import { metaPath } from 'util/metaPath'
import {
  ImunobiologicoSelectField,
  ImunobiologicoSelectModel,
} from 'view/lote-imunobiologico/components/ImunobiologicoSelectField'

import { BuscaAtivaVacinacaoFilterPopperFormModel } from '../model'
import { validatorFiltrosAvancados } from '../validator'

export const meta = metaPath<BuscaAtivaVacinacaoFilterPopperFormModel>()

interface BuscaAtivaVacinacaoFilterPopperProps {
  isTipoVisualizacaoCovid: boolean
  formValues?: BuscaAtivaVacinacaoFilterPopperFormModel
  grupoAlvoId?: ID
  closeFilterPopper(): void
  clearFilterPopper(): void
  applyFilterPopper(BuscaAtivaVacinacaoFilterPopperFormModel): void
}

export default function BuscaAtivaVacinacaoFilterPopper({
  isTipoVisualizacaoCovid,
  formValues,
  grupoAlvoId,
  closeFilterPopper,
  clearFilterPopper,
  applyFilterPopper,
}: BuscaAtivaVacinacaoFilterPopperProps) {
  const { BUSCA_ATIVA_VACINACAO_ENABLED } = useFlags()

  const [imunobiologicos, setImunobiologicos] = useState<ImunobiologicoSelectModel[]>(formValues?.imunobiologicos)

  const handleApplyFilters = (formValues: BuscaAtivaVacinacaoFilterPopperFormModel) => applyFilterPopper(formValues)
  const renderForm = ({ form, handleSubmit }: FormRenderProps<BuscaAtivaVacinacaoFilterPopperFormModel>) => {
    const handleOnSubmit = () => {
      handleSubmit()
      closeFilterPopper()
    }

    const handleOnClearFilters = () => {
      form.reset()
      clearFilterPopper()
    }

    const handleOnChangeImunobiologico = (values: ImunobiologicoSelectModel[]) => {
      setImunobiologicos(values)
    }

    return (
      <Box
        style={css`
          width: 100%;
          max-width: 48rem;
        `}
      >
        <Grid>
          <Cell size={6}>
            <ImunobiologicoSelectField
              name={meta.imunobiologicos}
              label='Imunobiológico'
              placeholder='Todos os imunobiológicos'
              semRegras
              onlyCalendarioVacinal={!isTipoVisualizacaoCovid}
              isOnlyCovid={isTipoVisualizacaoCovid}
              multiple
              grupoAlvoId={grupoAlvoId}
              onChange={handleOnChangeImunobiologico}
            />
          </Cell>
          <Cell size={6}>
            <DoseImunobiologicoVacinacaoSelectField
              name={meta.doses}
              label='Dose'
              placeholder='Todas as doses'
              imunobiologicoIds={imunobiologicos?.map((imuno) => imuno.id)}
              onlyCalendarioVacinal={!isTipoVisualizacaoCovid}
              multiple
              semRegras
              grupoAlvoId={grupoAlvoId}
            />
          </Cell>
          {isTipoVisualizacaoCovid && (
            <Cell size={12}>
              <GrupoAtendimentoSelectField
                name={meta.gruposAtendimento}
                label='Grupo de atendimento'
                placeholder='Todos os grupos'
                onlyAtivos={false}
                multiple
              />
            </Cell>
          )}
          {BUSCA_ATIVA_VACINACAO_ENABLED && (
            <Cell size={6}>
              <TextField name={meta.bairro} label='Bairro' placeholder='Todos os bairros' maxLength={72} />
            </Cell>
          )}
          <Cell size={12}>
            <HFlow justifyContent='flex-end'>
              <Button size='small' onClick={closeFilterPopper}>
                Fechar
              </Button>
              <Button onClick={handleOnClearFilters} size='small'>
                Limpar filtros
              </Button>
              <SubmitButton kind='primary' size='small' handleSubmit={handleOnSubmit}>
                Aplicar
              </SubmitButton>
            </HFlow>
          </Cell>
        </Grid>
      </Box>
    )
  }

  return (
    <Form<BuscaAtivaVacinacaoFilterPopperFormModel>
      render={renderForm}
      onSubmit={(values) => handleApplyFilters(values)}
      initialValues={formValues}
      validateOnBlur
      validate={validatorFiltrosAvancados}
    />
  )
}
