/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, focusBoxShadow, Heading, HFlow, Icon, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { formatDate } from 'util/date/formatDate'
import { isObjectDeepEmpty } from 'util/object'

import {
  ExameQueryModel,
  ResultadoExameComSolicitacaoModel,
  ResultadoExameEspecificoModel,
  ResultadoExameSemSolicitacaoModel,
} from '../../model'
import { getResultadoExameEspecificoProperties, showCodigoProcedimentoRef } from '../../util-resultadosExames'

export type CardResultadoExameStatus = 'default' | 'success' | 'error'

interface CardResultadoExameContainerProps {
  exame: ExameQueryModel
  children: any
  headerButtons?: React.ReactNode
  headerDates: {
    dataRealizado?: LocalDate
    dataResultado?: LocalDate
  }
  status: CardResultadoExameStatus
  ignoringTouchedAndDirtyStatus: CardResultadoExameStatus
}

export const CardResultadoExameContainer = (props: CardResultadoExameContainerProps) => {
  const {
    exame,
    children,
    headerButtons,
    headerDates: { dataRealizado, dataResultado },
    status,
    ignoringTouchedAndDirtyStatus,
  } = props

  const [expanded, setExpanded] = useState(false)

  const theme = useTheme()
  const classes = useMemo(() => styles(theme), [theme])

  const { unit } = getResultadoExameEspecificoProperties(exame)

  const ref = useRef<HTMLDivElement>()
  const headerRef = useRef<HTMLDivElement>()

  const listener = useCallback((e) => {
    const activeElement = document.activeElement

    if (!headerRef?.current?.contains(activeElement)) {
      const containsFocusedElement = ref?.current?.contains(activeElement)

      setExpanded(containsFocusedElement)
    }
  }, [])

  useEffect(() => {
    const currentRef = ref.current

    currentRef.addEventListener('focus', listener, true)
    currentRef.addEventListener('blur', () => setTimeout(listener, 100), true)

    return () => {
      currentRef.removeEventListener('focus', listener)
      currentRef.removeEventListener('blur', listener)
    }
  }, [listener])

  useEffect(() => {
    const refNotContainsActiveElement = !ref?.current?.contains(document.activeElement)

    if (!expanded && status === 'error') {
      setExpanded(true)
    } else if (expanded && status !== 'error' && refNotContainsActiveElement) {
      setExpanded(false)
    }
  }, [expanded, status])

  return (
    <div css={classes.box} ref={ref} aria-expanded={expanded} role='button' tabIndex={-1}>
      <VFlow vSpacing={0}>
        <div ref={headerRef}>
          <HFlow alignItems='center' justifyContent='space-between' style={classes.header}>
            <HFlow alignItems='center' justifyContent='flex-start' hSpacing={0.5} style={classes.hflow}>
              <Button
                skin='ghost'
                size='small'
                style={classes.collapseButton}
                tabIndex={-1}
                onClick={() => setExpanded((prevValue) => !prevValue)}
                disabled={status === 'error'}
              >
                <Icon name='accordionAngleIcon' icon='angleDown' />
              </Button>

              <Heading level={5} fontWeight='bold' style={classes.heading}>
                {showCodigoProcedimentoRef(exame, true).capitalize() + (unit ? ` (${unit})` : '')}
              </Heading>

              {ignoringTouchedAndDirtyStatus === 'success' && <Icon icon='checkCircleFilled' fill='success' size={1} />}
            </HFlow>

            <HFlow alignItems='center' justifyContent='flex-end' hSpacing={0.5}>
              <Text style={classes.headerDates}>
                {dataRealizado ? formatDate(dataRealizado) : '-'}
                <strong>{' | '}</strong>
                {dataResultado ? formatDate(dataResultado) : '-'}
              </Text>

              {headerButtons}
            </HFlow>
          </HFlow>
        </div>

        {children({ expanded })}
      </VFlow>
    </div>
  )
}

const styles = (theme: Theme) => ({
  box: css`
    padding: 0.5rem;
    padding-top: 0.25rem;
    background: ${theme.pallete.gray.c90};
    border: 1px solid ${theme.pallete.divider};
    border-radius: 2px;
    cursor: pointer;

    &:focus {
      outline: none;
      box-shadow: ${focusBoxShadow(theme, 'primary')};
      transition-property: box-shadow;
      transition-duration: 0.2s;
    }

    &[aria-expanded='true'] svg[name='accordionAngleIcon'],
    &[aria-selected='true'] svg[name='accordionAngleIcon'] {
      transform: rotate(180deg);
      transition: 0.4s;
    }
    &[aria-expanded='false'] svg[name='accordionAngleIcon'] {
      transform: rotate(0deg);
      transition: 0.4s;
    }
  `,
  header: css`
    margin-bottom: 0.5rem;
  `,
  headerDates: css`
    color: ${theme.pallete.gray.c20};
    white-space: nowrap;
  `,
  collapseButton: css`
    margin-left: -0.25rem;
    padding: calc(0.25rem - 1px);
  `,
  heading: css`
    line-height: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  hflow: css`
    grid-auto-columns: auto;
  `,
})

export const getCardResultadoExameStatus = (
  value: (ResultadoExameComSolicitacaoModel | ResultadoExameSemSolicitacaoModel) & ResultadoExameEspecificoModel,
  hasError: boolean
): CardResultadoExameStatus => {
  if (hasError) return 'error'
  if (!isObjectDeepEmpty(value) && !isObjectDeepEmpty(value?.resultado) && !hasError) return 'success'
  return 'default'
}
