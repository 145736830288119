import { Omit } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form/final-form'
import { IdentidadeGeneroEnum } from 'graphql/types.generated'
import React from 'react'

export interface IdentidadeGeneroSelectModel {
  identificador: IdentidadeGeneroEnum
  nome: string
}

export interface IdentidadeGeneroSelectFieldProps
  extends Omit<SelectFieldProps<IdentidadeGeneroSelectModel>, 'items' | 'itemToString'> {}

export function IdentidadeGeneroSelectField(props: IdentidadeGeneroSelectFieldProps) {
  const itemToString = (item: IdentidadeGeneroSelectModel) => item && item.nome

  return <SelectField<IdentidadeGeneroSelectModel> items={items} itemToString={itemToString} {...props} />
}

const items: IdentidadeGeneroSelectModel[] = [
  { identificador: IdentidadeGeneroEnum.HOMEM_TRANS, nome: 'Homem transgênero' },
  { identificador: IdentidadeGeneroEnum.MULHER_TRANS, nome: 'Mulher transgênero' },
  { identificador: IdentidadeGeneroEnum.TRANSGENERO, nome: 'Transgênero' },
  { identificador: IdentidadeGeneroEnum.TRAVESTI, nome: 'Travesti' },
  { identificador: IdentidadeGeneroEnum.HOMEM_CIS, nome: 'Homem cisgênero' },
  { identificador: IdentidadeGeneroEnum.MULHER_CIS, nome: 'Mulher cisgênero' },
  { identificador: IdentidadeGeneroEnum.NAO_BINARIO, nome: 'Não-Binário' },
  { identificador: IdentidadeGeneroEnum.OUTRO_IDENTIDADE_GENERO, nome: 'Outro' },
]
