/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Checkbox, HFlow, isEmpty, Text, VFlow } from 'bold-ui'
import { SexoEnum } from 'graphql/types.generated'
import React, { memo, useCallback } from 'react'
import {
  TermosOutrosMedicamentosImpressaoPrescricao,
  termosPrescricaoImpressao,
  TermosTalidomidaImpressaoPrescricao,
} from 'types/enums'

import { ImpressaoTermosDto } from '../../modal/impressao/ImpressaoMedicamentosInput'
import { MedicamentoFormModel } from '../../model'
import { MedicamentoImpressoInfoLabel } from './MedicamentoImpressoInfoLabel'
import { PrescricaoBoxItem } from './PrescricaoBoxItem'

interface TermosToPrintListProps {
  cidadaoIdade: number
  cidadaoSexo: SexoEnum
  talidomidas: MedicamentoFormModel[]
  termos: string[]
  hasAcitretinaIsotretinoinaTretinoina: boolean
  acitretinaIsotretinoinaTretinoinaSub: string
  alreadyPrinted(termo: ID): boolean
  onClickCheckbox(checked: boolean, termo: ImpressaoTermosDto): void
}

function TermosToPrintList(props: TermosToPrintListProps) {
  const {
    cidadaoIdade,
    cidadaoSexo,
    termos,
    acitretinaIsotretinoinaTretinoinaSub,
    hasAcitretinaIsotretinoinaTretinoina,
    onClickCheckbox,
    talidomidas,
    alreadyPrinted,
  } = props

  const isCidadaoMaior55 = cidadaoIdade >= 55
  const isCidadaoFemMenor55 = cidadaoSexo === SexoEnum.FEMININO && cidadaoIdade < 55
  const isCidadaoFemMaior55 = cidadaoSexo === SexoEnum.FEMININO && isCidadaoMaior55
  const anexoV = termosPrescricaoImpressao[TermosTalidomidaImpressaoPrescricao.ANEXO_V]

  const isSelecionado = useCallback((termo: string) => termos.includes(termo), [termos])

  const filtroTalidomida = useCallback(
    (termo: string) =>
      termo !== anexoV || (termo === anexoV && (isCidadaoFemMaior55 || cidadaoSexo === SexoEnum.MASCULINO)),
    [anexoV, cidadaoSexo, isCidadaoFemMaior55]
  )

  return (
    <VFlow
      vSpacing={0.5}
      style={css`
        margin-top: 0.5rem;
        margin-bottom: 1rem;
      `}
    >
      {!isEmpty(talidomidas) &&
        talidomidas?.map((talidomida) =>
          Object.keys(TermosTalidomidaImpressaoPrescricao)
            .filter((termo) => filtroTalidomida(termo))
            .map((key: TermosTalidomidaImpressaoPrescricao) => (
              <PrescricaoBoxItem>
                <Checkbox
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onClickCheckbox(e.target.checked, {
                      idMedicamentoReferencia: talidomida.id,
                      termo: key,
                      principioAtivo: talidomida.principioAtivoCombo?.principioAtivo.nome,
                      quantidade: talidomida.quantidade,
                      duracaoTratamento: talidomida.duracao,
                      unidadeMedidaDuracao: talidomida.escalaDuracao,
                      dataInicioTratamento: talidomida.dataInicioTratamento,
                    } as ImpressaoTermosDto)
                  }
                  checked={isSelecionado(key)}
                  onKeyDown={(e) => e.stopPropagation()}
                />
                <VFlow vSpacing={0.3}>
                  <HFlow>
                    <Text fontWeight='bold'>{TermosTalidomidaImpressaoPrescricao[key]}</Text>
                    {alreadyPrinted(key) && <MedicamentoImpressoInfoLabel />}
                  </HFlow>
                  <Text>Talidomida</Text>
                </VFlow>
              </PrescricaoBoxItem>
            ))
        )}
      {hasAcitretinaIsotretinoinaTretinoina && (
        <React.Fragment>
          {isCidadaoFemMenor55 ? (
            <PrescricaoBoxItem>
              <Checkbox
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onClickCheckbox(e.target.checked, {
                    termo: termosPrescricaoImpressao[TermosOutrosMedicamentosImpressaoPrescricao.ANEXO_XV],
                  } as ImpressaoTermosDto)
                }
                checked={isSelecionado(termosPrescricaoImpressao[TermosOutrosMedicamentosImpressaoPrescricao.ANEXO_XV])}
                onKeyDown={(e) => e.stopPropagation()}
              />
              <VFlow vSpacing={0.3}>
                <HFlow>
                  <Text fontWeight='bold'>{TermosOutrosMedicamentosImpressaoPrescricao.ANEXO_XV.toString()}</Text>
                  {alreadyPrinted(termosPrescricaoImpressao[TermosOutrosMedicamentosImpressaoPrescricao.ANEXO_XV]) && (
                    <MedicamentoImpressoInfoLabel />
                  )}
                </HFlow>
                <Text>{acitretinaIsotretinoinaTretinoinaSub}</Text>
              </VFlow>
            </PrescricaoBoxItem>
          ) : (
            (isCidadaoMaior55 || cidadaoSexo === SexoEnum.MASCULINO) && (
              <PrescricaoBoxItem>
                <Checkbox
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onClickCheckbox(e.target.checked, {
                      termo: termosPrescricaoImpressao[TermosOutrosMedicamentosImpressaoPrescricao.ANEXO_XVI],
                    } as ImpressaoTermosDto)
                  }
                  checked={isSelecionado(
                    termosPrescricaoImpressao[TermosOutrosMedicamentosImpressaoPrescricao.ANEXO_XVI]
                  )}
                  onKeyDown={(e) => e.stopPropagation()}
                />
                <VFlow vSpacing={0.3}>
                  <HFlow>
                    <Text fontWeight='bold'>{TermosOutrosMedicamentosImpressaoPrescricao.ANEXO_XVI}</Text>
                    {alreadyPrinted(
                      termosPrescricaoImpressao[TermosOutrosMedicamentosImpressaoPrescricao.ANEXO_XVI]
                    ) && <MedicamentoImpressoInfoLabel />}
                  </HFlow>
                  <Text>{acitretinaIsotretinoinaTretinoinaSub}</Text>
                </VFlow>
              </PrescricaoBoxItem>
            )
          )}
        </React.Fragment>
      )}
    </VFlow>
  )
}

export default memo(TermosToPrintList)
