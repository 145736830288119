/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { DecimalField } from 'components/form'
import { useMemo } from 'react'

import { FactoryResultadoExameEspecificoFieldProps } from '../../model'
import { getResultadoExameEspecificoProperties } from '../../util-resultadosExames'

export const ResultadoExameMarcadoresBioquimicosDecimalField = (props: FactoryResultadoExameEspecificoFieldProps) => {
  const { name, exame, expanded } = props

  const { maxLength, precision } = getResultadoExameEspecificoProperties(exame)

  const inputStyle = useMemo(() => styles(expanded), [expanded])

  return (
    <div
      css={css`
        margin-bottom: -1px;
      `}
    >
      <DecimalField
        name={name}
        maxLength={maxLength}
        precisao={precision}
        placeholder='Clique para inserir o resultado...'
        style={inputStyle}
      />
    </div>
  )
}

const styles = (expanded: boolean) => css`
  width: calc(100% + 1rem + 2px);
  margin: 0 calc((0.5rem + 1px) * -1);
  margin-bottom: ${expanded ? '0' : 'calc(-0.75rem - 1px)'};

  & + span[role='button'] {
    top: ${!expanded ? '4px' : '0'};
  }
`
