import { SimNaoEnum } from 'components/form/field/SimNaoRadioGroupField'
import { toDate } from 'date-fns'
import { CompartilhamentoCuidadoPlanoDto } from 'graphql/types.generated'
import { compact } from 'lodash'
import { emptyArray } from 'util/array'

import {
  CuidadoCompartilhadoPlanoModel,
  TipoCompartilhamentoCuidadoPlanoEnum,
} from '../cuidado-compartilhado/model-cuidadocompartilhado'
import { CompartilhamentoCuidadoPlanoModel } from './model-compartilhamentocuidado'

export const joinCompartilhamentosCuidado = (
  compartilhamentosAtendimentoAtual: CuidadoCompartilhadoPlanoModel[],
  compartilhamentosHistorico: CompartilhamentoCuidadoPlanoDto[],
  atendIniciadoEm: Date
): CompartilhamentoCuidadoPlanoModel[] => {
  return [
    ...(compartilhamentosAtendimentoAtual || emptyArray)
      .map((valorAtual) => convertValorAtualToJoined(valorAtual, atendIniciadoEm))
      .sort((a, b) => b.iniciadoEm.valueOf() - a.iniciadoEm.valueOf()),
    ...(compartilhamentosHistorico?.map(convertValorHistoricoToJoined) ?? emptyArray),
  ]
}

const convertValorAtualToJoined = (
  valorAtual: CuidadoCompartilhadoPlanoModel,
  iniciadoEm: Date
): CompartilhamentoCuidadoPlanoModel => ({
  cacheId: valorAtual._id,
  cbo: valorAtual.cbo.cbo,
  unidadeSaude: valorAtual.unidadeSaude,
  lotacaoSolicitante: valorAtual.lotacaoSolicitante,
  lotacaoExecutante: valorAtual.lotacao,
  prioridade: valorAtual.prioridade,
  iniciadoEm: toDate(valorAtual.lastUpdate) ?? iniciadoEm,
  discussao: valorAtual.discussao,
  problemasECondicoes: valorAtual.problemasECondicoes,
  cidadaoAceitaAtendTic: valorAtual.cidadaoAceitaAtendTic,
  isRegistradoAgora: true,
  isEncaminhamento: valorAtual.tipo === TipoCompartilhamentoCuidadoPlanoEnum.GUIA_ENCAMINHAMENTO,
})

const convertValorHistoricoToJoined = (
  valorHistorico: CompartilhamentoCuidadoPlanoDto
): CompartilhamentoCuidadoPlanoModel => ({
  dbId: valorHistorico.id,
  cbo: valorHistorico.cbo,
  unidadeSaude: valorHistorico.unidadeSaude,
  lotacaoSolicitante: valorHistorico.lotacaoSolicitante,
  lotacaoExecutante: valorHistorico.lotacaoExecutanteAtual,
  prioridade: valorHistorico.classificacaoPrioridadeAtual,
  iniciadoEm: valorHistorico.dataInicio,
  discussao: valorHistorico.discussao,
  problemasECondicoes: { ciap: valorHistorico.ciap, cid10: valorHistorico.cid10 },
  cidadaoAceitaAtendTic: valorHistorico.cidadaoAceitaAtendTic ? SimNaoEnum.SIM : SimNaoEnum.NAO,
  isRegistradoAgora: false,
  isEncaminhamento: valorHistorico.isEncaminhamento,
})

export const getProfissionaisCompartilhamentoCuidado = (
  compartilhamentosAtuais: CuidadoCompartilhadoPlanoModel[],
  compartilhamentosHistorico: CompartilhamentoCuidadoPlanoDto[]
) => {
  const profissionaisExecutantes = compact(
    (Array.isArray(compartilhamentosAtuais)
      ? compartilhamentosAtuais.map((item) => item.lotacao?.profissional)
      : []
    ).concat(compartilhamentosHistorico?.map((item) => item.lotacaoExecutanteAtual?.profissional))
  )
    .removeDuplicate((item) => item.id)
    .sort((a, b) => a.nome.localeCompare(b.nome))

  const profissionaisSolicitantes = compact(
    (Array.isArray(compartilhamentosAtuais)
      ? compartilhamentosAtuais.map((item) => item.lotacaoSolicitante?.profissional)
      : []
    ).concat(compartilhamentosHistorico?.map((item) => item.lotacaoSolicitante?.profissional))
  )
    .removeDuplicate((item) => item.id)
    .sort((a, b) => a.nome.localeCompare(b.nome))
  return {
    profissionaisSolicitantes,
    profissionaisExecutantes,
  }
}
