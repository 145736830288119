import { colors, Tag, Theme, useStyles } from 'bold-ui'
import { CiapCidSelectModel, getDescricao } from 'components/form/field/select/CiapCidSelectField/CiapCidSelectField'
import { SelectField } from 'components/form/final-form'
import { GrupoCondicaoSelectFieldDocument } from 'graphql/hooks.generated'
import {
  GrupoCondicaoEnum,
  GrupoCondicaoSelectFieldQuery,
  GrupoCondicaoSelectFieldQueryVariables,
} from 'graphql/types.generated'
import React, { CSSProperties } from 'react'
import { grupoCondicao } from 'types/enums'

import { AsyncSelectFieldProps, DEFAULT_SELECT_PAGE_PARAM, useAsyncQuerySelect } from '../useAsyncQuerySelect'

export interface GrupoCondicaoSelectModel {
  id: ID
  grupoCondicao: GrupoCondicaoEnum
  ciaps?: CiapCidSelectModel[]
  cids?: CiapCidSelectModel[]
}

export interface GrupoCondicaoSelectFieldProps
  extends Omit<AsyncSelectFieldProps<GrupoCondicaoSelectModel>, 'itemIsEqual'> {}

export function GrupoCondicaoSelectField(props: GrupoCondicaoSelectFieldProps) {
  const { classes } = useStyles(createStyles)

  const extractItems = (data: GrupoCondicaoSelectFieldQuery) => {
    return data?.grupos?.content
  }

  const handleItemToString = (item: GrupoCondicaoSelectModel) => {
    return `${grupoCondicao[item?.grupoCondicao]}`
  }

  const handleRenderItem = (item: GrupoCondicaoSelectModel) => {
    const ciapList: string[] = []
    const cidList: string[] = []

    item.ciaps?.forEach((i) => {
      ciapList.push(`${i.codigo} ${getDescricao(i)}`)
    })
    item.cids?.forEach((i) => {
      cidList.push(`${i.codigo} ${getDescricao(i)}`)
    })

    return (
      <div className={classes.listItem}>
        <Tag style={classes.itemTag}>
          <span>{grupoCondicao[item.grupoCondicao]}</span>
        </Tag>
        {ciapList.length !== 0 && `CIAP2: ${ciapList.join(', ')};`}
        {ciapList.length !== 0 && cidList.length !== 0 && ' | '}
        {cidList.length !== 0 && `CID10: ${cidList.join(', ')};`}
      </div>
    )
  }

  const variables = (inputQuery: string): GrupoCondicaoSelectFieldQueryVariables => ({
    inputGrupo: {
      query: inputQuery,
      pageParams: {
        ...DEFAULT_SELECT_PAGE_PARAM,
        sort: ['nomeGrupo'],
        size: 25,
      },
    },
  })

  const { selectProps } = useAsyncQuerySelect<
    GrupoCondicaoSelectModel,
    GrupoCondicaoSelectFieldQuery,
    GrupoCondicaoSelectFieldQueryVariables
  >({
    query: GrupoCondicaoSelectFieldDocument,
    extractItems,
    variables,
  })

  return (
    <SelectField<GrupoCondicaoSelectModel>
      itemToString={handleItemToString}
      renderItem={handleRenderItem}
      itemIsEqual={(a: GrupoCondicaoSelectModel, b: GrupoCondicaoSelectModel) => a?.grupoCondicao === b?.grupoCondicao}
      {...selectProps}
      {...props}
    />
  )
}

const createStyles = (theme: Theme) => ({
  listItem: {
    padding: '1px 1px 2px 2px',
    maxHeight: '2.6rem',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    WebkitLineClamp: 2,
    WebkitFlexWrap: 'nowrap',
    width: '28.2rem',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    MozBoxOrient: 'vertical',
  } as CSSProperties,
  itemTag: {
    backgroundColor: colors.blue.c80,
    marginRight: '5px',
  } as CSSProperties,
})
