/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, Icon, Tooltip } from 'bold-ui'
import { DateField } from 'components/form'
import { confirm } from 'components/modals/confirm'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { max } from 'lodash'
import { Fragment } from 'react'
import { isUndefinedOrNull } from 'util/checks'
import { toDate } from 'util/date/formatDate'
import { MetaPath } from 'util/metaPath'
import { isObjectDeepEmpty } from 'util/object'
import { getEditableItemRemoveTooltipText } from 'view/atendimentos/atendimento-individual/atendimento-observacao/util-atendObservacao'

import { ExameQueryModel, ResultadoExameEspecificoModel, ResultadoExameSemSolicitacaoModel } from '../../model'
import { isExameEspecifico } from '../../util-resultadosExames'
import { CardResultadoExameContainer, CardResultadoExameStatus } from '../components/CardResultadoExameContainer'
import { ResultadoExameExpandableContainer } from '../components/ResultadoExameExpandableContainer'
import { ResultadoExameTextAreaField } from '../components/ResultadoExameTextAreaField'
import { ResultadoExameEspecificoField } from '../exames-especificos/ResultadoExameEspecificoField'

interface ResultadoExameSemSolicitacaoFieldProps {
  name: MetaPath<ResultadoExameSemSolicitacaoModel>
  exame: ExameQueryModel
  value: ResultadoExameSemSolicitacaoModel & ResultadoExameEspecificoModel
  status: CardResultadoExameStatus
  ignoringTouchedAndDirtyStatus: CardResultadoExameStatus
  onRemove(): void
}

export const ResultadoExameSemSolicitacaoField = (props: ResultadoExameSemSolicitacaoFieldProps) => {
  const { name, exame, value, status, ignoringTouchedAndDirtyStatus, onRemove } = props

  const {
    atendimentoProfissional: { iniciadoEm },
    cidadao: { dataNascimento },
  } = useAtendimentoContext()

  const isEspecifico = isExameEspecifico(exame)

  const { dataSolicitado, dataRealizado, dataResultado, resultado } = value ?? {}
  const dataNascimentoAsDate = toDate(dataNascimento)
  const dataSolicitadoAsDate = toDate(dataSolicitado)
  const dataRealizadoAsDate = toDate(dataRealizado)

  const disableOtherFields = isObjectDeepEmpty(resultado)

  const { isEditable, tooltipText } = getEditableItemRemoveTooltipText(value)

  const handleRemove = () => {
    const { exame, ...valoresPreenchidos } = value

    if (!isObjectDeepEmpty(valoresPreenchidos)) {
      confirm({
        title: 'Deseja excluir este exame?',
        body: 'Os dados deste exame não serão salvos.',
        confirmLabel: 'Excluir',
        onConfirm: () => onRemove(),
      })()
    } else {
      onRemove()
    }
  }

  return (
    <CardResultadoExameContainer
      exame={exame}
      headerDates={{ dataRealizado, dataResultado }}
      headerButtons={
        <Tooltip text={tooltipText}>
          <Button
            skin='ghost'
            size='small'
            style={css`
              margin: -0.5rem 0;
            `}
            onClick={handleRemove}
            disabled={isEditable}
          >
            <Icon icon='trashOutline' fill='normal' />
          </Button>
        </Tooltip>
      }
      status={status}
      ignoringTouchedAndDirtyStatus={ignoringTouchedAndDirtyStatus}
    >
      {({ expanded }) =>
        isEspecifico ? (
          <ResultadoExameEspecificoField
            name={name as MetaPath<ResultadoExameEspecificoModel>}
            exame={exame}
            expanded={expanded}
            disableOtherFields={disableOtherFields}
            dataSolicitacao={dataSolicitadoAsDate}
            dataRealizado={dataRealizadoAsDate}
          />
        ) : (
          <Fragment>
            <ResultadoExameTextAreaField
              name={name.resultado}
              expanded={expanded}
              hasValue={!isUndefinedOrNull(resultado)}
            />

            <ResultadoExameExpandableContainer expanded={expanded}>
              <Grid gap={1.5}>
                <Cell size={5}>
                  <DateField
                    name={name.dataSolicitado}
                    label='Solicitado em'
                    disabled={disableOtherFields}
                    minDate={dataNascimentoAsDate}
                    maxDate={iniciadoEm}
                    inline
                  />
                </Cell>

                <Cell size={7} />

                <Cell size={5}>
                  <DateField
                    name={name.dataRealizado}
                    label='Realizado em'
                    disabled={disableOtherFields}
                    minDate={max([dataNascimentoAsDate, dataSolicitadoAsDate])}
                    maxDate={iniciadoEm}
                    required
                    inline
                  />
                </Cell>

                <Cell size={5}>
                  <DateField
                    name={name.dataResultado}
                    label='Resultado em'
                    disabled={disableOtherFields}
                    minDate={max([dataNascimentoAsDate, dataRealizadoAsDate])}
                    maxDate={iniciadoEm}
                    inline
                  />
                </Cell>
              </Grid>
            </ResultadoExameExpandableContainer>
          </Fragment>
        )
      }
    </CardResultadoExameContainer>
  )
}
