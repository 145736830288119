import { ExternalStyles, Heading } from 'bold-ui'
import React from 'react'

export function DoSearchPanel() {
  return (
    <div style={styles.div}>
      <Heading level={3}>
        Realize uma busca por cidadãos que tenham algum problema ou <br /> condição ativo ou avaliado.
      </Heading>
      <Heading level={5} css={styles.secondHeading}>
        É possível buscar uma lista de cidadãos a partir do preenchimento de qualquer informação, <br /> não esqueça de
        preencher os filtros obrigatórios.
      </Heading>
    </div>
  )
}

const styles = {
  div: {
    textAlign: 'center',
    marginTop: '1rem',
    paddingBottom: '4rem',
  } as React.CSSProperties,
  secondHeading: {
    fontWeight: 'lighter',
    marginTop: '1rem',
  } as ExternalStyles,
}
