import { Omit } from 'bold-ui'
import { SelectField } from 'components/form'
import {
  AsyncSelectFieldProps,
  DEFAULT_SELECT_PAGE_PARAM,
  useAsyncQuerySelect,
} from 'components/form/field/select/useAsyncQuerySelect'
import { TipoServicoSelectFieldDocument } from 'graphql/hooks.generated'
import { TipoServico, TipoServicoSelectFieldQuery, TipoServicoSelectFieldQueryVariables } from 'graphql/types.generated'
import React from 'react'

export interface TipoServicoSelectFieldProps extends AsyncSelectFieldProps<TipoServicoSelectFieldModel> {
  includeInterno?: boolean
}

export type TipoServicoSelectFieldModel = Omit<TipoServico, 'municipio'>

export function TipoServicoSelectField(props: TipoServicoSelectFieldProps) {
  const { includeInterno, ...rest } = props

  const { selectProps } = useAsyncQuerySelect<
    TipoServicoSelectFieldModel,
    TipoServicoSelectFieldQuery,
    TipoServicoSelectFieldQueryVariables
  >({
    query: TipoServicoSelectFieldDocument,
    extractItems: (data) => data && data.tiposServico && data.tiposServico.content,
    variables: (inputQuery) => ({
      input: {
        query: inputQuery,
        pageParams: {
          ...DEFAULT_SELECT_PAGE_PARAM,
          sort: ['nome'],
        },
        unidadeSaudeSessao: true,
        includeInterno,
      },
    }),
  })

  const renderItem = (item: TipoServicoSelectFieldModel) =>
    item && item.nome.slice(0, 1).toUpperCase() + item.nome.slice(1, item.nome.length).toLowerCase()

  return (
    <SelectField<TipoServicoSelectFieldModel>
      itemToString={renderItem}
      renderItem={renderItem}
      {...selectProps}
      {...rest}
    />
  )
}
