import { Button, HFlow, Icon, Text, Tooltip } from 'bold-ui'
import { useAlert } from 'components/alert'
import { DateTime } from 'components/date'
import { useTestarConexaoInternetMutation } from 'graphql/hooks.generated'
import { ConexaoQuery } from 'graphql/types.generated'
import React from 'react'

export interface TesteConexaoInternetProps {
  internet: ConexaoQuery['conexao']['internet']
  refetch(): void
}

export function ConfiguracaoInternetTesteConexao(props: TesteConexaoInternetProps) {
  const alert = useAlert()
  const [testarConexaoMutation] = useTestarConexaoInternetMutation()
  const { dataUltimoTeste, resultadoUltimoTeste } = props.internet

  const testarConexao = () => {
    return testarConexaoMutation().then(() => {
      alert('success', 'Teste de conexão com a internet finalizado.')
      props.refetch()
    })
  }

  return (
    <HFlow alignItems='center'>
      <Button skin='outline' size='small' onClick={testarConexao}>
        Testar conexão com a internet
      </Button>
      {dataUltimoTeste && resultadoUltimoTeste && <ConexaoBemSucedida dataTeste={dataUltimoTeste} />}
      {dataUltimoTeste && !resultadoUltimoTeste && <ConexaoMalSucedida dataTeste={dataUltimoTeste} />}
    </HFlow>
  )
}

interface ConexaoProps {
  dataTeste: number
}

function ConexaoBemSucedida(props: ConexaoProps) {
  return (
    <HFlow alignItems='center'>
      <Icon icon='checkCircleOutline' fill='success' size={1} />
      <Text color='success' style={{ margin: -8 }}>
        Conexão bem sucedida.
      </Text>
      <Text>
        Último teste em <DateTime value={props.dataTeste} />.
      </Text>
    </HFlow>
  )
}

function ConexaoMalSucedida(props: ConexaoProps) {
  return (
    <HFlow alignItems='center'>
      <Icon icon='banOutline' fill='danger' size={1} />
      <Text color='danger' style={{ margin: -8 }}>
        Conexão mal sucedida.
      </Text>
      <Tooltip
        style={{ maxWidth: '595px' }}
        text={
          'Os serviços do CADSUS, Hórus, Agenda Online, Envio Online e Videochamadas podem não operar ' +
          'adequadamente se a instalação estiver com problemas de conexão com a internet. '
        }
      >
        <Icon icon='infoCircleFilled' fill='alert' size={1} />
      </Tooltip>
      <Text>
        Último teste em <DateTime value={props.dataTeste} />.
      </Text>
    </HFlow>
  )
}
