import { SelectHelperMenuItem, Text, useLocale, VFlow } from 'bold-ui'
import { SelectField } from 'components/form'
import { AsyncSelectFieldProps, useAsyncQuerySelect } from 'components/form/field/select/useAsyncQuerySelect'
import { HLabel } from 'components/HLabel'
import { Cpf } from 'components/label'
import { LotacacaoCuidadoCompartilhadoSelectFieldDocument } from 'graphql/hooks.generated'
import {
  CuidadoCompartilhadoQueryFilterEnum,
  LotacacaoCuidadoCompartilhadoSelectFieldQuery,
  LotacacaoCuidadoCompartilhadoSelectFieldQueryVariables,
} from 'graphql/types.generated'
import React from 'react'
import {
  LotacaoCuidadaoCompartilhadoSelectFieldModel,
  LotacaoCuidadoCompartilhadoSelectFieldQueryModel,
} from 'view/atendimentos/detail/soap/plano/cuidado-compartilhado/model-cuidadocompartilhado'

interface LotacaoCuidadaoCompartilhadoSelectFieldProps
  extends AsyncSelectFieldProps<LotacaoCuidadaoCompartilhadoSelectFieldModel> {
  isLotacoesSolicitantes: boolean
  role: CuidadoCompartilhadoQueryFilterEnum
}

export const LotacaoCuidadaoCompartilhadoSelectField = (props: LotacaoCuidadaoCompartilhadoSelectFieldProps) => {
  const { isLotacoesSolicitantes, role, ...rest } = props
  const locale = useLocale()

  const { skipping, selectProps } = useAsyncQuerySelect<
    LotacaoCuidadaoCompartilhadoSelectFieldModel,
    LotacacaoCuidadoCompartilhadoSelectFieldQuery,
    LotacacaoCuidadoCompartilhadoSelectFieldQueryVariables
  >({
    query: LotacacaoCuidadoCompartilhadoSelectFieldDocument,
    extractItems: (data) => data?.lotacoesListaCuidadoCompartilhado.map(convertToModel),
    variables: (query) => ({
      input: {
        isLotacoesSolicitantes,
        role,
        query,
      },
    }),
    debounceTime: 500,
  })

  const render = (item: LotacaoCuidadaoCompartilhadoSelectFieldModel) => {
    return (
      <VFlow vSpacing={0}>
        <Text fontWeight='bold'>
          {item.profissional.nome} - <Cpf value={item.profissional.cpf} />
        </Text>
        <HLabel title='CBO '>{`${item.cbo.nome} - ${item.cbo.cbo2002}`}</HLabel>
        <HLabel title='INE '> {(item.equipe && `${item.equipe.nome} - ${item.equipe.ine}`) || 'Sem equipe'}</HLabel>
        <HLabel title='Unidade de saúde '>
          {item.unidadeSaude.nome} - {item.unidadeSaude.cnes}
        </HLabel>
      </VFlow>
    )
  }

  return (
    <SelectField<LotacaoCuidadaoCompartilhadoSelectFieldModel>
      itemToString={itemToString}
      renderItem={render}
      components={{
        EmptyItem: () => (
          <SelectHelperMenuItem>
            {skipping ? 'Digite para buscar um profissional.' : locale.select.emptyItem}
          </SelectHelperMenuItem>
        ),
      }}
      {...selectProps}
      {...rest}
    />
  )
}

const itemToString = (item: LotacaoCuidadaoCompartilhadoSelectFieldModel) => item && item.profissional.nome

const convertToModel = (
  lotacao: LotacaoCuidadoCompartilhadoSelectFieldQueryModel
): LotacaoCuidadaoCompartilhadoSelectFieldModel => ({
  id: lotacao.id,
  cbo: lotacao.cbo,
  equipe: lotacao.equipe,
  profissional: lotacao.profissional,
  unidadeSaude: lotacao.unidadeSaude,
})
